import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import i18n from "hel/i18n";
import LoginAuth from "services/LoginAuth";

var ps;

function Sidebar(props) {
  const sidebar = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname === routeName ? "active" : "";
  };
  const layout_turul = LoginAuth.layoutTurulAvah();
  const erh_hyzgaar = LoginAuth.erhAvah() === "admin" ? false : true;
  const service_subsc = LoginAuth.serviceSubscAvah();
  const baiguullaga_info = LoginAuth.baiguullagaInfoAvah();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const menuDarahad = () => {
    if (window.innerWidth < 992) {
      document.getElementById("mySidebar").style.display = "none";
      document.getElementById("myMainPanel").style.width = "100%";
    }
  };

  return (
    <div
      className="sidebar"
      id="mySidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
      onClick={menuDarahad}
    >
      <div className="header">
        <div
          style={{ color: "var(--grading-secondary-2)", cursor: "pointer" }}
          onClick={() => {
            props.history.push({ pathname: "/" });
          }}
        >
          YouSubsc Business
        </div>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {
            if (prop.invisible) return null;
            if (prop.nuuh_layout === layout_turul) return null;
            if (prop.erh_hyzgaar && erh_hyzgaar) return null;
            if (prop.is_subsc === true && service_subsc.subsc_service === false)
              return null;
            if (
              prop.is_tsag === true &&
              service_subsc.tsag_zahialga_service === false
            )
              return null;
            if (
              baiguullaga_info.tuluv !== "active" &&
              (prop.path === "/service/subscription/tickets" ||
                prop.path === "/service/subscriptions" ||
                prop.path === "/cards" ||
                prop.path === "/service/subscription/history" ||
                prop.path === "/niitGuilgee")
            ) {
              return null;
            }
            return (
              <li className={activeRoute(prop.path)} key={key}>
                <NavLink
                  to={prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <div className="neg-morond">
                    {/* <i className={prop.icon} /> */}
                    <i
                      className={
                        activeRoute(prop.path) === "active"
                          ? "bi bi-caret-right"
                          : "bi bi-arrow-right-short"
                      }
                    />
                    <p>{i18n.t(prop.name)}</p>
                  </div>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
