import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import baiguullagaReducer from "./baiguullagaReducer";
import hereglegchReducer from "./hereglegchReducer";
import burtgelReducer from "./burtgelReducer";
import uilajillagaaReducer from "./uilajillagaaReducer";
import stripeReducer from "./stripeReducer";
import ssubscReducer from "./ssubscReducer"

const rootReducer = combineReducers({
  form: formReducer,
  baiguullagaReducer: baiguullagaReducer,
  hereglegchReducer: hereglegchReducer,
  burtgelReducer: burtgelReducer,
  uilajillagaaReducer: uilajillagaaReducer,
  ssubscReducer:ssubscReducer,
  stripeReducer: stripeReducer,
});

export default rootReducer;
