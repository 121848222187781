import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import Husnegt from "components/Husnegt";
import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  shireeJagsaaltFn,
  shireeBurtgelFn,
} from "../../../store/actions/baiguullaga.actions";
import { validEseh, ylgaataiEseh } from "validation";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
import { togtmol } from "delgetsuud/zagvar";

class Shiree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout_turul: LoginAuth.layoutTurulAvah(),
      flag: 0,
      medegdel: false,

      dialogTuluv: false,
      songogdsonMor: {},
      songogdsonIndex: 0,
      garchignuud: [
        // {
        //   ner: "profile_zurag_dugaar",
        //   garchig: "zurag",
        //   turul: "zurag",
        //   validations: {
        //     validation: ["isZaaval"],
        //   },
        // },
        {
          ner: "ner",
          garchig: "shiree_dugaar",

          validations: { validation: ["isZaaval", "isDanToo"] },
        },
        {
          ner: "hun_min",
          garchig: "min_hunii_too",

          validations: { validation: ["isZaaval", "isDanToo"] },
        },
        {
          ner: "hun_max",
          garchig: "max_hunii_too",

          validations: { validation: ["isZaaval", "isDanToo"] },
        },
        {
          ner: "undsen_tulbur",
          garchig: "zahialga_une",
          nemelt_tailbar: LoginAuth.baiguullagaInfoAvah().temdegt,
          nuuh: !LoginAuth.serviceSubscAvah().preperation_fee_service,
          orolt_nuuh: !LoginAuth.serviceSubscAvah().preperation_fee_service,
          validations: {
            validation:
              LoginAuth.serviceSubscAvah().preperation_fee_service === false
                ? []
                : ["isZaaval", "isMongo"],
            utgaMin:
              LoginAuth.tulburinTurulAvah() === 2
                ? togtmol.tsag_tulbur_min
                : undefined,
            utgaMax:
              LoginAuth.tulburinTurulAvah() === 2
                ? togtmol.tsag_tulbur_max
                : undefined,
          },
        },
        {
          ner: "tuluv",
          garchig: "tuluv",
          turul: "switch",
        },
      ],
      shiree_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      countActive: 0,
      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
    };
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    this.props.shireeJagsaaltFn();
  }
  static getDerivedStateFromProps(props, state) {
    if (props.shiree_jagsaalt !== state.shiree_jagsaalt) {
      let shiree_jagsaalt = props.shiree_jagsaalt ? props.shiree_jagsaalt : [];

      let countActive = 0;
      shiree_jagsaalt.map((utga) => {
        if (utga.tuluv === "inactive") {
          utga.tuluv = false;
        } else {
          utga.tuluv = true;
          countActive++;
        }
        if (state.serviceSubsc_info.preperation_fee_service === false) {
          utga.undsen_tulbur = 0;
        }
        return null;
      });
      return {
        shiree_jagsaalt: props.shiree_jagsaalt,
        utguud: shiree_jagsaalt,
        countActive,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    if (
      this.state.serviceSubsc_info.preperation_fee_service === false &&
      ner === "undsen_tulbur"
    ) {
      songogdsonMor[ner] = 0;
      validationHariu[ner] = "";
    }
    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor:
        this.state.dialogTuluv === true ? {} : { ...this.state.songogdsonMor },
      validationHariu: {},
      medegdel: false,
    });
    this.props.shireeJagsaaltFn();
  }

  husnegtTovchDaragdahad(utga, index) {
    this.setState(
      {
        songogdsonIndex: index,
        songogdsonMor: utga,
      },
      function () {
        this.dialogToggle();
      }
    );
  }
  hadgalahDarahad() {
    let { songogdsonMor, serviceSubsc_info, countActive } = this.state;
    this.setState({ unshijBaigaa: true });
    if (
      serviceSubsc_info.preperation_fee_service === false &&
      serviceSubsc_info.service_subsc_staff_count <= countActive &&
      songogdsonMor.tuluv === true
    ) {
      this.setState({
        dialogTuluv: false,
        songogdsonMor: {},
        validationHariu: {},
        unshijBaigaa: false,
        medegdel: true,
        flag: 2,
      });
    } else {
      try {
        this.props.shireeBurtgelFn(songogdsonMor).then((hariu) => {
          if (hariu.amjilt === true) {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 0,
              unshijBaigaa: false,
            });
            this.props.shireeJagsaaltFn();
          } else if (hariu.message === "hyzgaar error") {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 3,
              unshijBaigaa: false,
            });
          } else {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
        });
      } catch (aldaa) {
        this.setState({
          dialogTuluv: false,
          songogdsonMor: {},
          validationHariu: {},
          medegdel: true,
          flag: 1,
          unshijBaigaa: false,
        });
      }
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  render() {
    const {
      validationHariu,
      garchignuud,
      utguud,
      dialogTuluv,
      songogdsonMor,
      songogdsonIndex,
      flag,
      medegdel,
      unshijBaigaa,
      layout_turul,
      erh_hyzgaar,
      serviceSubsc_info,
    } = this.state;
    if (
      layout_turul === 2 ||
      serviceSubsc_info.tsag_zahialga_service === false ||
      erh_hyzgaar === true
    ) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              {serviceSubsc_info.tulbur_tsag_zahialga_service === true && (
                <Row>
                  <Col md="9" />
                  <Col md="3" align="right">
                    <Button
                      className="ungu-primary"
                      round="true"
                      outline
                      onClick={this.dialogToggle}
                    >
                      {i18n.t("nemeh")}
                    </Button>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                    {flag === 2 && (
                      <span>{i18n.t("medegdel_aldaa_staff_count")}</span>
                    )}
                    {flag === 3 && (
                      <span>{i18n.t("medegdel_aldaa_table_hyzgaar")}</span>
                    )}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    tovchtoi_eseh={
                      serviceSubsc_info.tulbur_tsag_zahialga_service === true
                        ? true
                        : false
                    }
                    tovch_daragdahad={this.husnegtTovchDaragdahad}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            {dialogTuluv === true && (
              <Form>
                {garchignuud.map((orolt, index) => {
                  return (
                    <GarchigtaiOrolt
                      key={index}
                      index={index}
                      orolt={orolt}
                      utgaAvah={this.modalUtgaOorchlogdohod}
                      utga={songogdsonMor[orolt.ner]}
                      validationHariu={validationHariu[orolt.ner]}
                    />
                  );
                })}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="ungu-primary"
              onClick={this.hadgalahDarahad}
              disabled={
                !validEseh(garchignuud, songogdsonMor) ||
                !ylgaataiEseh(songogdsonMor, utguud[songogdsonIndex])
              }
            >
              {i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { shiree_jagsaalt } = state.baiguullagaReducer;
  return {
    shiree_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      shireeJagsaaltFn,
      shireeBurtgelFn,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shiree));
