import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  subscJagsaaltFn,
  subscBurtgelFn,
  subscDeactivateFn,
} from "../../../store/actions/baiguullaga.actions";
import { itemZuragFn } from "../../../store/actions/zurag.actions";
import { validationUrt, ylgaataiEseh, validEseh } from "validation";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
import { togtmol } from "delgetsuud/zagvar";
import tohirgoo from "tohirgoo";
import currency from "currency.js";

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      medegdel: false,
      subsc_service: LoginAuth.serviceSubscAvah().subsc_service,
      temdegt: LoginAuth.baiguullagaInfoAvah().temdegt,
      dialogTuluv: false,
      songogdsonMor: {},
      songogdsonIndex: 0,
      garchignuud: [
        {
          ner: "zurag_dugaar",
          garchig: "zurag",
          turul: "zurag",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "ner",
          garchig: "ner",
          validations: {
            validation: ["isZaaval", "isTemdegtgui"],
            urtMax: validationUrt.string,
          },
        },
        // {
        //   ner: "tuluv",
        //   garchig: "tuluv",
        //   turul: "switch",
        // },
        // {
        //   ner: "udur_limit",
        //   garchig: "udur_limit",
        //   validations: {
        //     validation: ["isDanToo", "isZaaval"],
        //   },
        // },
        {
          ner: "sar_limit",
          garchig: "sar_limit",
          validations: {
            validation: ["isDanToo", "isZaaval"],
            urtMax: validationUrt.turul_kod,
          },
        },
        {
          ner: "nas_asuudag_eseh",
          garchig: "nas_asuudag_eseh_subsc",
          turul: "switch",
        },
        {
          ner: "hyzgaarlah_nas",
          garchig: "hyzgaarlah_nas",
          nuuh_flag: true,
          validations: {
            validation: ["isDanToo"],
            urtMax: validationUrt.turul_kod,
          },
        },
        {
          ner: "negj_une",
          garchig: "negj_une",
          nemelt_tailbar: LoginAuth.baiguullagaInfoAvah().temdegt,
          validations: {
            validation: ["isZaaval", "isMongo"],
          },
        },
        {
          ner: "negj_une_undsen",
          garchig: "negj_une_undsen",
          nemelt_tailbar: LoginAuth.baiguullagaInfoAvah().temdegt,
          validations: {
            validation: ["isZaaval", "isMongo"],
          },
        },
        {
          ner: "discount_percent",
          garchig: "discount_percent",
          validations: {
            validation: ["isZaaval", "isDanToo"],
            utgaMin: togtmol.subsc_min_discnt_per,
          },
          disabled: true,
        },
        {
          ner: "une",
          garchig: "niit_une",
          nemelt_tailbar: LoginAuth.baiguullagaInfoAvah().temdegt,
          validations: {
            validation: ["isZaaval", "isMongo"],
            utgaMin: togtmol.subsc_tulbur_min,
            utgaMax: togtmol.subsc_tulbur_max
          },
          disabled: true,
        },
        {
          garchig: "local_tax",
          ner: "local_tax_rate",
          placeholder: "",
          nuuh_flag:
            LoginAuth.baiguullagaInfoAvah().uls_id === 3 ? false : true,
          validations: {
            validation:
              LoginAuth.baiguullagaInfoAvah().uls_id === 3
                ? ["isHuvi", "isZaaval"]
                : [],
          },
          warning_text: i18n.t("local_tax_warning"),
        },
        {
          ner: "medeelel",
          garchig: "medeelel",
          turul: "textarea",
          validations: {
            validation: ["isZaaval", "isTemdegttei"],
            urtMax: validationUrt.tailbar_bogino,
          },
        },
        {
          ner: "times_text",
          garchig: "times_text_label",
          validations: {
            validation: ["isDanTextSpacetei"],
            urtMax: validationUrt.string,
          },
          placeholder: i18n.t("times_text"),
        },
        // {
        //   ner: "eco_tailbar",
        //   garchig: "tailbar",
        //   turul: "textarea",
        //   validations: {
        //     validation: ["isZaaval", "isTemdegttei"],
        //     urtMax: validationUrt.tailbar_urt,
        //   },
        // },
      ],
      subsc_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
      popupTuluv: false,
      baiguullaga_tuluv: LoginAuth.baiguullagaInfoAvah().tuluv,
    };
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.subscSongohod = this.subscSongohod.bind(this);
    this.popupNeeh = this.popupNeeh.bind(this);
    this.deactivateDarahad = this.deactivateDarahad.bind(this);
  }

  componentDidMount() {
    this.props.subscJagsaaltFn();
  }
  static getDerivedStateFromProps(props, state) {
    if (props.subsc_jagsaalt !== state.subsc_jagsaalt) {
      let subsc_jagsaalt = props.subsc_jagsaalt ? props.subsc_jagsaalt : [];
      subsc_jagsaalt.map((utga) => {
        if (!!utga.local_tax_rate && parseFloat(utga.local_tax_rate) > 0) {
          utga.local_tax_rate = currency(Number(utga.local_tax_rate) * 100, {
            precision: 3,
            separator: "",
          });
        }
        return null;
      });

      subsc_jagsaalt.sort((a, b) =>
        a.buteegdehuun_dugaar > b.buteegdehuun_dugaar ? 1 : -1
      );
      return {
        subsc_jagsaalt: props.subsc_jagsaalt,
        utguud: subsc_jagsaalt,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;
    if (ner === "negj_une") {
      const mymonthly = songogdsonMor.sar_limit ? songogdsonMor.sar_limit : 1;
      // const myune = parseFloat(utga) * parseFloat(mymonthly);
      const myune = currency(Number(utga)).multiply(Number(mymonthly)).value;
      songogdsonMor.une = myune;
      if (
        myune > togtmol.subsc_tulbur_max ||
        myune < togtmol.subsc_tulbur_min
      ) {
        validationHariu["une"] = {
          isValid: false,
          message: i18n.t("validation_subsc_item_price_limit", {
            min: togtmol.subsc_tulbur_min,
            max: togtmol.subsc_tulbur_max,
          }),
        };
      } else {
        validationHariu["une"] = {};
      }
      const negj_une_undsen = songogdsonMor.negj_une_undsen
        ? songogdsonMor.negj_une_undsen
        : 0;
      if (negj_une_undsen !== 0) {
        // ashiglahgu baiga
        const percent = Math.floor(
          100 - (parseFloat(utga) * 100) / parseFloat(negj_une_undsen)
        );
        songogdsonMor.discount_percent = percent;
      } else {
        songogdsonMor.discount_percent = 0;
      }

      if (songogdsonMor.discount_percent < togtmol.subsc_min_discnt_per) {
        validationHariu["discount_percent"] = {
          isValid: false,
          message: i18n.t("price_discount_alert", {
            discount: songogdsonMor.discount_percent,
          }),
        };
      } else {
        validationHariu["discount_percent"] = {};
      }



    }
    if (ner === "sar_limit") {
      const mynegjune = songogdsonMor.negj_une ? songogdsonMor.negj_une : 0;
      // const myune = parseFloat(utga) * parseFloat(mynegjune);
      const myune = currency(Number(utga)).multiply(Number(mynegjune)).value;
      songogdsonMor.une = myune;
      if (
        myune > togtmol.subsc_tulbur_max ||
        myune < togtmol.subsc_tulbur_min
      ) {
        validationHariu["une"] = {
          isValid: false,
          message: i18n.t("validation_subsc_item_price_limit", {
            min: togtmol.subsc_tulbur_min,
            max: togtmol.subsc_tulbur_max,
          }),
        };
      } else {
        validationHariu["une"] = {};
      }
    }
    if (ner === "negj_une_undsen") {
      const negj_une = songogdsonMor.negj_une ? songogdsonMor.negj_une : 0;
      if (negj_une !== 0) {
        //ashiglahgui bga
        const percent = Math.floor(
          100 - (parseFloat(negj_une) * 100) / parseFloat(utga)
        );
        songogdsonMor.discount_percent = percent;
      } else {
        songogdsonMor.discount_percent = 0;
      }

      if (songogdsonMor.discount_percent < togtmol.subsc_min_discnt_per) {
        validationHariu["discount_percent"] = {
          isValid: false,
          message: i18n.t("price_discount_alert", {
            discount: songogdsonMor.discount_percent,
          }),
        };
      } else {
        validationHariu["discount_percent"] = {};
      }
    }
    
    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor:
        this.state.dialogTuluv === true ? {} : { ...this.state.songogdsonMor },
      validationHariu: {},
      medegdel: false,
    });
    this.props.subscJagsaaltFn();
  }
  popupNeeh() {
    this.setState({
      dialogTuluv: false,
      popupTuluv: !this.state.popupTuluv,
      medegdel: false,
    });
  }
  deactivateDarahad() {
    const { songogdsonMor } = this.state;
    this.props
      .subscDeactivateFn({
        buteegdehuun_dugaar: songogdsonMor.buteegdehuun_dugaar,
      })
      .then((hariu) => {
        console.log(
          "ustgah hariu",
          hariu,
          "hariu.amjilt === true :",
          hariu.amjilt === true
        );
        this.props.subscJagsaaltFn();
        if (hariu.amjilt === true) {
          this.setState({
            popupTuluv: false,
            songogdsonMor: {},
            medegdel: true,
            flag: 2,
          });
        } else if (hariu.message === "refreshDate") {
          this.setState({
            popupTuluv: false,
            songogdsonMor: {},
            medegdel: true,
            flag: 5,
          });
        } else {
          this.setState({
            popupTuluv: false,
            songogdsonMor: {},
            medegdel: true,
            flag: 1,
          });
        }
      });
  }
  subscSongohod = (subsc) => {
    this.setState({
      songogdsonMor: subsc,
      dialogTuluv: true,
    });
  };
  async hadgalahDarahad() {
    const { songogdsonMor } = this.state;
    this.setState({ unshijBaigaa: true });
    try {
      // songogdsonMor.zurag_dugaar = "43-404qr7kl528vtxj";
      if (
        songogdsonMor.zurag_dugaar &&
        songogdsonMor.zurag_dugaar.slice(0, 10) === "data:image"
      ) {
        const hariu = await this.props.itemZuragFn(songogdsonMor.zurag_dugaar);
        if (hariu.amjilt === true) {
          songogdsonMor.zurag_dugaar = hariu.imageid;
        } else {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 1,
            unshijBaigaa: false,
          });
          return;
        }
      }
      this.props.subscBurtgelFn(songogdsonMor).then((hariu) => {
        console.log("hariu:", hariu);
        if (hariu.amjilt === true) {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 0,
            unshijBaigaa: false,
          });
          this.props.subscJagsaaltFn();
        } else if (hariu.message === "hyzgaar error") {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 3,
            unshijBaigaa: false,
          });
        } else if (hariu.message === "hyzgaar error price") {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 4,
            unshijBaigaa: false,
          });
        } else {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 1,
            unshijBaigaa: false,
          });
        }
      });
    } catch (aldaa) {
      this.setState({
        dialogTuluv: false,
        songogdsonMor: {},
        validationHariu: {},
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  render() {
    const {
      validationHariu,
      garchignuud,
      utguud,
      dialogTuluv,
      songogdsonMor,
      songogdsonIndex,
      flag,
      medegdel,
      unshijBaigaa,
      erh_hyzgaar,
      subsc_service,
      serviceSubsc_info,
      popupTuluv,
      temdegt,
      baiguullaga_tuluv,
    } = this.state;
    if (erh_hyzgaar === true || subsc_service === false) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 || flag === 2 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                    {flag === 2 && (
                      <span>{i18n.t("medegdel_amjilt_ustsan")}</span>
                    )}
                    {flag === 3 && (
                      <span>{i18n.t("medegdel_amjilt_subsc_hyzgaar")}</span>
                    )}
                    {flag === 4 &&
                      i18n.t("validation_subsc_item_price_limit", {
                        min: togtmol.subsc_tulbur_min,
                        max: togtmol.subsc_tulbur_max,
                      })}
                    {flag === 5 &&
                      i18n.t("medegdel_aldaa_item_deactivate_refreshdate")}
                  </Alert>
                </Col>
              </Row>
              {serviceSubsc_info.tulbur_subsc_service === true &&
                baiguullaga_tuluv === "active" && (
                  <Row>
                    <Col md="9" />
                    <Col md="3" align="right">
                      <Button
                        className="ungu-primary"
                        round="true"
                        outline
                        onClick={this.dialogToggle}
                      >
                        {i18n.t("nemeh")}
                      </Button>
                    </Col>
                  </Row>
                )}
            </CardBody>
          </Card>
        </div>
        <div>
          <Row>
            {utguud.map((subsc, index) => {
              return (
                <Col md="4" key={index}>
                  <Card
                    onClick={() => {
                      if (serviceSubsc_info.tulbur_subsc_service === true) {
                        this.subscSongohod(subsc);
                      }
                    }}
                  >
                    <CardBody>
                      <Row>
                        <Col md="4">
                          <img
                            onError={(ev) => {
                              ev.target.src = "/apple-icon.png"; //default zurag zam zaah
                            }}
                            src={
                              subsc.zurag_dugaar
                                ? tohirgoo.img_url +
                                  "item/" +
                                  subsc.zurag_dugaar
                                : "/apple-icon.png" //default zurag zam zaah
                            }
                            style={{
                              height: "auto",
                              width: 80 + "%",
                              resize: "both",
                              objectFit: "cover",
                              objectPosition: "25% 50%",
                            }}
                            alt={subsc}
                          />
                        </Col>
                        <Col md="8">
                          <Row>
                            <Col>
                              {i18n.t("ner")}:{" "}
                              <span className="text-bold">{subsc.ner}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {i18n.t("sar_limit")}:{" "}
                              <span className="text-bold">
                                {subsc.sar_limit}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {i18n.t("negjiin_une")}:{" "}
                              <span className="text-bold">
                                {subsc.negj_une} {temdegt}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {i18n.t("commition_fee")}:{" "}
                              <span className="text-bold">
                                {subsc.service_fee} {temdegt}
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              {i18n.t("sum_before_tax")}:{" "}
                              <span className="text-bold">
                                {
                                  currency(subsc.une).add(subsc.service_fee)
                                    .value
                                }{" "}
                                {temdegt}
                              </span>
                            </Col>
                          </Row>

                          {subsc.pst && subsc.pst > 0 && (
                            <Row>
                              <Col>
                                {i18n.t("PST")}:{" "}
                                <span className="text-bold">
                                  {subsc.pst} {temdegt}
                                </span>
                              </Col>
                            </Row>
                          )}
                          {subsc.qst && subsc.qst > 0 && (
                            <Row>
                              <Col>
                                {i18n.t("QST")}:{" "}
                                <span className="text-bold">
                                  {subsc.qst} {temdegt}
                                </span>
                              </Col>
                            </Row>
                          )}
                          {subsc.hst && subsc.hst > 0 && (
                            <Row>
                              <Col>
                                {i18n.t("HST")}:{" "}
                                <span className="text-bold">
                                  {subsc.hst} {temdegt}
                                </span>
                              </Col>
                            </Row>
                          )}
                          {subsc.gst && subsc.gst > 0 && (
                            <Row>
                              <Col>
                                {i18n.t("GST")}:{" "}
                                <span className="text-bold">
                                  {subsc.gst} {temdegt}
                                </span>
                              </Col>
                            </Row>
                          )}
                          {subsc.local_tax && subsc.local_tax > 0 && (
                            <Row>
                              <Col>
                                {i18n.t("local_tax")}:{" "}
                                <span className="text-bold">
                                  {subsc.local_tax} {temdegt}
                                </span>
                              </Col>
                            </Row>
                          )}
                          {subsc.state_tax && subsc.state_tax > 0 && (
                            <Row>
                              <Col>
                                {i18n.t("state_tax")}:{" "}
                                <span className="text-bold">
                                  {subsc.state_tax} {temdegt}
                                </span>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>
                              {i18n.t("niit_une")}:{" "}
                              <span className="text-bold">
                                {subsc.total_gross}
                                {temdegt}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            {dialogTuluv === true && (
              <Form>
                {garchignuud.map((orolt, index) => {
                  return (
                    <GarchigtaiOrolt
                      key={index}
                      index={index}
                      orolt={orolt}
                      utgaAvah={this.modalUtgaOorchlogdohod}
                      utga={songogdsonMor[orolt.ner]}
                      disabled={
                        !!songogdsonMor.buteegdehuun_dugaar ? true : false
                      }
                      validationHariu={validationHariu[orolt.ner]}
                      nuuh_nohtsol={{
                        hyzgaarlah_nas: !songogdsonMor.nas_asuudag_eseh,
                      }}
                    />
                  );
                })}{" "}
                {!!songogdsonMor.buteegdehuun_dugaar && (
                  <Row>
                    <Col lg="1" md="0" sm="0" xs="0" />
                    <Col lg="10" md="11" sm="12" xs="12">
                      <div style={{ textAlign: "center" }}>
                        <Button
                          className="ungu-primary"
                          size="sm"
                          outline
                          onClick={this.popupNeeh}
                        >
                          {i18n.t("subscription_deactivate")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="ungu-primary"
              onClick={this.hadgalahDarahad}
              disabled={
                !validEseh(garchignuud, songogdsonMor) ||
                !ylgaataiEseh(songogdsonMor, utguud[songogdsonIndex])
              }
            >
              {i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={popupTuluv} toggle={this.popupNeeh}>
          <ModalHeader />
          <ModalBody>
            <div style={{ textAlign: "center", padding: togtmol.jijig_zai }}>
              <br />
              {i18n.t("subsc_deactivate_asuult_text")}
            </div>
          </ModalBody>
          <ModalFooter>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                style={{ width: "48%" }}
                color="secondary"
                onClick={this.popupNeeh}
              >
                {i18n.t("bolih")}
              </Button>{" "}
              <Button
                style={{ width: "48%" }}
                className="ungu-primary"
                onClick={this.deactivateDarahad}
              >
                {i18n.t("urgeljluuleh")}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { subsc_jagsaalt } = state.baiguullagaReducer;
  return {
    subsc_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      subscJagsaaltFn,
      subscBurtgelFn,
      itemZuragFn,
      subscDeactivateFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscription)
);
