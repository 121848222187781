import React, { Component } from "react";
// javascript plugin used to create scrollbars on windows
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { Fragment } from "react";
import LoginAuth from "services/LoginAuth";
import Banner from "components/Banner";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: LoginAuth.getIdToken(),
      me: LoginAuth.meAvah(),
    };

    console.log = function(w) {return;};
    console.time = function(w) {return;};
    console.timeEnd = function(w) {return;};
  }
  render() {
    const { token, me } = this.state;
    if (!!!token) {
      return <Redirect to="/login" />;
    } else if (!!!me) {
      return <Redirect to="/baiguullaga/burtgel" />;
    }
    return (
      <Fragment>
        <div className="wrapper">
          <Banner {...this.props} />
          <Sidebar
            {...this.props}
            routes={routes}
            bgColor={"white"}
            activeColor={"primary"}
          />
          <div className="main-panel" id="myMainPanel">
            <Navbar {...this.props} />
            <Switch>
              {routes.map((prop, key) => {
                return (
                  <Route
                    exact
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(App);
