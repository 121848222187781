import i18n from "hel/i18n";
import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

class SongoltBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myUtga: -1,
    };
    this.songohDarahad = this.songohDarahad.bind(this);
  }
  songohDarahad(event) {
    const index = event.target.value;
    this.setState({
      myUtga: index,
    });
    this.props.songohod(index);
  }
  render() {
    const songoltUtga = !!this.props.songoltUtga ? this.props.songoltUtga : [];
    const garchig = !!this.props.garchig ? this.props.garchig : "";
    const hevleh = !!this.props.hevleh ? this.props.hevleh : "ner";
    const index = !!this.props.utga
      ? songoltUtga.indexOf(this.props.utga)
      : this.state.myUtga;
    const disabled = !!this.props.disabled ? this.props.disabled : false;
    const isValid =
      this.props.isValid !== undefined ? this.props.isValid : true;
    const validationMessage = !!this.props.validationMessage
      ? this.props.validationMessage
      : "";
    const songolt_tailbar = !!this.props.songolt_tailbar
      ? this.props.songolt_tailbar
      : "";
    return (
      <FormGroup>
        <Label>{i18n.t(garchig)}</Label>
        <Input
          type="select"
          name="select"
          onChange={this.songohDarahad}
          value={index}
          disabled={disabled}
          invalid={!isValid}
        >
          <option key={-1} value={-1}>
            -
          </option>
          {songoltUtga.length > 0 &&
            songoltUtga.map((songolt, index) => {
              return (
                <option key={index} value={index}>
                  {songolt[hevleh]}
                </option>
              );
            })}
        </Input>
        <FormFeedback>{validationMessage}</FormFeedback>
        {songolt_tailbar !== "" && (
          <Label style={{ marginTop: 5 }}>{songolt_tailbar}</Label>
        )}
      </FormGroup>
    );
  }
}

export default SongoltBox;
