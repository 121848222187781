import { uilajillagaaConstants } from "store/constants";
import { hereglegchConstants } from "store/constants";
import { API } from "services/api";

export const zahialgaJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/zahialga/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: uilajillagaaConstants.ZAHIALGA_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "zahialgaJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const zahialgaTuuhJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/zahialga/tuuh/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: uilajillagaaConstants.ZAHIALGA_TUUH_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "zahialgaTuuhJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const ajiltanChuluuJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/ajiltan_chuluu_jagsaalt/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: uilajillagaaConstants.AJILTAN_CHULUU_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "ajiltanChuluuJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const ajiltanChuluuBurtgehFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/burtgel/chuluu_oruulah`, {
        ajiltan_id: state.ajiltan_id,
        chuluu_date: state.chuluu_date,
        chuluu_ehleh: state.chuluu_ehleh,
        chuluu_duusah: state.chuluu_duusah,
        tailbar: state.tailbar,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const dotroosTsagJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/dotroos_zahialga/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: uilajillagaaConstants.DOTROOS_TSAG_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "dotroosTsagJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const dotroosTsagBurtgehFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/hariltsagch/zahialga/uusgeh`, {
        ehleh_tsag: state.ehleh_tsag,
        duusah_tsag: state.duusah_tsag,
        ognoo: state.zahialsan_ognoo,
        ajiltan_id: state.ajiltan_ner.ajiltan_id,
        uilchilgee_id: state.uilchilgee_ner.uilchilgee_id,
        ner: state.hereglegch_ner,
        utas: state.hereglegch_utas,
        tailbar: state.tailbar,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
export const tulburTootsooJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/tulbur`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: uilajillagaaConstants.TULBUT_TOOTSOO_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "tulburTootsooJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};
export const tulburTootsooHuseltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/baiguullaga/huselt/tulbur`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const undsenJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/undsen/zahialga`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: uilajillagaaConstants.UNDSEN_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "undsenJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const niitZahialgaJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/niit_guilee/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: uilajillagaaConstants.NIIT_ZAHIALGA_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "niitZahialgaJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};
