import { togtmol } from "delgetsuud/zagvar";
import i18n from "hel/i18n";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  CustomInput,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { baiguullagaMedeelelBurtgelFn } from "store/actions/burtgel.actions";
import {
  shopZuragFn,
  shireeZuragFn,
  tsesZuragFn,
} from "store/actions/zurag.actions";
import { hereglegchMe } from "store/actions/hereglegch.actions";
import LoginAuth from "services/LoginAuth";
import MapPicker from "react-google-map-picker";
import tohirgoo from "tohirgoo";
import { validShalgah } from "validation";
import { validationUrt } from "validation";
import MyLoader from "components/MyLoader";
import currency from "currency.js";
import SongoltBox from "components/SongoltBox";

class BaiguullagaTab2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      medegdel: false,
      shalgalt: {
        ner: {
          validation: ["isZaaval", "isTemdegtgui"],
          urtMax: validationUrt.string,
        },
        haih_tovch_ner: {
          validation: ["isTemdegtgui"],
          urtMax: validationUrt.string,
        },
        utas: {
          validation: ["isUtas"],
        },
        // email: {
        //   validation: ["isZaaval", "isEmail"],
        //   urtMax: validationUrt.string,
        // },
        delgerengui_medeelel: {
          validation: ["isZaaval", "isTemdegttei"],
          urtMax: validationUrt.tailbar_urt,
        },
        hayag: {
          validation: ["isZaaval", "isTemdegttei"],
          urtMax: validationUrt.urt_string,
        },
        zahialga_umnuh_tsag: {
          validation: ["isZaaval", "isDanToo"],
          urtMax: validationUrt.turul_kod,
        },
        zahialga_avah_honog_limit: {
          validation: ["isZaaval", "isDanToo"],
          urtMax: validationUrt.turul_kod,
        },
        durem: {
          validation: ["isZaaval", "isTemdegttei"],
          urtMax: validationUrt.tailbar_urt,
        },
        shiree_muchlug: {
          validation:
            LoginAuth.layoutTurulAvah() === 1
              ? ["isZaaval", "isDanToo"]
              : ["isDanToo"],
          urtMax: validationUrt.turul_kod,
          utgaMin: togtmol.muchlug_min,
        },
        hst_dugaar: {
          validation: ["isTemdegttei"],
          urtMax: validationUrt.bogino_string,
        },
        pst_dugaar: {
          validation: ["isTemdegttei"],
          urtMax: validationUrt.bogino_string,
        },
        qst_dugaar: {
          validation: ["isTemdegttei"],
          urtMax: validationUrt.bogino_string,
        },
        gst_dugaar: {
          validation: ["isTemdegttei"],
          urtMax: validationUrt.bogino_string,
        },
        baiguullaga_temdegt_zurag_id: {
          validation: ["isZaaval"],
        },
        tses_zurag_id: {
          validation: LoginAuth.layoutTurulAvah() === 1 ? ["isZaaval"] : [],
        },
        shiree_bairlal_zurag_id: {
          validation: LoginAuth.layoutTurulAvah() === 1 ? ["isZaaval"] : [],
        },
        hyzgaarlah_nas: {
          validation: ["isDanToo"],
          urtMax: validationUrt.string,
        },
        dans_ner: {
          validation: ["isZaaval", "isTemdegtgui"],
          urtMax: validationUrt.urt_string,
        },
        bank_ner: {
          validation: ["isZaaval", "isTemdegtgui"],
          urtMax: validationUrt.urt_string,
        },
        branch_ner: {
          validation: ["isZaaval", "isTemdegtgui"],
          urtMax: validationUrt.urt_string,
        },
        branch_code: {
          validation: ["isDanToo"],
          urtMax: validationUrt.urt_string,
        },
        dans_dugaar: {
          validation: ["isZaaval", "isDanToo"],
          urtMax: validationUrt.urt_string,
        },
        local_tax: {
          validation:
            LoginAuth.baiguullagaInfoAvah().uls_id === 3
              ? ["isZaaval", "isHuvi"]
              : [],
        },
        tsagiin_bus_id: {
          validation: ["isZaaval"],
        },
        // company_name: {
        //   validation: ["isZaaval", "isTemdegtgui"],
        //   urtMax: validationUrt.string,
        // },
        // company_number: {
        //   validation: ["isZaaval", "isTemdegtgui"],
        //   urtMax: validationUrt.string,
        // },
      },
      lastcheck: [
        "ner",
        "haih_tovch_ner",
        "utas",
        // "email",
        "delgerengui_medeelel",
        "hayag",
        "zahialga_umnuh_tsag",
        "zahialga_avah_honog_limit",
        "durem",
        "shiree_muchlug",
        "hst_dugaar",
        "pst_dugaar",
        "qst_dugaar",
        "gst_dugaar",
        "baiguullaga_temdegt_zurag_id",
        "tses_zurag_id",
        "shiree_bairlal_zurag_id",
        "hyzgaarlah_nas",
        "dans_ner",
        "bank_ner",
        "branch_ner",
        "branch_code",
        "dans_dugaar",
        "local_tax",
        "tsagiin_bus_id",
      ],
      validationHariu: [],
      zasah: false,
      baiguullaga_info: LoginAuth.baiguullagaInfoAvah(),
      layout_turul: LoginAuth.layoutTurulAvah(),
      unshijBaigaa: false,
      tsagiin_bus_jagsaalt: LoginAuth.tmzone_avah(),
      tsagiin_bus_id_old: LoginAuth.baiguullagaInfoAvah().tsagiin_bus_id,
      tmzoneAsuuhShow: false,
    };
    this.tovchDarahad = this.tovchDarahad.bind(this);
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.bairlalOorchlogdohod = this.bairlalOorchlogdohod.bind(this);
    this.switchDarahad = this.switchDarahad.bind(this);
    this.zuragSongohod = this.zuragSongohod.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.bolihDarahad = this.bolihDarahad.bind(this);
    this.songohod = this.songohod.bind(this);
  }
  componentDidMount() {
    let { shalgalt, baiguullaga_info } = this.state;
    if (!!baiguullaga_info.hst && baiguullaga_info.hst > 0) {
      shalgalt.hst_dugaar.validation.push("isZaaval");
    }
    if (!!baiguullaga_info.pst && baiguullaga_info.pst > 0) {
      shalgalt.pst_dugaar.validation.push("isZaaval");
    }
    if (!!baiguullaga_info.qst && baiguullaga_info.qst > 0) {
      shalgalt.qst_dugaar.validation.push("isZaaval");
    }
    if (!!baiguullaga_info.gst && baiguullaga_info.gst > 0) {
      shalgalt.gst_dugaar.validation.push("isZaaval");
    }
  }
  bolihDarahad() {
    const { zasah } = this.state;
    this.setState({
      zasah: !zasah,
    });
  }
  async tovchDarahad() {
    let {
      zasah,
      baiguullaga_info,
      shalgalt,
      lastcheck,
      validationHariu,
      unshijBaigaa,
    } = this.state;
    if (zasah === true) {
      unshijBaigaa = true;
      this.setState({ unshijBaigaa });
      let isValid = true;
      lastcheck.map((item) => {
        const urDun = validShalgah(
          item,
          baiguullaga_info[item],
          shalgalt[item]
        );
        validationHariu[item] = urDun;
        if (urDun.isValid === false) {
          isValid = false;
        }
        return true;
      });
      this.setState({
        validationHariu,
      });
      if (isValid === true) {
        try {
          // baiguullaga_info.baiguullaga_temdegt_zurag_id = "9-404rizil07xjgit";
          if (
            baiguullaga_info.baiguullaga_temdegt_zurag_id &&
            baiguullaga_info.baiguullaga_temdegt_zurag_id.slice(0, 10) ===
              "data:image"
          ) {
            const hariu = await this.props.shopZuragFn(
              baiguullaga_info.baiguullaga_temdegt_zurag_id
            );
            if (hariu.amjilt === true) {
              baiguullaga_info.baiguullaga_temdegt_zurag_id = hariu.imageid;
            } else {
              this.setState({
                flag: 1,
                medegdel: true,
                modalUtga: {},
                zasah: !zasah,
                // unshijBaigaa: false,
              });
              return;
            }
          }

          // baiguullaga_info.shiree_bairlal_zurag_id = "9-404rizil07xjgit";
          if (
            baiguullaga_info.shiree_bairlal_zurag_id &&
            baiguullaga_info.shiree_bairlal_zurag_id.slice(0, 10) ===
              "data:image"
          ) {
            const hariu1 = await this.props.shireeZuragFn(
              baiguullaga_info.shiree_bairlal_zurag_id
            );
            if (hariu1.amjilt === true) {
              baiguullaga_info.shiree_bairlal_zurag_id = hariu1.imageid;
            } else {
              this.setState({
                flag: 1,
                medegdel: true,
                modalUtga: {},
                zasah: !zasah,
                // unshijBaigaa: false,
              });
              return;
            }
          }
          // baiguullaga_info.tses_zurag_id = "9-404rizil07xjgit";
          if (
            baiguullaga_info.tses_zurag_id &&
            baiguullaga_info.tses_zurag_id.slice(0, 10) === "data:image"
          ) {
            const hariu2 = await this.props.tsesZuragFn(
              baiguullaga_info.tses_zurag_id
            );
            if (hariu2.amjilt === true) {
              baiguullaga_info.tses_zurag_id = hariu2.imageid;
            } else {
              this.setState({
                flag: 1,
                medegdel: true,
                modalUtga: {},
                zasah: !zasah,
                // unshijBaigaa: false,
              });
              return;
            }
          }
          baiguullaga_info.local_tax =
            Number(baiguullaga_info.local_tax) < 1
              ? currency(Number(baiguullaga_info.local_tax) * 100, {
                  precision: 3,
                  separator: "",
                })
              : Number(baiguullaga_info.local_tax);
          this.props
            .baiguullagaMedeelelBurtgelFn(baiguullaga_info)
            .then((hariu) => {
              this.props.hereglegchMe();
              if (hariu.amjilt === true) {
                this.setState({
                  zasah: !zasah,
                  flag: 0,
                  medegdel: true,
                  modalUtga: {},
                  unshijBaigaa: false,
                });
              } else {
                this.setState({
                  flag: 1,
                  medegdel: true,
                  modalUtga: {},
                  zasah: !zasah,
                  unshijBaigaa: false,
                });
              }
            });
        } catch (aldaa) {
          this.setState({
            flag: 1,
            medegdel: true,
            modalUtga: {},
            zasah: !zasah,
            unshijBaigaa: false,
          });
        }
      } else {
        this.setState({
          flag: 2,
          medegdel: true,
          unshijBaigaa: false,
        });
      }
    } else {
      this.setState({
        zasah: !zasah,
      });
    }
  }
  utgaOorchlogdohod(event) {
    const ner = event.target.name;
    const utga = event.target.value;
    if (this.state.zasah === true) {
      let { baiguullaga_info, validationHariu, shalgalt } = this.state;
      baiguullaga_info[ner] = utga;
      if (!!shalgalt[ner]) {
        const urDun = validShalgah(ner, utga, shalgalt[ner]);
        validationHariu[ner] = urDun;
      }
      this.setState({
        validationHariu,
        baiguullaga_info,
      });
    }
  }
  zuragSongohod(event) {
    const file = event.target.files[0];
    const ner = event.target.name;
    if (this.state.zasah === true) {
      let { baiguullaga_info, validationHariu, shalgalt } = this.state;
      if (file !== undefined) {
        Resizer.imageFileResizer(file, 500, 500, "JPEG", 70, 0, (uri) => {
          if (!!shalgalt[ner]) {
            const urDun = validShalgah(ner, uri, shalgalt[ner]);
            validationHariu[ner] = urDun;
          }
          baiguullaga_info[ner] = uri;
          this.setState({
            validationHariu,
            baiguullaga_info,
          });
        });
      } else {
        baiguullaga_info[ner] = "";
        const urDun = validShalgah(ner, "", shalgalt[ner]);
        validationHariu[ner] = urDun;
        this.props.utgaAvah(ner, "", urDun);
        this.setState({
          validationHariu,
          baiguullaga_info,
        });
      }
    }
  }

  bairlalOorchlogdohod(latitude, longitude) {
    if (this.state.zasah === true) {
      let { baiguullaga_info } = this.state;
      baiguullaga_info.latitude = latitude;
      baiguullaga_info.longitude = longitude;
      this.setState({
        baiguullaga_info,
      });
    }
  }

  switchDarahad(a) {
    let { baiguullaga_info, shalgalt } = this.state;
    const ner = a.target.id;
    const utga = baiguullaga_info[ner] ? baiguullaga_info[ner] : false;
    baiguullaga_info[ner] = !utga;

    if (ner === "nas_asuudag_eseh") {
      shalgalt.hyzgaarlah_nas.validation =
        baiguullaga_info.nas_asuudag_eseh === true
          ? ["isDanToo", "isZaaval"]
          : ["isDanToo"];
    }
    this.setState({
      baiguullaga_info,
      shalgalt,
    });
  }
  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  songohod(index) {
    let {
      validationHariu,
      baiguullaga_info,
      tsagiin_bus_jagsaalt,
      tsagiin_bus_id_old,
    } = {
      ...this.state,
    };
    if (index < 0) {
      const old_songolt = tsagiin_bus_jagsaalt.filter(
        (utga) => utga.id === tsagiin_bus_id_old
      )[0];
      const index_old = tsagiin_bus_jagsaalt.indexOf(old_songolt);
      baiguullaga_info.tsagiin_bus_id = tsagiin_bus_jagsaalt[index_old].id;
      baiguullaga_info.tmzone = tsagiin_bus_jagsaalt[index_old].time_zone;
      baiguullaga_info.utc = tsagiin_bus_jagsaalt[index_old].utc_text;
      validationHariu["tsagiin_bus_id"] = {};
    } else {
      baiguullaga_info.tsagiin_bus_id = tsagiin_bus_jagsaalt[index].id;
      baiguullaga_info.tmzone = tsagiin_bus_jagsaalt[index].time_zone;
      baiguullaga_info.utc = tsagiin_bus_jagsaalt[index].utc_text;
      validationHariu["tsagiin_bus_id"] = {};
    }
    this.setState({
      baiguullaga_info,
      validationHariu,
      tmzoneAsuuhShow: true,
    });
  }

  tmzoneAsuuhToggle(utga) {
    let {
      validationHariu,
      baiguullaga_info,
      tsagiin_bus_jagsaalt,
      tsagiin_bus_id_old,
    } = {
      ...this.state,
    };
    if (utga !== "yes") {
      const old_songolt = tsagiin_bus_jagsaalt.filter(
        (utga) => utga.id === tsagiin_bus_id_old
      )[0];
      const index_old = tsagiin_bus_jagsaalt.indexOf(old_songolt);
      baiguullaga_info.tsagiin_bus_id = tsagiin_bus_jagsaalt[index_old].id;
      baiguullaga_info.tmzone = tsagiin_bus_jagsaalt[index_old].time_zone;
      baiguullaga_info.utc = tsagiin_bus_jagsaalt[index_old].utc_text;
      validationHariu["tsagiin_bus_id"] = {};
    }
    this.setState({
      tmzoneAsuuhShow: false,
      validationHariu,
      baiguullaga_info,
    });
  }

  render() {
    const {
      zasah,
      baiguullaga_info,
      validationHariu,
      layout_turul,
      medegdel,
      flag,
      unshijBaigaa,
      tsagiin_bus_jagsaalt,
      tmzoneAsuuhShow,
    } = this.state;

    baiguullaga_info.local_tax =
      Number(baiguullaga_info.local_tax) < 1
        ? currency(Number(baiguullaga_info.local_tax) * 100, {
            precision: 3,
            separator: "",
          })
        : baiguullaga_info.local_tax;
    return (
      <div className="tab-zai">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Row>
            <Col>
              <Alert
                isOpen={medegdel}
                toggle={this.medegdelToggle}
                color={flag === 0 ? "success" : "danger"}
              >
                {flag === 0 && (
                  <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                )}
                {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                {flag === 2 && (
                  <span>{i18n.t("medegdel_aldaa_validation_aldaa_zas")}</span>
                )}
              </Alert>
            </Col>
          </Row>
          {layout_turul > 0 && (
            <Form>
              <Row>
                <div className="update ml-auto mr-auto">
                  <Button
                    className="ungu-primary"
                    style={{ width: togtmol.tovchnii_urgun + "px" }}
                    onClick={this.tovchDarahad}
                  >
                    {zasah === true ? i18n.t("hadgalah") : i18n.t("zasah")}
                  </Button>
                </div>
              </Row>
              {zasah === true && (
                <Row>
                  <div className="update ml-auto mr-auto">
                    <Button
                      className="ungu-primary"
                      style={{ width: togtmol.tovchnii_urgun + "px" }}
                      onClick={this.bolihDarahad}
                    >
                      {i18n.t("bolih")}
                    </Button>
                  </div>
                </Row>
              )}
              <Row>
                <Col lg="4" md="4" sm="12" xs="12">
                  <FormGroup>
                    <Label>{i18n.t("baiguullaga_ner")}</Label>
                    <Input
                      autoFocus={zasah === true ? true : false}
                      type="text"
                      name="ner"
                      value={baiguullaga_info.ner ? baiguullaga_info.ner : ""}
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["ner"] &&
                        !validationHariu["ner"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["ner"] &&
                      !!validationHariu["ner"].message
                        ? validationHariu["ner"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  {/* <FormGroup>
                  <Label>{i18n.t("haih_tovch_ner")}</Label>
                  <Input
                    type="text"
                    name="haih_tovch_ner"
                    value={
                      baiguullaga_info.haih_tovch_ner
                        ? baiguullaga_info.haih_tovch_ner
                        : ""
                    }
                    onChange={this.utgaOorchlogdohod}
                    invalid={
                      validationHariu["haih_tovch_ner"] &&
                      !validationHariu["haih_tovch_ner"].isValid
                    }
                  />
                  <FormFeedback>
                    {!!validationHariu["haih_tovch_ner"] &&
                    !!validationHariu["haih_tovch_ner"].message
                      ? validationHariu["haih_tovch_ner"].message
                      : ""}
                  </FormFeedback>
                </FormGroup> */}
                  <FormGroup>
                    <Label>{i18n.t("uilchilgeenii_turul")}</Label>
                    <Input
                      type="text"
                      name="uilchilgeenii_turul_ner"
                      value={
                        baiguullaga_info.uilchilgeenii_turul_ner
                          ? baiguullaga_info.uilchilgeenii_turul_ner
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("company_name")}</Label>
                    <Input
                      type="text"
                      name="company_name"
                      value={
                        baiguullaga_info.company_name
                          ? baiguullaga_info.company_name
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("company_number")}</Label>
                    <Input
                      type="text"
                      name="company_number"
                      value={
                        baiguullaga_info.company_number
                          ? baiguullaga_info.company_number
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("utas_haragdah")}</Label>
                    <Input
                      type="number"
                      name="utas"
                      value={baiguullaga_info.utas ? baiguullaga_info.utas : ""}
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["utas"] &&
                        !validationHariu["utas"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["utas"] &&
                      !!validationHariu["utas"].message
                        ? validationHariu["utas"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  {/* <FormGroup>
                  <Label>{i18n.t("email")}</Label>
                  <Input
                    type="text"
                    name="email"
                    value={baiguullaga_info.email ? baiguullaga_info.email : ""}
                    onChange={this.utgaOorchlogdohod}
                    invalid={
                      validationHariu["email"] &&
                      !validationHariu["email"].isValid
                    }
                  />
                  <FormFeedback>
                    {!!validationHariu["email"] &&
                    !!validationHariu["email"].message
                      ? validationHariu["email"].message
                      : ""}
                  </FormFeedback>
                </FormGroup> */}
                  <FormGroup>
                    <div>
                      <CustomInput
                        id="zogsooltoi_eseh"
                        type="switch"
                        name="zogsooltoi_eseh"
                        defaultChecked={
                          baiguullaga_info.zogsooltoi_eseh
                            ? baiguullaga_info.zogsooltoi_eseh
                            : false
                        }
                        label={i18n.t("zogsooltoi_eseh")}
                        onClick={this.switchDarahad}
                        disabled={!zasah}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <SongoltBox
                      name="tsagiin_bus_id"
                      garchig={"tsagiin_bus"}
                      songoltUtga={tsagiin_bus_jagsaalt}
                      hevleh={"utc_text"}
                      utga={
                        baiguullaga_info.tsagiin_bus_id
                          ? tsagiin_bus_jagsaalt.filter(
                              (utga) =>
                                utga.id === baiguullaga_info.tsagiin_bus_id
                            )[0]
                          : 0
                      }
                      zaaval={true}
                      songohod={this.songohod}
                      disabled={!zasah}
                      isValid={
                        validationHariu["tsagiin_bus_id"] &&
                        validationHariu["tsagiin_bus_id"].isValid
                      }
                      validationMessage={
                        !!validationHariu["tsagiin_bus_id"] &&
                        !!validationHariu["tsagiin_bus_id"].message
                          ? validationHariu["tsagiin_bus_id"].message
                          : ""
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("hayag")}</Label>
                    <Input
                      type="text"
                      name="hayag"
                      value={
                        baiguullaga_info.hayag ? baiguullaga_info.hayag : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["hayag"] &&
                        !validationHariu["hayag"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["hayag"] &&
                      !!validationHariu["hayag"].message
                        ? validationHariu["hayag"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  <div>
                    {/* <br /> */}
                    <p>{i18n.t("map_tailbar_text")}</p>
                    <MapPicker
                      defaultLocation={{
                        lat: parseFloat(baiguullaga_info.latitude),
                        lng: parseFloat(baiguullaga_info.longitude),
                      }}
                      mapTypeId="roadmap"
                      style={{ height: window.innerHeight * 0.4 }}
                      onChangeLocation={this.bairlalOorchlogdohod}
                      disabled={!zasah}
                      apiKey={tohirgoo.mapKey}
                    />
                    <br />
                  </div>
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  {/* <FormGroup>
                  <Label>{i18n.t("solih_zuvshuurugduh_tsag")}</Label>
                  <Input
                    type="number"
                    name="solij_boloh_tsag"
                    value={
                      baiguullaga_info.solij_boloh_tsag
                        ? baiguullaga_info.solij_boloh_tsag
                        : ""
                    }
                    onChange={this.utgaOorchlogdohod}
                    invalid={
                      validationHariu["solij_boloh_tsag"] &&
                      !validationHariu["solij_boloh_tsag"].isValid
                    }
                  />
                  <FormFeedback>
                    {validationHariu["solij_boloh_tsag"] &&
                      validationHariu["solij_boloh_tsag"].validationMessage}
                  </FormFeedback>
                </FormGroup> */}
                  <FormGroup>
                    <Label>{i18n.t("zahialga_umnuh_tsag")}</Label>
                    <Input
                      type="number"
                      name="zahialga_umnuh_tsag"
                      value={
                        baiguullaga_info.zahialga_umnuh_tsag
                          ? baiguullaga_info.zahialga_umnuh_tsag
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["zahialga_umnuh_tsag"] &&
                        !validationHariu["zahialga_umnuh_tsag"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["zahialga_umnuh_tsag"] &&
                      !!validationHariu["zahialga_umnuh_tsag"].message
                        ? validationHariu["zahialga_umnuh_tsag"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("zahialga_avah_muchlug")}</Label>
                    <Input
                      type="number"
                      name="zahialga_avah_honog_limit"
                      value={
                        baiguullaga_info.zahialga_avah_honog_limit
                          ? baiguullaga_info.zahialga_avah_honog_limit
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["zahialga_avah_honog_limit"] &&
                        !validationHariu["zahialga_avah_honog_limit"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["zahialga_avah_honog_limit"] &&
                      !!validationHariu["zahialga_avah_honog_limit"].message
                        ? validationHariu["zahialga_avah_honog_limit"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  {/* <FormGroup>
                  <Label>{i18n.t("tsutslagdsand_tootsoh_tsag")}</Label>
                  <Input
                    type="number"
                    name="tsutslagdsand_tootsogdoh_hugatsaa"
                    value={
                      baiguullaga_info.tsutslagdsand_tootsogdoh_hugatsaa
                        ? baiguullaga_info.tsutslagdsand_tootsogdoh_hugatsaa
                        : ""
                    }
                    onChange={this.utgaOorchlogdohod}
                    invalid={
                      validationHariu["tsutslagdsand_tootsogdoh_hugatsaa"] &&
                      !validationHariu["tsutslagdsand_tootsogdoh_hugatsaa"]
                        .isValid
                    }
                  />
                  <FormFeedback>
                    {validationHariu["tsutslagdsand_tootsogdoh_hugatsaa"] &&
                      validationHariu["tsutslagdsand_tootsogdoh_hugatsaa"]
                        .validationMessage}
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <div>
                    <CustomInput
                      id="zahialgaas_umnu_ireh"
                      type="switch"
                      name="zahialgaas_umnu_ireh"
                      defaultChecked={
                        baiguullaga_info.zahialgaas_umnu_ireh
                          ? baiguullaga_info.zahialgaas_umnu_ireh
                          : false
                      }
                      label={i18n.t("tsagaas_umnu")}
                      onClick={this.switchDarahad}
                      disabled={!zasah}
                    />
                  </div>
                </FormGroup> */}

                  {layout_turul === 1 && (
                    <FormGroup>
                      <div>
                        <CustomInput
                          id="nas_asuudag_eseh"
                          type="switch"
                          name="nas_asuudag_eseh"
                          defaultChecked={
                            baiguullaga_info.nas_asuudag_eseh
                              ? baiguullaga_info.nas_asuudag_eseh
                              : false
                          }
                          label={i18n.t("nas_asuudag_eseh_paid")}
                          onClick={this.switchDarahad}
                          disabled={!zasah}
                        />
                      </div>
                    </FormGroup>
                  )}
                  {baiguullaga_info &&
                    baiguullaga_info.nas_asuudag_eseh === true && (
                      <FormGroup>
                        <Label>{i18n.t("hyzgaarlah_nas")}</Label>
                        <Input
                          type="number"
                          name="hyzgaarlah_nas"
                          value={
                            baiguullaga_info.hyzgaarlah_nas
                              ? baiguullaga_info.hyzgaarlah_nas
                              : ""
                          }
                          onChange={this.utgaOorchlogdohod}
                          invalid={
                            validationHariu["hyzgaarlah_nas"] &&
                            !validationHariu["hyzgaarlah_nas"].isValid
                          }
                        />
                        <FormFeedback>
                          {!!validationHariu["hyzgaarlah_nas"] &&
                          !!validationHariu["hyzgaarlah_nas"].message
                            ? validationHariu["hyzgaarlah_nas"].message
                            : ""}
                        </FormFeedback>
                      </FormGroup>
                    )}
                  {layout_turul === 1 && (
                    <FormGroup>
                      <Label>{i18n.t("muchlug_baiguullaga_burtgel")}</Label>
                      <Input
                        type="number"
                        name="shiree_muchlug"
                        value={
                          baiguullaga_info.shiree_muchlug
                            ? baiguullaga_info.shiree_muchlug
                            : ""
                        }
                        onChange={this.utgaOorchlogdohod}
                        invalid={
                          validationHariu["shiree_muchlug"] &&
                          !validationHariu["shiree_muchlug"].isValid
                        }
                      />
                      <FormFeedback>
                        {!!validationHariu["shiree_muchlug"] &&
                        !!validationHariu["shiree_muchlug"].message
                          ? validationHariu["shiree_muchlug"].message
                          : ""}
                      </FormFeedback>
                    </FormGroup>
                  )}
                  {/* <FormGroup>
                  <Label>{i18n.t("jijeg_turul")}</Label>{" "}
                  <Input
                    id="jijeg_turul"
                    multiple
                    name="jijeg_turul"
                    type="select"
                    invalid={
                      validationHariu["muchlug"] &&
                      !validationHariu["muchlug"].isValid
                    }
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Input>
                  <FormFeedback>
                    {validationHariu["muchlug"] &&
                      validationHariu["muchlug"].validationMessage}
                  </FormFeedback>
                </FormGroup> */}
                  <FormGroup>
                    <Label>{i18n.t("delgerengui_medeelel")}</Label>
                    <Input
                      type="textarea"
                      name="delgerengui_medeelel"
                      value={
                        baiguullaga_info.delgerengui_medeelel
                          ? baiguullaga_info.delgerengui_medeelel
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["delgerengui_medeelel"] &&
                        !validationHariu["delgerengui_medeelel"].isValid
                      }
                      style={{ height: "25vh" }}
                    />
                    <FormFeedback>
                      {!!validationHariu["delgerengui_medeelel"] &&
                      !!validationHariu["delgerengui_medeelel"].message
                        ? validationHariu["delgerengui_medeelel"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("durem")}</Label>
                    <Input
                      type="textarea"
                      name="durem"
                      value={
                        baiguullaga_info.durem ? baiguullaga_info.durem : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["durem"] &&
                        !validationHariu["durem"].isValid
                      }
                      style={{ height: "25vh" }}
                    />
                    <FormFeedback>
                      {!!validationHariu["durem"] &&
                      !!validationHariu["durem"].message
                        ? validationHariu["durem"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  {baiguullaga_info.pst > 0 && (
                    <FormGroup>
                      <Label>{i18n.t("pst_dugaar")}</Label>
                      <Input
                        name="pst_dugaar"
                        value={
                          baiguullaga_info.pst_dugaar
                            ? baiguullaga_info.pst_dugaar
                            : ""
                        }
                        onChange={this.utgaOorchlogdohod}
                        invalid={
                          validationHariu["pst_dugaar"] &&
                          !validationHariu["pst_dugaar"].isValid
                        }
                      />
                      <FormFeedback>
                        {!!validationHariu["pst_dugaar"] &&
                        !!validationHariu["pst_dugaar"].message
                          ? validationHariu["pst_dugaar"].message
                          : ""}
                      </FormFeedback>
                    </FormGroup>
                  )}
                  {baiguullaga_info.qst > 0 && (
                    <FormGroup>
                      <Label>{i18n.t("qst_dugaar")}</Label>
                      <Input
                        name="qst_dugaar"
                        value={
                          baiguullaga_info.qst_dugaar
                            ? baiguullaga_info.qst_dugaar
                            : ""
                        }
                        onChange={this.utgaOorchlogdohod}
                        invalid={
                          validationHariu["qst_dugaar"] &&
                          !validationHariu["qst_dugaar"].isValid
                        }
                      />
                      <FormFeedback>
                        {!!validationHariu["qst_dugaar"] &&
                        !!validationHariu["qst_dugaar"].message
                          ? validationHariu["qst_dugaar"].message
                          : ""}
                      </FormFeedback>
                    </FormGroup>
                  )}
                  {baiguullaga_info.gst > 0 && (
                    <FormGroup>
                      <Label>{i18n.t("gst_dugaar")}</Label>
                      <Input
                        name="gst_dugaar"
                        value={
                          baiguullaga_info.gst_dugaar
                            ? baiguullaga_info.gst_dugaar
                            : ""
                        }
                        onChange={this.utgaOorchlogdohod}
                        invalid={
                          validationHariu["gst_dugaar"] &&
                          !validationHariu["gst_dugaar"].isValid
                        }
                      />
                      <FormFeedback>
                        {!!validationHariu["gst_dugaar"] &&
                        !!validationHariu["gst_dugaar"].message
                          ? validationHariu["gst_dugaar"].message
                          : ""}
                      </FormFeedback>
                    </FormGroup>
                  )}
                  {baiguullaga_info.hst > 0 && (
                    <FormGroup>
                      <Label>{i18n.t("hst_dugaar")}</Label>
                      <Input
                        name="hst_dugaar"
                        value={
                          baiguullaga_info.hst_dugaar
                            ? baiguullaga_info.hst_dugaar
                            : ""
                        }
                        onChange={this.utgaOorchlogdohod}
                        invalid={
                          validationHariu["hst_dugaar"] &&
                          !validationHariu["hst_dugaar"].isValid
                        }
                      />
                      <FormFeedback>
                        {!!validationHariu["hst_dugaar"] &&
                        !!validationHariu["hst_dugaar"].message
                          ? validationHariu["hst_dugaar"].message
                          : ""}
                      </FormFeedback>
                    </FormGroup>
                  )}
                </Col>
                <Col lg="4" md="4" sm="12" xs="12">
                  {baiguullaga_info.uls_id === 3 && (
                    <FormGroup>
                      <Label>{i18n.t("local_tax_label")}</Label>
                      <Input
                        type="text"
                        name="local_tax"
                        value={
                          !!baiguullaga_info.local_tax
                            ? baiguullaga_info.local_tax
                            : baiguullaga_info.local_tax === 0
                            ? "0"
                            : ""
                        }
                        onChange={this.utgaOorchlogdohod}
                        invalid={
                          validationHariu["local_tax"] &&
                          !validationHariu["local_tax"].isValid
                        }
                      />
                      <FormFeedback>
                        {!!validationHariu["local_tax"] &&
                        !!validationHariu["local_tax"].message
                          ? validationHariu["local_tax"].message
                          : ""}
                      </FormFeedback>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label>{i18n.t("bank_ner")}</Label>
                    <Input
                      type="text"
                      name="bank_ner"
                      value={
                        baiguullaga_info.bank_ner
                          ? baiguullaga_info.bank_ner
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["bank_ner"] &&
                        !validationHariu["bank_ner"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["bank_ner"] &&
                      !!validationHariu["bank_ner"].message
                        ? validationHariu["bank_ner"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("branch_ner")}</Label>
                    <Input
                      type="text"
                      name="branch_ner"
                      value={
                        baiguullaga_info.branch_ner
                          ? baiguullaga_info.branch_ner
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["branch_ner"] &&
                        !validationHariu["branch_ner"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["branch_ner"] &&
                      !!validationHariu["branch_ner"].message
                        ? validationHariu["branch_ner"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("branch_code")}</Label>
                    <Input
                      type="text"
                      name="branch_code"
                      value={
                        baiguullaga_info.branch_code
                          ? baiguullaga_info.branch_code
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["branch_code"] &&
                        !validationHariu["branch_code"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["branch_code"] &&
                      !!validationHariu["branch_code"].message
                        ? validationHariu["branch_code"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("dans_ner")}</Label>
                    <Input
                      type="text"
                      name="dans_ner"
                      value={
                        baiguullaga_info.dans_ner
                          ? baiguullaga_info.dans_ner
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["dans_ner"] &&
                        !validationHariu["dans_ner"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["dans_ner"] &&
                      !!validationHariu["dans_ner"].message
                        ? validationHariu["dans_ner"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>{i18n.t("dans_dugaar")}</Label>
                    <Input
                      type="text"
                      name="dans_dugaar"
                      value={
                        baiguullaga_info.dans_dugaar
                          ? baiguullaga_info.dans_dugaar
                          : ""
                      }
                      onChange={this.utgaOorchlogdohod}
                      invalid={
                        validationHariu["dans_dugaar"] &&
                        !validationHariu["dans_dugaar"].isValid
                      }
                    />
                    <FormFeedback>
                      {!!validationHariu["dans_dugaar"] &&
                      !!validationHariu["dans_dugaar"].message
                        ? validationHariu["dans_dugaar"].message
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleFile">
                      {i18n.t("baiguullaga_temdegt_zurag")}
                    </Label>
                    {zasah ? (
                      <div>
                        <Input
                          type="file"
                          name="baiguullaga_temdegt_zurag_id"
                          // accept="image/*"
                          accept=".jpg, .png, .jpeg"
                          onChange={this.zuragSongohod}
                          invalid={
                            validationHariu["baiguullaga_temdegt_zurag_id"] &&
                            !validationHariu["baiguullaga_temdegt_zurag_id"]
                              .isValid
                          }
                        />
                        <FormFeedback>
                          {!!validationHariu["baiguullaga_temdegt_zurag_id"] &&
                          !!validationHariu["baiguullaga_temdegt_zurag_id"]
                            .message
                            ? validationHariu["baiguullaga_temdegt_zurag_id"]
                                .message
                            : ""}
                        </FormFeedback>
                      </div>
                    ) : (
                      <div>
                        <img
                          onError={(ev) => {
                            ev.target.src = "/apple-icon.png"; //default zurag zam zaah
                          }}
                          src={
                            baiguullaga_info["baiguullaga_temdegt_zurag_id"]
                              ? tohirgoo.img_url +
                                "shop/" +
                                baiguullaga_info["baiguullaga_temdegt_zurag_id"]
                              : "/apple-icon.png" //default zurag zam zaah
                          }
                          style={{
                            height: "auto",
                            width: 30 + "%",
                            resize: "both",
                            objectFit: "cover",
                            objectPosition: "25% 50%",
                          }}
                          alt={"baiguullaga_temdegt_zurag_id"}
                        />
                      </div>
                    )}
                  </FormGroup>
                  {layout_turul === 1 && (
                    <FormGroup>
                      <Label for="exampleFile">
                        {i18n.t("shiree_bairlal_zurag")}
                      </Label>
                      {zasah ? (
                        <div>
                          <Input
                            type="file"
                            name="shiree_bairlal_zurag_id"
                            // accept="image/*"
                            accept=".jpg, .png, .jpeg"
                            onChange={this.zuragSongohod}
                            invalid={
                              validationHariu["shiree_bairlal_zurag_id"] &&
                              !validationHariu["shiree_bairlal_zurag_id"]
                                .isValid
                            }
                          />
                          <FormFeedback>
                            {!!validationHariu["shiree_bairlal_zurag_id"] &&
                            !!validationHariu["shiree_bairlal_zurag_id"].message
                              ? validationHariu["shiree_bairlal_zurag_id"]
                                  .message
                              : ""}
                          </FormFeedback>
                        </div>
                      ) : (
                        <div>
                          <img
                            onError={(ev) => {
                              ev.target.src = "/apple-icon.png"; //default zurag zam zaah
                            }}
                            src={
                              baiguullaga_info["shiree_bairlal_zurag_id"]
                                ? tohirgoo.img_url +
                                  "layout/" +
                                  baiguullaga_info["shiree_bairlal_zurag_id"]
                                : "/apple-icon.png" //default zurag zam zaah
                            }
                            style={{
                              height: "auto",
                              width: 30 + "%",
                              resize: "both",
                              objectFit: "cover",
                              objectPosition: "25% 50%",
                            }}
                            alt={"shiree_bairlal_zurag_id"}
                          />
                        </div>
                      )}
                    </FormGroup>
                  )}
                  {layout_turul === 1 && (
                    <FormGroup>
                      <Label for="exampleFile">{i18n.t("tses_zurag")}</Label>
                      {zasah ? (
                        <div>
                          <Input
                            type="file"
                            name="tses_zurag_id"
                            // accept="image/*"
                            accept=".jpg, .png, .jpeg"
                            onChange={this.zuragSongohod}
                            invalid={
                              validationHariu["tses_zurag_id"] &&
                              !validationHariu["tses_zurag_id"].isValid
                            }
                          />
                          <FormFeedback>
                            {!!validationHariu["tses_zurag_id"] &&
                            !!validationHariu["tses_zurag_id"].message
                              ? validationHariu["tses_zurag_id"].message
                              : ""}
                          </FormFeedback>
                        </div>
                      ) : (
                        <div>
                          <img
                            onError={(ev) => {
                              ev.target.src = "/apple-icon.png"; //default zurag zam zaah
                            }}
                            src={
                              baiguullaga_info["tses_zurag_id"]
                                ? tohirgoo.img_url +
                                  "tses/" +
                                  baiguullaga_info["tses_zurag_id"]
                                : "/apple-icon.png" //default zurag zam zaah
                            }
                            style={{
                              height: "auto",
                              width: 30 + "%",
                              resize: "both",
                              objectFit: "cover",
                              objectPosition: "25% 50%",
                            }}
                            alt={"tses_zurag_id"}
                          />
                        </div>
                      )}
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Form>
          )}
          <Form>
            <div style={{ textAlign: "center" }}>
              <Label
                style={{
                  fontsize: "110%",
                  color: "var(--main-blue)",
                }}
              >
                {i18n.t("uichilgee_turul_request_garchig")}
              </Label>
              <br />
            </div>
            <FormGroup>
              {" "}
              <FormGroup>
                <Label>{i18n.t("register_notes")}</Label>
                <Input
                  type="text"
                  name="register_notes"
                  value={
                    baiguullaga_info.register_notes
                      ? baiguullaga_info.register_notes
                      : ""
                  }
                  disabled
                />
              </FormGroup>
              <div>
                <CustomInput
                  id="register_req_subsc"
                  type="switch"
                  name="register_req_subsc"
                  defaultChecked={
                    baiguullaga_info.register_req_subsc
                      ? baiguullaga_info.register_req_subsc
                      : false
                  }
                  label={i18n.t("register_req_subsc")}
                  disabled
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div>
                <CustomInput
                  id="register_req_booking"
                  type="switch"
                  name="register_req_booking"
                  defaultChecked={
                    baiguullaga_info.register_req_booking
                      ? baiguullaga_info.register_req_booking
                      : false
                  }
                  label={i18n.t("register_req_booking")}
                  disabled
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div>
                <CustomInput
                  id="register_req_booking_unegui"
                  type="switch"
                  name="register_req_booking_unegui"
                  defaultChecked={
                    baiguullaga_info.register_req_booking_unegui
                      ? baiguullaga_info.register_req_booking_unegui
                      : false
                  }
                  label={i18n.t("register_req_booking_unegui")}
                  disabled
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label>{i18n.t("register_req_booking_unegui_too")}</Label>
              <Input
                type="text"
                name="register_req_booking_unegui_too"
                value={
                  baiguullaga_info.register_req_booking_unegui_too
                    ? baiguullaga_info.register_req_booking_unegui_too
                    : ""
                }
                disabled
              />
            </FormGroup>
          </Form>
          {tmzoneAsuuhShow && (
            <Modal
              isOpen={tmzoneAsuuhShow}
              toggle={() => this.tmzoneAsuuhToggle("no")}
            >
              <ModalHeader toggle={() => this.tmzoneAsuuhToggle("no")}>
                <div>{i18n.t("tmzone_change_alert_header")}</div>
              </ModalHeader>
              <ModalBody>
                <div align="center" style={{ color: "red", padding: 30 }}>
                  <div>{i18n.t("tmzone_change_alert_text")}</div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div style={{ width: "100%", justifyContent: "space-between" }}>
                  <Button
                    color="info"
                    size="sm"
                    style={{ width: "49%" }}
                    onClick={() => this.tmzoneAsuuhToggle("no")}
                  >
                    {i18n.t("bolih")}
                  </Button>
                  <Button
                    className="ungu-primary"
                    size="sm"
                    style={{ width: "49%" }}
                    onClick={() => {
                      this.tmzoneAsuuhToggle("yes");
                    }}
                  >
                    {i18n.t("zovshooroh")}
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  // const {  } =
  //   state.burtgelReducer;
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      baiguullagaMedeelelBurtgelFn,
      hereglegchMe,
      shopZuragFn,
      shireeZuragFn,
      tsesZuragFn,
    },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaiguullagaTab2)
);
