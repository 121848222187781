const hel = {
  undsen_garchig: "Booking Main Page",
  baiguullaga_garchig: "Shop Information",
  uilchilgee_garchig: "Service registration",
  ajiltan_garchig: "Employee Registration",
  shiree_garchig: "Table Registration",
  tulbur_garchig: "Balance / Transfer list",
  zahialga_garchig: "Booking list",
  zahialga_tuuh_garchig: "Booking history",
  ajiltan_uilchilgee_garchig: "Services provided by Employees",
  ajiltan_chuluu_garchig: "Employee absense",
  dotroos_tsag_garchig: "Register booking",
  // shiree_block_garchig: "Block the table",
  subscription_garchig: "Subscription",
  subsc_active_garchig: "Active subscs report",
  subsc_sold_garchig: "Sold report",
  subsc_get_garchig: "Item Get report",
  service_subsc_garchig: "Purchase Solutions",
  cards_garchig: "Card registration",
  tickets_garchig: "Purchased Solutions",
  service_subsc_history_garchig: "Solution payment history",
  nevtreh: "Login",
  burtguuleh: "Register",
  garah: "Log out",
  nevtreh_hayag: "Username",
  nuuts_ug: "Password",
  nuuts_ug_davt: "Repeat password",
  utasnii_dugaar: "Phone number",
  ner: "Name",
  hadgalah: "Save",
  baiguullaga: "Shop",
  uilchilgee: "Services provided by the Shop",
  uilchilgeenii_ner: "Service name",
  undsen_tarif: "Service price minimum (just a information for user)",
  undsen_tarif_max: "Service price maximum (just a information for user)",
  muchlug_baiguullaga_burtgel: "Booking Period (in minutes)(just in case)",
  muchlug: "Booking Period (in minutes)",
  tailbar: "Information",
  tuluv: "Status",
  uildel: "Action",
  nemeh: "Add new",
  zasah: "Edit",
  bolih: "Cancel",
  ajiltan: "Employee",
  ovog: "Surname",
  utas: "Phone number",
  namtar: "Biography",
  medegdel_aldaa_nevtreh: "Username or password is incorrect.",
  medegdel_aldaa_1: "An error occurred. Please try again.",
  medegdel_aldaa_nuutsug_ylgaatai: "Password does not match",
  medegdel_aldaa_burtgeltei:
    "This phone number is already registered. Please log in.",
  medegdel_amjilt_burtgegdsen: "Successfully registered",
  medegdel_amjilt_ilgeesen: "Code sent.",
  medegdel_aldaa_kod: "The code is incorrect.",
  medegdel_amjilt_batalgaajsan: "Successfully confirmed",
  medegdel_amjilt_1: "successfully completed",
  medegdel_aldaa_burtgelgui: "User not found",
  medegdel_aldaa_kod_tur_hulee:
    "An error occurred while sending the code, please wait a minute and try again.",
  medegdel_amjilt_nuutsug_soligdson: "Password changed successfully.",
  medegdel_aldaa_nuutsug_soligdoogui:
    "An error occurred while changing password. Please try again.",
  medegdel_aldaa_validation: "Please correct the error.",
  medegdel_aldaa_nuutsug_buruu: "Password you entered is wrong.",
  medegdel_amjilt_soligdson: "Successfully changed",
  medegdel_aldaa_uilchilgeenii_nohtsol:
    "You must agree to the Terms of Service to register.",
  medegdel_aldaa_tsag_zahialgatai:
    "It cannot be changed, there is booking in chosen time",
  medegdel_aldaa_card_declined: "Card declined",
  medegdel_aldaa_card_burtgeltei: "Card is already registered",
  medegdel_amjilt_ustsan: "Successfully deleted",
  nuutsug_martsan: "Forgot password?",
  nuutsug_sergeeh: "Recover password",
  urgeljluuleh: "Proceed",
  batalgaajuulah: "Confirm",
  batalgaajuulah_kod: "Verification code",
  nuutsug_solih: "Change password",
  shine_nuuts_ug: "New password",
  tsag_tulbur: "Paid booking fee",
  zurag: "Picture",
  shiree_dugaar: "Table number",
  min_hunii_too: "Number of people (minimum)",
  max_hunii_too: "Number of people (maximum)",
  zahialga_une: "Booking price",
  ognoo: "Date",
  ehleh_tsag: "Start time",
  duusah_tsag: "End time",
  hereglegch_ner: "Username",
  hereglegch_utas: "Customer phone number",
  haih: "Search",
  ajiltan_haih: "Search by employee",
  shiree_haih: "Search by table",
  ognoo_haih: "Search by date",
  ajiltan_ner: "Employee Name",
  tulbur: "Price",
  niit_mongon_dun: "Total amount",
  shimtgel: "Fees",
  shiljuulsen_dun: "Transferred amount",
  dansnii_uldegdel: "Account balance",
  validation_isZaaval: "Cannot be empty. ",

  validation_isNuutsug:
    "Letters and numbers must be included. Length is 8 or more. Allowed symbols: !@#$%^&*",
  validation_isDanToo: "Only digits are allowed.",
  validation_isDanText: "Only letters are allowed.",
  validation_isDanTextSpacetei: "Only letters and spaces are available",

  validation_isTemdegttei: "No characters other than ... are available.",
  validation_isTemdegtgui: "Symbols are not allowed",
  validation_isTentsuu: "Length must be {{urt}}.",
  validation_isUrtMax: "Length must be less than {{urt}}.",
  validation_isUrtMin: "Length must be longer than {{urt}}",
  validation_isUrtHoorond: "Length must be between {{urtMin}} and {{urtMax}}.",
  validation_isEmail: "Please enter an email address.",
  validation_isUtas: "Phone number must be 10 digits.",
  validation_isTsag: "Please enter a time",
  validation_isOgnoo: "Please enter a date",
  validation_isMongo: "Enter the price (example:  1.23)",
  validation_isTokenId: "Validation error",
  validation_isLongitudeLatitude: "Choose the correct location",
  validation_isUtgaMin: "Value must be greater than {{urt}}.",
  validation_isUtgaMax: "Value must be less than {{urt}}.",
  validation_isHuvi: "Enter percentage. (examples: 1 , 1.23 , 1.234)",
  dahin_kod_avah: "Resend code",
  tooluur_text: "You can resend the code again after {{tooluur}} seconds",
  butsah: "Back",
  baiguullaga_ner: "Shop Name",
  hayag: "Address",
  email: "Email Address",
  baiguullaga_medeelel: "Shop Information",
  hereglegch_medeelel: "User Information",
  tsag_medeelel: "Schedule",
  husnegt_hooson: "No information found",
  amralt1_ehleh_tsag: "Break 1 start time",
  amralt1_duusah_tsag: "Break 1 end time",
  amralt2_ehleh_tsag: "Break 2 start time",
  amralt2_duusah_tsag: "Break 2 end time",
  undsen_tsagiin_huvaari: "Booking Main page",
  ontsgoi_tsagiin_huvaari: "Special",
  is_amrah: "Closed",
  // solih_zuvshuurugduh_tsag: "Permissible time that can be changed (by hours)",
  zahialga_umnuh_tsag:
    "How many minutes before the booking time the user should book (by minutes)(just in case)",
  zogsooltoi_eseh: "Has parking?",
  delgerengui_medeelel: "About shop",
  // tulbur_buren_hiigdsen_eseh: "Fully paid?",
  tax: "Tax",
  zahialga_avah_muchlug:
    "How many days in advance can a user book? (by days)(just in case)",
  // tsutslagdsand_tootsoh_tsag: "Time to count as canceled (by minutes)",
  tsagaas_umnu: "Arrive before the booked time",
  garag: "Days",
  davaa: "Monday",
  mygmar: "Tuesday",
  lhagva: "Wednesday",
  purev: "Thursday",
  baasan: "Friday",
  byamba: "Saturday",
  nyam: "Sunday",
  uilchilgeenii_turul: "Shop Category",
  utas_haragdah: "Phone number (Visible for app users)",
  tuluv_idevhtei: "Status (active)",
  idevhtei: "Active",
  idevhgui: "Inactive",
  admin_hereglegch: "Admin user",
  sub_hereglegch: "Staff user",
  nuuts_ug_oruul: "Enter your password",
  nevtrelt_batalgaajuulah_anhaaruulah:
    "A verification code has been sent to your registered email address. If no code has been received, please check the spam page.",
  status_solih: "Change status",
  uilchilgee_nuhtsul_text: "Click here to read and agree the terms of service.",
  zovshooroh: "Agree",
  medeelel_shinechleh: "Refresh",
  nas_asuudag_eseh_paid: "Require age confirmation for Paid Booking",
  nas_asuudag_eseh_free: "Require age confirmation for Free Booking",
  nas_asuudag_eseh_subsc: "Require age confirmation",
  hyzgaarlah_nas: "Minimum age",
  haih_tovch_ner: "Short Name",
  baiguullaga_temdegt_zurag: "Shop image",
  shiree_bairlal_zurag: "Table layout image",
  tses_zurag: "Menu image",
  dans_ner: "Account holder's name",
  dans_dugaar: "Account number",
  jijeg_turul: "Sub category",
  nickname: "Nickname",
  ajiltan_detail_haruulna: "Visible for users",
  valiut: "Currency",
  is_eco: "Is eco",
  udur_limit: "Daily Limit",
  sar_limit: "Number of units available per month",
  medeelel: "Information",
  negj_une: "Unit Price",
  used_amount: "Used amount",
  orlogo: "To Shop (Income)",
  id: "Number",
  view_subsc: "Subscription info",
  orlogo_tax: "Tax revenue",
  subsc_price: "Subscription price",
  // cashback_huselt: "Send request for cashback",
  mongon_dun: "Balance",
  ognoo_huselt_gargasan: "Requested date",
  ognoo_shiljuulsen: "Transfered date",
  default_service_subsc: "Basic solutions",
  custom_service_subsc: "Offering solutions",
  service_subsc_ner: "Solution name",
  service_subsc_turul: "Solution type",
  service_subsc_une: "Solution fee (by month)",
  subscribe: "Subscribe",
  card_number: "Card number",
  valid_thru: "Valid until",
  service_subsc_period: "Valid date",
  service_subsc_ognoo: "Date",
  subsc_service: "Subscription solution",
  tsag_zahialga_service: "Booking solution",
  preperation_fee_service: "Paid booking solution",
  service_subsc_shimtgel: "Fees",
  service_subsc_niit_une: "Total price",
  tulbur_helber_songoh: "Please select a payment method",
  credit_card: "Credit card (Stripe)",
  paypal: "Paypal",
  shine_card_nemeh: "Add new card",
  tulbur_tuluh: "Pay",
  ustgah: "Delete",
  muj: "State",
  durem: "Rules",
  hst_dugaar: "HST number",
  pst_dugaar: "PST number",
  qst_dugaar: "QST number",
  gst_dugaar: "GST number",
  banner_tulbur_tuluugui:
    "Solution fee is not paid. Click here for more information",
  une: "Price",
  negjiin_une: "Unit Price",
  niit_une: "Total price",
  uls: "Country",
  discount_percent: "Discount rate ( % )",
  medegdel_aldaa_staff_count: "Your registration number has been exceeded",
  medegdel_aldaa_tsag_davhtsaj_baina: "Already booked for this time slot",
  tsagiin_bus: "Time zone",
  medegdel_aldaa_validation_aldaa_zas: "Please correct the errors",
  ssubsc_sard: "month",
  ssubsc_batalgaajuulah_dahin_subsc:
    "You have already purchased this solution subscription. Please note that by subscribing this solution subscription, the previous solution subscription will become invalid and the payment will not be refunded. If you are sure to re-subscribe, click Proceed.",
  service_subsc_count_choose_label_staff:
    "Please enter the number of employees here to purchase booking solution.",
  service_subsc_count_choose_label_table:
    "Please enter the number of tables here to purchase booking solution.",
  count_table_label: "Number of tables available to register",
  count_staff_label: "Number of employees available to register",
  ssubsc_button_zogsooh: "Solution stop",
  ssubsc_text_zogsson: "Solution stopped",
  ssubsc_ticket_list: "List of solutions",
  subscription_deactivate: "Deactivate subsc",
  subsc_deactivate_asuult_text:
    "By deactivating the subscription, you will not be able to activate this subscription again, and all the subscription tickets of all users who purchased this subscription will be stopped and will not be renewed. This operation cannot be undone. If you agree, click Proceed.",
  map_tailbar_text:
    "Please drag and drop the mark to the position of the shop.",
  bank_ner: "Bank name",
  branch_ner: "Branch name",
  branch_code: "Branch number",
  hugatsaa: "Time",
  timeline_locked: "Booking in process (Payment not completed)",
  timeline_active: "Payment completed",
  timeline_hooson: "No booking",
  timeline_ajiltan_chuluu: "The Employee is absent",
  timeline_zahialga_dotroos: "Manually booked time",
  tiim: "Yes",
  ugui: "No",
  medegdel_aldaa_tsag_burtgeltei:
    "You cannot register 2 or more special schedule on the same day.",
  tulbur_heregsel: "Payment method",
  local_tax_label: "Paid booking service Local Tax ( % )",
  local_tax_warning:
    "Please note that the Shop will be responsible for any issues related to incorrect local tax.",
  burtgel_warning:
    "By checking agree check box and clicking Register button, I confirm that I have read and agree to be bound the Terms of Service.",
  state_tax: "State tax",
  local_tax: "Service Local Tax",
  nuuts_ug_shine: "New password",
  nevtreh_hayag_shine: "New username",
  burtgel_nemelt_medeelel_avne_text:
    "Please note that additional information may be required to verify registration",
  tulugduugui: "Unpaid",
  borluulaltiin_orlogoos: "From sales revenue",
  dansaar: "By transaction",
  free_trial: "Free trail",
  company_number: "Corporation number",
  company_name: "Company Name",
  email_tailbartai_garchig:
    "Email (Please enter the correct email to confirm when logging in)",
  email_tailbar:
    "Please enter a valid email. One time code will be sent to this email for login",
  zogsoohdoo_itegeltei_eseh: "Are you sure you want to disable this solution",
  zogsooh_anhaaruulah_text_subsc:
    "By disabling this solution, your shop's all the subscription tickets purchased by all users will be stopped and will not be renewed. This operation cannot be undone. And also users will not be able to purchase new subscription tickets. Please note that the services must be provided to the users who have already purchased subscription tickets, even after disabling this solution. In addition, solution subscription fees are not refundable. If you agree, click Agree",
  zogsooh_anhaaruulah_text_tsag:
    "By disabling this solution, users will not be able to book at your shop. Please note that the services must be provided to the users who have already booked, even after disabling this solution. In addition, solution subscription fees are not refundable. If you agree, click Agree.",
  stripe: "Credit card (Stripe)",
  suutgal: "Basic Monthly Fee",
  negj_une_undsen: "Normal Unit price",
  times_text: "times",
  times_text_label: "Explanatory text to use",
  card_ustgah_alert:
    "Are you sure you want to delete this card? If you have purchased solution subscription by this card, please note that deleting the card may not renew your subscription.",
  erh_zogsooh: "Disable Solution",

  ticket_zogsooh_text:
    "The basic monthly fee will no longer be paid by subscription. If you do not pay the basic monthly fee by subscription, it will be deducted from the sales proceeds..",
  ssubsc_erh_text_zogsson: "Solution disabled",
  medegdel_aldaa_refreshdate:
    "Can't stop on solution subscription renewal date",
  batalgaajuulah_utas_tailbar_text:
    "Please enter a 6-digit code sent to the {{utas}} number you entered.",
  batalgaajuulah_email_tailbar_text:
    "Please enter a 6-digit code sent to the {{email}} address you entered.",
  medegdel_aldaa_kod_limit_utas:
    "Today's message limit has been reached, please try again tomorrow",
  medegdel_aldaa_kod_limit_email:
    "Today's email limit has been reached, please try again tomorrow",
  tsag: "Time",
  shiree: "Table",
  niit_guilgee: "Total transactions",
  guilgeenii_turul: "Transaction Type",
  tsag_zahialga: "Booking",
  subscription: "Subsc",
  get: "Used",
  busad: "Other",
  active: "Active",
  inactive: "Inactive",
  aldaa: "Error",
  tsag_duussan: "Booking expired",
  locked: "Booking processing",
  completed: "Completed",
  pending: "Payment pending",
  cashback_dugaar: "Whether the bill has been processed",
  hiigdsen: "Yes",
  hiigdeegui: "No",
  uusgesen_ognoo: "Transaction date",
  sum: "Total",
  text_select_card: "After selecting your card, click the pay button",
  ajiltan_too: "Number of employees",
  idevhjuuleh: "Activate",
  commission_fee: "Commission fee",

  bolson: "Transferred",
  // cash back byren   transaction byriin ard
  boloogui: "Not transferred",
  // cash back  hiigdehgyi

  hagas: "Partially transferred",
  // cash back dutuu
  tootsoo_delgerengui: "Transfer details",
  tootsoonii_uldegdel: "Transaction balance after transfer",
  // cash back yldegdel

  // niit balance
  completed_tootsoo: "Transferred",
  pending_tootsoo: "Хүлээгдэж байгаа",
  privacypolicy_garchig: "Privacy Policy",
  termofservice_garchig: "Terms Of Service",
  sum_before_tax: "Sum before tax",
  service_fee: "Service fee",
  ognoo_haih_transaction: "Search by transaction date",
  transaction_time: "Transaction time",
  expired: "Expired",
  tsutsalsan: "Booking canceled",
  ognoo_booking: "Booking date",
  shop_commission: "Shop Commission to YouSubsc",
  user_commission: "User Commission to YouSubsc",
  total_sum_toshop: "To Shop Total",
  ner_subsc: "Subsc name",
  negj_une_subsc: "Subsc unit price",
  sum_toshop: "To Shop Total",
  HST_toshop: "To Shop HST",
  GST_toshop: "To Shop GST",
  PST_toshop: "To Shop PST",
  QST_toshop: "To Shop QST",
  state_tax_toshop: "To Shop state tax",
  local_tax_toshop: "To Shop local tax",
  tsag_zahialga_turul: "Paid Booking",
  subscription_turul: "Subsc Purchase",
  get_turul: "Subsc Usage",
  busad_turul: "Other",
  tax_orlogo: "To Shop (Tax)",
  orlogo_niit: "To Shop (Total)",
  info: "Info",
  sub_pass_text:
    "The first password is the same as Admin user's password. Please change before using staff account",
  ontsgoi_tsag_ustgah_asuult: "Are you sure you want to delete this schedule?",
  email_shine: "New email",
  solilt_batalgaajuulah: "Confirmation",
  medegdel_aldaa_card_hyzgaar: "Card registration limit has reached",
  medegdel_aldaa_uilchilgee_hyzgaar: "Service registration limit has reached",
  medegdel_amjilt_subsc_hyzgaar:
    "Subscription item registration limit has reached",
  validation_subsc_item_price_limit:
    "Subscription item price should be between {{min}} and {{max}}",
  medegdel_aldaa_staff_hyzgaar: "Employee registration limit has reached",
  medegdel_aldaa_table_hyzgaar: "Table registration limit has reached",
  banner_tuluv_not_active:
    "Please register all information below. We'll contact you after checking your registration information, so please check your email. You will be able to use platform after YouSubsc activate your shop. Please note that additional information may be required to verify registration.",
  medegdel_aldaa_item_deactivate_refreshdate:
    "Can't deactivate item between 8 PM to 5 AM.",
  medegdel_aldaa_disable_solution_regreshdate:
    "Can't disable solution between 8 PM to 5 AM",
  category_tailbar_paid:
    "Applies to paid booking solution. App users pay to book. Shop needs to pay a basic monthly fee.",
  category_tailbar_free:
    "Applies to free booking solution. App users can book for free. Shop needs to pay a basic monthly fee. The basic monthly fee varies depending on the scale.",
  local_tax_rate: "Local tax rate",
  tmzone_change_alert_header: "Changing time zone, Are you sure? ",
  tmzone_change_alert_text:
    "Your shop's time zone is about to change. Users who have booked in the old time zone should also be served.",
  register_notes:
    "What kind of shop is your shop ? (Category request) (For example : Coffee shop, Restaurant, barber shop etc.)",
  register_req_subsc: "Subscription Solution",
  register_req_booking: "Paid Booking Solution",
  register_req_booking_unegui: "Free Booking Solution",
  register_req_booking_unegui_too: "Number of Employees",
  uichilgee_turul_request_garchig: "Category request",
  ognoo_haih_booking: "Search by booking date",
  id_garchig: "id",
  niit_mongon_dun_detail: "Total amount",
  shiljuulsen_dun_detail: "Total Transferred to shop",
  suutgal_detail: "Basic Monthly Fee",
  solution_request_label: "Which solutions do you want to use",
  ajiltan_uilchilgee_nemeh: "Add service to employee",
  business_account_nevtreh: "YouSubsc Business Login",
  medegdel_aldaa_hyzgaar_absense: "Absense limit has reached",
  price_discount_alert: "Price must be at least 5% off. Now {{discount}}% off.",
};
export default hel;
