import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import i18n from "hel/i18n";
import {
  cardJagsaaltFn,
  addCustomerStripeCard,
  delCustomerStripeCard,
} from "../../../store/actions/stripe.actions";
import tohirgoo from "tohirgoo";
import LoginAuth from "services/LoginAuth";
import CardNemeh from "./tuslah/CardNemeh";
import MyLoader from "components/MyLoader";

const stripePromise = loadStripe(tohirgoo.stripe_publishableKey);

class CardInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medegdel: false,
      flag: 0,
      dialogUstgahTuluv: false,
      dialogTuluv: false,
      songogdsonMor: {},
      unshijBaigaa: false,

      card_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      // brand: "visa",
      // last4: "4444",
      // expiryYear: "22",
      // expiryMonth: "12",
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      me: LoginAuth.meAvah(),
    };
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.addNewCard = this.addNewCard.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.ustgahDarahad = this.ustgahDarahad.bind(this);
  }
  componentDidMount() {
    const info = LoginAuth.baiguullagaInfoAvah();
    if (info.tuluv !== "active") {
      this.props.history.push({ pathname: "/" });
    }
    this.setState({
      unshijBaigaa: true,
    });
    this.props.cardJagsaaltFn().then((hariu) =>
      this.setState({
        unshijBaigaa: false,
      })
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.card_jagsaalt !== state.card_jagsaalt) {
      let card_jagsaalt = props.card_jagsaalt ? props.card_jagsaalt : [];
      return {
        card_jagsaalt: props.card_jagsaalt,
        utguud: card_jagsaalt,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      medegdel: false,
    });
  }
  dialogUstgahToggle(utga) {
    this.setState({
      songogdsonMor: utga,
      dialogUstgahTuluv: !this.state.dialogUstgahTuluv,
      medegdel: false,
    });
  }

  addNewCard = async (stripeResponse, error_msg) => {
    const cid = this.state.me.useri;
    this.setState({ unshijBaigaa: true });
    if (error_msg === "incomplete_number") {
      this.setState({
        medegdel: true,
        flag: 2,
      });
      return;
    } else if (error_msg === "card_declined") {
      this.setState({
        medegdel: true,
        flag: 2,
      });
      return;
    } else if (error_msg === "invalid_number") {
      this.setState({
        medegdel: true,
        flag: 2,
      });
      return;
    }

    this.setState({
      isAdding: true,
      modalVisible: false,
      data_loading: true,
    });

    try {
      let check = this.props.card_jagsaalt.filter(
        (e) => e.last4 === stripeResponse.card.last4
      );

      if (check.length > 0) {
        this.setState(
          {
            isAdding: false,
            modalVisible: false,
            data_loading: false,
          },
          () => {
            this.setState({
              medegdel: true,
              flag: 3,
            });
          }
        );
        return;
      }

      this.props
        .addCustomerStripeCard({
          idstripe: cid,
          tokenId: stripeResponse.id,
          cardid: "",
        })
        .then((hariu) => {
          this.dialogToggle();
          if (hariu.amjilt === true) {
            this.setState({
              medegdel: true,
              flag: 0,
            });
          } else if (hariu.message === "hyzgaar error") {
            this.setState({
              medegdel: true,
              flag: 4,
            });
          } else {
            this.setState({
              medegdel: true,
              flag: 1,
            });
          }
          setTimeout(() => {
            this.props.cardJagsaaltFn().then((hariu) => {
              this.setState({
                unshijBaigaa: false,
              });
            });
          }, 3000);
        });
    } catch (aldaa) {
      if (aldaa.code === "sourceStatusFailed") console.log("3DS failed");
    }
  };

  ustgahDarahad = (card) => {
    try {
      this.setState({ unshijBaigaa: true });
      this.props
        .delCustomerStripeCard({
          id: card.id,
        })
        .then((hariu) => {
          if (hariu.amjilt === true) {
            this.setState({
              medegdel: true,
              flag: -1,
            });
            this.props.cardJagsaaltFn().then((hariu) => {
              this.setState({
                unshijBaigaa: false,
              });
            });
          } else {
            this.setState({
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
        });
    } catch (aldaa) {
      this.setState({
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  };

  render() {
    const {
      medegdel,
      flag,
      utguud,
      dialogTuluv,
      erh_hyzgaar,
      dialogUstgahTuluv,
      songogdsonMor,
      unshijBaigaa,
    } = this.state;
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <Card style={{ height: window.innerHeight * 0.8 }}>
          <CardBody>
            {medegdel && (
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Alert
                        isOpen={medegdel}
                        toggle={this.medegdelToggle}
                        color={flag === 0 || flag === -1 ? "success" : "danger"}
                      >
                        {" "}
                        {flag === -1 && (
                          <span>{i18n.t("medegdel_amjilt_ustsan")}</span>
                        )}
                        {flag === 0 && (
                          <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                        )}
                        {flag === 1 && (
                          <span>{i18n.t("medegdel_aldaa_1")}</span>
                        )}
                        {flag === 2 && (
                          <span>{i18n.t("medegdel_aldaa_card_declined")}</span>
                        )}
                        {flag === 3 && (
                          <span>
                            {i18n.t("medegdel_aldaa_card_burtgeltei")}
                          </span>
                        )}
                        {flag === 4 && (
                          <span>{i18n.t("medegdel_aldaa_card_hyzgaar")}</span>
                        )}
                      </Alert>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
            <Row>
              {utguud &&
                utguud.map((each, index) => {
                  const myCard = each.card;
                  return (
                    <Col lg="3" md="6" sm="12" xs="12" key={index}>
                      <Card
                        className="ungu-primary"
                        style={{
                          height: "25vh",
                          borderColor: "black",
                          borderWidth: 1,
                          backgroundImage: "url('../../../../../img/card.png')",
                          color: "white",
                        }}
                      >
                        <CardHeader
                          align="right"
                          onClick={() => this.dialogUstgahToggle(each)}
                          style={{ cursor: "pointer" }}
                        >
                          {i18n.t("ustgah")}
                        </CardHeader>
                        <CardBody>
                          <Row style={{ position: "absolute", bottom: "2vh" }}>
                            <Col>
                              <div align="left">
                                <Label style={{ color: "white" }}>
                                  {i18n.t("card_number")} :{" "}
                                </Label>{" "}
                                **** **** **** {myCard.last4}
                                <br />
                                <Label style={{ color: "white" }}>
                                  {i18n.t("valid_thru")} :{" "}
                                </Label>{" "}
                                {myCard.exp_month}/{myCard.exp_year}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              <Col lg="3" md="6" sm="12" xs="12">
                <Card
                  className="ungu-primary"
                  style={{ color: "white", height: "25vh" }}
                  onClick={this.dialogToggle}
                >
                  <CardBody>
                    <Row>
                      <Col align="center">
                        <div style={{ marginTop: "10vh", marginBottom: 65 }}>
                          {i18n.t("shine_card_nemeh")}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            <div align="center">
              <Elements stripe={stripePromise}>
                <CardNemeh add_card={this.addNewCard} />
              </Elements>
            </div>
          </ModalBody>
        </Modal>

        {dialogUstgahTuluv && (
          <Modal
            isOpen={dialogUstgahTuluv}
            toggle={() => this.dialogUstgahToggle({})}
          >
            <ModalHeader toggle={() => this.dialogUstgahToggle({})} />
            <ModalBody>
              <div align="center">
                <div>{i18n.t("card_ustgah_alert")}</div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div style={{ width: "100%", justifyContent: "space-between" }}>
                <Button
                  color="info"
                  size="sm"
                  style={{ width: "49%" }}
                  onClick={() => this.dialogUstgahToggle({})}
                >
                  {i18n.t("bolih")}
                </Button>
                <Button
                  className="ungu-primary"
                  size="sm"
                  style={{ width: "49%" }}
                  onClick={() => {
                    this.dialogUstgahToggle({});
                    this.ustgahDarahad(songogdsonMor);
                  }}
                >
                  {i18n.t("urgeljluuleh")}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    card_jagsaalt: state.stripeReducer.card_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { cardJagsaaltFn, addCustomerStripeCard, delCustomerStripeCard },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardInfo)
);
