const hel = {
  undsen_garchig: "Үндсэн",
  baiguullaga_garchig: "Байгууллага мэдээлэл",
  uilchilgee_garchig: "Үйлчилгээ бүртгэл",
  ajiltan_garchig: "Ажилтан бүртгэл",
  shiree_garchig: "Ширээ бүртгэл",
  tulbur_garchig: "Төлбөр тооцоо",
  zahialga_garchig: "Захиалгын жагсаалт",
  zahialga_tuuh_garchig: "Захиалгын түүх",
  ajiltan_uilchilgee_garchig: "Ажилтны үйлчилгээнүүд",
  ajiltan_chuluu_garchig: "Ажилтан чөлөө",
  dotroos_tsag_garchig: "Захиалга бүртгэх",
  shiree_block_garchig: "Ширээ блоклох",
  subscription_garchig: "Subscription",
  subsc_active_garchig: "Active subscs report",
  subsc_sold_garchig: "Sold report",
  subsc_get_garchig: "Item Get report",
  service_subsc_garchig: "Service subscriptions",
  cards_garchig: "Карт бүртгэл",
  tickets_garchig: "Service subsc list",
  service_subsc_history_garchig: "Service төлбөрийн түүх",
  nevtreh: "Нэвтрэх",
  burtguuleh: "Бүртгүүлэх",
  garah: "Гарах",
  nevtreh_hayag: "Нэвтрэх хаяг",
  nuuts_ug: "Нууц үг",
  nuuts_ug_davt: "Нууц үг давтах",
  utasnii_dugaar: "Утасны дугаар",
  ner: "Нэр",
  hadgalah: "Хадгалах",
  baiguullaga: "Байгууллага",
  uilchilgee: "Үйлчилгээ",
  uilchilgeenii_ner: "Үйлчилгээний нэр",
  undsen_tarif: "Үндсэн тариф минимум",
  undsen_tarif_max: "Үндсэн тариф максимум",
  muchlug_baiguullaga_burtgel: "Booking Period (in minutes)(just in case)",
  muchlug: "Мөчлөг (минутаар)",
  tailbar: "Тайлбар",
  tuluv: "Төлөв",
  uildel: "Үйлдэл",
  nemeh: "Шинээр нэмэх",
  zasah: "Засах",
  bolih: "Болих",
  ajiltan: "Ажилтан",
  ovog: "Овог",
  utas: "Утасны дугаар",
  namtar: "Намтар",
  medegdel_aldaa_nevtreh: "Нэвтрэх нэр эсвэл нууц үг буруу байна.",
  medegdel_aldaa_1: "Алдаа гарлаа. Дахин оролдоно уу.",
  medegdel_aldaa_nuutsug_ylgaatai: "Нууц үг таарахгүй байна",
  medegdel_aldaa_burtgeltei: "Бүртгэлтэй хэрэглэгч байна. Нэвтэрнэ үү.",
  medegdel_amjilt_burtgegdsen: "Амжилттай бүртгэгдлээ",
  medegdel_amjilt_ilgeesen: "Код илгээгдлээ.",
  medegdel_aldaa_kod: "Код буруу байна.",
  medegdel_amjilt_batalgaajsan: "Амжилттай баталгаажлаа",
  medegdel_amjilt_1: "Амжилттай",
  medegdel_aldaa_burtgelgui: "Бүртгэлгүй дугаар байна.",
  medegdel_aldaa_kod_tur_hulee:
    "Код илгээхэд алдаа гарлаа та 1 минут хүлээгээд дахин оролдоно уу.",
  medegdel_amjilt_nuutsug_soligdson: "Нууц үг амжилттай солигдлоо.",
  medegdel_aldaa_nuutsug_soligdoogui:
    "Нууц үг солих амжилтгүй боллоо. Дахин оролдоно уу.",
  medegdel_aldaa_validation: "Таны оруулсан утга алдаатай байна засна уу.",
  medegdel_aldaa_nuutsug_buruu: "Нууц үг таарсангүй.",
  medegdel_amjilt_soligdson: "Амжилттай солигдлоо",
  medegdel_aldaa_uilchilgeenii_nohtsol:
    "Үйлчилгээний нөхцөл зөвшөөрснөөр бүртгүүлэх боломжтой.",
  medegdel_aldaa_tsag_zahialgatai:
    "Энэ цаг дээр захиалгатай тул өөрчлөх боломжгүй",
  medegdel_aldaa_card_declined: "Card declined",
  medegdel_aldaa_card_burtgeltei: "Бүртгэлтэй карт байна",
  medegdel_amjilt_ustsan: "Амжилттай устлаа",
  nuutsug_martsan: "Нууц үг мартсан ?",
  nuutsug_sergeeh: "Нууц үг сэргээх",
  urgeljluuleh: "Үргэлжлүүлэх",
  batalgaajuulah: "Баталгаажуулах",
  batalgaajuulah_kod: "Баталгаажуулах код",
  nuutsug_solih: "Нууц үг солих",
  shine_nuuts_ug: "Шинэ нууц үг",
  tsag_tulbur: "Цаг авах төлбөр",
  zurag: "Зураг",
  shiree_dugaar: "Ширээний дугаар",
  min_hunii_too: "Хүний тоо (доод)",
  max_hunii_too: "Хүний тоо (дээд)",
  zahialga_une: "Захиалгын үнэ",
  ognoo: "Огноо",
  ehleh_tsag: "Эхлэх цаг",
  duusah_tsag: "Дуусах цаг",
  hereglegch_ner: "Хэрэглэгчийн нэр",
  hereglegch_utas: "Хэрэглэгчийн утас",
  haih: "Хайх",
  ajiltan_haih: "Ажилтнаар хайх",
  shiree_haih: "Ширээгээр хайх",
  ognoo_haih: "Огноогоор хайх",
  ajiltan_ner: "Ажилтны нэр",
  tulbur: "Төлбөр",
  niit_mongon_dun: "Нийт мөнгөн дүн",
  shimtgel: "Шимтгэл",
  shiljuulsen_dun: "Шилжүүлсэн дүн",
  dansnii_uldegdel: "Дансны үлдэгдэл",
  validation_isZaaval: "Хоосон байж болохгүй. ",
  validation_isNuutsug:
    "Үсэг тоо заавал орсон байх. 8с илүү урттай байх. !@#$%^&* орж болно",
  validation_isDanToo: "Дан тоо байх. ",
  validation_isDanText: "Дан үсэг байх. ",
  validation_isDanTextSpacetei: "Зөвхөн үсэг болон зай авах боломжтой",
  validation_isTemdegttei: "-()_/.,: -с бусад тэмдэгтүүд ороогүй байх. ",
  validation_isTemdegtgui: "Тэмдэгт ороогүй байх. ",
  validation_isTentsuu: "Урт {{urt}} байх. ",
  validation_isUrtMax: "Урт {{urt}}-с бага байх. ",
  validation_isUrtMin: "Урт {{urt}}-с их байх. ",
  validation_isUrtHoorond: "Урт {{urtMin}}, {{urtMax}} хооронд байх. ",
  validation_isEmail: "И-мэйл хаяг оруулна уу.",
  validation_isUtas: "Утасны дугаар 10 оронтой байх ёстой.",
  validation_isTsag: "Цаг оруулна уу",
  validation_isOgnoo: "Огноо оруулна уу",
  validation_isMongo: "Мөнгөн дүн оруулна уу (.00)",
  validation_isTokenId: "Validation давсангүй",
  validation_isLongitudeLatitude: "Зөв байршил сонгоно уу",
  validation_isUtgaMin: "Утга {{urt}}-с их байх. ",
  validation_isUtgaMax: "Утга {{urt}}-с бага байх. ",
  validation_isHuvi: "Хувь оруулна уу. (.000)",
  dahin_kod_avah: "Дахин код авах",
  tooluur_text: " {{tooluur}} секундийн дараа дахин код авах боломжтой.",
  butsah: "Буцах",
  baiguullaga_ner: "Байгууллагын нэр",
  hayag: "Хаяг",
  email: "И-мэйл хаяг",
  baiguullaga_medeelel: "Байгууллагын мэдээлэл",
  hereglegch_medeelel: "Хэрэглэгчийн мэдээлэл",
  tsag_medeelel: "Цагийн хуваарь",
  husnegt_hooson: "Мэдээлэл олдсонгүй",
  amralt1_ehleh_tsag: "Амралт 1 эхлэх цаг",
  amralt1_duusah_tsag: "Амралт 1 дуусах цаг",
  amralt2_ehleh_tsag: "Амралт 2 эхлэх цаг",
  amralt2_duusah_tsag: "Амралт 2 дуусах цаг",
  undsen_tsagiin_huvaari: "Үндсэн",
  ontsgoi_tsagiin_huvaari: "Онцгой",
  is_amrah: "Ажиллахгүй",
  solih_zuvshuurugduh_tsag: "Цаг сольж болох зөвшөөрөгдөх цаг (цагаар)",
  zahialga_umnuh_tsag:
    "Одоогоос хойш ямар хугацаанд цаг авахгүй хугацаа (минутаар)",
  zogsooltoi_eseh: "Зогсоолтой эсэх",
  delgerengui_medeelel: "Дэлгэрэнгүй мэдээлэл",
  tulbur_buren_hiigdsen_eseh: "Төлбөр бүрэн хийгдсэн эсэх",
  tax: "Такс",
  zahialga_avah_muchlug: "Захиалга авах мөчлөг (өдрөөр)",
  tsutslagdsand_tootsoh_tsag: "Захиалга цуцлагдсанд тооцох цаг (минутаар)",
  tsagaas_umnu: "Захиалсан цагаас өмнө ирэх",
  garag: "Гараг",
  davaa: "Даваа",
  mygmar: "Мягмар",
  lhagva: "Лхагва",
  purev: "Пүрэв",
  baasan: "Баасан",
  byamba: "Бямба",
  nyam: "Ням",
  uilchilgeenii_turul: "Үйлчилгээний төрөл",
  utas_haragdah: "Утасны дугаар (Хэрэглэгчид харагдана)",
  tuluv_idevhtei: "Төлөв (идэвхтэй)",
  idevhtei: "Идэвхтэй",
  idevhgui: "Идэвхгүй",
  admin_hereglegch: "Админ хэрэглэгч",
  sub_hereglegch: "Жирийн хэрэглэгч",
  nuuts_ug_oruul: "Нууц үгээ оруулна уу",
  nevtrelt_batalgaajuulah_anhaaruulah:
    "Таны бүртгэлтэй и-мэйл хаягруу баталгаажуулах код илгээсэн. Код ирээгүй бол spam хуудас шалгана уу.",
  status_solih: "Статус солих",
  uilchilgee_nuhtsul_text: "Та энд дарж үйлчилгээний нөхцөлтэй танилцна уу.",
  zovshooroh: "Зөвшөөрөх",
  medeelel_shinechleh: "Мэдээлэл шинэчлэх",
  nas_asuudag_eseh_paid: "Require age confirmation for Paid Booking",
  nas_asuudag_eseh_free: "Require age confirmation for Free Booking",
  nas_asuudag_eseh_subsc: "Require age confirmation",
  hyzgaarlah_nas: "Хязгаарлах нас",
  haih_tovch_ner: "Хайх товч нэр",
  baiguullaga_temdegt_zurag: "Байгууллагын зураг",
  shiree_bairlal_zurag: "Ширээний байрлал",
  tses_zurag: "Цэс",
  dans_ner: "Данс эзэмшигчийн нэр",
  dans_dugaar: "Дансны дугаар",
  jijeg_turul: "Жижиг төрөл",
  nickname: "Nickname",
  ajiltan_detail_haruulna: "Хэрэглэгчид харуулна",
  valiut: "Валиут",
  is_eco: "Эко эсэх",
  udur_limit: "Өдрийн хязгаар",
  sar_limit: "Сарын хязгаар",
  medeelel: "Мэдээлэл",
  negj_une: "Нэгж үнэ",
  used_amount: "Хэрэглэсэн тоо",
  orlogo: "Орлого",
  id: "Дугаар",
  view_subsc: "Subscription info",
  orlogo_tax: "Татварын орлого",
  subsc_price: "Subscription үнэ",
  cashback_huselt: "Мөнгө авах хүсэлт илгээх",
  mongon_dun: "Мөнгөн дүн",
  ognoo_huselt_gargasan: "Хүсэлт гаргасан огноо",
  ognoo_shiljuulsen: "Шилжүүлсэн огноо",
  default_service_subsc: "Үндсэн үйлчилгээнүүд",
  custom_service_subsc: "Санал болгох үйлчилгээнүүд",
  service_subsc_ner: "Үйлчилгээний нэр",
  service_subsc_turul: "Үйлчилгээний төрөл",
  service_subsc_une: "Суурь хураамж (Сараар)",
  subscribe: "Subscribe хийх",
  card_number: "Card number",
  valid_thru: "Valid until",
  service_subsc_period: "Хүчинтэй огноо",
  service_subsc_ognoo: "Хугацаа",
  subsc_service: "Subscription үйлчилгээний эрх",
  tsag_zahialga_service: "Цаг захиалга үйлчилгээний эрх",
  preperation_fee_service: "Төлбөртэй цаг захиалга үйлчилгээний эрх",
  service_subsc_shimtgel: "Шимтгэл",
  service_subsc_niit_une: "Нийт үнэ",
  tulbur_helber_songoh: "Төлбөрийн хэлбэр ээ сонгоно уу",
  credit_card: "Credit card",
  paypal: "Paypal",
  shine_card_nemeh: "Шинэ карт бүртгэх",
  tulbur_tuluh: "Төлбөр төлөх",
  ustgah: "Устгах",
  muj: "Муж",
  durem: "Дүрэм",
  hst_dugaar: "HST дугаар",
  pst_dugaar: "PST дугаар",
  qst_dugaar: "QST дугаар",
  gst_dugaar: "GST дугаар",
  banner_tulbur_tuluugui:
    "Та үйлчилгээний суурь хураамжаа төлөөгүй байна. Мэдээлэл харах бол энд дарна уу",
  une: "Үнэ",
  negjiin_une: "Нэгжийн үнэ",
  niit_une: "Нийт үнэ",
  uls: "Улс",
  discount_percent: "Хямдралын хувь",
  medegdel_aldaa_staff_count: "Таны бүртгэх эрхийн тоо хэтэрсэн байна",
  medegdel_aldaa_tsag_davhtsaj_baina:
    "Энэ цаг дээр захиалгатай байгаа тул боломжгүй",
  tsagiin_bus: "Цагийн бүс",
  medegdel_aldaa_validation_aldaa_zas: "Алдаанууд засна уу",
  ssubsc_sard: "month",
  ssubsc_batalgaajuulah_dahin_subsc:
    "Танд энэ идэвхтэй цаг захиалгын үйлчилгээ байна.Subscribe хийснээр өмнөх subscription хүчингүй болох бөгөөд төлбөр буцаагдахгүй гэдгийг анхаарна уу. Давхар subscribe хийхдээ итгэлтэй байвал үргэлжлүүлэх дарна уу.",
  service_subsc_count_choose_label_staff:
    "Хэрэв та цаг захиалах Service -н эрх авах гэж байгаа бол хэдэн ажилтан бүртгэх тоогоо энд оруулснаар subscription харагдах болно",
  service_subsc_count_choose_label_table:
    "Хэрэв та цаг захиалах Service -н эрх авах гэж байгаа бол хэдэн ширээ бүртгэх тоогоо энд оруулснаар subscription харагдах болно",
  count_table_label: "Бүртгэх боломжтой ширээний тоо",
  count_staff_label: "Бүртгэх боломжтой ажилтны тоо",
  ssubsc_button_zogsooh: "Subscription зогсоох",
  ssubsc_text_zogsson: "Subcription зогссон",
  ssubsc_ticket_list: "Авсан үйлчилгээний жагсаалт",
  subscription_deactivate: "Subscription идэвхгүй болгох",
  subsc_deactivate_asuult_text:
    "Subscription deactivate хийснээр та дахин subscription-г сэргээх боломжгүй бөгөөд энэ subscription-г худалдан авсан бүх хэрэглэгчдийн subscription дахин сунгагдахгүй. Та зөвшөөрч байвал үргэлжлүүлэх дарна уу.",
  map_tailbar_text: "Marker-г чирж байрлуулна уу",
  bank_ner: "Банкны нэр",
  branch_ner: "Салбар нэр",
  branch_code: "Branch дугаар",
  hugatsaa: "Хугацаа",
  timeline_locked: "Захиалга хийгдэж байгаа ( баталгаажаагүй )",
  timeline_active: "Захиалга баталгаажсан",
  timeline_hooson: "Цаг аваагүй",
  timeline_ajiltan_chuluu: "Ажилтан чөлөө авсан",
  timeline_zahialga_dotroos: "Гараар бүртгэсэн цаг",
  tiim: "Тийм",
  ugui: "Үгүй",
  medegdel_aldaa_tsag_burtgeltei:
    "Тухайн огноо дээр бүртгэлтэй хуваарь байгаа тул засна уу.",
  tulbur_heregsel: "Төлбөрийн хэрэгсэл",
  local_tax_label: "Local tax % ( Үйлчилгээ үзүүлэх )",
  local_tax_warning:
    "Local tax-н хувь буруу оруулснаас болж гарсан асуудлыг байгууллага өөрөө хариуцна гэдгийг анхаарна уу",
  burtgel_warning: "Бүртгүүлснээр таныг term condition-г зөвшөөрсөнд тооцно",
  state_tax: "State tax",
  local_tax: "Local tax",
  nuuts_ug_shine: "Шинэ нууц үг",
  nevtreh_hayag_shine: "Шинэ нэвтрэх хаяг",
  burtgel_nemelt_medeelel_avne_text:
    "Бүртгэл баталгаажуулахад нэмэлт мэдээлэл шаардаж болохыг анхаарна уу",
  tulugduugui: "Төлөгдөөгүй",
  borluulaltiin_orlogoos: "Борлуулалтын орлогоос",
  dansaar: "Дансаар",
  free_trial: "Free trial",
  company_number: "Компани дугаар",
  company_name: "Компани нэр",
  email_tailbartai_garchig:
    "И-мэйл ( Нэвтрэх үед баталгаажуулахыг анхаарж зөв мэйл оруулна уу )",
  email_tailbar: "Нэвтрэх үед баталгаажуулахыг анхаарж зөв мэйл оруулна уу",
  zogsoohdoo_itegeltei_eseh: "Зогсоохдоо итгэлтэй байна уу?",
  zogsooh_anhaaruulah_text_subsc:
    "Service зогсоосноор үйлчилгээ дахин сунгагдахгүй бөгөөд бүртгэлтэй бүх subscription устах болно. Үйлчлүүлэгч дахин шинээр subscription авах боломжгүй болно. Харин хуучин subscription авсан хэрэглэгчдэд заавал үйлчилгээгээ үзүүлэх ёстойг анхаарна уу. Итгэлтэй байвал зөвшөөрөх дарна уу",
  zogsooh_anhaaruulah_text_tsag:
    "Service зогсоосноор үйлчилгээ дахин сунгагдахгүй. Үйлчлүүлэгч дахин шинээр үйлчилгээ авах боломжгүй болно. Харин хуучин үйлчилгээ авсан хэрэглэгчдэд заавал үйлчилгээгээ үзүүлэх ёстойг анхаарна уу. Итгэлтэй байвал зөвшөөрөх дарна уу",
  stripe: "Credit card ( Stripe )",
  suutgal: "Суутгал",
  negj_une_undsen: "Үндсэн нэгж үнэ",
  times_text: "удаа",
  times_text_label: "Хэрэглэх тайлбар текст",
  card_ustgah_alert:
    "Хэрвээ энэ картаар subscription авсан бол карт устгаснаар таны авсан subscription сунгагдахгүй байж болзошгүйг анхаарна уу. Та энэ үйлдэл хийхдээ итгэлтэй байна уу",
  erh_zogsooh: "Үйлчилгээний эрх зогсоох",
  ticket_zogsooh_text:
    "Subscription зогсоосноор зөвхөн холбсон картаас автоматаар татах зогсох бөгөөд таны авсан үйлчилгээний эрх зогсохгүй гэдгийг анхаарна уу",
  ssubsc_erh_text_zogsson: "Эрх зогсоогдсон",
  medegdel_aldaa_refreshdate: "Амжилтгүй. Эрх дуусах өдөр зогсоох боломжгүй",
  batalgaajuulah_utas_tailbar_text:
    "Таны оруулсан {{utas}} дугаарт 6 оронтой код илгээсэн тул та кодоо оруулан баталгаажуулна уу",
  batalgaajuulah_email_tailbar_text:
    "Таны оруулсан {{email}} хаягруу 6 оронтой код илгээсэн тул та кодоо оруулан баталгаажуулна уу",
  medegdel_aldaa_kod_limit_utas:
    "Өнөөдрийн мессеж хязгаар тулсан тул маргааш дахин оролдоно уу",
  medegdel_aldaa_kod_limit_email:
    "Өнөөдрийн и-мэйл хязгаар тулсан тул маргааш дахин оролдоно уу",
  tsag: "Цаг",
  shiree: "Ширээ",
  niit_guilgee: "Нийт гүйлгээ",
  guilgeenii_turul: "Гүйлгээний төрөл",
  tsag_zahialga: "Цаг захиалга",
  subscription: "Subsc",
  get: "Хэрэглэсэн",
  busad: "Бусад",
  active: "Идэвхтэй",
  inactive: "Идэвхгүй",
  aldaa: "Алдаа",
  tsag_duussan: "Цаг өнгөрсөн",
  locked: "Гүйлгээ хүлээгдэж байгаа",
  completed: "Гүйцэтгэсэн",
  pending: "Төлбөр хүлээгдэж байгаа",
  cashback_dugaar: "Тооцоо хийгдсэн эсэх",
  hiigdsen: "Хийгдсэн",
  hiigdeegui: "Хийгдээгүй",
  uusgesen_ognoo: "Гүйлгээний огноо",
  sum: "Нийт",
  text_select_card: "Картаа сонгосны дараа төлбөр төлөх товч дарна уу",
  ajiltan_too: "Ажилтан эрхийн тоо",
  idevhjuuleh: "Идэвхжүүлэх",
  commission_fee: "Commission fee",
  bolson: "Тооцоо хийгдсэн",
  boloogui: "Тооцоо хийгдээгүй",
  hagas: "Тооцооны үлдэгдэлтэй",
  tootsoo_delgerengui: "Тооцоо дэлгэрэнгүй",
  tootsoonii_uldegdel: "Тооцооны үлдэгдэл",
  active_tootsoo: "Тооцоо хийгдсэн",
  completed_tootsoo: "Тооцоо хийгдсэн",
  pending_tootsoo: "Хүлээгдэж байгаа",
  privacypolicy_garchig: "Privacy Policy",
  termofservice_garchig: "Terms Of Service",
  sum_before_tax: "Sum before tax",
  service_fee: "Service fee",
  ognoo_haih_transaction: "Search by transaction date",
  transaction_time: "Transaction time",
  expired: "Expired",
  tsutsalsan: "Booking canceled",
  ognoo_booking: "Booking date",
  shop_commission: "Shop Commission to YouSubsc",
  user_commission: "User Commission to YouSubsc",
  total_sum_toshop: "To Shop Total",
  ner_subsc: "Subsc name",
  negj_une_subsc: "Subsc unit price",
  sum_toshop: "To Shop Total",
  HST_toshop: "To Shop HST",
  GST_toshop: "To Shop GST",
  PST_toshop: "To Shop PST",
  QST_toshop: "To Shop QST",
  state_tax_toshop: "To Shop state tax",
  local_tax_toshop: "To Shop local tax",
  tsag_zahialga_turul: "Paid Booking",
  subscription_turul: "Subsc Purchase",
  get_turul: "Subsc Usage",
  busad_turul: "Other",
  tax_orlogo: "To Shop (Tax)",
  orlogo_niit: "To Shop (Total)",
  info: "Info",
  sub_pass_text:
    "The first password is the same as Admin user's password. Please change before using staff account",
  ontsgoi_tsag_ustgah_asuult: "Are you sure you want to delete this schedule?",
  email_shine: "New email address",
  solilt_batalgaajuulah: "Confirmation",
  medegdel_aldaa_card_hyzgaar: "Card registration limit has reached",
  medegdel_aldaa_uilchilgee_hyzgaar: "Service registration limit has reached",
  medegdel_amjilt_subsc_hyzgaar:
    "Subscription item registration limit has reached",
  validation_subsc_item_price_limit:
    "Subscription item price must in between {{min}} and {{max}}",
  medegdel_aldaa_staff_hyzgaar: "Employee registration limit has reached",
  medegdel_aldaa_table_hyzgaar: "Table registration limit has reached",
  medegdel_aldaa_item_deactivate_refreshdate:
    "Can't deactivate item between 8 PM to 5 AM",
  medegdel_aldaa_disable_solution_regreshdate:
    "Can't disable solution between 8 PM to 5 AM",
  category_tailbar_paid:
    "Applies to paid booking solution. App users pay to book. Shop needs to pay a basic monthly fee.",
  category_tailbar_free:
    "Applies to free booking solution. App users can book for free. Shop needs to pay a basic monthly fee. The basic monthly fee varies depending on the scale.",
  local_tax_rate: "Local tax rate",
  tmzone_change_alert_header: "Changing time zone, Are you sure? ",
  tmzone_change_alert_text: "Changing time zone ........ text",
  register_notes: "register_notes",
  register_req_subsc: "register_req_subsc",
  register_req_booking: "register_req_booking",
  register_req_booking_unegui: "register_req_booking_unegui",
  register_req_booking_unegui_too: "register_req_booking_unegui_too",
  uichilgee_turul_request_garchig: "Category request",
  ognoo_haih_booking: "Search by booking date",
  id_garchig: "id",
  niit_mongon_dun_detail: "Total amount",
  shiljuulsen_dun_detail: "Total Transferred to shop",
  suutgal_detail: "Basic Monthly Fee",
  solution_request_label: "Which solutions do you want to use",
  ajiltan_uilchilgee_nemeh: "Add service to employee",
  business_account_nevtreh: "YouSubsc Business Login",
  medegdel_aldaa_hyzgaar_absense: "Absense limit has reached",
  price_discount_alert: "Price must be at least 5% off. Now {{discount}}% off.",
};
export default hel;
