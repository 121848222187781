import i18n from "hel/i18n";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { tsagAvah } from "validation";
import { undsenJagsaaltFn } from "store/actions/uilajillagaa.actions";
import {
  ajiltanJagsaaltFn,
  shireeJagsaaltFn,
} from "store/actions/baiguullaga.actions";
import LoginAuth from "services/LoginAuth";
import UndsenComp from "components/UndsenComp";
import MyLoader from "components/MyLoader";
import { ognooFormat_noTimezone } from "validation/uildluud";

class Undsen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTuluv: false,
      songogdsonMor: {},
      validationHariu: {},
      garchignuud: [],
      utguud: [],
      undsen_jagsaalt: [],
      staff_jagsaalt: [],
      ajiltan_jagsaalt: [],
      shiree_jagsaalt: [],
      unshijBaigaa: false,
      layout_turul: LoginAuth.layoutTurulAvah(),
      ognooHyzgaarMax: tsagAvah("zahialgaAvahHonogLimit"),
      ognooHyzgaarMin: tsagAvah("onoodor"),
      songodsonOgnoo: tsagAvah("onoodor"),
    };
    this.ognooSongohod = this.ognooSongohod.bind(this);
    this.shinechleh = this.shinechleh.bind(this);
  }
  componentDidMount() {
    if (this.state.layout_turul === 1) {
      this.props.shireeJagsaaltFn();
    } else {
      this.props.ajiltanJagsaaltFn();
    }
    this.shinechleh();
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.undsen_jagsaalt !== state.undsen_jagsaalt) {
      let undsen_jagsaalt = props.undsen_jagsaalt ? props.undsen_jagsaalt : [];
      hariu.undsen_jagsaalt = props.undsen_jagsaalt
        ? props.undsen_jagsaalt
        : [];
      hariu.utguud = undsen_jagsaalt.filter(
        (utga) =>
          // ognooFormat_noTimezone(utga.zahialsan_ognoo, "YYYY-MM-DD") ===
          utga.zahialsan_ognoo ===
          state.songodsonOgnoo
      );
    }
    if (props.ajiltan_jagsaalt !== state.ajiltan_jagsaalt) {
      let ajiltan_jagsaalt = props.ajiltan_jagsaalt
        ? props.ajiltan_jagsaalt
        : [];
      hariu.ajiltan_jagsaalt = props.ajiltan_jagsaalt;
      hariu.staff_jagsaalt = ajiltan_jagsaalt;
    }
    if (props.shiree_jagsaalt !== state.shiree_jagsaalt) {
      let shiree_jagsaalt = props.shiree_jagsaalt ? props.shiree_jagsaalt : [];
      hariu.shiree_jagsaalt = props.shiree_jagsaalt;
      hariu.staff_jagsaalt = shiree_jagsaalt;
    }
    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  ognooSongohod(event) {
    this.setState({
      unshijBaigaa: true,
    });
    this.props
      .undsenJagsaaltFn()
      .then((hariu) => this.setState({ unshijBaigaa: false }));
    let ognoo = event.target.value;
    console.log("*** songogdson ognoo ***", event.target.value);
    const { undsen_jagsaalt } = this.state;
    let utguud = [];
    if (ognoo === "") {
      ognoo = tsagAvah("onoodor");
    }
    utguud = undsen_jagsaalt.filter(
      (utga) =>
        ognooFormat_noTimezone(utga.zahialsan_ognoo, "YYYY-MM-DD") === ognoo
    );
    console.log("*** undsen jagsaalt ***", utguud);

    this.setState({
      utguud,
      songodsonOgnoo: ognoo,
    });
  }

  shinechleh() {
    const onoodor = tsagAvah("onoodor");
    this.setState({
      songodsonOgnoo: onoodor,
    });
    this.setState({
      unshijBaigaa: true,
    });
    this.props
      .undsenJagsaaltFn()
      .then((hariu) => this.setState({ unshijBaigaa: false }));
  }

  render() {
    const {
      ognooHyzgaarMax,
      ognooHyzgaarMin,
      staff_jagsaalt,
      utguud,
      songodsonOgnoo,
      unshijBaigaa,
    } = this.state;
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <div>
                <Row>
                  <Col md="4" xs="12">
                    <Button
                      className="ungu-primary"
                      round="true"
                      outline
                      size="md"
                      onClick={this.shinechleh}
                    >
                      {i18n.t("medeelel_shinechleh")}
                    </Button>
                  </Col>
                  <Col md="4" />
                  <Col md="4" xs="12">
                    <Label>{i18n.t("ognoo_haih")}</Label>
                    <Input
                      type="date"
                      name="date"
                      value={songodsonOgnoo}
                      placeholder="date placeholder"
                      max={ognooHyzgaarMax}
                      min={ognooHyzgaarMin}
                      onChange={this.ognooSongohod}
                    />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <div className="timeline-container">
            {!!staff_jagsaalt &&
              staff_jagsaalt.length > 0 &&
              staff_jagsaalt.map((staff, index) => {
                return (
                  <div key={index}>
                    <div className="timetable">
                      <UndsenComp
                        staff={staff}
                        zahialguud={utguud}
                        songogdsonOgnoo={songodsonOgnoo}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { undsen_jagsaalt } = state.uilajillagaaReducer;
  const { ajiltan_jagsaalt, shiree_jagsaalt } = state.baiguullagaReducer;
  return {
    undsen_jagsaalt,
    ajiltan_jagsaalt,
    shiree_jagsaalt,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      undsenJagsaaltFn,
      ajiltanJagsaaltFn,
      shireeJagsaaltFn,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Undsen));
