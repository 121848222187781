import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "perfect-scrollbar/css/perfect-scrollbar.css";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "delgetsuud/zagvar/zagvar.css";

import { Provider } from "react-redux";

import App from "./App";
import store from "storeConfig";
import Nevtreh from "delgetsuud/gadnaas/Nevtreh";
import Burtguuleh from "delgetsuud/gadnaas/Burtguuleh";
import NuutsugSergeeh from "delgetsuud/gadnaas/NuutsugSergeeh";
import Batalgaajuulah from "delgetsuud/gadnaas/Batalgaajuulah";
import NuutsugSolih from "delgetsuud/gadnaas/NuutsugSolih";
import NuutsugBatalgaajuulah from "delgetsuud/gadnaas/NuutsugBatalgaajuulah";
import BaiguullagaBurtgel from "delgetsuud/gadnaas/BaiguullagaBurtgel";
import NevtreltBatalgaajuulah from "delgetsuud/gadnaas/NevtreltBatalgaajuulah";
import BatalgaajuulahNew from "delgetsuud/gadnaas/BatalgaajuulahNew";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/login" render={(props) => <Nevtreh {...props} />} />
        <Route
          path="/register"
          render={(props) => <Burtguuleh {...props} />}
        />
        <Route
          path="/batalgaajuulah"
          render={(props) => <Batalgaajuulah {...props} />}
        />
        <Route
          path="/burtgel/batalgaajuulah"
          render={(props) => <BatalgaajuulahNew {...props} />}
        />
        <Route
          path="/nuutsugSergeeh"
          render={(props) => <NuutsugSergeeh {...props} />}
        />
        <Route
          path="/nuutsug/batalgaajuul"
          render={(props) => <NuutsugBatalgaajuulah {...props} />}
        />
        <Route
          path="/nuutsug/solih"
          render={(props) => <NuutsugSolih {...props} />}
        />
        <Route
          path="/baiguullaga/burtgel"
          render={(props) => <BaiguullagaBurtgel {...props} />}
        />
        <Route
          path="/nevtrelt/batalgaajuulah"
          render={(props) => <NevtreltBatalgaajuulah {...props} />}
        />
        <Route path="/" render={(props) => <App {...props} />} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
