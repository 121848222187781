import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { Button } from "reactstrap";
import i18n from "hel/i18n";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

class CardNemeh extends React.Component {
  nemehDarahad = async (event) => {
    event.preventDefault();

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    const result = await stripe.createToken(card);

    if (result.error) {
      if (result.error.type === "validation_error") {
        this.props.add_card(result.token, result.error.code);
      } else if (result.error.type === "card_error") {
        this.props.add_card(result.token, result.error.code);
      }
    } else {
      this.props.add_card(result.token, null);
    }
  };

  render() {
    return (
      <div style={{ width: "80%", marginTop: "5%" }}>
        <form onSubmit={this.dialogToggle}>
          <div>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
          <div
            style={{
              marginTop: "5%",
            }}
            align="right"
          >
            <Button
              className="ungu-primary"
              size="sm"
              onClick={this.nemehDarahad}
            >
              {i18n.t("nemeh")}
            </Button>{" "}
            <Button color="secondary" size="sm" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardNemeh {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
