export const ungu = {
  tsaivar_saaral: "#E0E0E0",
  tsagaan: "#ffffff",
  har: "#000",
  main_dark_blue: "#006680",
  timeline_moment: "var(--main-dark-blue)",
  //timeline
  hooson: "transparent",
  locked:
    "linear-gradient(to right, rgba(255, 179, 217,0.4), rgba(187, 153, 255,0.4), rgba(255, 179, 217,0.4))",
  active:
    "linear-gradient(to right, rgba(119, 51, 255,0.4), rgba(77, 121, 255,0.4), rgba(119, 51, 255,0.4))",
  ajiltan_chuluu: "rgba(148, 148, 184,0.4)",
  zahialga_dotroos:
    "linear-gradient(to right, rgba(26, 178, 255,0.4), rgba(187, 153, 255,0.4), rgba(26, 178, 255,0.4))",
  songogdsonTsag: "rgba(102, 102, 255,0.4)",
  warning: "#ff9966",
};
