import { uilajillagaaConstants } from "store/constants";

const INITIAL_STATE = {
  zahialga_jagsaalt: [],
  zahialga_tuuh_jagsaalt: [],
  ajiltan_chuluu_jagsaalt: [],
  dotroos_tsag_jagsaalt: [],
  tulbur_tootsoo_jagsaalt: [],
  undsen_jagsaalt: [],
  niit_zahialga_jagsaalt: [],
};

const hariu = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case uilajillagaaConstants.ZAHIALGA_JAGSAALT:
      return { ...state, zahialga_jagsaalt: action.payload };
    case uilajillagaaConstants.ZAHIALGA_TUUH_JAGSAALT:
      return { ...state, zahialga_tuuh_jagsaalt: action.payload };
    case uilajillagaaConstants.AJILTAN_CHULUU_JAGSAALT:
      return { ...state, ajiltan_chuluu_jagsaalt: action.payload };
    case uilajillagaaConstants.DOTROOS_TSAG_JAGSAALT:
      return { ...state, dotroos_tsag_jagsaalt: action.payload };
    case uilajillagaaConstants.TULBUT_TOOTSOO_JAGSAALT:
      return { ...state, tulbur_tootsoo_jagsaalt: action.payload };
    case uilajillagaaConstants.UNDSEN_JAGSAALT:
      return {
        ...state,
        undsen_jagsaalt: action.payload,
      };
    case uilajillagaaConstants.NIIT_ZAHIALGA_JAGSAALT:
      return { ...state, niit_zahialga_jagsaalt: action.payload };
    default:
      return state;
  }
};

export default hariu;
