import React, { Component } from "react";
import { Row, Card, Col, CardBody, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import i18n from "hel/i18n";
import LoginAuth from "services/LoginAuth";
import { tsagAvah } from "validation";
import Husnegt from "components/Husnegt";
import { subscRepGetFn } from "store/actions/baiguullaga.actions";
import MyLoader from "components/MyLoader";
import currency from "currency.js";
class SubcsGet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unshijBaigaa: false,

      dialogTuluv: false,
      songogdsonMor: {},

      utguud: [],
      validationHariu: {},
      get_subsc_jagsaalt: [],
      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
      ognooHyzgaarMax: tsagAvah("ognooHyzgaarMax"),
      ognooHyzgaarMin: tsagAvah("ognooHyzgaarMin"),
      ognoo: tsagAvah("onoodor"),
      total_sum: 0,
      garchignuud: [
        {
          ner: "created",
          turul: "tsag",
          garchig: "tsag",
        },
        {
          ner: "ner",
          garchig: "ner_subsc",
        },
        {
          ner: "negj_une",
          garchig: "negj_une_subsc",
        },
        {
          ner: "baiguullaga_mungun_dun",
          garchig: "orlogo",
        },
        {
          ner: "orlogo_mungun_dun",
          garchig: "shop_commission",
        },
        // {
        //   ner: "subsc_id",
        //   garchig: "id",
        // },
      ],
    };
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.ognooSongohod = this.ognooSongohod.bind(this);
    this.shinechleh = this.shinechleh.bind(this);
  }
  componentDidMount() {
    this.setState({
      unshijBaigaa: true,
    });
    this.props.subscRepGetFn(this.state.ognoo).then((hariu) => {
      this.setState({
        unshijBaigaa: false,
      });
    });
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};

    if (props.get_subsc_jagsaalt !== state.get_subsc_jagsaalt) {
      let get_subsc_jagsaalt = props.get_subsc_jagsaalt
        ? props.get_subsc_jagsaalt
        : [];
      let total_sum = 0;
      get_subsc_jagsaalt.map((utga) => {
        total_sum = currency(Number(total_sum)).add(
          Number(utga.baiguullaga_mungun_dun)
        );
        return null;
      });
      hariu.get_subsc_jagsaalt = props.get_subsc_jagsaalt;
      hariu.utguud = get_subsc_jagsaalt;
      hariu.total_sum = total_sum;
    }

    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  husnegtTovchDaragdahad(utga, index, tovch) {
    if (tovch === "view_subsc") {
      // usreh
    }
  }

  ognooSongohod(event) {
    const ognoo = event.target.value;
    this.props.subscRepGetFn(ognoo);
    this.setState({ ognoo });
  }
  shinechleh() {
    const onoodor = tsagAvah("onoodor");
    this.setState({
      unshijBaigaa: true,
      ognoo: onoodor,
    });
    this.props.subscRepGetFn(onoodor).then((hariu) => {
      this.setState({
        unshijBaigaa: false,
      });
    });
  }
  render() {
    const {
      serviceSubsc_info,
      ognooHyzgaarMax,
      ognooHyzgaarMin,
      garchignuud,
      utguud,
      ognoo,
      unshijBaigaa,
      total_sum,
    } = this.state;
    if (serviceSubsc_info.subsc_service === false) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card className="card-user">
            <CardBody>
              <Row>
                <Col md="4">
                  <Label>{i18n.t("sum_toshop")}</Label>
                  <Input disabled value={total_sum} />
                </Col>
                <Col md="4" xs="12">
                  <Label>{i18n.t("ognoo_haih")}</Label>
                  <Input
                    type="date"
                    name="date"
                    placeholder="date placeholder"
                    max={ognooHyzgaarMax}
                    min={ognooHyzgaarMin}
                    value={ognoo}
                    onChange={this.ognooSongohod}
                  />
                </Col>
                <Col md="4" xs="12" style={{ textAlign: "right" }}>
                  <Button
                    className="ungu-primary"
                    round="true"
                    outline
                    size="md"
                    onClick={this.shinechleh}
                  >
                    {i18n.t("medeelel_shinechleh")}
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    // tovchtoi_eseh={true}
                    // tovch={["view_subsc"]}
                    // tovch_daragdahad={this.husnegtTovchDaragdahad}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { get_subsc_jagsaalt } = state.baiguullagaReducer;
  return { get_subsc_jagsaalt };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      subscRepGetFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubcsGet)
);
