import { hereglegchConstants } from "store/constants";

const INITIAL_STATE = {
  nevtrehHariu: "",
  aldaaNer: "",
  aldaaniiMessage: "",
  burtgehHariu: "",
};

const hariu = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case hereglegchConstants.ALDAA:
      return {
        ...state,
        aldaaniiMessage: action.payload,
        aldaaNer: action.ner,
      };
    case hereglegchConstants.NEVTREH_AMJILT:
      return { ...state, nevtrehHariu: action.payload };
    case hereglegchConstants.BURTGEL_AMJILT:
      return { ...state, burtgehHariu: action.payload };

    default:
      return state;
  }
};

export default hariu;
