import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Label,
  CardHeader,
  Input,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import {
  serviceSubscJagsaaltFn,
  ssubscTicketJagsaaltFn,
} from "../../../store/actions/ssubsc.actions";
import { tulburTuluhPaypalFn } from "../../../store/actions/stripe.actions";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
import { togtmol } from "delgetsuud/zagvar";
import TulburCardSongoh from "./tuslah/TulburCardSongoh";
import { hereglegchMe } from "store/actions/hereglegch.actions";

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import tohirgoo from "tohirgoo";
import { ungu } from "delgetsuud/zagvar";
import currency from "currency.js";
class ServiceSubsc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      medegdel: false,

      dialogTuluv: false,
      dialogTulburTuluv: false,
      dialogPaymentTuluv: false,
      dialogBatalgaajuulah: false,
      songogdsonMor: {},
      validationHariu: {},
      unshijBaigaa: false,
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
      layout_turul: LoginAuth.layoutTurulAvah(),
      songogdsonToo: 0,

      subsc_jagsaalt: [],
      tsag_jagsaalt: [],
      prepfee_jagsaalt: [],
      subsc_jagsaalt_custom: [],
      tsag_jagsaalt_custom: [],
      prepfee_jagsaalt_custom: [],

      ssubsc_ticket_jagsaalt: [],
      subsc_ticket_count: 0,
      tsag_ticket_count: 0,
      prep_ticket_count: 0,

      orderIdPaypal: false,
    };
    this.subscSongohod = this.subscSongohod.bind(this);
    this.tulburSongohod = this.tulburSongohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.dialogTulburToggle = this.dialogTulburToggle.bind(this);
    this.dialogPaymentToggle = this.dialogPaymentToggle.bind(this);
    this.subscribeDarahad = this.subscribeDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.dialogBatalgaajuulahToggle =
      this.dialogBatalgaajuulahToggle.bind(this);
    this.askPopup = this.askPopup.bind(this);
    this.tooSongohod = this.tooSongohod.bind(this);
    this.onApprove = this.onApprove.bind(this);
  }

  componentDidMount() {
    const info = LoginAuth.baiguullagaInfoAvah();
    if (info.tuluv !== "active") {
      this.props.history.push({ pathname: "/" });
    }
    this.setState({
      unshijBaigaa: true,
    });
    this.props.serviceSubscJagsaaltFn().then((hariu) => {
      this.setState({
        unshijBaigaa: false,
      });
    });
    this.props.ssubscTicketJagsaaltFn();
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.service_subsc_jagsaalt !== state.service_subsc_jagsaalt) {
      let service_subsc_jagsaalt = props.service_subsc_jagsaalt
        ? props.service_subsc_jagsaalt
        : [];
      const undsen_jagsaalt = service_subsc_jagsaalt.undsen
        ? service_subsc_jagsaalt.undsen
        : [];
      const custom_jagsaalt = service_subsc_jagsaalt.custom
        ? service_subsc_jagsaalt.custom
        : [];

      let subsc_jagsaalt = [];
      let tsag_jagsaalt = [];
      let prepfee_jagsaalt = [];

      let subsc_jagsaalt_custom = [];
      let tsag_jagsaalt_custom = [];
      let prepfee_jagsaalt_custom = [];
      undsen_jagsaalt.map((item) => {
        if (item.mungun_temdegt === state.serviceSubsc_info.temdegt) {
          if (item.uilchilgee_turul === "subsc_service") {
            subsc_jagsaalt.push(item);
          } else if (item.uilchilgee_turul === "tsag_zahialga_service") {
            tsag_jagsaalt.push(item);
          } else if (item.uilchilgee_turul === "preperation_fee_service") {
            prepfee_jagsaalt.push(item);
          }
        }
        return null;
      });
      custom_jagsaalt.map((item) => {
        if (item.mungun_temdegt === state.serviceSubsc_info.temdegt) {
          if (item.uilchilgee_turul === "subsc_service") {
            subsc_jagsaalt_custom.push(item);
          } else if (item.uilchilgee_turul === "tsag_zahialga_service") {
            tsag_jagsaalt_custom.push(item);
          } else if (item.uilchilgee_turul === "preperation_fee_service") {
            prepfee_jagsaalt_custom.push(item);
          }
        }
        return null;
      });
      tsag_jagsaalt.sort((a, b) => (a.ajiltan_too > b.ajiltan_too ? 1 : -1));
      hariu.service_subsc_jagsaalt = props.service_subsc_jagsaalt;
      hariu.subsc_jagsaalt = subsc_jagsaalt;
      hariu.tsag_jagsaalt = tsag_jagsaalt;
      hariu.prepfee_jagsaalt = prepfee_jagsaalt;
      hariu.subsc_jagsaalt_custom = subsc_jagsaalt_custom;
      hariu.tsag_jagsaalt_custom = tsag_jagsaalt_custom;
      hariu.prepfee_jagsaalt_custom = prepfee_jagsaalt_custom;
    }
    if (props.ssubsc_ticket_jagsaalt !== state.ssubsc_ticket_jagsaalt) {
      const jags = props.ssubsc_ticket_jagsaalt
        ? props.ssubsc_ticket_jagsaalt
        : [];
      const subsc_ticket_count = jags.filter(
        (ticket) =>
          ticket.uilchilgee_turul === "subsc_service" &&
          ticket.guilgeenii_turul === 120
      ).length;
      const tsag_ticket_count = jags.filter(
        (ticket) =>
          ticket.uilchilgee_turul === "tsag_zahialga_service" &&
          ticket.guilgeenii_turul === 120
      ).length;
      const prep_ticket_count = jags.filter(
        (ticket) =>
          ticket.uilchilgee_turul === "preperation_fee_service" &&
          ticket.guilgeenii_turul === 120
      ).length;
      hariu.ssubsc_ticket_jagsaalt = props.ssubsc_ticket_jagsaalt;
      hariu.subsc_ticket_count = subsc_ticket_count;
      hariu.tsag_ticket_count = tsag_ticket_count;
      hariu.prep_ticket_count = prep_ticket_count;
    }
    return hariu ? hariu : null;
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor:
        this.state.dialogTuluv === true ? {} : { ...this.state.songogdsonMor },
      medegdel: false,
    });
  }

  dialogTulburToggle() {
    this.setState({
      dialogTulburTuluv: !this.state.dialogTulburTuluv,
      songogdsonMor:
        this.state.dialogTulburTuluv === true
          ? {}
          : { ...this.state.songogdsonMor },
      medegdel: false,
    });
  }

  dialogPaymentToggle() {
    this.setState({
      dialogPaymentTuluv: !this.state.dialogPaymentTuluv,
      songogdsonMor:
        this.state.dialogPaymentTuluv === true
          ? {}
          : { ...this.state.songogdsonMor },
      medegdel: false,
    });
  }
  dialogBatalgaajuulahToggle() {
    this.setState({
      dialogBatalgaajuulah: !this.state.dialogBatalgaajuulah,
      songogdsonMor:
        this.state.dialogBatalgaajuulah === true
          ? {}
          : { ...this.state.songogdsonMor },
      medegdel: false,
    });
  }
  askPopup = (subsc) => {
    this.setState({
      dialogBatalgaajuulah: true,
      songogdsonMor: subsc,
      medegdel: false,
    });
  };

  subscSongohod = (subsc) => {
    let songogdsonMor = subsc;
    songogdsonMor.service_subsc_turul = i18n.t(subsc.uilchilgee_turul);
    this.setState({ songogdsonMor, dialogTulburTuluv: true });
  };

  subscribeDarahad() {
    const { songogdsonMor } = this.state;
    this.dialogToggle();
    this.setState({
      songogdsonMor,
      dialogPaymentTuluv: true,
      flag: songogdsonMor.tulburiin_helber === "paypal" ? 1 : 0,
    });
  }

  tulburSongohod = (turul) => {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor.tulburiin_helber = turul;
    this.dialogTulburToggle();
    if (turul === "stripe") {
      songogdsonMor.shimtgel = songogdsonMor.stripe_fee_amount;
      songogdsonMor.total = songogdsonMor.gross_total_amount;
    } else if (turul === "paypal") {
      songogdsonMor.shimtgel = songogdsonMor.paypal_fee_amount;
      songogdsonMor.total = songogdsonMor.paypal_gross_total_amount;
    }
    this.setState({
      songogdsonMor,
      dialogTuluv: true,
      flag: songogdsonMor.tulburiin_helber === "paypal" ? 1 : 0,
    });
  };

  onError = (err) => {
    console.log("An error occured with your payment", err);
  };

  onApprove = (data, actions) => {
    const subsc = { ...this.state.songogdsonMor };

    this.setState({
      unshijBaigaa: true,
    });
    this.props
      .tulburTuluhPaypalFn({
        subscriptionID: data.subscriptionID,
        orderID: data.orderID,
        buteegdehuun_dugaar: subsc.id,
      })
      .then((hariu) => {
        this.props.hereglegchMe();
        if (hariu.amjilt === true) {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            medegdel: true,
            flag: 0,
            unshijBaigaa: false,
          });
          this.props.history.push({
            pathname: "/service/subscription/history",
          });
        } else {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            medegdel: true,
            flag: 1,
            unshijBaigaa: false,
          });
        }
      })
      .catch((hariu) => {
        this.setState({
          dialogTuluv: false,
          songogdsonMor: {},
          medegdel: true,
          flag: 1,
          unshijBaigaa: false,
        });
      });
  };
  createSubscription = (data, actions) => {
    const subsc = { ...this.state.songogdsonMor };
    const self = this;
    return actions.subscription
      .create({
        plan_id: subsc.subsc_paypal_plan_id, // Creates the subscription
        description: "Yousubsc",
      })
      .then((orderID) => {
        self.setState({
          orderIdPaypal: orderID,
        });
        return orderID;
      })
      .catch((hariu) => {
        console.log("hariu catch: ", hariu);
      });
  };
  tooSongohod(event) {
    const utga = event.target.value;
    this.setState({
      songogdsonToo: utga,
    });
  }

  render() {
    const {
      dialogTuluv,
      dialogTulburTuluv,
      songogdsonMor,
      flag,
      medegdel,
      unshijBaigaa,
      erh_hyzgaar,
      serviceSubsc_info,
      dialogPaymentTuluv,
      dialogBatalgaajuulah,
      layout_turul,
      subsc_jagsaalt,
      tsag_jagsaalt,
      prepfee_jagsaalt,
      subsc_jagsaalt_custom,
      tsag_jagsaalt_custom,
      prepfee_jagsaalt_custom,
      songogdsonToo,
      subsc_ticket_count,
      tsag_ticket_count,
      prep_ticket_count,
      // successPaypal,
    } = this.state;
    const { temdegt } = serviceSubsc_info;
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    let tsag_jagsaalt_filtered = tsag_jagsaalt.filter(
      (item) => Number(item.ajiltan_too) >= Number(songogdsonToo)
    );
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          {medegdel && (
            <Row>
              <Col>
                <Alert
                  isOpen={medegdel}
                  toggle={this.medegdelToggle}
                  color={flag === 0 ? "success" : "danger"}
                >
                  {flag === 0 && (
                    <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                  )}
                  {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                </Alert>
              </Col>
            </Row>
          )}
          {/* <Card className="ungu-primary">
            <CardHeader style={{ color: "white" }}>
              <Row>
                <Col lg="6" md="6" sm="6" xs="12">
                  {i18n.t(
                    layout_turul === 1
                      ? "service_subsc_count_choose_label_table"
                      : "service_subsc_count_choose_label_staff"
                  )}
                </Col>
                <Col lg="6" md="6" sm="6" xs="12">
                  <Input
                    type="number"
                    name="utas"
                    // value={}
                    onChange={this.tooSongohod}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody></CardBody>
          </Card> */}

          <Card className="ungu-primary">
            <CardHeader style={{ color: "white" }}>
              {i18n.t("subsc_service")}
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Label style={{ color: "white" }}>
                    {i18n.t("default_service_subsc")}
                  </Label>
                </Col>
              </Row>
              <Row>
                {subsc_jagsaalt.map((subsc, index) => {
                  let haagdsan = false;

                  if (subsc_ticket_count > 0) {
                    haagdsan = true;
                  }
                  return (
                    <Col md="4" key={index}>
                      <Card
                        onClick={() => {
                          if (haagdsan === false) {
                            this.subscSongohod(subsc);
                          }
                        }}
                        style={{
                          background:
                            haagdsan === true
                              ? ungu.tsaivar_saaral
                              : ungu.tsagaan,
                        }}
                      >
                        <CardBody>
                          <Label>{i18n.t("service_subsc_ner")}: </Label>{" "}
                          {subsc.title}
                          <br />
                          <Label>{i18n.t("service_subsc_une")}: </Label>{" "}
                          {subsc.uilchilgeenii_price} {temdegt}{" "}
                          <Label>
                            {" / "}
                            {i18n.t("ssubsc_sard")}
                          </Label>
                          <br />
                          {/* <Label>{i18n.t("tailbar")}: </Label>
                          <Label>{subsc.temdeglel}</Label> */}
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              {!!subsc_jagsaalt_custom && subsc_jagsaalt_custom.length > 0 && (
                <div>
                  <Row>
                    <Col>
                      <Label style={{ color: "white" }}>
                        {i18n.t("custom_service_subsc")}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    {subsc_jagsaalt_custom.map((subsc, index) => {
                      let haagdsan = false;

                      if (subsc_ticket_count > 0) {
                        haagdsan = true;
                      }
                      return (
                        <Col md="4" key={index}>
                          <Card
                            onClick={() => {
                              if (haagdsan === false) {
                                this.subscSongohod(subsc);
                              }
                            }}
                            style={{
                              background:
                                haagdsan === true
                                  ? ungu.tsaivar_saaral
                                  : ungu.tsagaan,
                            }}
                          >
                            <CardBody>
                              <Label>{i18n.t("service_subsc_ner")}: </Label>{" "}
                              {subsc.title}
                              <br />
                              <Label>
                                {i18n.t("service_subsc_une")}:{" "}
                              </Label>{" "}
                              {subsc.uilchilgeenii_price} {temdegt}{" "}
                              <Label>
                                {" / "}
                                {i18n.t("ssubsc_sard")}
                              </Label>
                              <br />
                              {/* <Label>{i18n.t("tailbar")}: </Label>
                              <Label>{subsc.temdeglel}</Label> */}
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
            </CardBody>
          </Card>
          {/* tsag zahialga */}

          {layout_turul === 2 && (
            <Card className="ungu-primary">
              <CardHeader style={{ color: "white" }}>
                {i18n.t("tsag_zahialga_service")}
              </CardHeader>
              <CardBody>
                <Row>
                  {/* <Col lg="3" md="3" sm="3" xs="0" /> */}
                  <Col>
                    <FormGroup>
                      <Label style={{ color: "white" }}>
                        {i18n.t(
                          layout_turul === 1
                            ? "service_subsc_count_choose_label_table"
                            : "service_subsc_count_choose_label_staff"
                        )}
                      </Label>
                      {/* <Input type="number" onChange={this.tooSongohod} /> */}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {/* <Col lg="3" md="3" sm="3" xs="0" /> */}
                  <Col lg="4" md="4" sm="6" xs="12">
                    <FormGroup>
                      {/* <Label style={{ color: "white" }}>
                      {i18n.t(
                        layout_turul === 1
                          ? "service_subsc_count_choose_label_table"
                          : "service_subsc_count_choose_label_staff"
                      )}
                    </Label> */}
                      <Input type="number" onChange={this.tooSongohod} />
                    </FormGroup>
                  </Col>
                </Row>
                <br />

                {tsag_jagsaalt_filtered.length > 0 && (
                  <div>
                    <Row>
                      <Col>
                        <Label style={{ color: "white" }}>
                          {i18n.t("default_service_subsc")}
                        </Label>
                      </Col>
                    </Row>

                    <Row>
                      {tsag_jagsaalt_filtered.map((subsc, index) => {
                        if (index === 0 && songogdsonToo !== 0) {
                          let haagdsan = false;
                          if (
                            tsag_ticket_count > 0 &&
                            serviceSubsc_info.service_subsc_staff_count ===
                              subsc.ajiltan_too
                          ) {
                            haagdsan = true;
                          }
                          return (
                            <Col md="4" key={index}>
                              <Card
                                onClick={() => {
                                  if (haagdsan === false) {
                                    if (tsag_ticket_count > 0) {
                                      this.askPopup(subsc);
                                    } else {
                                      this.subscSongohod(subsc);
                                    }
                                  }
                                }}
                                style={{
                                  background:
                                    haagdsan === true
                                      ? ungu.tsaivar_saaral
                                      : ungu.tsagaan,
                                }}
                              >
                                <CardBody>
                                  <Label>{i18n.t("service_subsc_ner")}: </Label>{" "}
                                  {subsc.title}
                                  <br />
                                  <Label>
                                    {i18n.t("service_subsc_une")}:{" "}
                                  </Label>{" "}
                                  {subsc.uilchilgeenii_price} {temdegt}{" "}
                                  <Label>
                                    {" / "}
                                    {i18n.t("ssubsc_sard")}
                                  </Label>
                                  <br />
                                  <Label>
                                    {i18n.t(
                                      layout_turul === 1
                                        ? "count_table_label"
                                        : "count_staff_label"
                                    )}
                                    :{" "}
                                  </Label>{" "}
                                  {subsc.ajiltan_too} <br />
                                  {/* <Label>{i18n.t("tailbar")}: </Label>
                                  <Label>{subsc.temdeglel}</Label> */}
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Row>
                  </div>
                )}
                {!!tsag_jagsaalt_custom && tsag_jagsaalt_custom.length > 0 && (
                  <div>
                    <Row>
                      <Col>
                        <Label style={{ color: "white" }}>
                          {i18n.t("custom_service_subsc")}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      {tsag_jagsaalt_custom.map((subsc, index) => {
                        let haagdsan = false;

                        if (
                          tsag_ticket_count > 0 &&
                          serviceSubsc_info.service_subsc_staff_count ===
                            subsc.ajiltan_too
                        ) {
                          haagdsan = true;
                        }
                        return (
                          <Col lg="4" md="4" sm="6" xs="12" key={index}>
                            <Card
                              onClick={() => {
                                if (haagdsan === false) {
                                  if (tsag_ticket_count > 0) {
                                    this.askPopup(subsc);
                                  } else {
                                    this.subscSongohod(subsc);
                                  }
                                }
                              }}
                              style={{
                                background:
                                  haagdsan === true
                                    ? ungu.tsaivar_saaral
                                    : ungu.tsagaan,
                              }}
                            >
                              <CardBody>
                                <Label>{i18n.t("service_subsc_ner")}: </Label>{" "}
                                {subsc.title}
                                <br />
                                <Label>
                                  {i18n.t("service_subsc_une")}:{" "}
                                </Label>{" "}
                                {subsc.uilchilgeenii_price} {temdegt}{" "}
                                <Label>
                                  {" / "}
                                  {i18n.t("ssubsc_sard")}
                                </Label>
                                <br />
                                <Label>
                                  {i18n.t(
                                    layout_turul === 1
                                      ? "count_table_label"
                                      : "count_staff_label"
                                  )}
                                  :{" "}
                                </Label>{" "}
                                {subsc.ajiltan_too} <br />
                                {/* <Label>{i18n.t("tailbar")}: </Label>
                                <Label>{subsc.temdeglel}</Label> */}
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          {/* preperation fee */}
          {layout_turul === 1 && (
            <Card className="ungu-primary">
              <CardHeader style={{ color: "white" }}>
                {i18n.t("preperation_fee_service")}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Label style={{ color: "white" }}>
                      {i18n.t("default_service_subsc")}
                    </Label>
                  </Col>
                </Row>
                <Row>
                  {prepfee_jagsaalt.map((subsc, index) => {
                    let haagdsan = false;

                    if (prep_ticket_count > 0) {
                      haagdsan = true;
                    }
                    return (
                      <Col md="4" key={index}>
                        <Card
                          onClick={() => {
                            if (haagdsan === false) {
                              this.subscSongohod(subsc);
                            }
                          }}
                          style={{
                            background:
                              haagdsan === true
                                ? ungu.tsaivar_saaral
                                : ungu.tsagaan,
                          }}
                        >
                          <CardBody>
                            <Label>{i18n.t("service_subsc_ner")}: </Label>{" "}
                            {subsc.title}
                            <br />
                            <Label>{i18n.t("service_subsc_une")}: </Label>{" "}
                            {subsc.uilchilgeenii_price} {temdegt}{" "}
                            <Label>
                              {" / "}
                              {i18n.t("ssubsc_sard")}
                            </Label>
                            <br />
                            {/* <Label>{i18n.t("tailbar")}: </Label>
                            <Label>{subsc.temdeglel}</Label> */}
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
                {!!prepfee_jagsaalt_custom &&
                  prepfee_jagsaalt_custom.length > 0 && (
                    <div>
                      <Row>
                        <Col>
                          <Label style={{ color: "white" }}>
                            {i18n.t("custom_service_subsc")}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        {prepfee_jagsaalt_custom.map((subsc, index) => {
                          let haagdsan = false;

                          if (prep_ticket_count > 0) {
                            haagdsan = true;
                          }
                          return (
                            <Col md="4" key={index}>
                              <Card
                                onClick={() => {
                                  if (haagdsan === false) {
                                    this.subscSongohod(subsc);
                                  }
                                }}
                                style={{
                                  background:
                                    haagdsan === true
                                      ? ungu.tsaivar_saaral
                                      : ungu.tsagaan,
                                }}
                              >
                                <CardBody>
                                  <Label>{i18n.t("service_subsc_ner")}: </Label>{" "}
                                  {subsc.title}
                                  <br />
                                  <Label>
                                    {i18n.t("service_subsc_une")}:{" "}
                                  </Label>{" "}
                                  {subsc.uilchilgeenii_price} {temdegt}{" "}
                                  <Label>
                                    {" / "}
                                    {i18n.t("ssubsc_sard")}
                                  </Label>
                                  <br />
                                  {/* <Label>{i18n.t("tailbar")}: </Label>
                                  <Label>{subsc.temdeglel}</Label> */}
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  )}
              </CardBody>
            </Card>
          )}
        </div>

        {dialogTuluv && (
          <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
            <ModalHeader toggle={this.dialogToggle} />
            <ModalBody>
              <div>
                <div>
                  <label style={styles.myLabel}>
                    {i18n.t("service_subsc_turul")}
                    {" : "}
                  </label>{" "}
                  {i18n.t(songogdsonMor.uilchilgee_turul)}
                </div>
                <div>
                  <label style={styles.myLabel}>
                    {i18n.t("service_subsc_ner")}
                    {" : "}
                  </label>{" "}
                  {songogdsonMor.title}
                </div>
                <div>
                  <label style={styles.myLabel}>
                    {i18n.t("service_subsc_une")}
                    {" : "}
                  </label>{" "}
                  {songogdsonMor.uilchilgeenii_price} {temdegt}
                </div>
                <div>
                  <label style={styles.myLabel}>
                    {i18n.t("tax")}
                    {" ( "}{" "}
                    {
                      currency(
                        (Number(songogdsonMor.hst_rate) +
                          Number(songogdsonMor.qst_rate) +
                          Number(songogdsonMor.gst_rate) +
                          Number(songogdsonMor.pst_rate)) *
                          100,
                        {
                          precision: 3,
                          separator: "",
                        }
                      ).value
                    }{" "}
                    {"% ) "}
                    {" : "}
                  </label>{" "}
                  {songogdsonMor.tax_amount} {temdegt}
                </div>

                <div style={styles.myLabelTotal}>
                  <label style={styles.myLabel}>
                    {i18n.t("service_subsc_niit_une")}
                    {" : "}
                  </label>{" "}
                  {songogdsonMor.tulburiin_helber === "stripe" &&
                    songogdsonMor.gross_total_amount + " " + temdegt}
                  {songogdsonMor.tulburiin_helber === "paypal" &&
                    songogdsonMor.paypal_gross_total_amount + " " + temdegt}
                  {songogdsonMor.tulburiin_helber && (
                    <label style={styles.myLabel}>
                      {" / "}
                      {i18n.t("ssubsc_sard")}
                    </label>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {flag === 1 ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%", marginLeft: "50%" }}>
                    <PayPalScriptProvider
                      options={{
                        "client-id": tohirgoo.client_id,
                        // intent: "subscription",
                        vault: true,
                        "disable-funding": "card",
                      }}
                    >
                      <PayPalButtons
                        style={{
                          color: "blue",
                          label: "subscribe",
                          height: 30,
                        }}
                        onApprove={this.onApprove}
                        // onError={this.onError}
                        createSubscription={this.createSubscription}
                      />
                    </PayPalScriptProvider>
                  </div>
                </div>
              ) : (
                <div>
                  <Button
                    onClick={this.subscribeDarahad}
                    size="sm"
                    className="ungu-primary"
                  >
                    {i18n.t("subscribe")}
                  </Button>
                  {/* <Button color="secondary" onClick={this.dialogToggle}>
                  {i18n.t("bolih")}
                </Button> */}
                </div>
              )}
            </ModalFooter>
          </Modal>
        )}

        {/* Tulbur songuulah */}
        {dialogTulburTuluv && (
          <Modal isOpen={dialogTulburTuluv} toggle={this.dialogTulburToggle}>
            <ModalHeader toggle={this.dialogTulburToggle}>
              {" "}
              {i18n.t("tulbur_helber_songoh")}
            </ModalHeader>
            <ModalBody>
              <div align="center">
                <Button
                  className="ungu-primary"
                  style={{ width: togtmol.tovchnii_urgun_tulbur + "px" }}
                  onClick={() => this.tulburSongohod("stripe")}
                >
                  {i18n.t("credit_card")}
                </Button>{" "}
                <Button
                  color="info"
                  style={{ width: togtmol.tovchnii_urgun_tulbur + "px" }}
                  onClick={() => this.tulburSongohod("paypal")}
                >
                  {i18n.t("paypal")}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}

        {/* Payment */}
        {dialogPaymentTuluv && (
          <Modal isOpen={dialogPaymentTuluv} toggle={this.dialogPaymentToggle}>
            <ModalHeader toggle={this.dialogPaymentToggle}>
              {" "}
              {flag === 1 ? "Paypal" : "Stripe"}
            </ModalHeader>
            <ModalBody>
              {flag === 1 ? (
                <div></div>
              ) : (
                <div align="center">
                  <TulburCardSongoh
                    tulburInfo={songogdsonMor}
                    haah={(flag) => {
                      this.dialogPaymentToggle();
                      this.setState({ flag, medegdel: true });
                      if (flag === 0) {
                        this.props.history.push({
                          pathname: "/service/subscription/history",
                        });
                      }
                    }}
                  />
                </div>
              )}
            </ModalBody>
          </Modal>
        )}
        {/* batalgaajuulj asuuh */}
        {dialogBatalgaajuulah && (
          <Modal
            isOpen={dialogBatalgaajuulah}
            toggle={this.dialogBatalgaajuulahToggle}
          >
            <ModalHeader />
            <ModalBody>
              <div
                style={{
                  margin: togtmol.tom_zai,
                  textAlign: "center",
                }}
              >
                {i18n.t("ssubsc_batalgaajuulah_dahin_subsc")}
              </div>
            </ModalBody>
            <ModalFooter>
              <div style={{ width: "100%", justifyContent: "space-between" }}>
                <Button
                  color="info"
                  size="sm"
                  style={{ width: "49%" }}
                  onClick={this.dialogBatalgaajuulahToggle}
                >
                  {i18n.t("bolih")}
                </Button>
                <Button
                  className="ungu-primary"
                  size="sm"
                  style={{ width: "49%" }}
                  onClick={() => {
                    this.dialogBatalgaajuulahToggle();
                    this.subscSongohod(songogdsonMor);
                  }}
                >
                  {i18n.t("urgeljluuleh")}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}
const styles = {
  myLabel: {
    fontSize: "0.9em",
    marginBottom: togtmol.jijig_zai,
    marginTop: togtmol.jijig_zai,
    color: togtmol.saaral,
  },
  myLabelTotal: {
    borderTop: togtmol.dropdown_brdr,
    textAlign: "right",
    marginTop: togtmol.jijig_zai,
    paddingTop: togtmol.jijig_zai,
  },
};
function mapStateToProps(state) {
  const { service_subsc_jagsaalt, ssubsc_ticket_jagsaalt } =
    state.ssubscReducer;
  return {
    service_subsc_jagsaalt,
    ssubsc_ticket_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      serviceSubscJagsaaltFn,
      tulburTuluhPaypalFn,
      ssubscTicketJagsaaltFn,
      hereglegchMe,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ServiceSubsc)
);
