import i18n from "hel/i18n";

const ButsahTovch = (props) => {
  const zam = props.zam ? props.zam : "/";
  return (
    <div>
      <button
        style={{ background: "transparent", border: "none" }}
        onClick={() => {
          props.history.push({ pathname: zam });
        }}
      >
        <div className="neg-morond">
          <i className="bi bi-caret-left" />
          <span className="golluulah"> {i18n.t("butsah")}</span>
        </div>
      </button>
      <br />
    </div>
  );
};
export default ButsahTovch;
