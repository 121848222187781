import { API } from "services/api";
import { ssubscConstants } from "store/constants";
import { hereglegchConstants } from "store/constants";

export const serviceSubscJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/service/subsc/jagsaalt`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: ssubscConstants.SERVICE_SUBSC_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "serviceSubscJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const ssubscTulburTuuhFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/service/subsc/tulbur/tuuh`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: ssubscConstants.SERVICE_SUBSC_TULBUR_TUUH,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "ssubscTulburTuuhFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const ssubscTicketJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/service/subsc/ticket/jagsaalt`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: ssubscConstants.SERVICE_SUBSC_TICKET_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "ssubscTicketJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const subscZogsoohFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/service/subsc/zogsooh`, {
        zahialga_id: state.zahialga_id,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const subscTicketZogsoohFn = (state) => async (dispatch) => {
  try {
    console.log("yes here we are", state.zahialga_id);
    return API()
      .post(`/huvilbar1/service/subsc/ticket/zogsooh`, {
        zahialga_id: state.zahialga_id,
      })
      .then((response) => {
        console.log("yes here we are", response);
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    console.log("yes here we are catch");
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
