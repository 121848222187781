import { stripeConstants } from "../constants";

const INITIAL_STATE = {
  card_jagsaalt: [],
  stripe_add_card_info: {},
};

const hariu = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case stripeConstants.STRIPE_CARDS:
      return { ...state, card_jagsaalt: action.payload };
    case stripeConstants.STRIPE_ADD_CARDS:
      return { ...state, stripe_add_card_info: action.payload };
    default:
      return state;
  }
};
export default hariu;
