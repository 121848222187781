import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import i18n from "hel/i18n";
import {
  cardJagsaaltFn,
  addCustomerStripeCard,
  tulburTuluhStripeFn,
} from "../../../../store/actions/stripe.actions";
import { hereglegchMe } from "store/actions/hereglegch.actions";
import tohirgoo from "tohirgoo";
import LoginAuth from "services/LoginAuth";
import CardNemeh from "./CardNemeh";
import MyLoader from "components/MyLoader";

const stripePromise = loadStripe(tohirgoo.stripe_publishableKey);

class TulburCardSongoh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medegdel: false,
      flag: 0,
      unshijBaigaa: false,
      dialogTuluv: false,
      songogdsonMor: {},

      card_jagsaalt: [],
      utguud: [],
      // validationHariu: {},
      // brand: "visa",
      // last4: "4444",
      // expiryYear: "22",
      // expiryMonth: "12",
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      me: LoginAuth.meAvah(),
      songogdsonCard: {},
      tulburInfo: this.props.tulburInfo ? this.props.tulburInfo : {},
    };
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.addNewCard = this.addNewCard.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.cardSongohod = this.cardSongohod.bind(this);
    this.tulburTuluh = this.tulburTuluh.bind(this);
  }
  componentDidMount() {
    this.props.cardJagsaaltFn();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.card_jagsaalt !== state.card_jagsaalt) {
      let card_jagsaalt = props.card_jagsaalt ? props.card_jagsaalt : [];
      return {
        card_jagsaalt: props.card_jagsaalt,
        utguud: card_jagsaalt,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      medegdel: false,
    });
  }

  tulburTuluh() {
    const { tulburInfo, songogdsonCard } = this.state;
    const utguud = {
      cardid: songogdsonCard.id,
      buteegdehuun_dugaar: tulburInfo.id,
      price_id_stripe: tulburInfo.subsc_price_id,
      last4: songogdsonCard.card.last4,
    };
    this.setState({
      unshijBaigaa: true,
    });
    try {
      this.props.tulburTuluhStripeFn(utguud).then((hariu) => {
        this.props.hereglegchMe();
        if (hariu.amjilt === true) {
          // this.setState({
          //   unshijBaigaa: false,
          // });
          this.props.haah(0);
        } else {
          // this.setState({
          //   unshijBaigaa: false,
          // });
          this.props.haah(1);
        }
      });
    } catch (aldaa) {
      this.props.haah(1);
    }
  }

  cardSongohod = (card) => {
    this.setState({
      songogdsonCard: card,
    });
  };

  addNewCard = async (stripeResponse, error_msg) => {
    const cid = this.state.me.useri;

    if (error_msg === "incomplete_number") {
      this.setState({
        medegdel: true,
        flag: 2,
      });
      return;
    } else if (error_msg === "card_declined") {
      this.setState({
        medegdel: true,
        flag: 2,
      });
      return;
    } else if (error_msg === "invalid_number") {
      this.setState({
        medegdel: true,
        flag: 2,
      });
      return;
    }

    this.setState({
      isAdding: true,
      modalVisible: false,
      data_loading: true,
      unshijBaigaa: true,
    });

    try {
      let check = this.props.card_jagsaalt.filter(
        (e) => e.last4 === stripeResponse.card.last4
      );

      if (check.length > 0) {
        this.setState(
          {
            isAdding: false,
            modalVisible: false,
            data_loading: false,
          },
          () => {
            this.setState({
              medegdel: true,
              flag: 3,
              unshijBaigaa: false,
            });
          }
        );
        return;
      }

      this.props
        .addCustomerStripeCard({
          idstripe: cid,
          tokenId: stripeResponse.id,
          cardid: "",
        })
        .then((hariu) => {
          this.dialogToggle();
          if (hariu.amjilt === true) {
            this.setState({
              medegdel: true,
              flag: 0,
              unshijBaigaa: false,
            });
          } else {
            this.setState({
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
          setTimeout(() => {
            this.props.cardJagsaaltFn();
          }, 3000);
        });
    } catch (error) {
      this.setState({
        unshijBaigaa: false,
      });
      console.log(error);
      if (error.code === "sourceStatusFailed") console.log("3DS failed");
    }
  };

  render() {
    const {
      medegdel,
      flag,
      utguud,
      dialogTuluv,
      erh_hyzgaar,
      songogdsonCard,
      unshijBaigaa,
    } = this.state;
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        {medegdel && (
          <Row>
            <Col>
              <Alert
                isOpen={medegdel}
                toggle={this.medegdelToggle}
                color={flag === 0 ? "success" : "danger"}
              >
                {flag === 0 && (
                  <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                )}
                {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                {flag === 2 && (
                  <span>{i18n.t("medegdel_aldaa_card_declined")}</span>
                )}
                {flag === 3 && (
                  <span>{i18n.t("medegdel_aldaa_card_burtgeltei")}</span>
                )}
              </Alert>
            </Col>
          </Row>
        )}
        <div align="center">
          <Row>
            <Col>
              <div>{i18n.t("text_select_card")}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="ungu-primary"
                style={{ color: "white", width: "100%" }}
                onClick={this.tulburTuluh}
                disabled={songogdsonCard.id === undefined}
              >
                <div>{i18n.t("tulbur_tuluh")}</div>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color="info"
                style={{ color: "white", width: "100%" }}
                onClick={this.dialogToggle}
              >
                <div>{i18n.t("shine_card_nemeh")}</div>
              </Button>
            </Col>
          </Row>
          {utguud &&
            utguud.map((each, index) => {
              const myCard = each.card;
              return (
                <Row key={index}>
                  <Col>
                    <Card
                      // color={songogdsonCard.id === each.id ? "success" : "info"}
                      style={{
                        marginTop: "1vh",
                        height: "20vh",
                        borderColor: "black",
                        borderWidth: 1,
                        backgroundImage:
                          songogdsonCard.id === each.id
                            ? "url('../../../../../img/card.png')"
                            : "url('../../../../../img/card_disabled.png')",
                        color: "white",
                      }}
                      onClick={() => this.cardSongohod(each)}
                    >
                      <CardBody>
                        <div align="right">
                          <Label style={{ color: "white" }}>
                            {i18n.t("card_number")} :{" "}
                          </Label>{" "}
                          **** **** **** {myCard.last4}
                          <br />
                          <Label style={{ color: "white" }}>
                            {i18n.t("valid_thru")} :{" "}
                          </Label>{" "}
                          {myCard.exp_month}/{myCard.exp_year}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              );
            })}
        </div>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            <div align="center">
              <Elements stripe={stripePromise}>
                <CardNemeh add_card={this.addNewCard} />
              </Elements>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    card_jagsaalt: state.stripeReducer.card_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      cardJagsaaltFn,
      addCustomerStripeCard,
      tulburTuluhStripeFn,
      hereglegchMe,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TulburCardSongoh)
);
