import { API } from "services/api";
import { hereglegchConstants } from "store/constants";
export const shopZuragFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/zurag/shop/base64`, {
        file: state,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true, imageid: response.data.data.imageid };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
export const shireeZuragFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/zurag/shiree/base64`, {
        file: state,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true, imageid: response.data.data.imageid };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
export const ajiltanZuragFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/zurag/ajiltan/base64`, {
        file: state,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true, imageid: response.data.data.imageid };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const itemZuragFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/zurag/item/base64`, {
        file: state,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true, imageid: response.data.data.imageid };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const tsesZuragFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/zurag/tses/base64`, {
        file: state,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true, imageid: response.data.data.imageid };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
