import i18n from "hel/i18n";
import React, { Fragment } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import PaginationComponent from "react-reactstrap-pagination";
import { tsagFormat, ognooFormat } from "validation";
import { togtmol } from "delgetsuud/zagvar";
import tohirgoo from "tohirgoo";
import { ognooFormat_noTimezone } from "validation/uildluud";
import currency from "currency.js";

class Husnegt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      songogdson: 0,
    };
    this.huudasSoligdohod = this.huudasSoligdohod.bind(this);
  }

  huudasSoligdohod(songogdson) {
    if (this.props.huudas_soligdohod !== undefined) {
      this.props.huudas_soligdohod(songogdson - 1);
    } else {
      this.setState({
        songogdson: songogdson - 1,
      });
    }
  }
  render() {
    const { songogdson } = this.state;
    // const isMobile = window.matchMedia("(max-width: 767px)").matches;
    // const isTablet = window.matchMedia(
    //   "(min-width:768px) and (max-width: 1024px)"
    // ).matches;
    // const jijig_delgets = isMobile || isTablet;
    const garchignuud = this.props.garchignuud ? this.props.garchignuud : [];
    const utguud = this.props.utguud ? this.props.utguud : [];
    const tovchtoi_eseh = this.props.tovchtoi_eseh
      ? this.props.tovchtoi_eseh
      : false;
    const tovch = this.props.tovch
      ? this.props.tovch
      : tovchtoi_eseh === true
      ? ["zasah"]
      : [];
    const niitToo = this.props.niitToo ? this.props.niitToo : utguud.length;
    const huudaslalt = this.props.huudaslalt ? this.props.huudaslalt : 30;
    const auto = this.props.niitToo !== undefined ? false : true;
    const ongoor_ylgah =
      this.props.ongoor_ylgah !== undefined ? this.props.ongoor_ylgah : false;
    const tovch_nuhtsul = this.props.tovch_nuhtsul;
    // const tableUtga =
    //   utguud.length <= huudaslalt
    //     ? utguud
    //     : utguud.filter(
    //         (index) =>
    //           index >= huudaslalt * songogdson &&
    //           index < huudaslalt * (songogdson + 1)
    //       );
    return (
      <div className="myHusnegt">
        <Table
          // responsive={jijig_delgets || utguud.length > 0 ? true : false}
          bordered={utguud.length > 0 ? true : false}
          style={{ textAlign: "center" }}
        >
          <thead className="text-primary">
            <tr>
              <th>#</th>
              {garchignuud.map((garchig, index) => {
                if (!!!garchig.nuuh) {
                  return (
                    <th key={index}>
                      {i18n.t(garchig.garchig)}
                      {garchig.ner_nemelt ? " " + garchig.ner_nemelt : ""}
                    </th>
                  );
                }
                return null;
              })}
              {tovchtoi_eseh === true && <th>{i18n.t("uildel")}</th>}
            </tr>
          </thead>
          {utguud.length > 0 && (
            <tbody>
              {utguud.map((utga, index) => {
                if (!!utga) {
                  if (
                    (auto === true && index < songogdson * huudaslalt) ||
                    index >= huudaslalt * (songogdson + 1)
                  ) {
                    return null;
                  } else {
                    return (
                      <tr
                        key={index}
                        style={
                          ongoor_ylgah !== false
                            ? {
                                backgroundColor: utga[ongoor_ylgah]
                                  ? utga[ongoor_ylgah]
                                  : "",
                              }
                            : {}
                        }
                      >
                        <td style={{ maxWidth: "50px" }}>{index + 1}</td>
                        {garchignuud.map((garchig, index) => {
                          if (!!!garchig.nuuh) {
                            if (garchig.turul === "zurag") {
                              return (
                                <td style={{ maxWidth: "300px" }} key={index}>
                                  <img
                                    onError={(ev) => {
                                      ev.target.src = "/apple-icon.png"; //default zurag zam zaah
                                    }}
                                    src={
                                      utga[garchig.ner]
                                        ? tohirgoo.img_url +
                                          (garchig.img_folder
                                            ? garchig.img_folder + "/"
                                            : "") +
                                          utga[garchig.ner]
                                        : "/apple-icon.png" //default zurag zam zaah
                                    }
                                    style={{
                                      height: "auto",
                                      width: 100 + "%",
                                      resize: "both",
                                      objectFit: "cover",
                                      objectPosition: "25% 50%",
                                    }}
                                    alt={garchig.ner}
                                  />
                                </td>
                              );
                            } else if (
                              utga.is_amrah !== undefined &&
                              utga.is_amrah === true &&
                              garchig.turul === "time"
                            ) {
                              return (
                                <td style={{ maxWidth: "300px" }} key={index}>
                                  -
                                </td>
                              );
                            } else if (garchig.turul === "time") {
                              return (
                                <td style={{ maxWidth: "300px" }} key={index}>
                                  {tsagFormat(utga[garchig.ner], "HH:mm")}
                                </td>
                              );
                            } else if (garchig.turul === "tsag") {
                              return (
                                <td style={{ maxWidth: "300px" }} key={index}>
                                  {ognooFormat(utga[garchig.ner], "HH:mm:ss")}
                                </td>
                              );
                            } else if (garchig.turul === "ognoo_tsag") {
                              return (
                                <td style={{ maxWidth: "300px" }} key={index}>
                                  {ognooFormat(
                                    utga[garchig.ner],
                                    "YYYY.MM.DD HH:mm:ss"
                                  )}
                                </td>
                              );
                            } else if (garchig.turul === "date") {
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                  }}
                                  key={index}
                                >
                                  {!!utga[garchig.ner]
                                    ? ognooFormat(
                                        utga[garchig.ner],
                                        "YYYY-MM-DD"
                                      )
                                    : ""}
                                </td>
                              );
                            } else if (garchig.turul === "date_notimezone") {
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                  }}
                                  key={index}
                                >
                                  {!!utga[garchig.ner]
                                    ? ognooFormat_noTimezone(
                                        utga[garchig.ner],
                                        "YYYY-MM-DD"
                                      )
                                    : ""}
                                </td>
                              );
                            } else if (garchig.turul === "songoh") {
                              return (
                                <td style={{ maxWidth: "300px" }} key={index}>
                                  {!!utga[garchig.ner]
                                    ? utga[garchig.ner].ner
                                      ? utga[garchig.ner].ner
                                      : utga[garchig.ner]
                                    : ""}
                                </td>
                              );
                            } else if (garchig.turul === "switch") {
                              return (
                                <td style={{ maxWidth: "300px" }} key={index}>
                                  {utga[garchig.ner] === true
                                    ? garchig.ner === "tuluv" ||
                                      garchig.ner === "ajiltan_uilchilgee_tuluv"
                                      ? i18n.t("idevhtei")
                                      : i18n.t("tiim")
                                    : garchig.ner === "tuluv" ||
                                      garchig.ner === "ajiltan_uilchilgee_tuluv"
                                    ? i18n.t("idevhgui")
                                    : i18n.t("ugui")}
                                </td>
                              );
                            } else if (garchig.turul === "tulburiin_helber") {
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                  }}
                                  key={index}
                                >
                                  {utga[garchig.ner] === "stripe"
                                    ? "**** **** **** " + utga.suuliin_too
                                    : utga[garchig.ner] === "paypal"
                                    ? utga.paypal_email
                                    : utga[garchig.ner] === "orlogoor"
                                    ? i18n.t("borluulaltiin_orlogoos")
                                    : utga[garchig.ner] === "dansaar"
                                    ? i18n.t("dansaar")
                                    : utga[garchig.ner] === "trail"
                                    ? i18n.t("free_trial")
                                    : i18n.t("tulugduugui")}
                                </td>
                              );
                            } else if (garchig.turul === "guilgeenii_turul") {
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                  }}
                                  key={index}
                                >
                                  {utga[garchig.ner] === 100
                                    ? i18n.t("tsag_zahialga_turul")
                                    : utga[garchig.ner] === 101
                                    ? i18n.t("subscription_turul")
                                    : utga[garchig.ner] === 102
                                    ? i18n.t("get_turul")
                                    : i18n.t("busad_turul")}
                                </td>
                              );
                            } else if (garchig.turul === "period") {
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "200px",
                                  }}
                                  key={index}
                                >
                                  {!!utga[garchig.ner] && !!utga[garchig.ner2]
                                    ? ognooFormat_noTimezone(
                                        utga[garchig.ner],
                                        "YYYY.MM.DD"
                                      ) +
                                      " - " +
                                      ognooFormat_noTimezone(
                                        utga[garchig.ner2],
                                        "YYYY.MM.DD"
                                      )
                                    : " - "}
                                </td>
                              );
                            } else if (garchig.turul === "tuluv") {
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                  }}
                                  key={index}
                                >
                                  {" "}
                                  {i18n.t(utga[garchig.ner])}
                                </td>
                              );
                            } else if (garchig.turul === "tuluv-tootsoo") {
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                  }}
                                  key={index}
                                >
                                  {" "}
                                  {i18n.t(utga[garchig.ner] + "_tootsoo")}
                                </td>
                              );
                            } else if (garchig.turul === "cashback_dugaar") {
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                  }}
                                  key={index}
                                >
                                  {utga[garchig.ner] > 0
                                    ? i18n.t("hiigdsen")
                                    : i18n.t("hiigdeegui")}
                                </td>
                              );
                            } else if (garchig.turul === "huvi") {
                              const huvi = currency(
                                Number(utga[garchig.ner]) * 100,
                                {
                                  precision: 3,
                                  separator: "",
                                }
                              ).value;
                              return (
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                  }}
                                  key={index}
                                >
                                  {!!utga[garchig.ner] ? huvi + " %" : " - "}
                                </td>
                              );
                            } else {
                              if (!!garchig.lang && garchig.lang === true) {
                                return (
                                  <td
                                    style={{
                                      maxWidth: "300px",
                                      minWidth: "100px",
                                    }}
                                    key={index}
                                  >
                                    {i18n.t(utga[garchig.ner])}
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    style={{
                                      maxWidth: "300px",
                                      minWidth: "100px",
                                    }}
                                    key={index}
                                  >
                                    {utga[garchig.ner] &&
                                    utga[garchig.ner].length > 50
                                      ? utga[garchig.ner].substring(0, 60) +
                                        "..."
                                      : utga[garchig.ner]}
                                    {utga[garchig.ner] === null && "-"}
                                  </td>
                                );
                              }
                            }
                          }
                          return null;
                        })}
                        {tovchtoi_eseh === true && tovch_nuhtsul === undefined && (
                          <th>
                            {tovch.length < 2 ? (
                              <Button
                                className="ungu-primary"
                                size="sm"
                                onClick={() =>
                                  this.props.tovch_daragdahad(
                                    utga,
                                    index,
                                    tovch[0]
                                  )
                                }
                              >
                                {i18n.t(tovch[0])}
                              </Button>
                            ) : (
                              <UncontrolledDropdown group>
                                <DropdownToggle
                                  caret
                                  className="ungu-primary"
                                  size="sm"
                                >
                                  {i18n.t("uildel")}
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{
                                    border: togtmol.dropdown_brdr,
                                  }}
                                >
                                  {tovch.map((t, lilIndex) => {
                                    return (
                                      <Fragment key={lilIndex}>
                                        {lilIndex !== 0 && (
                                          <DropdownItem divider />
                                        )}
                                        <DropdownItem
                                          onClick={() =>
                                            this.props.tovch_daragdahad(
                                              utga,
                                              index,
                                              t
                                            )
                                          }
                                        >
                                          {i18n.t(t)}
                                        </DropdownItem>
                                      </Fragment>
                                    );
                                  })}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}
                          </th>
                        )}
                        {tovchtoi_eseh === true &&
                        tovch_nuhtsul === "niitguilgee" &&
                        utguud[index].cashbackbolson_eseh !== "boloogui" ? (
                          <th>
                            {tovch.length < 2 ? (
                              <Button
                                className="ungu-primary"
                                size="sm"
                                onClick={() =>
                                  this.props.tovch_daragdahad(
                                    utga,
                                    index,
                                    tovch[0]
                                  )
                                }
                              >
                                {i18n.t(tovch[0])}
                              </Button>
                            ) : (
                              <UncontrolledDropdown group>
                                <DropdownToggle
                                  caret
                                  className="ungu-primary"
                                  size="sm"
                                >
                                  {i18n.t("uildel")}
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{
                                    border: togtmol.dropdown_brdr,
                                  }}
                                >
                                  {tovch.map((t, lilIndex) => {
                                    return (
                                      <Fragment key={lilIndex}>
                                        {lilIndex !== 0 && (
                                          <DropdownItem divider />
                                        )}
                                        <DropdownItem
                                          onClick={() =>
                                            this.props.tovch_daragdahad(
                                              utga,
                                              index,
                                              t
                                            )
                                          }
                                        >
                                          {i18n.t(t)}
                                        </DropdownItem>
                                      </Fragment>
                                    );
                                  })}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}
                          </th>
                        ) : (
                          tovchtoi_eseh === true &&
                          tovch_nuhtsul === "niitguilgee" && <th>-</th>
                        )}
                      </tr>
                    );
                  }
                } else {
                  return null;
                }
              })}
            </tbody>
          )}
        </Table>
        {utguud.length < 1 && (
          <div style={{ textAlign: "center" }}>
            <Label>{i18n.t("husnegt_hooson")}</Label>
          </div>
        )}
        <br />
        <div align="center">
          <div style={{ width: "215px" }}>
            {niitToo > huudaslalt && (
              <PaginationComponent
                totalItems={parseInt(niitToo)}
                pageSize={huudaslalt}
                onSelect={this.huudasSoligdohod}
                size="sm"
                maxPaginationNumbers={3}
                firstPageText="<<"
                previousPageText="<"
                nextPageText=">"
                lastPageText=">>"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Husnegt;
