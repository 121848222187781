import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import Husnegt from "components/Husnegt";
import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  ajiltanChuluuBurtgehFn,
  ajiltanChuluuJagsaaltFn,
} from "store/actions/uilajillagaa.actions";
import { shireeJagsaaltFn } from "store/actions/baiguullaga.actions";
import { validEseh, ylgaataiEseh, tsagAvah } from "validation";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";

class ShireeBlockloh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout_turul: LoginAuth.layoutTurulAvah(),
      flag: 0,
      medegdel: false,

      dialogTuluv: false,
      songogdsonMor: {},
      songogdsonIndex: 0,
      garchignuud: [
        {
          ner: "ajiltan_ner",
          garchig: "shiree_dugaar",
          turul: "songoh",
          songoh: "shiree",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "chuluu_date",
          garchig: "ognoo",
          turul: "date",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "chuluu_ehleh",
          garchig: "ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "chuluu_duusah",
          garchig: "duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval"],
          },
        },
      ],
      ajiltan_jagsaalt: [],
      ajiltan_chuluu_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      ognooHyzgaarMax: tsagAvah("ognooHyzgaarMax"),
    };
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    this.props.ajiltanChuluuJagsaaltFn();
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.shiree_jagsaalt !== state.shiree_jagsaalt) {
      let shiree_jagsaalt = props.shiree_jagsaalt ? props.shiree_jagsaalt : [];

      hariu.shiree_jagsaalt = shiree_jagsaalt;
    }
    if (props.ajiltan_chuluu_jagsaalt !== state.ajiltan_chuluu_jagsaalt) {
      let ajiltan_chuluu_jagsaalt = props.ajiltan_chuluu_jagsaalt
        ? props.ajiltan_chuluu_jagsaalt
        : [];
      ajiltan_chuluu_jagsaalt.map((ajiltan) => {
        ajiltan.ajiltan_ner = state.ajiltan_jagsaalt.filter(
          (a) => a.ajiltan_id === ajiltan.ajiltan_id
        )[0];
        return null;
      });
      hariu.ajiltan_chuluu_jagsaalt = ajiltan_chuluu_jagsaalt;
      hariu.utguud = ajiltan_chuluu_jagsaalt;
    }

    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    if (ner === "ajiltan_ner") {
      songogdsonMor[ner] = utga;
      songogdsonMor["ajiltan_id"] =
        !!utga && !!utga.ajiltan_id ? utga.ajiltan_id : null;
      // validationHariu[ner]={isValid: false, message: i18n("")}
    }
    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor: {},
      validationHariu: {},
      medegdel: false,
    });
    this.props.ajiltanChuluuJagsaaltFn();
  }
  hadgalahDarahad() {
    const { songogdsonMor } = this.state;
    this.setState({ unshijBaigaa: true });
    try {
      songogdsonMor.tailbar = "Ширээ блоклох";
      this.props.ajiltanChuluuBurtgehFn(songogdsonMor).then((hariu) => {
        if (hariu.amjilt === true) {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 0,
            unshijBaigaa: false,
          });
          this.props.ajiltanChuluuJagsaaltFn();
        } else {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 1,
            unshijBaigaa: false,
          });
        }
      });
    } catch (aldaa) {
      this.setState({
        dialogTuluv: false,
        songogdsonMor: {},
        validationHariu: {},
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  render() {
    const {
      validationHariu,
      garchignuud,
      utguud,
      dialogTuluv,
      songogdsonMor,
      songogdsonIndex,
      flag,
      medegdel,
      unshijBaigaa,
      shiree_jagsaalt,
      layout_turul,
    } = this.state;
    if (layout_turul === 2) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md="9" />
                <Col md="3" align="right">
                  <Button
                    className="ungu-primary"
                    round="true"
                    outline
                    onClick={this.dialogToggle}
                  >
                    {i18n.t("nemeh")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Husnegt garchignuud={garchignuud} utguud={utguud} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            {dialogTuluv === true && (
              <Form>
                {garchignuud.map((orolt, index) => {
                  return (
                    <GarchigtaiOrolt
                      key={index}
                      index={index}
                      orolt={orolt}
                      utgaAvah={this.modalUtgaOorchlogdohod}
                      utga={songogdsonMor[orolt.ner]}
                      validationHariu={validationHariu[orolt.ner]}
                      shiree={shiree_jagsaalt}
                    />
                  );
                })}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="ungu-primary"
              onClick={this.hadgalahDarahad}
              disabled={
                !validEseh(garchignuud, songogdsonMor) ||
                !ylgaataiEseh(songogdsonMor, utguud[songogdsonIndex])
              }
            >
              {i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { ajiltan_chuluu_jagsaalt } = state.uilajillagaaReducer;
  const { shiree_jagsaalt } = state.baiguullagaReducer;
  return {
    ajiltan_chuluu_jagsaalt,
    shiree_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { shireeJagsaaltFn, ajiltanChuluuBurtgehFn, ajiltanChuluuJagsaaltFn },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShireeBlockloh)
);
