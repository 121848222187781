import i18n from "hel/i18n";
import React from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { validShalgah, tsagAvah } from "validation";
import tohirgoo from "tohirgoo";
import SongoltBox from "components/SongoltBox";
import { ognooFormat } from "validation";
import MapPicker from "react-google-map-picker";

import Resizer from "react-image-file-resizer";
import { ungu } from "delgetsuud/zagvar";
class GarchigtaiOrolt extends React.Component {
  constructor(props) {
    super(props);
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.bairlalOorchlogdohod = this.bairlalOorchlogdohod.bind(this);
    this.songohod = this.songohod.bind(this);
    this.switchDarahad = this.switchDarahad.bind(this);
    this.ugtaZooh = this.ugtaZooh.bind(this);
    this.zuragSongohod = this.zuragSongohod.bind(this);
  }

  utgaOorchlogdohod(event) {
    const ner = event.target.name;
    const utga = event.target.value;
    const shalgalt = this.props.orolt.validations;
    const urDun = validShalgah(ner, utga, shalgalt);
    if (
      this.props.utgaAvah !== undefined &&
      this.props.orolt.disabled !== true
    ) {
      this.props.utgaAvah(ner, utga, urDun);
    }
  }
  bairlalOorchlogdohod(lat, lng) {
    const ner = this.props.orolt.ner;
    if (
      this.props.utgaAvah !== undefined &&
      this.props.orolt.disabled !== true
    ) {
      this.props.utgaAvah(ner, { lat, lng }, "");
    }
  }
  songohod(index) {
    const ner = this.props.orolt.ner;
    const songoh = this.props.orolt.songoh;
    let songoltList = this.props[songoh] ? this.props[songoh] : [];
    const hamaarah =
      this.props.orolt && this.props.orolt.hamaarah
        ? this.props.orolt.hamaarah
        : "";
    if (hamaarah !== "") {
      const hamaarah_data = this.props.hamaarah_data
        ? this.props.hamaarah_data
        : [];
      const hamaarah_shalgalt =
        this.props.orolt && this.props.orolt.hamaarah_shalgalt
          ? this.props.orolt.hamaarah_shalgalt
          : "";
      const myHamaarah = hamaarah_data[hamaarah] ? hamaarah_data[hamaarah] : 0;
      songoltList = songoltList.filter(
        (item) => item[hamaarah_shalgalt] === myHamaarah
      );
    }
    const songolt = songoltList[index];
    const shalgalt = this.props.orolt.validations;
    const urDun = validShalgah(ner, songolt, shalgalt);
    if (
      this.props.utgaAvah !== undefined &&
      this.props.orolt.disabled !== true
    ) {
      this.props.utgaAvah(ner, songolt, urDun);
    }
  }
  switchDarahad(a) {
    const ner = a.target.id;
    const utga = this.props.utga ? this.props.utga : false;
    if (
      this.props.utgaAvah !== undefined &&
      this.props.orolt.disabled !== true
    ) {
      this.props.utgaAvah(ner, !utga, "");
    }
  }
  ugtaZooh(ner, utga) {
    if (
      this.props.utgaAvah !== undefined &&
      this.props.orolt.disabled !== true
    ) {
      this.props.utgaAvah(ner, utga, "");
    }
  }
  zuragSongohod(event) {
    const file = event.target.files[0];
    const ner = event.target.name;
    const shalgalt = this.props.orolt.validations;
    if (file !== undefined) {
      Resizer.imageFileResizer(file, 300, 300, "JPEG", 70, 0, (uri) => {
        const urDun = validShalgah(ner, uri, shalgalt);
        this.props.utgaAvah(ner, uri, urDun);
      });
    } else {
      const urDun = validShalgah(ner, "", shalgalt);
      this.props.utgaAvah(ner, "", urDun);
    }
  }

  render() {
    const ognooHyzgaarMax = tsagAvah("ognooHyzgaarMax");
    let ognooHyzgaarMin = tsagAvah("onoodor");
    const lg = this.props.md ? this.props.md : 10;
    const offset = this.props.offset ? this.props.offset : 1;
    let utga = this.props.utga ? this.props.utga : "";

    const garchig =
      this.props.orolt && this.props.orolt.garchig
        ? this.props.orolt.garchig
        : "";
    const ner =
      this.props.orolt && this.props.orolt.ner ? this.props.orolt.ner : "";
    const turul =
      this.props.orolt && this.props.orolt.turul
        ? this.props.orolt.turul
        : "text";
    const placeholder =
      this.props.orolt && this.props.orolt.placeholder
        ? this.props.orolt.placeholder
        : "";
    const isValid =
      this.props.validationHariu &&
      this.props.validationHariu.isValid !== undefined
        ? this.props.validationHariu.isValid
        : true;
    const validationMessage =
      this.props.validationHariu && this.props.validationHariu.message
        ? this.props.validationHariu.message
        : "";

    const tovchtoi =
      this.props.orolt && this.props.orolt.tovchtoi && !this.props.tovchgui
        ? this.props.orolt.tovchtoi
        : false;
    let songolt = [];
    const songoh =
      this.props.orolt && this.props.orolt.songoh
        ? this.props.orolt.songoh
        : "";
    const songolt_hevleh =
      this.props.orolt && this.props.orolt.songolt_hevleh
        ? this.props.orolt.songolt_hevleh
        : "ner";
    const disabled =
      (this.props.orolt && this.props.orolt.disabled === true) ||
      this.props.disabled === true
        ? true
        : false;
    const nuuh_flag =
      this.props.orolt && this.props.orolt.nuuh_flag
        ? this.props.orolt.nuuh_flag
        : false;
    const nuuh_nohtsol =
      this.props.nuuh_nohtsol !== undefined &&
      this.props.nuuh_nohtsol[ner] === false
        ? false
        : true;
    const orolt_nuuh =
      this.props.orolt && this.props.orolt.orolt_nuuh
        ? this.props.orolt.orolt_nuuh
        : false;

    const hamaarah =
      this.props.orolt && this.props.orolt.hamaarah
        ? this.props.orolt.hamaarah
        : "";
    const hamaarah_shalgalt =
      this.props.orolt && this.props.orolt.hamaarah_shalgalt
        ? this.props.orolt.hamaarah_shalgalt
        : "";

    const hamaarah_data = this.props.hamaarah_data
      ? this.props.hamaarah_data
      : [];

    const ontsgoi_tsagiin_date =
      this.props.orolt && this.props.orolt.ontsgoi_tsagiin_date === true
        ? this.props.orolt.ontsgoi_tsagiin_date
        : false;
    const warning_text =
      this.props.orolt && this.props.orolt.warning_text
        ? this.props.orolt.warning_text
        : false;
    const nemelt_tailbar =
      this.props.orolt && this.props.orolt.nemelt_tailbar
        ? " ( " + this.props.orolt.nemelt_tailbar + " ) "
        : "";
    const songolt_tailbar =
      !!this.props.orolt && !!this.props.orolt.songolt_tailbar
        ? this.props.orolt.songolt_tailbar
        : "";
    if (turul === "hayag" && utga === "") {
      utga = tohirgoo.bairlal;
      this.ugtaZooh(ner, utga);
    }
    if (turul === "songoh") {
      songolt = this.props[songoh] ? this.props[songoh] : [];
      if (hamaarah !== "") {
        const myHamaarah = hamaarah_data[hamaarah]
          ? hamaarah_data[hamaarah]
          : 0;
        songolt = songolt.filter(
          (item) => item[hamaarah_shalgalt] === myHamaarah
        );
      }
    }
    if (turul === "switch" && utga === "") {
      utga = false;
    }
    if (turul === "date" && ontsgoi_tsagiin_date === true) {
      ognooHyzgaarMin = tsagAvah("zahialgaAvahHonogLimit");
    }
    if (turul === "date" && !!utga) {
      utga = ognooFormat(utga, "YYYY-MM-DD");
    }
    if (!!orolt_nuuh) {
      return <div></div>;
    }
    if (nuuh_flag === true && nuuh_nohtsol === true) {
      return <div></div>;
    }
    return (
      <Row>
        <Col lg={offset} md={offset - 1} sm="0" xs="0" />
        <Col lg={lg} md={lg + 1} sm="12" xs="12">
          <div className={tovchtoi ? "form-row neg-morond" : "form-row"}>
            <div
              className={
                tovchtoi === true
                  ? "col-lg-11 col-md-11 col-sm-11 col-xs-11"
                  : "col-lg-12 col-md-12 col-sm-12 col-xs-12"
              }
              style={tovchtoi ? { width: "85%", marginRight: "5px" } : {}}
            >
              {turul === "hayag" ? (
                <div>
                  <br />
                  <p>{i18n.t("map_tailbar_text")}</p>
                  <MapPicker
                    // defaultLocation={utga}
                    defaultLocation={{
                      lat: parseFloat(utga.lat),
                      lng: parseFloat(utga.lng),
                    }}
                    mapTypeId="roadmap"
                    style={{ height: window.innerHeight * 0.4 }}
                    onChangeLocation={this.bairlalOorchlogdohod}
                    // onChangeZoom={handleChangeZoom}
                    // zoom={zoom}
                    apiKey={tohirgoo.mapKey}
                  />
                  <br />
                </div>
              ) : turul === "songoh" ? (
                <SongoltBox
                  garchig={songoh}
                  songoltUtga={songolt}
                  hevleh={songolt_hevleh}
                  utga={utga}
                  zaaval={true}
                  songohod={this.songohod}
                  disabled={disabled}
                  isValid={isValid}
                  validationMessage={validationMessage}
                  songolt_tailbar={songolt_tailbar}
                />
              ) : turul === "switch" ? (
                <FormGroup check>
                  <Label check>
                    <Input
                      id={ner}
                      type="checkbox"
                      name={ner}
                      checked={utga}
                      onChange={() => {}}
                      //  label=
                      onClick={this.switchDarahad}
                      disabled={disabled}
                    />
                    {garchig === "tuluv"
                      ? i18n.t("idevhjuuleh")
                      : i18n.t(garchig)}
                  </Label>
                </FormGroup>
              ) : turul === "zurag" ? (
                <FormGroup>
                  <Label for="exampleFile">{i18n.t(garchig)}</Label>
                  <br />
                  <Input
                    type="file"
                    name={ner}
                    // accept="image/*"
                    accept=".jpg, .png, .jpeg"
                    onChange={this.zuragSongohod}
                    invalid={!isValid}
                    disabled={disabled}
                  />
                  <FormFeedback>{validationMessage}</FormFeedback>
                </FormGroup>
              ) : (
                <FormGroup className={isValid === false ? "has-danger" : ""}>
                  <Label>
                    {i18n.t(garchig)}
                    {nemelt_tailbar}
                  </Label>
                  <Input
                    autoFocus={
                      this.props.index !== undefined && this.props.index === 0
                        ? true
                        : false
                    }
                    type={turul === "date_notimezone" ? "date" : turul}
                    name={ner}
                    value={utga}
                    placeholder={i18n.t(placeholder)}
                    onChange={this.utgaOorchlogdohod}
                    invalid={!isValid}
                    max={
                      turul === "date" || turul === "date_notimezone"
                        ? ognooHyzgaarMax
                        : undefined
                    }
                    min={
                      turul === "date" || turul === "date_notimezone"
                        ? ognooHyzgaarMin
                        : undefined
                    }
                    disabled={disabled}
                  />
                  <FormFeedback>{validationMessage}</FormFeedback>
                  {warning_text !== false && (
                    <Label style={{ textAlign: "right", color: ungu.warning }}>
                      {warning_text}
                    </Label>
                  )}
                </FormGroup>
              )}
            </div>
            {tovchtoi && (
              <div
                className="col-lg-1 col-md-1 col-sm-1 col-xs-1"
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  size="sm"
                  className={"ungu-primary"}
                  onClick={() => this.props.tovchDarahad(ner)}
                >
                  <i className="bi bi-pencil-square" />
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default GarchigtaiOrolt;
