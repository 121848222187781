import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import Husnegt from "components/Husnegt";
import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  uilchilgeeJagsaaltFn,
  uilchilgeeBurtgelFn,
  ajiltanUilchilgeeJagsaaltFn,
  ajiltanUilchilgeeBurtgelFn,
} from "../../../store/actions/baiguullaga.actions";
import { validationUrt, validEseh } from "validation";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
import ButsahTovch from "components/ButsahTovch";

class AjiltanUilchilgee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout_turul: LoginAuth.layoutTurulAvah(),
      flag: 0,
      medegdel: false,

      ajiltanInfo: this.props.location.state
        ? this.props.location.state.songogdsonMor
        : {},
      ajiltan_id:
        this.props.location.state && this.props.location.state.songogdsonMor
          ? this.props.location.state.songogdsonMor.ajiltan_id
          : 0,
      damjsan_eseh:
        this.props.location.state && this.props.location.state.songogdsonMor
          ? true
          : false,
      dialogTuluv: false,
      songogdsonMor: {},
      garchignuud: [
        {
          ner: "ner",
          garchig: "uilchilgeenii_ner",
          validations: {
            validation: ["isZaaval", "isTemdegtgui"],
            urtMax: validationUrt.string,
          },
        },
        {
          ner: "uilchilgeenii_undsen_tulbur",
          garchig: "undsen_tarif",
          validations: {
            validation: ["isZaaval", "isDanToo"],
          },
          buhelchleh: true,
        },
        {
          ner: "undsen_tulbur_max",
          garchig: "undsen_tarif_max",
          validations: {
            validation: ["isZaaval", "isDanToo"],
          },
          buhelchleh: true,
        },
        {
          ner: "muchlug",
          garchig: "muchlug",
          validations: {
            validation: ["isZaaval", "isDanToo"],
          },
        },
        {
          ner: "tailbar",
          garchig: "tailbar",
          turul: "textarea",
          validations: {
            validation: ["isZaaval", "isTemdegttei"],
            urtMax: validationUrt.tailbar_urt,
          },
        },
        {
          ner: "tuluv",
          garchig: "tuluv",
          turul: "switch",
        },
      ],
      modal1: [
        {
          ner: "uilchilgee",
          garchig: "uilchilgee",
          turul: "songoh",
          songoh: "uilchilgee",
          validations: {
            validation: ["isZaaval"],
          },
        },
      ],
      uilchilgee_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
    };
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    const { ajiltan_id, damjsan_eseh } = this.state;
    if (damjsan_eseh === false) {
      this.props.history.push({ pathname: "/ajiltan" });
    } else {
      this.props.ajiltanUilchilgeeJagsaaltFn(ajiltan_id);
      this.props.uilchilgeeJagsaaltFn();
    }
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};

    if (
      props.ajiltan_uilchilgee_jagsaalt !== state.ajiltan_uilchilgee_jagsaalt
    ) {
      let ajiltan_uilchilgee_jagsaalt = props.ajiltan_uilchilgee_jagsaalt
        ? props.ajiltan_uilchilgee_jagsaalt
        : [];
      ajiltan_uilchilgee_jagsaalt.sort((a, b) => (a.tuluv > b.tuluv ? 1 : -1));
      ajiltan_uilchilgee_jagsaalt.map((utga) => {
        utga.tuluv = utga.tuluv === "inactive" ? false : true;
        return null;
      });
      hariu.ajiltan_uilchilgee_jagsaalt = props.ajiltan_uilchilgee_jagsaalt
        ? props.ajiltan_uilchilgee_jagsaalt
        : [];
      hariu.utguud = ajiltan_uilchilgee_jagsaalt;
    }

    if (props.uilchilgee_jagsaalt !== state.uilchilgee_jagsaalt) {
      const uilchilgee_jagsaalt = props.uilchilgee_jagsaalt
        ? props.uilchilgee_jagsaalt
        : [];
      const ajiltan_uilchilgee_jagsaalt = state.ajiltan_uilchilgee_jagsaalt
        ? state.ajiltan_uilchilgee_jagsaalt
        : [];
      let ilgeeh = [];
      uilchilgee_jagsaalt.map((utga) => {
        const filtered = ajiltan_uilchilgee_jagsaalt.filter(
          (ua) => ua.uilchilgee_id === utga.uilchilgee_id
        );
        if (filtered.length === 0) {
          ilgeeh.push(utga);
        }
        return null;
      });
      hariu.uilchilgee_jagsaalt = ilgeeh;
    }
    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor: {},
      validationHariu: {},
      medegdel: false,
    });
    this.props.uilchilgeeJagsaaltFn();
  }

  husnegtTovchDaragdahad(utga) {
    this.setState({ unshijBaigaa: true });
    const { ajiltan_id } = this.state;
    const ilgeeh = {
      ajiltan_id: ajiltan_id,
      uilchilgee_id: utga.uilchilgee_id,
      tuluv: !utga.tuluv, // status solij baigaa uchir
      ajiltan_uilchilgee_id: utga.ajiltan_uilchilgee_id,
    };
    try {
      this.props.ajiltanUilchilgeeBurtgelFn(ilgeeh).then((hariu) => {
        if (hariu.amjilt === true) {
          this.setState({
            medegdel: true,
            flag: 0,
            unshijBaigaa: false,
          });
          this.props.ajiltanUilchilgeeJagsaaltFn(ajiltan_id);
          this.props.uilchilgeeJagsaaltFn();
        } else {
          this.setState({
            medegdel: true,
            flag: 1,
            unshijBaigaa: false,
          });
        }
      });
    } catch (aldaa) {
      this.setState({
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  }
  hadgalahDarahad() {
    const { songogdsonMor, ajiltan_id } = this.state;
    this.setState({ unshijBaigaa: true });
    try {
      const ilgeeh = {
        ajiltan_id: ajiltan_id,
        uilchilgee_id: songogdsonMor.uilchilgee["uilchilgee_id"],
        tuluv: true,
        ajiltan_uilchilgee_id: 0,
      };
      this.props.ajiltanUilchilgeeBurtgelFn(ilgeeh).then((hariu) => {
        if (hariu.amjilt === true) {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 0,
            unshijBaigaa: false,
          });
          this.props.ajiltanUilchilgeeJagsaaltFn(ajiltan_id);
        } else {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 1,
            unshijBaigaa: false,
          });
        }
      });
    } catch (aldaa) {
      this.setState({
        dialogTuluv: false,
        songogdsonMor: {},
        validationHariu: {},
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  render() {
    const {
      validationHariu,
      garchignuud,
      utguud,
      dialogTuluv,
      songogdsonMor,
      flag,
      medegdel,
      unshijBaigaa,
      layout_turul,
      modal1,
      uilchilgee_jagsaalt,
      erh_hyzgaar,
    } = this.state;
    if (layout_turul === 1) {
      return <Redirect to="/" />;
    }
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md="9">
                  <ButsahTovch {...this.props} zam="/ajiltan" />
                </Col>
                <Col md="3" align="right">
                  <Button
                    className="ungu-primary"
                    round="true"
                    outline
                    onClick={this.dialogToggle}
                  >
                    {i18n.t("ajiltan_uilchilgee_nemeh")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    tovchtoi_eseh={true}
                    tovch={["status_solih"]}
                    tovch_daragdahad={this.husnegtTovchDaragdahad}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            {dialogTuluv === true && (
              <Form>
                {modal1.map((orolt, index) => {
                  return (
                    <GarchigtaiOrolt
                      key={index}
                      index={index}
                      orolt={orolt}
                      utgaAvah={this.modalUtgaOorchlogdohod}
                      utga={songogdsonMor[orolt.ner]}
                      uilchilgee={uilchilgee_jagsaalt}
                      validationHariu={validationHariu[orolt.ner]}
                    />
                  );
                })}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="ungu-primary"
              onClick={this.hadgalahDarahad}
              disabled={!validEseh(modal1, songogdsonMor)}
            >
              {i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { uilchilgee_jagsaalt, ajiltan_uilchilgee_jagsaalt } =
    state.baiguullagaReducer;
  return {
    uilchilgee_jagsaalt,
    ajiltan_uilchilgee_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uilchilgeeJagsaaltFn,
      uilchilgeeBurtgelFn,
      ajiltanUilchilgeeJagsaaltFn,
      ajiltanUilchilgeeBurtgelFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AjiltanUilchilgee)
);
