import axios from "axios";
export const UNDSEN_TSES = "/";
export const ID_TOKEN_KEY = "token_id";
export const HEL = "lang";
export const MEDEELEL_ME_KEY = "me";
export const MEDEELEL_JIJIG_TURUL_KEY = "jijig_turul";
export const MEDEELEL_TOM_TURUL_KEY = "tom_turul";
export const MEDEELEL_BAIGUULLAGA_INFO_KEY = "baiguullaga_info";
export const TSAG_HUVAARI_KEY = "tsagiin_huvaari";
export const TMZONE_KEY = "tsagiin_bus";
export default class LoginAuth {
  static setIdToken(idToken) {
    localStorage.setItem(ID_TOKEN_KEY, idToken);
    axios.defaults.headers.common["Authorization"] = "JWT " + this.getIdToken();
  }
  static getIdToken() {
    return localStorage.getItem(ID_TOKEN_KEY);
  }
  static helZooh(lang) {
    localStorage.setItem(HEL, lang);
  }
  static helAvah() {
    const hel = localStorage.getItem(HEL);
    return !!hel ? hel : "en";
  }

  static localStorageTseverleh() {
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.removeItem(MEDEELEL_ME_KEY);
    localStorage.removeItem(MEDEELEL_JIJIG_TURUL_KEY);
    localStorage.removeItem(MEDEELEL_TOM_TURUL_KEY);
    localStorage.removeItem(MEDEELEL_BAIGUULLAGA_INFO_KEY);
    localStorage.removeItem(TSAG_HUVAARI_KEY);
  }
  static garah(transitionTo = UNDSEN_TSES) {
    this.localStorageTseverleh();
  }

  static hereglegchMeZooh(medeelel) {
    if (medeelel.hereglegch.length > 0) {
      localStorage.setItem(
        MEDEELEL_ME_KEY,
        JSON.stringify(medeelel.hereglegch ? medeelel.hereglegch : [])
      );
    } else {
      localStorage.setItem(
        MEDEELEL_ME_KEY,
        JSON.stringify(medeelel.me ? medeelel.me : [])
      );
    }
    localStorage.setItem(
      MEDEELEL_JIJIG_TURUL_KEY,
      JSON.stringify(medeelel.jijeg_turul ? medeelel.jijeg_turul : [])
    );
    localStorage.setItem(
      MEDEELEL_TOM_TURUL_KEY,
      JSON.stringify(medeelel.tomturul ? medeelel.tomturul : [])
    );
    localStorage.setItem(
      TSAG_HUVAARI_KEY,
      JSON.stringify(medeelel.tsagiin_huvaari ? medeelel.tsagiin_huvaari : [])
    );
    localStorage.setItem(
      TMZONE_KEY,
      JSON.stringify(medeelel.tsagiin_bus ? medeelel.tsagiin_bus : [])
    );
    let utga = medeelel.baiguullaga_info;
    utga.balance = medeelel.baiguullaga_balance.uldegdel;
    const filtered = medeelel.tomturul.filter(
      (val) => val.id === utga.uilchilgeenii_turul
    );
    utga.uilchilgeenii_turul_ner = filtered[0] ? filtered[0].ner : "";
    utga.layout_turul = filtered[0] ? filtered[0].layout_turul : 1;
    localStorage.setItem(MEDEELEL_BAIGUULLAGA_INFO_KEY, JSON.stringify(utga));
  }
  static meAvah() {
    return JSON.parse(localStorage.getItem(MEDEELEL_ME_KEY));
  }
  static jijigTurulAvah() {
    return JSON.parse(localStorage.getItem(MEDEELEL_JIJIG_TURUL_KEY));
  }
  static tomTurulAvah() {
    return JSON.parse(localStorage.getItem(MEDEELEL_TOM_TURUL_KEY));
  }
  static baiguullagaInfoAvah() {
    return JSON.parse(localStorage.getItem(MEDEELEL_BAIGUULLAGA_INFO_KEY));
  }
  static layoutTurulAvah() {
    const b_info = JSON.parse(
      localStorage.getItem(MEDEELEL_BAIGUULLAGA_INFO_KEY)
    );
    const layout_turul = b_info.layout_turul ? b_info.layout_turul : 1;
    // restaurant bol 1
    return layout_turul;
  }
  static dansniiUldegdelAvah() {
    const info = JSON.parse(
      localStorage.getItem(MEDEELEL_BAIGUULLAGA_INFO_KEY)
    );
    return info.balance;
  }
  static erhAvah() {
    const user = JSON.parse(localStorage.getItem(MEDEELEL_ME_KEY));
    let erh = "";
    if (user.length === undefined && user.ene_darga === false) {
      erh = "user";
    } else {
      erh = "admin";
    }
    return erh;
  }

  static tulburinTurulAvah() {
    // shimtgel tuldug bol 1 butsaah
    // saraar bol 2 butsaana
    return 2;
  }
  static serviceSubscAvah() {
    let hariu = {
      subsc_service: false,
      tsag_zahialga_service: false,
      preperation_fee_service: false,
      tulbur_banner: false,
      service_subsc_staff_count: 0,

      tulbur_subsc_service: true,
      tulbur_tsag_zahialga_service: true,
      temdegt: "CAD",
    };
    const info = JSON.parse(
      localStorage.getItem(MEDEELEL_BAIGUULLAGA_INFO_KEY)
    );
    hariu.temdegt = info.temdegt;
    if (info.subsc_service === true) {
      hariu.subsc_service = info.subsc_service;
    }
    if (info.tsag_zahialga_service === true) {
      hariu.tsag_zahialga_service = info.tsag_zahialga_service;
    }
    if (info.preperation_fee_service === true) {
      hariu.preperation_fee_service = info.preperation_fee_service;
    }
    if (hariu.subsc_service === true && hariu.subsc_service_tulbur === false) {
      hariu.tulbur_banner = true;
      hariu.tulbur_subsc_service = false;
    }
    if (
      hariu.tsag_zahialga_service === true &&
      hariu.tsag_zahialga_service_tulbur === false
    ) {
      hariu.tulbur_banner = true;
      hariu.tulbur_tsag_zahialga_service = false;
    }
    if (
      hariu.preperation_fee_service === true &&
      hariu.preperation_fee_service_tulbur === false
    ) {
      hariu.tulbur_banner = true;
    }
    if (
      info.tsag_zahialga_service === true &&
      info.service_subsc_staff_count > 0
    ) {
      hariu.service_subsc_staff_count = info.service_subsc_staff_count;
    }

    hariu.subsc_service_auto_sungalt =
      info.subsc_service_auto_sungalt === true ? true : false;
    hariu.tsag_zahialga_service_auto_sungalt =
      info.tsag_zahialga_service_auto_sungalt === true ? true : false;
    hariu.preperation_fee_service_auto_sungalt =
      info.preperation_fee_service_auto_sungalt === true ? true : false;
    // hariu.subsc_service = true;
    // hariu.tsag_zahialga_service = true;
    // hariu.preperation_fee_service = true;
    return hariu;
  }

  static tsagiinHuvaariAvah() {
    return JSON.parse(localStorage.getItem(TSAG_HUVAARI_KEY));
  }
  static tmzone_avah() {
    return JSON.parse(localStorage.getItem(TMZONE_KEY));
  }
}
