import { baiguullagaConstants } from "store/constants";
import { hereglegchConstants } from "store/constants";
import { API } from "services/api";
import i18n from "hel/i18n";

export const uilchilgeeJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/uilchilgee`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: baiguullagaConstants.UILCHILGEENII_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "uilchilgeeJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};
export const uilchilgeeBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/burtgel/uilchilgee`, {
        uilchilgee_id: state.uilchilgee_id ? state.uilchilgee_id : 0,
        ner: state.ner,
        tulbur: state.uilchilgeenii_undsen_tulbur,
        tulbur_max: state.undsen_tulbur_max,
        muchlug: state.muchlug,
        tailbar: state.tailbar,
        tuluv: state.tuluv === true ? "active" : "inactive",
        zurag_dugaar: 1,
        nas_asuudag_eseh: state.nas_asuudag_eseh
          ? state.nas_asuudag_eseh
          : false,
        hyzgaarlah_nas: state.hyzgaarlah_nas ? state.hyzgaarlah_nas : "0",
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
export const ajiltanJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/ajiltan`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: baiguullagaConstants.AJILTAN_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "ajiltanJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};
export const ajiltanBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/burtgel/ajiltan`, {
        ajiltan_id: state.ajiltan_id ? state.ajiltan_id : 0,
        ovog: state.ovog ? state.ovog : "",
        ner: state.ner ? state.ner : "",
        utas: state.utas ? state.utas : "",
        namtar: state.namtar ? state.namtar : "",
        tuluv: state.tuluv === true ? "active" : "inactive",
        undsen_tulbur: state.undsen_tulbur ? state.undsen_tulbur : 0,
        turul: 1,
        hun_min: 0,
        hun_max: 0,
        ajiltan_detail_haruulna: state.ajiltan_detail_haruulna
          ? state.ajiltan_detail_haruulna
          : false,
        nickname: "",
        profile_zurag_dugaar: state.profile_zurag_dugaar
          ? state.profile_zurag_dugaar
          : "",
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const shireeBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/burtgel/ajiltan`, {
        ajiltan_id: state.ajiltan_id ? state.ajiltan_id : 0,
        ovog: "",
        ner: state.ner,
        utas: "",
        namtar: "",
        tuluv: state.tuluv === true ? "active" : "inactive",
        undsen_tulbur: state.undsen_tulbur ? state.undsen_tulbur : 0,
        turul: 2,
        hun_min: state.hun_min,
        hun_max: state.hun_max,
        ajiltan_detail_haruulna: false,
        profile_zurag_dugaar: 0,
        nickname: "",
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const shireeJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/shiree`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: baiguullagaConstants.SHIREE_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "ajiltanJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const ajiltanUilchilgeeJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/ajiltan_uilchilgee/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: baiguullagaConstants.AJILTAN_UILCHILGEE_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "ajiltanUilchilgeeJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const ajiltanUilchilgeeBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/hereglegch/ajiltan_uilchilgee_manage`, {
        ajiltan_id: state.ajiltan_id,
        uilchilgee_id: state.uilchilgee_id,
        tuluv: state.tuluv === true ? "active" : "inactive",
        ajiltan_uilchilgee_id: state.ajiltan_uilchilgee_id
          ? state.ajiltan_uilchilgee_id
          : 0,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
export const subscJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/baraa`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: baiguullagaConstants.SUBSC_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "subscJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};
export const subscBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/hereglegch/baraa/nemeh`, {
        buteegdehuun_dugaar: state.buteegdehuun_dugaar
          ? state.buteegdehuun_dugaar
          : 0,
        ner: state.ner,
        une: state.une,
        tuluv: "active",
        zurag_dugaar: state.zurag_dugaar,
        medeelel: state.medeelel,
        sar_limit: state.sar_limit,
        is_eco: false,
        eco_tailbar: "",
        // udur_limit: state.udur_limit,
        negj_une: state.negj_une,
        discount_percent: 0,
        nas_asuudag_eseh: state.nas_asuudag_eseh
          ? state.nas_asuudag_eseh
          : false,
        hyzgaarlah_nas: state.hyzgaarlah_nas ? state.hyzgaarlah_nas : 0,
        local_tax_rate: state.local_tax_rate ? state.local_tax_rate : 0,
        negj_une_undsen: state.negj_une_undsen,
        times_text: state.times_text ? state.times_text : i18n.t("times_text"),
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
export const subscRepActiveFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/report/baraa/active/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: baiguullagaConstants.REPORT_ACTIVE_SUBSC_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "subscRepActiveFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};
export const subscRepGetFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/report/baraa/get/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: baiguullagaConstants.REPORT_GET_SUBSC_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "subscRepGetFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const subscRepSoldFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/report/baraa/sold/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: baiguullagaConstants.REPORT_SOLD_SUBSC_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "subscRepSoldFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const subscDeactivateFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/hereglegch/baraa/ustgah`, {
        buteegdehuun_dugaar: state.buteegdehuun_dugaar,
      })
      .then((response) => {
        console.log("response", response);
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
