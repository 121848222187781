import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import i18n from "hel/i18n";
import BaiguullagaTab1 from "./duudagdah/BaiguullagaTab1";
import BaiguullagaTab2 from "./duudagdah/BaiguullagaTab2";
import BaiguullagaTab3 from "./duudagdah/BaiguullagaTab3";
import LoginAuth from "services/LoginAuth";

class Baiguullaga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTuluv: false,
      songogdsonMor: {},

      utguud: [],
      validationHariu: {},
      activeTab: "1",

      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
    };
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.tabToggle = this.tabToggle.bind(this);
  }

  utgaOorchlogdohod(ner, utga, urDun) {
    let utguud = { ...this.state.utguud };
    utguud[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      utguud,
      validationHariu,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor:
        this.state.dialogTuluv === true ? {} : { ...this.state.songogdsonMor },
    });
    this.props.uilchilgeeJagsaaltFn();
  }
  tabToggle = (activeTab) => {
    this.setState({ activeTab });
  };

  husnegtTovchDaragdahad(utga) {
    this.setState(
      {
        songogdsonMor: utga,
      },
      function () {
        this.dialogToggle();
      }
    );
  }
  hadgalahDarahad() {}
  render() {
    const { activeTab, erh_hyzgaar } = this.state;
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <div>
          <Card className="card-user">
            <CardBody>
              <div>
                <Nav tabs justified>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        this.tabToggle("1");
                      }}
                    >
                      {i18n.t("hereglegch_medeelel")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        this.tabToggle("2");
                      }}
                    >
                      {i18n.t("baiguullaga_medeelel")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        this.tabToggle("3");
                      }}
                    >
                      {i18n.t("tsag_medeelel")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        {activeTab === "1" && <BaiguullagaTab1 />}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        {activeTab === "2" && <BaiguullagaTab2 />}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        {activeTab === "3" && <BaiguullagaTab3 />}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Baiguullaga)
);
