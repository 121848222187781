import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import i18n from "hel/i18n";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
  Alert,
  Row,
  Card,
  Col,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
} from "reactstrap";

import {
  nevtrehFn,
  hereglegchMe,
} from "../../store/actions/hereglegch.actions";
import LoginAuth from "services/LoginAuth";
import { togtmol } from "delgetsuud/zagvar";
import MyLoader from "components/MyLoader";

class Nevtreh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medegdel: false,
      token: LoginAuth.getIdToken(),
      flag: 1,
      oroltuud: [
        {
          garchig: "nevtreh_hayag",
          turul: "number",
          ner: "nevtreh_hayag",
          placeholder: "utasnii_dugaar",
        },
        {
          garchig: "nuuts_ug",
          ner: "nuuts_ug",
          placeholder: "nuuts_ug",
          turul: "password",
        },
      ],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      nevtrehDaragdsan: false,
    };
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.nevtrehDarahad = this.nevtrehDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  utgaOorchlogdohod(ner, utga, urDun) {
    let utguud = { ...this.state.utguud };
    utguud[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      utguud,
      validationHariu,
    });
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  nevtrehDarahad() {
    this.setState({ unshijBaigaa: true, nevtrehDaragdsan: true });
    const { utguud } = this.state;
    try {
      this.props.nevtrehFn(utguud).then((hariu) => {
        if (hariu.amjilt === true) {
          this.props.hereglegchMe().then(() => {
            this.props.history.push({ pathname: "/" });
          });
        } else if (
          hariu.status !== undefined &&
          (hariu.status === 1 || hariu.status === 5)
        ) {
          this.props.history.push({
            pathname: "/batalgaajuulah",
            state: {
              nevtreh_hayag: utguud.nevtreh_hayag,
              email: utguud.email,
              status: hariu.status,
            },
          });
        } else if (hariu.status !== undefined && hariu.status === 6) {
          this.props.history.push({
            pathname: "/nevtrelt/batalgaajuulah",
            state: {
              nevtreh_hayag: utguud.nevtreh_hayag,
              email: hariu.email,
            },
          });
        } else {
          this.setState({
            medegdel: true,
            flag: 2,
            unshijBaigaa: false,
            nevtrehDarahad: false,
          });
        }
      });
    } catch (aldaa) {
      this.setState({
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
        nevtrehDarahad: false,
      });
    }
  }

  render() {
    const {
      flag,
      token,
      validationHariu,
      oroltuud,
      utguud,
      unshijBaigaa,
      // nevtrehDaragdsan,
      medegdel,
    } = this.state;
    if (!!token) {
      this.props.history.push({ pathname: "/" });
    }
    return (
      <div className="deerees-zai">
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <a href="https://www.yousubsc.com">Home</a>
          </Col>
        </Row>
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Alert
              isOpen={medegdel}
              toggle={this.medegdelToggle}
              color={flag === 0 ? "success" : "danger"}
            >
              {flag === 0 && <span>{i18n.t("medegdel_amjilt_1")}</span>}
              {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
              {flag === 2 && <span>{i18n.t("medegdel_aldaa_nevtreh")}</span>}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <br />
            <Card>
              <CardHeader>
                <CardTitle tag="h5" align="center">
                  {i18n.t("business_account_nevtreh")}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  {oroltuud.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.utgaOorchlogdohod}
                        utga={utguud[orolt.ner]}
                        validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                  <div align="right">
                    <a
                      href="/nuutsugSergeeh"
                      style={{ color: "var(--grading-secondary-1)" }}
                    >
                      {i18n.t("nuutsug_martsan")}
                    </a>
                  </div>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        onClick={this.nevtrehDarahad}
                      >
                        {i18n.t("nevtreh")}
                      </Button>
                    </div>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        round="true"
                        color="primary"
                        outline
                        onClick={() =>
                          this.props.history.push({ pathname: "/register" })
                        }
                      >
                        {i18n.t("burtguuleh")}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nevtrehFn,
      hereglegchMe,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Nevtreh)
);
