import React, { Component } from "react";
import { Row, Card, Col, CardBody, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import i18n from "hel/i18n";
import LoginAuth from "services/LoginAuth";
import Husnegt from "components/Husnegt";
import { tsagAvah } from "validation";
import { subscRepSoldFn } from "../../../store/actions/baiguullaga.actions";
import MyLoader from "components/MyLoader";
import currency from "currency.js";

class SubcsSold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTuluv: false,
      songogdsonMor: {},

      utguud: [],
      sold_subsc_jagsaalt: [],
      validationHariu: {},
      unshijBaigaa: false,

      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
      baiguullaga_info: LoginAuth.baiguullagaInfoAvah(),

      ognooHyzgaarMax: tsagAvah("ognooHyzgaarMax"),
      ognooHyzgaarMin: tsagAvah("ognooHyzgaarMin"),
      ognoo: tsagAvah("onoodor"),
      total_sum: 0,
      garchignuud: [
        {
          ner: "created",
          turul: "tsag",
          garchig: "tsag",
        },
        {
          ner: "ner",
          garchig: "ner_subsc",
        },
        {
          ner: "une",
          garchig: "subsc_price",
        },
        {
          ner: "hereglegch_shimtgel_dun",
          garchig: "user_commission",
        },
        // {
        //   ner: "gross_dun",
        //   garchig: "gro",
        // },
        {
          ner: "gst",
          garchig: "GST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "pst",
          garchig: "PST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "hst",
          garchig: "HST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "qst",
          garchig: "QST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "state_tax",
          garchig: "state_tax_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "local_tax",
          garchig: "local_tax_toshop",
          nuuh: true,
          // ner_nemelt: "",
        },
        {
          ner: "local_tax_rate",
          garchig: "local_tax_rate",
          turul: "huvi",
          nuuh: true,
          ner_nemelt: "",
        },
      ],
    };
    this.ognooSongohod = this.ognooSongohod.bind(this);
  }
  componentDidMount() {
    this.setState({
      unshijBaigaa: true,
    });
    this.props.subscRepSoldFn(this.state.ognoo).then((hariu) =>
      this.setState({
        unshijBaigaa: false,
      })
    );
    const info = this.state.baiguullaga_info;
    let myGarchig = this.state.garchignuud;
    myGarchig.map((garchig) => {
      if (
        garchig.ner_nemelt !== undefined &&
        !!info[garchig.ner] &&
        Number(info[garchig.ner]) > 0
      ) {
        garchig.nuuh = false;
        garchig.ner_nemelt =
          "( " +
          currency(info[garchig.ner] * 100, { precision: 3, separator: "" }) +
          "% )";
      }
      if (garchig.ner === "local_tax" && info.uls_id === 3) {
        garchig.nuuh = false;
      }
      if (garchig.ner === "local_tax_rate" && info.uls_id === 3) {
        garchig.nuuh = false;
      }
      return null;
    });
    this.setState({
      garchignuud: myGarchig,
    });
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};

    if (props.sold_subsc_jagsaalt !== state.sold_subsc_jagsaalt) {
      let sold_subsc_jagsaalt = props.sold_subsc_jagsaalt
        ? props.sold_subsc_jagsaalt
        : [];
      let total_sum = 0;
      sold_subsc_jagsaalt.map((utga) => {
        total_sum = currency(Number(total_sum)).add(Number(utga.gst));
        total_sum = currency(Number(total_sum)).add(Number(utga.pst));
        total_sum = currency(Number(total_sum)).add(Number(utga.hst));
        total_sum = currency(Number(total_sum)).add(Number(utga.qst));
        total_sum = currency(Number(total_sum)).add(Number(utga.local_tax));
        total_sum = currency(Number(total_sum)).add(Number(utga.state_tax));
        return null;
      });
      hariu.sold_subsc_jagsaalt = sold_subsc_jagsaalt;
      hariu.utguud = sold_subsc_jagsaalt;
      hariu.total_sum = total_sum;
    }

    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  ognooSongohod(event) {
    const ognoo = event.target.value;
    this.props.subscRepSoldFn(ognoo);
    this.setState({ ognoo });
  }
  render() {
    const {
      serviceSubsc_info,
      ognooHyzgaarMax,
      ognooHyzgaarMin,
      garchignuud,
      utguud,
      ognoo,
      total_sum,
      unshijBaigaa,
    } = this.state;
    if (serviceSubsc_info.subsc_service === false) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card className="card-user">
            <CardBody>
              <Row>
                <Col md="4">
                  <Label>{i18n.t("sum_toshop")}</Label>
                  <Input disabled value={total_sum} />
                </Col>
                <Col md="4" />
                <Col md="4" xs="12">
                  <Label>{i18n.t("ognoo_haih")}</Label>
                  <Input
                    type="date"
                    name="date"
                    placeholder="date placeholder"
                    max={ognooHyzgaarMax}
                    min={ognooHyzgaarMin}
                    value={ognoo}
                    onChange={this.ognooSongohod}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Husnegt garchignuud={garchignuud} utguud={utguud} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { sold_subsc_jagsaalt } = state.baiguullagaReducer;
  return { sold_subsc_jagsaalt };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      subscRepSoldFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubcsSold)
);
