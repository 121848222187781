import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  Alert,
  Label,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
import {
  ssubscTicketJagsaaltFn,
  subscZogsoohFn,
  subscTicketZogsoohFn,
} from "../../../store/actions/ssubsc.actions";
import { hereglegchMe } from "store/actions/hereglegch.actions";
import moment from "moment";
import { togtmol } from "delgetsuud/zagvar";

class ServiceSubscTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      medegdel: false,

      dialogTuluv: false,
      songogdsonMor: {
        service_subsc_turul: "Subsc service",
        service_subsc_ner: "Default Subsc service",
        service_subsc_une: "100",
        tailbar:
          "Tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar Tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar Tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar tailbar",
      },
      songogdsonIndex: 0,
      garchignuud: [
        {
          ner: "service_subsc_turul",
          garchig: "service_subsc_turul",
        },
        {
          ner: "service_subsc_ner",
          garchig: "service_subsc_ner",
        },
        {
          ner: "service_subsc_une",
          garchig: "service_subsc_une",
        },
        {
          ner: "tailbar",
          garchig: "tailbar",
          turul: "textarea",
        },
      ],
      ssubsc_ticket_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
      dialogZovshooruulTuluv: false,
      songogdson_ustgah_zahialga_id: "",
      songogdson_uilchilgeenii_turul: "",
      subsc_ticket: {},
      tsag_ticket: {},
      prep_ticket: {},
    };
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    const info = LoginAuth.baiguullagaInfoAvah();
    if (info.tuluv !== "active") {
      this.props.history.push({ pathname: "/" });
    }
    this.setState({
      unshijBaigaa: true,
    });
    this.props.ssubscTicketJagsaaltFn().then((hariu) => {
      this.setState({
        unshijBaigaa: false,
      });
    });
    this.props.hereglegchMe().then(() => {
      this.setState({
        serviceSubsc_info: LoginAuth.serviceSubscAvah(),
      });
    });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.ssubsc_ticket_jagsaalt !== state.ssubsc_ticket_jagsaalt) {
      let utguud = props.ssubsc_ticket_jagsaalt
        ? props.ssubsc_ticket_jagsaalt
        : [];
      utguud = utguud.sort((a, b) => (a.zahialga_id < b.zahialga_id ? -1 : 1));
      let subsc_ticket = {};
      let tsag_ticket = {};
      let prep_ticket = {};
      utguud.map((ticket) => {
        if (ticket.uilchilgee_turul === "subsc_service") {
          if (
            !(
              subsc_ticket.guilgeenii_turul === 120 &&
              subsc_ticket.avtomat_subsc === true
            )
          ) {
            subsc_ticket = ticket;
          }
        }
        if (ticket.uilchilgee_turul === "tsag_zahialga_service") {
          if (
            !(
              tsag_ticket.guilgeenii_turul === 120 &&
              tsag_ticket.avtomat_subsc === true
            )
          ) {
            tsag_ticket = ticket;
          }
        }

        if (ticket.uilchilgee_turul === "preperation_fee_service") {
          if (
            !(
              prep_ticket.guilgeenii_turul === 120 &&
              prep_ticket.avtomat_subsc === true
            )
          ) {
            prep_ticket = ticket;
          }
        }
        return null;
      });
      return {
        ssubsc_ticket_jagsaalt: props.ssubsc_ticket_jagsaalt,
        utguud,
        subsc_ticket,
        tsag_ticket,
        prep_ticket,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  subscZogsoohDarahad(zahialga_id, uilchilgeenii_turul, flag) {
    this.setState({
      songogdson_ustgah_zahialga_id: zahialga_id,
      songogdson_uilchilgeenii_turul: uilchilgeenii_turul,
      dialogZovshooruulTuluv: true,
      flag,
    });
  }
  zogsoohFunc() {
    const { songogdson_ustgah_zahialga_id, flag } = this.state;
    const self = this;
    try {
      if (flag === 1) {
        this.props
          .subscZogsoohFn({ zahialga_id: songogdson_ustgah_zahialga_id })
          .then((hariu) => {
            this.props.ssubscTicketJagsaaltFn();
            this.props.hereglegchMe().then(() => {
              self.setState({
                serviceSubsc_info: LoginAuth.serviceSubscAvah(),
              });
            });
            if (hariu.amjilt === true) {
              this.setState({
                medegdel: true,
                flag: 0,
              });
            } else if (hariu.message === "refreshDate_ticket") {
              this.setState({
                medegdel: true,
                flag: 2,
              });
            } else if (hariu.message === "refreshDate") {
              this.setState({
                medegdel: true,
                flag: 3,
              });
            } else {
              this.setState({
                medegdel: true,
                flag: 1,
              });
            }
          });
      } else {
        this.props
          .subscTicketZogsoohFn({ zahialga_id: songogdson_ustgah_zahialga_id })
          .then((hariu) => {
            this.props.ssubscTicketJagsaaltFn();
            if (hariu.amjilt === true) {
              this.setState({
                medegdel: true,
                flag: 0,
              });
            } else if (hariu.message === "refreshDate_ticket") {
              this.setState({
                medegdel: true,
                flag: 2,
              });
            } else {
              this.setState({
                medegdel: true,
                flag: 1,
              });
            }
          });
      }
    } catch (aldaa) {
      this.setState({
        medegdel: true,
        flag: 1,
      });
    }
  }
  render() {
    const {
      flag,
      medegdel,
      unshijBaigaa,
      erh_hyzgaar,
      serviceSubsc_info,
      dialogZovshooruulTuluv,
      songogdson_uilchilgeenii_turul,
      subsc_ticket,
      tsag_ticket,
      prep_ticket,
    } = this.state;
    const { temdegt } = serviceSubsc_info;
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          {medegdel && (
            <Row>
              <Col>
                <Alert
                  isOpen={medegdel}
                  toggle={this.medegdelToggle}
                  color={flag === 0 ? "success" : "danger"}
                >
                  {flag === 0 && <span>{i18n.t("medegdel_amjilt_1")}</span>}
                  {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                  {flag === 2 && (
                    <span>{i18n.t("medegdel_aldaa_refreshdate")}</span>
                  )}{" "}
                  {flag === 3 && (
                    <span>
                      {i18n.t("medegdel_aldaa_disable_solution_regreshdate")}
                    </span>
                  )}
                </Alert>
              </Col>
            </Row>
          )}
          {!!subsc_ticket.zahialga_id && (
            <Card className="ungu-primary">
              <CardHeader style={{ color: "white" }}>
                <Row>
                  <div style={{ width: "50%" }}>
                    <div style={{ textAlign: "left" }}>
                      {i18n.t("subsc_service")}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    {serviceSubsc_info.subsc_service_auto_sungalt === true ? (
                      <div
                        style={{ textAlign: "right", cursor: "pointer" }}
                        onClick={() => {
                          this.subscZogsoohDarahad(
                            subsc_ticket.zahialga_id,
                            subsc_ticket.uilchilgee_turul,
                            1
                          );
                        }}
                      >
                        {i18n.t("erh_zogsooh")}
                      </div>
                    ) : (
                      <div style={{ textAlign: "right" }}>
                        {i18n.t("ssubsc_erh_text_zogsson")}
                      </div>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* {utguud.map((ticket, index) => {
                return (
                  <Row key={index}>
                    <Col lg="6" md="7" sm="8" xs="12">
                      <Card onClick={this.dialogToggle}>
                        <CardBody>
                          {ticket.zahialga_id}
                          <br />
                          <Label>{i18n.t("service_subsc_turul")}: </Label>{" "}
                          {i18n.t(ticket.uilchilgee_turul)}
                          <br />
                          <Label>{i18n.t("service_subsc_ner")}: </Label>
                          {ticket.title}
                          <br />
                          <Label>{i18n.t("service_subsc_une")}: </Label>{" "}
                          {ticket.gross_dun} {temdegt}{" "}
                          <Label>
                            {" / "}
                            {i18n.t("ssubsc_sard")}
                          </Label>
                          <br />
                          <Label>{i18n.t("service_subsc_period")}: </Label>{" "}
                          {moment(ticket.created).format("YYYY.MM.DD") +
                            " - " +
                            moment(ticket.huchintei_ognoo).format("YYYY.MM.DD")}
                          <br />
                          <div>
                            {" "}
                            <Label>{i18n.t("tulbur_heregsel")}: </Label>{" "}
                            {ticket.tulburiin_helber === "stripe"
                              ? ticket.suuliin_too
                                ? "**** **** **** " + ticket.suuliin_too
                                : "Stripe"
                              : ticket.tulburiin_helber === "paypal"
                              ? ticket.paypal_email
                                ? ticket.paypal_email
                                : "Paypal"
                              : ticket.tulburiin_helber === "dansaar"
                              ? i18n.t("dansaar")
                              : ticket.tulburiin_helber ===
                                "borluulaltiin_orlogoos"
                              ? i18n.t("borluulaltiin_orlogoos")
                              : ticket.tulburiin_helber === "trail"
                              ? i18n.t("free_trial")
                              : i18n.t("tulugduugui")}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            {(ticket.uilchilgee_turul === "subsc_service" &&
                              serviceSubsc_info.subsc_service_auto_sungalt ===
                                true) ||
                            (ticket.uilchilgee_turul ===
                              "tsag_zahialga_service" &&
                              serviceSubsc_info.tsag_zahialga_service_auto_sungalt ===
                                true) ||
                            (ticket.uilchilgee_turul ===
                              "preperation_fee_service" &&
                              serviceSubsc_info.preperation_fee_service_auto_sungalt ===
                                true) ? (
                              <Button
                                outline
                                className="ungu-primary"
                                onClick={() =>
                                  this.subscZogsoohDarahad(
                                    ticket.zahialga_id,
                                    ticket.uilchilgee_turul
                                  )
                                }
                              >
                                {i18n.t("ssubsc_button_zogsooh")}
                              </Button>
                            ) : (
                              i18n.t("ssubsc_text_zogsson")
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                );
              })} */}
                <Row>
                  <Col lg="8" md="10" sm="11" xs="11">
                    <Card onClick={this.dialogToggle}>
                      <CardBody>
                        {/* <Label>{i18n.t("service_subsc_turul")}: </Label>{" "}
                        {i18n.t("subsc_service")}
                        <br /> */}
                        <Label>{i18n.t("service_subsc_ner")}: </Label>
                        {" " + subsc_ticket.title}
                        <br />
                        <Label>{i18n.t("service_subsc_une")}: </Label>{" "}
                        {subsc_ticket.gross_dun} {temdegt}{" "}
                        <Label>
                          {" / "}
                          {i18n.t("ssubsc_sard")}
                        </Label>
                        <br />
                        <Label>{i18n.t("service_subsc_period")}: </Label>{" "}
                        {moment(subsc_ticket.uusgesen_ognoo).format(
                          "YYYY.MM.DD"
                        ) +
                          " - " +
                          moment(subsc_ticket.huchintei_ognoo).format(
                            "YYYY.MM.DD"
                          )}
                        <br />
                        <div>
                          {" "}
                          <Label>{i18n.t("tulbur_heregsel")}: </Label>{" "}
                          {subsc_ticket.tulburiin_helber === "stripe"
                            ? subsc_ticket.suuliin_too
                              ? "**** **** **** " + subsc_ticket.suuliin_too
                              : "Stripe"
                            : subsc_ticket.tulburiin_helber === "paypal"
                            ? subsc_ticket.paypal_email
                              ? subsc_ticket.paypal_email
                              : "Paypal"
                            : subsc_ticket.tulburiin_helber === "dansaar"
                            ? i18n.t("dansaar")
                            : subsc_ticket.tulburiin_helber ===
                              "borluulaltiin_orlogoos"
                            ? i18n.t("borluulaltiin_orlogoos")
                            : subsc_ticket.tulburiin_helber === "trail"
                            ? i18n.t("free_trial")
                            : i18n.t("tulugduugui")}
                        </div>
                        {subsc_ticket.guilgeenii_turul === 120 && (
                          <div style={{ textAlign: "right" }}>
                            {subsc_ticket.avtomat_subsc === true ? (
                              <Button
                                outline
                                className="ungu-primary"
                                onClick={() =>
                                  this.subscZogsoohDarahad(
                                    subsc_ticket.zahialga_id,
                                    subsc_ticket.uilchilgee_turul,
                                    2
                                  )
                                }
                              >
                                {i18n.t("ssubsc_button_zogsooh")}
                              </Button>
                            ) : (
                              i18n.t("ssubsc_text_zogsson")
                            )}
                          </div>
                        )}
                        {/* <Label>{i18n.t("tailbar")}: </Label>
                      <Label>
                        Tailbar tailbar tailbar tailbar tailbar tailbar tailbar
                        tailbar tailbar tailbar Tailbar tailbar tailbar tailbar
                        tailbar tailbar tailbar tailbar tailbar tailbar Tailbar
                        tailbar tailbar tailbar tailbar tailbar tailbar tailbar
                        tailbar tailbar
                      </Label> */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          {!!tsag_ticket.zahialga_id && (
            <Card className="ungu-primary">
              <CardHeader style={{ color: "white" }}>
                <Row>
                  <div style={{ width: "50%" }}>
                    <div style={{ textAlign: "left" }}>
                      {i18n.t("tsag_zahialga_service")}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    {serviceSubsc_info.tsag_zahialga_service_auto_sungalt ===
                    true ? (
                      <div
                        style={{ textAlign: "right", cursor: "pointer" }}
                        onClick={() => {
                          this.subscZogsoohDarahad(
                            tsag_ticket.zahialga_id,
                            tsag_ticket.uilchilgee_turul,
                            1
                          );
                        }}
                      >
                        {i18n.t("erh_zogsooh")}
                      </div>
                    ) : (
                      <div style={{ textAlign: "right" }}>
                        {i18n.t("ssubsc_erh_text_zogsson")}
                      </div>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="8" md="10" sm="11" xs="11">
                    <Card onClick={this.dialogToggle}>
                      <CardBody>
                        {/* <Label>{i18n.t("service_subsc_turul")}: </Label>{" "}
                        {i18n.t("tsag_zahialga_service")}
                        <br /> */}
                        <Label>{i18n.t("service_subsc_ner")}: </Label>
                        {" " + tsag_ticket.title}
                        <br />
                        <Label>{i18n.t("ajiltan_too")}: </Label>
                        {" " + tsag_ticket.ajiltan_too}
                        <br />
                        <Label>{i18n.t("service_subsc_une")}: </Label>{" "}
                        {" " + tsag_ticket.gross_dun} {temdegt}{" "}
                        <Label>
                          {" / "}
                          {i18n.t("ssubsc_sard")}
                        </Label>
                        <br />
                        <Label>{i18n.t("service_subsc_period")}: </Label>{" "}
                        {moment(tsag_ticket.uusgesen_ognoo).format(
                          "YYYY.MM.DD"
                        ) +
                          " - " +
                          moment(tsag_ticket.huchintei_ognoo).format(
                            "YYYY.MM.DD"
                          )}
                        <br />
                        <div>
                          {" "}
                          <Label>{i18n.t("tulbur_heregsel")}: </Label>{" "}
                          {tsag_ticket.tulburiin_helber === "stripe"
                            ? tsag_ticket.suuliin_too
                              ? "**** **** **** " + tsag_ticket.suuliin_too
                              : "Stripe"
                            : tsag_ticket.tulburiin_helber === "paypal"
                            ? tsag_ticket.paypal_email
                              ? tsag_ticket.paypal_email
                              : "Paypal"
                            : tsag_ticket.tulburiin_helber === "dansaar"
                            ? i18n.t("dansaar")
                            : tsag_ticket.tulburiin_helber ===
                              "borluulaltiin_orlogoos"
                            ? i18n.t("borluulaltiin_orlogoos")
                            : tsag_ticket.tulburiin_helber === "trail"
                            ? i18n.t("free_trial")
                            : i18n.t("tulugduugui")}
                        </div>
                        {tsag_ticket.guilgeenii_turul === 120 && (
                          <div style={{ textAlign: "right" }}>
                            {tsag_ticket.avtomat_subsc === true ? (
                              <Button
                                outline
                                className="ungu-primary"
                                onClick={() =>
                                  this.subscZogsoohDarahad(
                                    tsag_ticket.zahialga_id,
                                    tsag_ticket.uilchilgee_turul,
                                    2
                                  )
                                }
                              >
                                {i18n.t("ssubsc_button_zogsooh")}
                              </Button>
                            ) : (
                              i18n.t("ssubsc_text_zogsson")
                            )}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          {!!prep_ticket.zahialga_id && (
            <Card className="ungu-primary">
              <CardHeader style={{ color: "white" }}>
                <Row>
                  <div style={{ width: "50%" }}>
                    <div style={{ textAlign: "left" }}>
                      {i18n.t("preperation_fee_service")}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    {serviceSubsc_info.preperation_fee_service_auto_sungalt ? (
                      <div
                        style={{ textAlign: "right", cursor: "pointer" }}
                        onClick={() => {
                          this.subscZogsoohDarahad(
                            prep_ticket.zahialga_id,
                            prep_ticket.uilchilgee_turul,
                            1
                          );
                        }}
                      >
                        {i18n.t("erh_zogsooh")}
                      </div>
                    ) : (
                      <div style={{ textAlign: "right" }}>
                        {i18n.t("ssubsc_erh_text_zogsson")}
                      </div>
                    )}
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="8" md="10" sm="11" xs="11">
                    <Card onClick={this.dialogToggle}>
                      <CardBody>
                        {/* <Label>{i18n.t("service_subsc_turul")}: </Label>{" "}
                        {i18n.t("preperation_fee_service")}
                        <br /> */}
                        <Label>{i18n.t("service_subsc_ner")}: </Label>
                        {" " + prep_ticket.title}
                        <br />
                        <Label>{i18n.t("service_subsc_une")}: </Label>{" "}
                        {prep_ticket.gross_dun} {temdegt}{" "}
                        <Label>
                          {" / "}
                          {i18n.t("ssubsc_sard")}
                        </Label>
                        <br />
                        <Label>{i18n.t("service_subsc_period")}: </Label>{" "}
                        {moment(prep_ticket.uusgesen_ognoo).format(
                          "YYYY.MM.DD"
                        ) +
                          " - " +
                          moment(prep_ticket.huchintei_ognoo).format(
                            "YYYY.MM.DD"
                          )}
                        <br />
                        <div>
                          {" "}
                          <Label>{i18n.t("tulbur_heregsel")}: </Label>{" "}
                          {prep_ticket.tulburiin_helber === "stripe"
                            ? prep_ticket.suuliin_too
                              ? "**** **** **** " + prep_ticket.suuliin_too
                              : "Stripe"
                            : prep_ticket.tulburiin_helber === "paypal"
                            ? prep_ticket.paypal_email
                              ? prep_ticket.paypal_email
                              : "Paypal"
                            : prep_ticket.tulburiin_helber === "dansaar"
                            ? i18n.t("dansaar")
                            : prep_ticket.tulburiin_helber ===
                              "borluulaltiin_orlogoos"
                            ? i18n.t("borluulaltiin_orlogoos")
                            : prep_ticket.tulburiin_helber === "trail"
                            ? i18n.t("free_trial")
                            : i18n.t("tulugduugui")}
                        </div>
                        {prep_ticket.guilgeenii_turul === 120 && (
                          <div style={{ textAlign: "right" }}>
                            {prep_ticket.avtomat_subsc === true ? (
                              <Button
                                outline
                                className="ungu-primary"
                                onClick={() =>
                                  this.subscZogsoohDarahad(
                                    prep_ticket.zahialga_id,
                                    prep_ticket.uilchilgee_turul,
                                    2
                                  )
                                }
                              >
                                {i18n.t("ssubsc_button_zogsooh")}
                              </Button>
                            ) : (
                              i18n.t("ssubsc_text_zogsson")
                            )}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </div>
        {dialogZovshooruulTuluv && (
          <Modal
            isOpen={dialogZovshooruulTuluv}
            toggle={this.dialogTulburToggle}
          >
            <ModalHeader toggle={this.dialogTulburToggle}>
              {" "}
              {i18n.t("zogsoohdoo_itegeltei_eseh")}
            </ModalHeader>
            <ModalBody>
              {flag === 1 ? (
                <div style={{ color: "red", textAlign: "center" }}>
                  {songogdson_uilchilgeenii_turul === "subsc_service"
                    ? i18n.t("zogsooh_anhaaruulah_text_subsc")
                    : i18n.t("zogsooh_anhaaruulah_text_tsag")}
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  {i18n.t("ticket_zogsooh_text")}
                </div>
              )}
              <div align="center" style={{ marginTop: "40px" }}>
                <Button
                  className="ungu-primary"
                  style={{ width: togtmol.tovchnii_urgun + "px" }}
                  onClick={() => {
                    this.setState({
                      songogdson_ustgah_zahialga_id: "",
                      songogdson_uilchilgeenii_turul: "",
                      dialogZovshooruulTuluv: false,
                    });
                  }}
                >
                  {i18n.t("bolih")}
                </Button>{" "}
                <Button
                  color="info"
                  style={{ width: togtmol.tovchnii_urgun + "px" }}
                  onClick={() => {
                    this.setState({
                      songogdson_ustgah_zahialga_id: "",
                      songogdson_uilchilgeenii_turul: "",
                      dialogZovshooruulTuluv: false,
                    });
                    this.zogsoohFunc();
                  }}
                >
                  {i18n.t("zovshooroh")}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { ssubsc_ticket_jagsaalt } = state.ssubscReducer;
  return {
    ssubsc_ticket_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ssubscTicketJagsaaltFn,
      subscZogsoohFn,
      subscTicketZogsoohFn,
      hereglegchMe,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ServiceSubscTickets)
);
