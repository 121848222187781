import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import LoginAuth from "services/LoginAuth";

class Garah extends Component {
  componentDidMount() {
    LoginAuth.garah();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default Garah;
