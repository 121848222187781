module.exports = {
  api: {
    url: "https://sh.yousubsc.com:432",
  },
  bairlal: {
    lat: 52.73103274396697,
    lng: -104.66242012692472,
  },
  timeZoneStr: "Asia/Ulaanbaatar",
  mapKey: "AIzaSyASz-9LUoc8X-3jb3irEEWNjen4ydnwsUQ",

  stripe_publishableKey: "pk_live_51L8PS6IZQ79plW7eXVsV0TxEJJMNGCcxsmjPupNoq329Ap5U3wczfPvqHzGjfAF5TmU2MOexTCDQxayuvahIHKEA00I7d7SZuj",
  // stripe_publishableKey: "pk_test_51HhCm4KPvkb4V7RPV0qaLYYznAvH5Eg0HMb37Dmu6TumlGz9Hmin8TlNJM32wGV3U1FcEsBLDfvNhdayI0iHvJMX00Gzbqb9nZ",

  client_id: "AWT_ck7to4Ls3k9sliL7v9HBxhA7Losoht5GAv-lDMEdpyDU0ctCrIwBesd4VfJQ2DUIQ9ZOcU5mk3Kk",
  // client_id: "Acx6W9xxcNT0c7cZWeKW4fYf6PiBvJaWU4Ed2jFqDy5V84jDCSy-e5CXbVXF0O6io4RL6jMZZLiuFWWM",

  ulsuls_id: 1,
  img_url: "https://zurag.yousubsc.com/",
};
