import { API } from "services/api";
import { stripeConstants } from "store/constants";
import { hereglegchConstants } from "store/constants";

export const cardJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/card/jagsaalt`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: stripeConstants.STRIPE_CARDS,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "cardJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const addCustomerStripeCard = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/card/nemeh`, {
        id: state.idstripe,
        tokenId: state.tokenId,
        cardid: state.cardid,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const delCustomerStripeCard = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/card/hasah`, {
        id: state.id,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const tulburTuluhStripeFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/erh_hudaldan_avah_stripe`, {
        tokenId: "",
        cardid: state.cardid,
        buteegdehuun_dugaar: state.buteegdehuun_dugaar,
        price_id_stripe: state.price_id_stripe,
        last4: state.last4 ? state.last4 : "",
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const tulburTuluhPaypalFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/erh_hudaldan_avah_paypal`, {
        subscriptionID: state.subscriptionID,
        orderID: state.orderID,
        buteegdehuun_dugaar: state.buteegdehuun_dugaar,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true, utga: response.data.data };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};
