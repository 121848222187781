import axios from "axios";
import { API } from "services/api";
import LoginAuth from "services/LoginAuth";
// import LoginAuth from "services/LoginAuth";
import { hereglegchConstants } from "store/constants";

import tohirgoo from "../../tohirgoo";

const API_URL = tohirgoo.api.url;

export const nevtrehFn = (state) => async (dispatch) => {
  try {
    return await axios
      .post(`${API_URL}/huvilbar1/nevtreh`, {
        ner: state.nevtreh_hayag,
        nuutsug: state.nuuts_ug,
        hel: LoginAuth.helAvah(),
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          LoginAuth.setIdToken(response.data.data.token);
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "nevtrehFn",
            payload: response.data.message,
            status: 200,
          });
          return {
            amjilt: false,
            status: response.data.data.status,
            email: response.data.data.email,
            message: response.data.message,
          };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const burtguulehFn = (state) => async (dispatch) => {
  try {
    return await axios
      .post(`${API_URL}/huvilbar1/burtgel`, {
        nevtreh_hayag: state.nevtreh_hayag,
        nuutsug: state.nuuts_ug,
        email: state.email,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "burtguulehFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const burtgelBatalgaajuulahFn = (state) => async (dispatch) => {
  try {
    return await axios
      .post(`${API_URL}/huvilbar1/burtgel_batalgaajuulah_business`, {
        turul: state.turul, // 1-kod shalgah, 2-kod avah
        ner: state.ner,
        kod: state.kod,
        email: state.email,
        email_kod: state.email_kod,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "burtgelBatalgaajuulahFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const nuutsUgSergeehFn = (state) => async (dispatch) => {
  try {
    return await axios
      .post(`${API_URL}/huvilbar1/burtgel/nuutsug/sergeeh`, {
        turul: state.turul, // 1-kod shalgah, 2-kod avah
        ner: state.ner,
        kod: state.kod,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "nuutsUgSergeehFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const nuutsUgSolihFn = (state) => async (dispatch) => {
  try {
    return await axios
      .post(`${API_URL}/huvilbar1/burtgel/nuutsug/solih`, {
        ner: state.ner,
        nuuts_ug: state.nuuts_ug,
        kod: state.kod,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "nuutsUgSolihFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const hereglegchMe = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/baiguullaga/me`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          LoginAuth.hereglegchMeZooh(response.data.data);
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const tugjeeTailahFn = (state) => async (dispatch) => {
  try {
    return await axios
      .post(`${API_URL}/huvilbar1/tugjee_tailah`, {
        turul: state.turul, // 1-kod shalgah, 2-kod avah
        ner: state.ner,
        kod: state.kod,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "tugjeeTailahFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const nevtreltBatalgaajuulahFn = (state) => async (dispatch) => {
  try {
    return await axios
      .post(`${API_URL}/huvilbar1/hereglegch/nevtrelt_batalgaajuulah`, {
        turul: state.turul, // 1-kod shalgah, 2-kod avah
        ner: state.ner,
        kod: state.kod,
        hel: LoginAuth.helAvah(),
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          if (!!response.data.data && response.data.data.token !== undefined) {
            LoginAuth.setIdToken(response.data.data.token);
          }
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "nevtreltBatalgaajuulahFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const nShalgahFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/hereglegch/n_shalgalt`, {
        nuuts_ug: state,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "nShalgahFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const hayagSolihFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/medeelel/solih`, {
        data: state.data,
        isEmail: state.isEmail,
        turul: state.turul,
        kod: state.kod,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "hayagSolihFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const subHayagSolihFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/hereglegch/sub_hereglegch_zasah`, {
        baiguullaga_hereglegch_id: state.baiguullaga_hereglegch_id,
        ner: state.nevtreh_hayag,
        tuluv: 2,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "subHayagSolihFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const nuutsugSolihFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/hereglegch/nuuts_ug_solih`, {
        odoo_nuuts_ug: state.nuuts_ug_shalgah,
        shine_nuuts_ug: state.nuuts_ug,
        shine_nuuts_ug_davt: state.nuuts_ug_davt,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "nuutsugSolihFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const subNuutsugSolihFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/hereglegch/sub_hereglegch_nuutsug`, {
        baiguullaga_hereglegch_id: state.baiguullaga_hereglegch_id,
        nuutsug1: state.nuuts_ug,
        nuutsug2: state.nuuts_ug_davt,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else if (response.data.result === "aldaa") {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "subNuutsugSolihFn",
            payload: response.data.message,
            status: 200,
          });
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const termsOfServiceFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/hereglegch/termcondition`)
      .then((response) => {
        if (response.data.data !== undefined) {
          return { amjilt: true, data: response.data.data };
        } else {
          return { amjilt: false };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};

export const privacyPolicyFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/hereglegch/privacy_policy`)
      .then((response) => {
        if (response.data.data !== undefined) {
          return { amjilt: true, data: response.data.data };
        } else {
          return { amjilt: false };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
    });
    return { amjilt: false };
  }
};
