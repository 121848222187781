
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from 'store/reducers';

const isProduction = process.env.NODE_ENV === "production";


let store = null;

if (isProduction) {
    const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
    store = createStoreWithMiddleware(rootReducer, {
        auth: { authenticated: localStorage.getItem("token_id") }
    });
} else {

    const createStoreWithMiddleware = applyMiddleware(thunk, createLogger())(createStore);
    store = createStoreWithMiddleware(rootReducer);
}


export default store;
