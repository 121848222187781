import { baiguullagaConstants } from "store/constants";

const INITIAL_STATE = {
  uilchilgee_jagsaalt: [],
  ajiltan_jagsaalt: [],
  shiree_jagsaalt: [],
  ajiltan_uilchilgee_jagsaalt: [],
  active_subsc_jagsaalt: [],
  subsc_jagsaalt: [],
  sold_subsc_jagsaalt: [],
  get_subsc_jagsaalt: [],
};

const hariu = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case baiguullagaConstants.UILCHILGEENII_JAGSAALT:
      return { ...state, uilchilgee_jagsaalt: action.payload };
    case baiguullagaConstants.AJILTAN_JAGSAALT:
      return { ...state, ajiltan_jagsaalt: action.payload };
    case baiguullagaConstants.SHIREE_JAGSAALT:
      return { ...state, shiree_jagsaalt: action.payload };
    case baiguullagaConstants.AJILTAN_UILCHILGEE_JAGSAALT:
      return { ...state, ajiltan_uilchilgee_jagsaalt: action.payload };
    case baiguullagaConstants.SUBSC_JAGSAALT:
      return { ...state, subsc_jagsaalt: action.payload };
    case baiguullagaConstants.REPORT_ACTIVE_SUBSC_JAGSAALT:
      return { ...state, active_subsc_jagsaalt: action.payload };
    case baiguullagaConstants.REPORT_SOLD_SUBSC_JAGSAALT:
      return { ...state, sold_subsc_jagsaalt: action.payload };
    case baiguullagaConstants.REPORT_GET_SUBSC_JAGSAALT:
      return { ...state, get_subsc_jagsaalt: action.payload };

    default:
      return state;
  }
};

export default hariu;
