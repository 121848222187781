export const togtmol = {
  batalgaajuulah_tooluur: 60,
  tovchnii_urgun: 155,
  dropdown_brdr: "1px solid var(--grading-secondary-1)",
  report_hyzgaar_min: 6,
  tsag_tulbur_min: 0.5,
  subsc_tulbur_min: 0.5,
  tsag_tulbur_max: 300,
  subsc_tulbur_max: 1000,
  tovchnii_urgun_tulbur: 300,
  saaral: "#9a9a9a",
  jijig_zai: "5px",
  tom_zai: "35px",
  font: 14,
  muchlug_min: 15,
  subsc_min_discnt_per: 5,
};
