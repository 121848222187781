import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import i18n from "hel/i18n";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import LoginAuth from "services/LoginAuth";
import { validationUrt } from "validation";

import {
  nShalgahFn,
  hayagSolihFn,
  subNuutsugSolihFn,
  nuutsugSolihFn,
  subHayagSolihFn,
} from "../../../../store/actions/hereglegch.actions";
import { validEseh } from "validation";
import MyLoader from "components/MyLoader";

class BaiguullagaTab1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oroltuud: [
        {
          garchig: "nevtreh_hayag",
          turul: "number",
          ner: "nevtreh_hayag",
          placeholder: "utasnii_dugaar",
          validations: {
            validation: ["isUtas", "isZaaval"],
          },
          tovchtoi: true,
        },
        {
          garchig: "email",
          ner: "email",
          placeholder: "email",
          validations: {
            validation: ["isEmail", "isZaaval"],
            urtMax: validationUrt.string,
          },
          tovchtoi: true,
        },
        {
          garchig: "nuuts_ug",
          ner: "nuuts_ug",
          placeholder: "********",
          turul: "password",
          validations: {
            validation: ["isNuutsug", "isZaaval"],
            urtMax: validationUrt.string,
          },
          tovchtoi: true,
        },
      ],
      modal1: [
        {
          garchig: "nuuts_ug_oruul",
          ner: "nuuts_ug_shalgah",
          // placeholder: "********",
          turul: "password",
          // validations: {
          //   validation: ["isZaaval", "isNuutsug"],
          // },
        },
      ],
      modal2: [
        {
          garchig: "nevtreh_hayag_shine",
          turul: "number",
          ner: "nevtreh_hayag",
          // placeholder: "********",
          validations: {
            validation: ["isUtas", "isZaaval"],
          },
        },
      ],
      modal3: [
        {
          garchig: "nuuts_ug_shine",
          ner: "nuuts_ug",
          // placeholder: "nuuts_ug",
          turul: "password",
          validations: {
            validation: ["isNuutsug", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
        {
          garchig: "nuuts_ug_davt",
          ner: "nuuts_ug_davt",
          // placeholder: "nuuts_ug",
          turul: "password",
          validations: {
            validation: ["isNuutsug", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
      ],
      modal4: [
        {
          garchig: "email_shine",
          ner: "email",
          validations: {
            validation: ["isEmail", "isZaaval"],
          },
        },
      ],
      utguud: [],
      flag: 1,
      alert_flag: 0,
      dialogTuluv: false,
      validationHariu: {},
      modalUtga: {},
      songogdsonIndex: -1,
      songogdsonNer: "",
      medegdel: false,
      unshijBaigaa: false,
    };
    this.oroltTovchDarahad = this.oroltTovchDarahad.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }
  componentDidMount() {
    let utguud = LoginAuth.meAvah();
    if (utguud.length !== undefined) {
      utguud.sort((a, b) => (a.ene_darga < b.ene_darga ? 1 : -1));
      utguud.map((utga) => {
        utga.email = LoginAuth.baiguullagaInfoAvah().email;
        return null;
      });
    } else {
      utguud.email = LoginAuth.baiguullagaInfoAvah().email;
    }
    this.setState({
      utguud,
    });
  }
  dialogToggle() {
    this.setState({
      medegdel: false,
      dialogTuluv: !this.state.dialogTuluv,
      modalUtga: {},
      validationHariu: {},
    });
  }
  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  oroltTovchDarahad = (ner, index) => {
    let { modal2, utguud } = this.state;
    let urt = validationUrt.sub_user_tentsuu;
    if (
      (index === -1 && utguud.ene_darga === false) ||
      (index !== -1 && utguud[index].ene_darga === false)
    ) {
      modal2[0].validations["urtTentsuu"] = urt;
    }
    this.setState({
      flag: 1,
      dialogTuluv: true,
      songogdsonIndex: index,
      songogdsonNer: ner,
      modal2,
    });
  };
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let { modalUtga, validationHariu } = { ...this.state };
    modalUtga[ner] = utga;
    validationHariu[ner] = urDun;
    this.setState({
      modalUtga,
      validationHariu,
    });
  }
  hadgalahDarahad() {
    let { flag, modalUtga, songogdsonNer, songogdsonIndex, utguud } =
      this.state;
    this.setState({
      unshijBaigaa: true,
    });
    console.log("mdoal utga", modalUtga);
    if (flag === 1) {
      //password shalgah
      try {
        this.props.nShalgahFn(modalUtga.nuuts_ug_shalgah).then((hariu) => {
          if (hariu.amjilt === true) {
            this.setState({
              flag:
                songogdsonNer === "nevtreh_hayag"
                  ? 2
                  : songogdsonNer === "nuuts_ug"
                  ? 3
                  : 4,
              medegdel: false,
              unshijBaigaa: false,
            });
          } else {
            this.setState({
              alert_flag: 2,
              medegdel: true,
              unshijBaigaa: false,
            });
          }
        });
      } catch (aldaa) {
        this.setState({
          alert_flag: 1,
          medegdel: true,
          dialogTuluv: false,
          unshijBaigaa: false,
        });
      }
    } else if (flag === 2) {
      let myUtas = modalUtga.nevtreh_hayag;
      if (!!myUtas && myUtas.length === 10) {
        myUtas = "1" + myUtas;
      }
      this.props.history.push({
        pathname: "/solih/batalgaajuulah",
        state: {
          data: myUtas,
          isEmail: false,
        },
      });
      //nevtreh hayag
    } else if (flag === 4) {
      this.props.history.push({
        pathname: "/solih/batalgaajuulah",
        state: {
          data: modalUtga.email,
          isEmail: true,
        },
      });
      //email
    } else if (flag === 3) {
      if (modalUtga.nuuts_ug === modalUtga.nuuts_ug_davt) {
        try {
          if (
            songogdsonIndex !== -1 &&
            utguud[songogdsonIndex].ene_darga === false
          ) {
            modalUtga.baiguullaga_hereglegch_id =
              utguud[songogdsonIndex].baiguullaga_hereglegch_id;

            this.props.subNuutsugSolihFn(modalUtga).then((hariu) => {
              if (hariu.amjilt === true) {
                this.setState({
                  alert_flag: 0,
                  medegdel: true,
                  modalUtga: {},
                  dialogTuluv: false,
                  unshijBaigaa: false,
                });
              } else {
                this.setState({
                  alert_flag: 1,
                  medegdel: true,
                  modalUtga: {},
                  dialogTuluv: false,
                  unshijBaigaa: false,
                });
              }
            });
          } else {
            this.props.nuutsugSolihFn(modalUtga).then((hariu) => {
              if (hariu.amjilt === true) {
                LoginAuth.garah();
                this.props.history.push({ pathname: "/login" });
              } else {
                this.setState({
                  alert_flag: 1,
                  medegdel: true,
                  modalUtga: {},
                  dialogTuluv: false,
                  unshijBaigaa: false,
                });
              }
            });
          }
        } catch (aldaa) {
          this.setState({
            alert_flag: 1,
            medegdel: true,
            modalUtga: {},
            dialogTuluv: false,
            unshijBaigaa: false,
          });
        }
      } else {
        this.setState({
          alert_flag: 3,
          medegdel: true,
          unshijBaigaa: false,
        });
      }
    }
  }
  render() {
    const {
      oroltuud,
      utguud,
      validationHariu,
      flag,
      alert_flag,
      dialogTuluv,
      modal1,
      modal2,
      modal3,
      modal4,
      modalUtga,
      medegdel,
      unshijBaigaa,
    } = this.state;
    return (
      <div className="tab-zai">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Row>
            <Col>
              <Alert
                isOpen={medegdel && alert_flag < 2}
                toggle={this.medegdelToggle}
                color={alert_flag === 0 ? "success" : "danger"}
              >
                {alert_flag === 0 && (
                  <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                )}
                {alert_flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
              </Alert>
            </Col>
          </Row>
          <Form>
            {utguud.length > 0 ? (
              utguud.map((utga, index) => {
                return (
                  <FormGroup key={index}>
                    <p>
                      {utga.ene_darga === true
                        ? i18n.t("admin_hereglegch")
                        : i18n.t("sub_hereglegch")}
                    </p>
                    {oroltuud.map((orolt, jijigIndex) => {
                      let tovchgui = false;
                      if (
                        orolt.ner === "nevtreh_hayag" &&
                        utga.ene_darga === false
                      ) {
                        tovchgui = true;
                      }
                      if (orolt.ner === "email" && utga.ene_darga === false) {
                        return null;
                      }
                      return (
                        <div key={jijigIndex + orolt.ner}>
                          <GarchigtaiOrolt
                            key={jijigIndex}
                            index={index}
                            orolt={orolt}
                            utga={utga[orolt.ner]}
                            tovchgui={tovchgui}
                            tovchDarahad={(ner) =>
                              this.oroltTovchDarahad(ner, index)
                            }
                          />
                          {orolt.ner === "nuuts_ug" &&
                            utga.ene_darga === false && (
                              <div>
                                <Row>
                                  <Col lg={1} md={0} sm="0" xs="0" />
                                  <Col lg={10} md={11} sm="12" xs="12">
                                    <Label>{i18n.t("sub_pass_text")}</Label>
                                  </Col>
                                </Row>
                              </div>
                            )}
                        </div>
                      );
                    })}
                    <br />
                  </FormGroup>
                );
              })
            ) : (
              <FormGroup>
                {oroltuud.map((orolt, index) => {
                  let tovchgui = false;
                  if (
                    orolt.ner === "nevtreh_hayag" &&
                    utguud.ene_darga === false
                  ) {
                    tovchgui = true;
                  }
                  return (
                    <GarchigtaiOrolt
                      key={index}
                      index={index}
                      orolt={orolt}
                      utga={utguud[orolt.ner]}
                      tovchgui={tovchgui}
                      tovchDarahad={(ner) => this.oroltTovchDarahad(ner, -1)}
                    />
                  );
                })}
              </FormGroup>
            )}
          </Form>
        </div>

        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          {dialogTuluv === true && (
            <ModalBody>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel && alert_flag > 1}
                    toggle={this.medegdelToggle}
                    color={alert_flag === 0 ? "success" : "danger"}
                  >
                    {alert_flag === 2 && (
                      <span>{i18n.t("medegdel_aldaa_nuutsug_buruu")}</span>
                    )}
                    {flag === 3 && (
                      <span>{i18n.t("medegdel_aldaa_nuutsug_ylgaatai")}</span>
                    )}
                  </Alert>
                </Col>
              </Row>
              {flag === 1 && ( //nuuts ug shalgah
                <Form>
                  {modal1.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.modalUtgaOorchlogdohod}
                        utga={modalUtga[orolt.ner]}
                        // validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                </Form>
              )}
              {flag === 2 && ( // nevtreh_hayag
                <Form>
                  {modal2.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.modalUtgaOorchlogdohod}
                        utga={modalUtga[orolt.ner]}
                        validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                </Form>
              )}
              {flag === 3 && (
                <Form>
                  {modal3.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.modalUtgaOorchlogdohod}
                        utga={modalUtga[orolt.ner]}
                        validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                </Form>
              )}
              {flag === 4 && ( // nevtreh_hayag
                <Form>
                  {modal4.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.modalUtgaOorchlogdohod}
                        utga={modalUtga[orolt.ner]}
                        validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                </Form>
              )}
            </ModalBody>
          )}
          <ModalFooter>
            <Button
              className="ungu-primary"
              onClick={this.hadgalahDarahad}
              disabled={
                !validEseh(
                  flag === 1
                    ? modal1
                    : flag === 2
                    ? modal2
                    : flag === 3
                    ? modal3
                    : modal4,
                  modalUtga
                )
              }
            >
              {flag === 1 ? i18n.t("urgeljluuleh") : i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { tsagiin_huvaari_jagsaalt, ontsgoi_tsagiin_huvaari_jagsaalt } =
    state.burtgelReducer;
  return {
    tsagiin_huvaari_jagsaalt,
    ontsgoi_tsagiin_huvaari_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nShalgahFn,
      hayagSolihFn,
      subNuutsugSolihFn,
      nuutsugSolihFn,
      subHayagSolihFn,
    },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaiguullagaTab1)
);
