import React, { Component } from "react";
import { Row, Card, Col, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import LoginAuth from "services/LoginAuth";
import Husnegt from "components/Husnegt";
import { ssubscTulburTuuhFn } from "../../../store/actions/ssubsc.actions";

class TulburTuuh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTuluv: false,
      songogdsonMor: {},
      garchignuud: [
        {
          ner: "uusgesen_ognoo",
          ner2: "huchintei_ognoo",
          turul: "period",
          garchig: "service_subsc_ognoo",
        },
        {
          ner: "ssubsc_turul",
          lang: true,
          garchig: "service_subsc_turul",
        },
        {
          ner: "ssubsc_ner",
          garchig: "service_subsc_ner",
        },
        // {
        //   ner: "uilchilgeenii_price",
        //   garchig: "service_subsc_une",
        // },
        // {
        //   ner: "muj_shimtgel_dun",
        //   garchig: "tax",
        // },
        // {
        //   ner: "shimtgel",
        //   garchig: "shimtgel",
        // },
        {
          ner: "gross_dun",
          garchig: "niit_mongon_dun",
        },
        {
          ner: "tulburiin_helber",
          turul: "tulburiin_helber",
          garchig: "tulbur_heregsel",
        },
      ],
      utguud: [],
      ssubsc_tulbur_tuuh: [],
      validationHariu: {},

      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
    };
  }
  componentDidMount() {
    const info = LoginAuth.baiguullagaInfoAvah();
    if (info.tuluv !== "active") {
      this.props.history.push({ pathname: "/" });
    }
    this.props.ssubscTulburTuuhFn();
  }
  static getDerivedStateFromProps(props, state) {
    if (props.ssubsc_tulbur_tuuh !== state.ssubsc_tulbur_tuuh) {
      let utguud = props.ssubsc_tulbur_tuuh ? props.ssubsc_tulbur_tuuh : [];
      // utguud = utguud.sort((a, b) => (a.zahialga_id < b.zahialga_id ? -1 : 1));
      return {
        ssubsc_tulbur_tuuh: props.ssubsc_tulbur_tuuh,
        utguud,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  husnegtTovchDaragdahad(utga) {
    this.setState(
      {
        songogdsonMor: utga,
      },
      function () {
        this.dialogToggle();
      }
    );
  }
  hadgalahDarahad() {}
  render() {
    const { erh_hyzgaar, garchignuud, utguud } = this.state;
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    tovchtoi_eseh={false}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    ssubsc_tulbur_tuuh: state.ssubscReducer.ssubsc_tulbur_tuuh,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ssubscTulburTuuhFn }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TulburTuuh)
);
