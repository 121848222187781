import { burtgelConstants } from "store/constants";

const INITIAL_STATE = {
  tsagiin_huvaari_jagsaalt: [],
  ontsgoi_tsagiin_huvaari_jagsaalt: [],
  tom_turul_jagsaalt: [],
  muj_jagsaalt: [],
  uls_jagsaalt: [],
  tsagiin_bus_jagsaalt: [],
};

const hariu = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case burtgelConstants.TSAGIIN_HUVAARI_JAGSAALT:
      return { ...state, tsagiin_huvaari_jagsaalt: action.payload };
    case burtgelConstants.ONTSGOI_TSAGIIN_HUVAARI_JAGSAALT:
      return { ...state, ontsgoi_tsagiin_huvaari_jagsaalt: action.payload };
    case burtgelConstants.BURTGEL_NEMELT_JAGSAALT:
      return {
        ...state,
        tom_turul_jagsaalt: action.payload.tom_turul,
        muj_jagsaalt: action.payload.muj,
        uls_jagsaalt: action.payload.uls,
        tsagiin_bus_jagsaalt: action.payload.tsagiin_bus,
      };
    default:
      return state;
  }
};

export default hariu;
