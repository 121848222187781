import React, { Component } from "react";
import { Row, Card, Col, CardBody, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import Husnegt from "components/Husnegt";
import {
  tulburTootsooJagsaaltFn,
  tulburTootsooHuseltFn,
} from "../../../store/actions/uilajillagaa.actions";
import { hereglegchMe } from "store/actions/hereglegch.actions";
import { tsagAvah } from "validation";
import { ognooFormat } from "validation";
import LoginAuth from "services/LoginAuth";
import i18n from "hel/i18n";

class Tulbur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTuluv: false,
      songogdsonMor: {},
      garchignuud: [
        {
          ner: "dugaar",
          garchig: "id_garchig",
        },
        {
          ner: "huselt_gargasan_ognoo",
          garchig: "ognoo_huselt_gargasan",
          turul: "date",
        },
        {
          ner: "mungu",
          garchig: "mongon_dun",
        },
        {
          ner: "tuluv",
          garchig: "tuluv",
          turul: "tuluv-tootsoo",
        },
        {
          ner: "shimtgel",
          garchig: "suutgal",
        },
        {
          ner: "shiljuulsen_dun",
          garchig: "shiljuulsen_dun",
        },
        {
          ner: "shiljuulsen_ognoo",
          garchig: "ognoo_shiljuulsen",
          turul: "date",
        },
      ],

      utguud: [],
      validationHariu: {},
      ognooHyzgaarMax: tsagAvah("ognooHyzgaarMax"),
      ognooHyzgaarMin: tsagAvah("ognooHyzgaarMin"),
      dansnii_uldegdel: 0,
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      temdegt: LoginAuth.baiguullagaInfoAvah().temdegt,
    };
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.ognooSongohod = this.ognooSongohod.bind(this);
  }

  componentDidMount() {
    this.props.tulburTootsooJagsaaltFn();
    this.props.hereglegchMe().then((hariu) => {
      this.setState({
        dansnii_uldegdel: LoginAuth.dansniiUldegdelAvah(),
      });
    });
  }

  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.tulbur_tootsoo_jagsaalt !== state.tulbur_tootsoo_jagsaalt) {
      let tulbur_tootsoo_jagsaalt = props.tulbur_tootsoo_jagsaalt
        ? props.tulbur_tootsoo_jagsaalt
        : [];
      hariu.tulbur_tootsoo_jagsaalt = tulbur_tootsoo_jagsaalt;
      hariu.utguud = tulbur_tootsoo_jagsaalt;
    }

    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }

  utgaOorchlogdohod(ner, utga, urDun) {
    let utguud = { ...this.state.utguud };
    utguud[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      utguud,
      validationHariu,
    });
  }

  ognooSongohod(event) {
    const ognoo = event.target.value;
    const { tulbur_tootsoo_jagsaalt } = this.state;
    let utguud = [];
    if (ognoo === "") {
      utguud = tulbur_tootsoo_jagsaalt;
    } else {
      utguud = tulbur_tootsoo_jagsaalt.filter(
        (utga) => ognooFormat(utga.shiljuulsen_ognoo, "YYYY-MM-DD") === ognoo
      );
    }
    this.setState({
      utguud,
    });
  }
  // cashbackHuselt() {
  //   this.props.tulburTootsooHuseltFn().then((hariu) => {
  //     if (hariu.amjilt === true) {
  //     } else {
  //     }
  //   });
  // }
  render() {
    const { erh_hyzgaar, garchignuud, utguud, dansnii_uldegdel, temdegt } =
      this.state;
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md="4" xs="12" style={{ paddingLeft: 20 }}>
                  <Label
                    style={{
                      fontSize: "150%",
                      color: "var(--main-blue)",
                    }}
                  >
                    {i18n.t("dansnii_uldegdel")}
                  </Label>
                  <Input
                    style={{ fontSize: "130%" }}
                    plaintext
                    value={
                      !!dansnii_uldegdel && dansnii_uldegdel > 0
                        ? dansnii_uldegdel + " " + temdegt
                        : "0.00 " + temdegt
                    }
                    // onChange={() => {}}
                    disabled
                  />
                </Col>
                {/* <Col md="4" />
                <Col md="4" align="right" style={{ paddingRight: 50 }}>
                  <Button
                    className="ungu-primary"
                    round="true"
                    outline
                    onClick={this.cashbackHuselt}
                  >
                    {i18n.t("cashback_huselt")}
                  </Button>
                </Col> */}
              </Row>
              <br />
              <Row style={{ marginTop: "5vh" }}>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    tovchtoi_eseh={false}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { tulbur_tootsoo_jagsaalt } = state.uilajillagaaReducer;
  return {
    tulbur_tootsoo_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      tulburTootsooJagsaaltFn,
      tulburTootsooHuseltFn,
      hereglegchMe,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tulbur));
