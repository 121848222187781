import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import Husnegt from "components/Husnegt";
import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  uilchilgeeJagsaaltFn,
  uilchilgeeBurtgelFn,
} from "../../../store/actions/baiguullaga.actions";
import { validationUrt, validEseh, ylgaataiEseh } from "validation";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
import { togtmol } from "delgetsuud/zagvar";

class Uilchilgee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout_turul: LoginAuth.layoutTurulAvah(),
      flag: 0,
      medegdel: false,

      dialogTuluv: false,
      songogdsonMor: {},
      songogdsonIndex: 0,
      garchignuud: [
        {
          ner: "ner",
          garchig: "uilchilgeenii_ner",
          validations: {
            validation: ["isZaaval", "isTemdegtgui"],
            urtMax: validationUrt.string,
          },
        },
        {
          ner: "uilchilgeenii_undsen_tulbur",
          garchig: "undsen_tarif",
          validations: {
            validation: ["isZaaval", "isMongo"],
          },
          buhelchleh: true,
        },
        {
          ner: "undsen_tulbur_max",
          garchig: "undsen_tarif_max",
          validations: {
            validation: ["isZaaval", "isMongo"],
          },
          buhelchleh: true,
        },
        {
          ner: "muchlug",
          garchig: "muchlug",
          validations: {
            validation: ["isZaaval", "isDanToo"],
            utgaMin: togtmol.muchlug_min,
          },
        },
        {
          ner: "nas_asuudag_eseh",
          garchig: "nas_asuudag_eseh_free",
          turul: "switch",
        },
        {
          ner: "hyzgaarlah_nas",
          garchig: "hyzgaarlah_nas",
          nuuh_flag: true,
          validations: {
            validation: ["isDanToo"],
            urtMax: validationUrt.turul_kod,
          },
        },
        {
          ner: "tailbar",
          garchig: "tailbar",
          turul: "textarea",
          validations: {
            validation: ["isZaaval", "isTemdegttei"],
            urtMax: validationUrt.tailbar_bogino,
          },
        },
        {
          ner: "tuluv",
          garchig: "tuluv",
          turul: "switch",
          validations: {
            validation: [],
          },
        },
      ],
      uilchilgee_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
    };
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    this.props.uilchilgeeJagsaaltFn();
  }
  static getDerivedStateFromProps(props, state) {
    if (props.uilchilgee_jagsaalt !== state.uilchilgee_jagsaalt) {
      let uilchilgee_jagsaalt = props.uilchilgee_jagsaalt
        ? props.uilchilgee_jagsaalt
        : [];
      uilchilgee_jagsaalt.map((utga) => {
        utga.tuluv = utga.tuluv === "inactive" ? false : true;
        return null;
      });
      return {
        uilchilgee_jagsaalt: props.uilchilgee_jagsaalt,
        utguud: uilchilgee_jagsaalt,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor:
        this.state.dialogTuluv === true ? {} : { ...this.state.songogdsonMor },
      validationHariu: {},
      medegdel: false,
    });
    this.props.uilchilgeeJagsaaltFn();
  }

  husnegtTovchDaragdahad(utga, index) {
    this.setState(
      {
        songogdsonIndex: index,
        songogdsonMor: utga,
      },
      function () {
        this.dialogToggle();
      }
    );
  }
  hadgalahDarahad() {
    const { songogdsonMor } = this.state;
    this.setState({ unshijBaigaa: true });
    try {
      this.props.uilchilgeeBurtgelFn(songogdsonMor).then((hariu) => {
        if (hariu.amjilt === true) {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 0,
            unshijBaigaa: false,
          });
          this.props.uilchilgeeJagsaaltFn();
        } else if (hariu.message === "hyzgaar error") {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 2,
            unshijBaigaa: false,
          });
        } else {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 1,
            unshijBaigaa: false,
          });
        }
      });
    } catch (aldaa) {
      this.setState({
        dialogTuluv: false,
        songogdsonMor: {},
        validationHariu: {},
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  render() {
    const {
      validationHariu,
      garchignuud,
      utguud,
      dialogTuluv,
      songogdsonMor,
      songogdsonIndex,
      flag,
      medegdel,
      unshijBaigaa,
      layout_turul,
      erh_hyzgaar,
      serviceSubsc_info,
    } = this.state;
    if (
      layout_turul === 1 ||
      serviceSubsc_info.tsag_zahialga_service === false ||
      erh_hyzgaar === true
    ) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md="9" />
                <Col md="3" align="right">
                  <Button
                    className="ungu-primary"
                    round="true"
                    outline
                    onClick={this.dialogToggle}
                  >
                    {i18n.t("nemeh")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                    {flag === 2 && (
                      <span>{i18n.t("medegdel_aldaa_uilchilgee_hyzgaar")}</span>
                    )}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    tovchtoi_eseh={true}
                    tovch_daragdahad={this.husnegtTovchDaragdahad}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            {dialogTuluv === true && (
              <Form>
                {garchignuud.map((orolt, index) => {
                  return (
                    <GarchigtaiOrolt
                      key={index}
                      index={index}
                      orolt={orolt}
                      utgaAvah={this.modalUtgaOorchlogdohod}
                      utga={songogdsonMor[orolt.ner]}
                      validationHariu={validationHariu[orolt.ner]}
                      nuuh_nohtsol={{
                        hyzgaarlah_nas: !songogdsonMor.nas_asuudag_eseh,
                      }}
                    />
                  );
                })}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="ungu-primary"
              onClick={this.hadgalahDarahad}
              disabled={
                !validEseh(garchignuud, songogdsonMor) ||
                !ylgaataiEseh(songogdsonMor, utguud[songogdsonIndex])
              }
            >
              {i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { uilchilgee_jagsaalt } = state.baiguullagaReducer;
  return {
    uilchilgee_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uilchilgeeJagsaaltFn,
      uilchilgeeBurtgelFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Uilchilgee)
);
