import React from "react";
import { Navbar, NavbarBrand, Container } from "reactstrap";

import routes from "routes.js";

import i18n from "hel/i18n";
import LoginAuth from "services/LoginAuth";

function Header(props) {
  const baiguullagaInfo = LoginAuth.baiguullagaInfoAvah();
  const getBrand = () => {
    let brandName = "";
    routes.map((prop, key) => {
      if (window.location.pathname === prop.path) {
        brandName = i18n.t(prop.name);
      }
      return null;
    });
    return brandName;
  };
  const openSidebar = () => {
    document.getElementById("mySidebar").style.display = "block";
    document.getElementById("myMainPanel").style.width = "0";
  };
  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      expand="lg"
      className={"navbar-absolute fixed-top navbar-transparent "}
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div>
            <button
              type="button"
              className="navbar-toggler"
              onClick={() => openSidebar()}
              style={{
                color: "var(--main-blue)",
                paddingLeft: -15,
                marginLeft: -15,
              }}
            >
              <i className="bi bi-menu-app" />
            </button>
          </div>
          <NavbarBrand href="/">{getBrand()}</NavbarBrand>
        </div>
        <NavbarBrand
          href="/"
          className="bgllgNer"
          style={{ color: "var(--grading-secondary-1)", marginRight: "3vw" }}
        >
          {baiguullagaInfo.ner}
        </NavbarBrand>
      </Container>
    </Navbar>
  );
}

export default Header;
