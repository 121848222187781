import { hereglegchConstants } from "store/constants";
import { API } from "services/api";
import { burtgelConstants } from "store/constants";
import moment from "moment";
import tohirgoo from "tohirgoo";

export const baiguullagaBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/burtgel/baiguullaga`, {
        ner: state.ner,
        hayag: state.hayag,
        latitude: state.bairlal
          ? state.bairlal.lat.toString()
          : tohirgoo.bairlal.lat,
        longitude: state.bairlal
          ? state.bairlal.lng.toString()
          : tohirgoo.bairlal.lng,
        utas: state.utas ? state.utas : "",
        utas2: "",
        // email: state.email,
        // uilchilgeenii_turul: state.uilchilgeenii_turul.id,
        muj_id: state.muj.id,
        uls_id: state.uls.id,
        tsagiin_bus_time_zone: state.tsagiin_bus.time_zone,
        tsagiin_bus_utc_text: state.tsagiin_bus.utc_text,
        local_tax: state.local_tax ? state.local_tax : 0,
        company_number: state.company_number ? state.company_number : "",
        company_name: state.company_name ? state.company_name : "",
        tsagiin_bus_id: state.tsagiin_bus.id,

        register_notes: state.register_notes ? state.register_notes : "",
        register_req_subsc: state.register_req_subsc
          ? state.register_req_subsc
          : false,
        register_req_booking: state.register_req_booking
          ? state.register_req_booking
          : false,
        register_req_booking_unegui: state.register_req_booking_unegui
          ? state.register_req_booking_unegui
          : false,
        register_req_booking_unegui_too: state.register_req_booking_unegui_too
          ? state.register_req_booking_unegui_too
          : 0,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const tsagiinHuvaariJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/tsagiinhuvaari/1`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: burtgelConstants.TSAGIIN_HUVAARI_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "tsagiinHuvaariJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const ontsgoiTsagiinHuvaariJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/jagsaalt/tsagiinhuvaari/2`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: burtgelConstants.ONTSGOI_TSAGIIN_HUVAARI_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "ontsgoiTsagiinHuvaariJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const tsagiinHuvaariBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/burtgel/tsagiinhuvaari`, {
        tsagiin_huvaari_dugaar: state.tsagiin_huvaari_id,
        udur: state.udur,
        ehleh_tsag: moment(state.ehleh_tsag, "HH:mm").format("HH:mm"),
        duusah_tsag: moment(state.duusah_tsag, "HH:mm").format("HH:mm"),
        amralt1_ehleh_tsag: moment(state.amralt1_ehleh_tsag, "HH:mm").format(
          "HH:mm"
        ),
        amralt1_duusah_tsag: moment(state.amralt1_duusah_tsag, "HH:mm").format(
          "HH:mm"
        ),
        amralt2_ehleh_tsag: moment(state.amralt2_ehleh_tsag, "HH:mm").format(
          "HH:mm"
        ),
        amralt2_duusah_tsag: moment(state.amralt2_duusah_tsag, "HH:mm").format(
          "HH:mm"
        ),
        is_amrah: state.is_amrah === true ? true : false,
        durin_ognoo: null,
        turul: 1,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const ontsgoiTsagiinHuvaariBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/burtgel/tsagiinhuvaari`, {
        tsagiin_huvaari_dugaar: state.tsagiin_huvaari_id
          ? state.tsagiin_huvaari_id
          : 0,
        udur: 0,
        ehleh_tsag: moment(state.ehleh_tsag, "HH:mm").format("HH:mm"),
        duusah_tsag: moment(state.duusah_tsag, "HH:mm").format("HH:mm"),
        amralt1_ehleh_tsag: moment(state.amralt1_ehleh_tsag, "HH:mm").format(
          "HH:mm"
        ),
        amralt1_duusah_tsag: moment(state.amralt1_duusah_tsag, "HH:mm").format(
          "HH:mm"
        ),
        amralt2_ehleh_tsag: moment(state.amralt2_ehleh_tsag, "HH:mm").format(
          "HH:mm"
        ),
        amralt2_duusah_tsag: moment(state.amralt2_duusah_tsag, "HH:mm").format(
          "HH:mm"
        ),
        is_amrah: state.is_amrah === true ? true : false,
        turul: 2,
        durin_ognoo: state.durin_ognoo,
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const burtgelNemeltJagsaaltFn = (state) => async (dispatch) => {
  try {
    return API()
      .get(`/huvilbar1/burtgelNemelt`)
      .then((response) => {
        if (response.data.result === "amjilt") {
          dispatch({
            type: burtgelConstants.BURTGEL_NEMELT_JAGSAALT,
            payload: response.data.data,
          });
          return true;
        } else {
          dispatch({
            type: hereglegchConstants.ALDAA,
            ner: "burtgelNemeltJagsaaltFn",
            payload: response.data.message,
          });
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};

export const baiguullagaMedeelelBurtgelFn = (state) => async (dispatch) => {
  try {
    return API()
      .post(`/huvilbar1/baiguullaga/hadagalah`, {
        ner: state.ner,
        haih_tovch_ner: state.haih_tovch_ner ? state.haih_tovch_ner : "",
        utas: state.utas ? state.utas : "",
        utas2: "",
        zogsooltoi_eseh: state.zogsooltoi_eseh ? state.zogsooltoi_eseh : false,
        // email: state.email ? state.email : "",
        delgerengui_medeelel: state.delgerengui_medeelel
          ? state.delgerengui_medeelel
          : "",
        hayag: state.hayag,
        latitude: state.latitude,
        longitude: state.longitude,
        tses_zurag_id: state.tses_zurag_id,
        shiree_bairlal_zurag_id: state.shiree_bairlal_zurag_id,
        baiguullaga_temdegt_zurag_id: state.baiguullaga_temdegt_zurag_id,
        nas_asuudag_eseh: state.nas_asuudag_eseh
          ? state.nas_asuudag_eseh
          : false,
        hyzgaarlah_nas: state.hyzgaarlah_nas ? state.hyzgaarlah_nas : 0,
        dans_ner: state.dans_dugaar ? state.dans_dugaar : "",
        dans_dugaar: state.dans_dugaar ? state.dans_dugaar : "",
        shiree_muchlug: state.shiree_muchlug ? state.shiree_muchlug : 0,
        zahialga_avah_honog_limit: state.zahialga_avah_honog_limit,
        zahialga_umnuh_tsag: state.zahialga_umnuh_tsag,
        durem: state.durem,
        hst_dugaar: state.hst_dugaar ? state.hst_dugaar : "",
        pst_dugaar: state.pst_dugaar ? state.pst_dugaar : "",
        qst_dugaar: state.qst_dugaar ? state.qst_dugaar : "",
        gst_dugaar: state.gst_dugaar ? state.gst_dugaar : "",
        bank_ner: state.bank_ner,
        branch_ner: state.branch_ner ? state.branch_ner : "",
        branch_code: state.branch_code ? state.branch_code : "",
        local_tax: state.local_tax ? state.local_tax : 0,
        tsagiin_bus_id: state.tsagiin_bus_id ? state.tsagiin_bus_id : 0,
        tmzone: state.tmzone ? state.tmzone : "",
        utc: state.utc ? state.utc : "",
      })
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
        return { amjilt: false };
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return { amjilt: false };
  }
};

export const ontsgoiTsagDeleteFn = (state) => async (dispatch) => {
  try {
    return API()
      .delete(`/huvilbar1/jagsaalt/ontsgoitsag/ustgah/` + state)
      .then((response) => {
        if (response.data.result === "amjilt") {
          return { amjilt: true };
        } else {
          return { amjilt: false, message: response.data.message };
        }
      })
      .catch((aldaa) => {
        dispatch({
          type: hereglegchConstants.ALDAA,
          payload: "Aldaa : " + aldaa,
          status: 500,
        });
      });
  } catch (aldaa) {
    dispatch({
      type: hereglegchConstants.ALDAA,
      payload: "Aldaa : " + aldaa,
      status: 500,
    });
    return false;
  }
};
