import i18n from "hel/i18n";
import React, { Component } from "react";
import { Row, Card, Col, CardBody, Alert } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Husnegt from "components/Husnegt";
import { zahialgaTuuhJagsaaltFn } from "../../../store/actions/uilajillagaa.actions";
import {
  ajiltanJagsaaltFn,
  shireeJagsaaltFn,
} from "store/actions/baiguullaga.actions";
import MyLoader from "components/MyLoader";
import SongoltBox from "components/SongoltBox";
import { tsagAvah } from "validation";
import { ognooFormat } from "validation";
import LoginAuth from "services/LoginAuth";
import currency from "currency.js";

class ZahialgaTuuh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      medegdel: false,

      garchignuud: [
        {
          ner: "uusgesen_ognoo",
          garchig: "uusgesen_ognoo",
          turul: "ognoo_tsag",
        },
        {
          ner: "zahialsan_ognoo",
          garchig: "ognoo",
          turul: "date",
        },
        {
          ner: "ehleh_tsag",
          garchig: "ehleh_tsag",
          turul: "time",
        },
        {
          ner: "duusah_tsag",
          garchig: "duusah_tsag",
          turul: "time",
        },
        {
          ner: "ajiltan_ner",
          garchig:
            LoginAuth.layoutTurulAvah() === 2 ? "ajiltan_ner" : "shiree_dugaar",
        },
        {
          ner: "uilchilgee_ner",
          garchig: "uilchilgee",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? true : false,
        },
        {
          ner: "user_ner",
          garchig: "hereglegch_ner",
        },
        {
          ner: "user_utas",
          garchig: "hereglegch_utas",
          nuuh: LoginAuth.serviceSubscAvah().preperation_fee_service,
        },
        {
          ner: "tulbur_dun",
          garchig: "tulbur",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? false : true,
        },
        {
          ner: "baiguullaga_mungun_dun",
          garchig: "orlogo",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? false : true,
        },
        // {
        //   ner: "muj_shimtgel_dun",
        //   garchig: "tax",
        // },
        {
          ner: "hereglegch_shimtgel_dun",
          garchig: "commission_fee",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? false : true,
        },
        {
          ner: "gst",
          garchig: "GST",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "pst",
          garchig: "PST",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "hst",
          garchig: "HST",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "qst",
          garchig: "QST",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "state_tax",
          garchig: "state_tax",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "local_tax",
          garchig: "local_tax",
          nuuh: true,
          ner_nemelt: "",
        },
      ],
      zahialga_tuuh_jagsaalt: [],
      zahialga_tuuh_jagsaalt_utga: [],
      ajiltan_jagsaalt: [],
      staff_jagsaalt: [],
      utguud: [],
      unshijBaigaa: false,
      niitToo: 1,
      songogdsonHuudasToo: 1,
      ognooHyzgaarMax: tsagAvah("ognooHyzgaarMax"),
      ognooHyzgaarMin: tsagAvah("ognooHyzgaarMin"),
      layout_turul: LoginAuth.layoutTurulAvah(),
    };
    this.huudasSoligdohod = this.huudasSoligdohod.bind(this);
    this.ajiltanSongohod = this.ajiltanSongohod.bind(this);
    this.ognooSongohod = this.ognooSongohod.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    if (this.state.layout_turul === 1) {
      this.props.shireeJagsaaltFn();
    } else {
      this.props.ajiltanJagsaaltFn();
    }
    this.props.zahialgaTuuhJagsaaltFn(0);

    const info = LoginAuth.baiguullagaInfoAvah();
    let myGarchig = this.state.garchignuud;
    myGarchig.map((garchig) => {
      if (
        garchig.ner_nemelt !== undefined &&
        !!info[garchig.ner] &&
        Number(info[garchig.ner]) > 0
      ) {
        garchig.nuuh = LoginAuth.layoutTurulAvah() === 1 ? false : true;
        garchig.ner_nemelt =
          "( " +
          currency(info[garchig.ner] * 100, { precision: 3, separator: "" }) +
          "% )";
      }
      return null;
    });
    this.setState({
      garchignuud: myGarchig,
    });
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.zahialga_tuuh_jagsaalt !== state.zahialga_tuuh_jagsaalt) {
      let zahialga_tuuh_jagsaalt = props.zahialga_tuuh_jagsaalt
        ? props.zahialga_tuuh_jagsaalt
        : [];
      hariu.zahialga_tuuh_jagsaalt = props.zahialga_tuuh_jagsaalt;
      hariu.zahialga_tuuh_jagsaalt_utga = zahialga_tuuh_jagsaalt.jagsaalt
        ? zahialga_tuuh_jagsaalt.jagsaalt
        : [];
      hariu.utguud = zahialga_tuuh_jagsaalt.jagsaalt
        ? zahialga_tuuh_jagsaalt.jagsaalt
        : [];
      hariu.niitToo = zahialga_tuuh_jagsaalt.too
        ? zahialga_tuuh_jagsaalt.too
        : 1;
    }
    if (props.ajiltan_jagsaalt !== state.ajiltan_jagsaalt) {
      let ajiltan_jagsaalt = props.ajiltan_jagsaalt
        ? props.ajiltan_jagsaalt
        : [];
      hariu.ajiltan_jagsaalt = props.ajiltan_jagsaalt;
      hariu.staff_jagsaalt = ajiltan_jagsaalt;
    }
    if (props.shiree_jagsaalt !== state.shiree_jagsaalt) {
      let shiree_jagsaalt = props.shiree_jagsaalt ? props.shiree_jagsaalt : [];
      hariu.shiree_jagsaalt = props.shiree_jagsaalt;
      hariu.staff_jagsaalt = shiree_jagsaalt;
    }
    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }

  huudasSoligdohod(huudas) {
    this.props.zahialgaTuuhJagsaaltFn(huudas);
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  ajiltanSongohod(index) {
    const { zahialga_tuuh_jagsaalt_utga, ajiltan_jagsaalt } = { ...this.state };
    let utguud = [];
    if (index < 0) {
      utguud = zahialga_tuuh_jagsaalt_utga;
    } else {
      const ajiltan = ajiltan_jagsaalt[index];
      const ajiltan_id = ajiltan.ajiltan_id;
      utguud = zahialga_tuuh_jagsaalt_utga.filter(
        (utga) => utga.ajiltan_id === ajiltan_id
      );
    }
    this.setState({
      utguud,
    });
  }
  ognooSongohod(event) {
    const ognoo = event.target.value;
    const { zahialga_tuuh_jagsaalt_utga } = { ...this.state };
    let utguud = [];
    if (ognoo === "") {
      utguud = zahialga_tuuh_jagsaalt_utga;
    } else {
      utguud = zahialga_tuuh_jagsaalt_utga.filter(
        (utga) => ognooFormat(utga.zahialsan_ognoo, "YYYY-MM-DD") === ognoo
      );
    }
    this.setState({
      utguud,
    });
  }
  render() {
    const {
      garchignuud,
      utguud,
      flag,
      medegdel,
      unshijBaigaa,
      niitToo,
      staff_jagsaalt,
      // ognooHyzgaarMax,
      // ognooHyzgaarMin,
      layout_turul,
    } = this.state;
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md="4" xs="12">
                  <SongoltBox
                    garchig={
                      layout_turul === 1 ? "shiree_haih" : "ajiltan_haih"
                    }
                    songoltUtga={staff_jagsaalt}
                    songohod={this.ajiltanSongohod}
                  />
                </Col>
                <Col md="8" />
                {/* <Col md="4" xs="12">
                  <Label>{i18n.t("ognoo_haih")}</Label>
                  <Input
                    type="date"
                    name="date"
                    placeholder="date placeholder"
                    max={ognooHyzgaarMax}
                    min={ognooHyzgaarMin}
                    onChange={this.ognooSongohod}
                  />
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                  </Alert>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    niitToo={niitToo}
                    huudas_soligdohod={this.huudasSoligdohod}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { zahialga_tuuh_jagsaalt } = state.uilajillagaaReducer;
  const { ajiltan_jagsaalt, shiree_jagsaalt } = state.baiguullagaReducer;
  return {
    zahialga_tuuh_jagsaalt,
    ajiltan_jagsaalt,
    shiree_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      zahialgaTuuhJagsaaltFn,
      ajiltanJagsaaltFn,
      shireeJagsaaltFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ZahialgaTuuh)
);
