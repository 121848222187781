import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  Alert,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Label,
  FormGroup,
  Input,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";

import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  burtguulehFn,
  termsOfServiceFn,
} from "../../store/actions/hereglegch.actions";
import { togtmol } from "delgetsuud/zagvar";
import { validationUrt, validEseh } from "validation";
import LoginAuth from "services/LoginAuth";
import MyLoader from "components/MyLoader";
// import axios from "axios";
class Burtguuleh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: LoginAuth.getIdToken(),
      medegdel: false,
      flag: 1,
      oroltuud: [
        {
          garchig: "nevtreh_hayag",
          ner: "nevtreh_hayag",
          placeholder: "utasnii_dugaar",
          validations: {
            validation: ["isUtas", "isZaaval"],
          },
        },
        {
          garchig: "email",
          ner: "email",
          placeholder: "email",
          validations: {
            validation: ["isEmail", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
        {
          garchig: "nuuts_ug",
          ner: "nuuts_ug",
          placeholder: "nuuts_ug",
          turul: "password",
          validations: {
            validation: ["isNuutsug", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
        {
          garchig: "nuuts_ug_davt",
          ner: "nuuts_ug_davt",
          placeholder: "nuuts_ug_davt",
          turul: "password",
          validations: {
            validation: ["isNuutsug", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
      ],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      dialogTuluv: false,
      zovshoorol: false,
      termdata: "",
    };
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.burtguulehDarahad = this.burtguulehDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.zovshoorolDarahad = this.zovshoorolDarahad.bind(this);
  }
  async componentDidMount() {
    try {
      const hariu_term = await this.props.termsOfServiceFn();
      const mylink = hariu_term.data;
      const response = await fetch(mylink);
      // const response = await fetch("https://helpseotools.com/");
      const template = await response.text();
      console.log(template);
      this.setState({
        termdata: template,
      });
    } catch (aldaa) {
      console.log(aldaa);
    }
  }
  utgaOorchlogdohod(ner, utga, urDun) {
    let utguud = { ...this.state.utguud };
    utguud[ner] = utga;

    if (
      ner === "nuuts_ug_davt" &&
      utguud.nuuts_ug !== utguud.nuuts_ug_davt &&
      urDun.message === ""
    ) {
      urDun.isValid = false;
      urDun.message = i18n.t("medegdel_aldaa_nuutsug_ylgaatai");
    }

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      utguud,
      validationHariu,
    });
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      medegdel: false,
    });
  }

  zovshoorolDarahad() {
    this.setState({
      zovshoorol: !this.state.zovshoorol,
      // dialogTuluv: false,
    });
  }

  burtguulehDarahad() {
    const { utguud, zovshoorol } = this.state;
    if (utguud.nuuts_ug === utguud.nuuts_ug_davt && zovshoorol === true) {
      if (utguud.nevtreh_hayag.length === 10) {
        utguud.nevtreh_hayag = "1" + utguud.nevtreh_hayag;
      }
      this.setState({ unshijBaigaa: true });
      try {
        this.props.burtguulehFn(utguud).then((hariu) => {
          if (hariu.amjilt === true) {
            this.props.history.push({
              pathname: "/burtgel/batalgaajuulah",
              state: {
                nevtreh_hayag: utguud.nevtreh_hayag,
                email: utguud.email,
                status: 1,
              },
            });
          } else if (hariu.message === "no") {
            this.setState({
              medegdel: true,
              flag: 3,
              unshijBaigaa: false,
            });
          } else {
            this.setState({
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
        });
      } catch (aldaa) {
        this.setState({
          medegdel: true,
          flag: 1,
          unshijBaigaa: false,
        });
      }
    } else {
      this.setState({
        medegdel: true,
        flag: zovshoorol === false ? 4 : 2,
      });
    }
  }

  render() {
    const {
      flag,
      validationHariu,
      oroltuud,
      utguud,
      token,
      medegdel,
      unshijBaigaa,
      dialogTuluv,
      zovshoorol,
    } = this.state;
    if (!!token) {
      LoginAuth.garah();
    }
    return (
      <div className="deerees-zai">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Alert
              isOpen={medegdel}
              toggle={this.medegdelToggle}
              color={flag === 0 ? "success" : "danger"}
            >
              {flag === 0 && (
                <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
              )}
              {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
              {flag === 2 && (
                <span>{i18n.t("medegdel_aldaa_nuutsug_ylgaatai")}</span>
              )}
              {flag === 3 && <span>{i18n.t("medegdel_aldaa_burtgeltei")}</span>}
              {flag === 4 && (
                <span>{i18n.t("medegdel_aldaa_uilchilgeenii_nohtsol")}</span>
              )}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Card>
              <CardHeader>
                <CardTitle tag="h5" align="center">
                  {i18n.t("burtguuleh")}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  {oroltuud.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.utgaOorchlogdohod}
                        utga={utguud[orolt.ner]}
                        validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                  <Row>
                    <Col lg="1" md="0" sm="0" xs="0" />
                    <Col lg="10" md="11" sm="12" xs="12">
                      <div style={{ textAlign: "center" }}>
                        <div>
                          <Label
                            onClick={this.dialogToggle}
                            style={{
                              color: "var(--grading-secondary-1)",
                              cursor: "pointer",
                            }}
                          >
                            {i18n.t("uilchilgee_nuhtsul_text")}
                          </Label>
                        </div>
                        <div>
                          <Label>{i18n.t("burtgel_warning")}</Label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        onClick={this.burtguulehDarahad}
                        disabled={!validEseh(oroltuud, utguud)}
                      >
                        {i18n.t("burtguuleh")}
                      </Button>
                    </div>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        round="true"
                        outline
                        onClick={() => {
                          LoginAuth.garah();
                          this.props.history.push({ pathname: "/login" });
                        }}
                      >
                        {i18n.t("nevtreh")}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          {dialogTuluv === true && (
            <ModalBody>
              <iframe
                title="TermofService"
                src="https://zurag.yousubsc.com/terms-of-service/Business-TermsOfService.html"
                width="100%"
                height={window.innerHeight * 0.6}
              ></iframe>
            </ModalBody>
          )}
          <ModalFooter>
            <div>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="checkbox"
                    defaultChecked={zovshoorol}
                    onChange={this.zovshoorolDarahad}
                  />{" "}
                  {i18n.t("zovshooroh")}
                </Label>
              </FormGroup>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      burtguulehFn,
      termsOfServiceFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Burtguuleh)
);
