import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  Alert,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Husnegt from "components/Husnegt";
import { zahialgaJagsaaltFn } from "../../../store/actions/uilajillagaa.actions";
import {
  ajiltanJagsaaltFn,
  shireeJagsaaltFn,
} from "store/actions/baiguullaga.actions";
import MyLoader from "components/MyLoader";
import SongoltBox from "components/SongoltBox";
import { tsagAvah } from "validation";
import LoginAuth from "services/LoginAuth";
import currency from "currency.js";

class Zahialga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      medegdel: false,

      garchignuud: [
        {
          ner: "created",
          garchig: "transaction_time",
          turul: "ognoo_tsag",
        },
        {
          ner: "ajiltan_ner",
          garchig:
            LoginAuth.layoutTurulAvah() === 2 ? "ajiltan_ner" : "shiree_dugaar",
        },
        {
          ner: "zahialsan_ognoo",
          garchig: "ognoo_booking",
          turul: "date_notimezone",
        },
        {
          ner: "ehleh_tsag",
          garchig: "ehleh_tsag",
          turul: "time",
        },
        {
          ner: "duusah_tsag",
          garchig: "duusah_tsag",
          turul: "time",
        },
        {
          ner: "uilchilgee_ner",
          garchig: "uilchilgee",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? true : false,
        },
        {
          ner: "user_ner",
          garchig: "hereglegch_ner",
        },
        {
          ner: "user_utas",
          garchig: "hereglegch_utas",
          nuuh: LoginAuth.serviceSubscAvah().preperation_fee_service,
        },
        {
          ner: "tuluv",
          garchig: "tuluv",
          turul: "tuluv",
        },
        {
          ner: "tulbur_dun",
          garchig: "tulbur",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? false : true,
        },
        {
          ner: "baiguullaga_mungun_dun",
          garchig: "orlogo",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? false : true,
        },
        // {
        //   ner: "muj_shimtgel_dun",
        //   garchig: "tax",
        // },
        {
          ner: "gst",
          garchig: "GST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "pst",
          garchig: "PST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "hst",
          garchig: "HST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "qst",
          garchig: "QST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "state_tax",
          garchig: "state_tax_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "local_tax",
          garchig: "local_tax_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "orlogo_mungun_dun",
          garchig: "shop_commission",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? false : true,
        },
        {
          ner: "hereglegch_shimtgel_dun",
          garchig: "user_commission",
          nuuh: LoginAuth.layoutTurulAvah() === 1 ? false : true,
        },
      ],
      zahialga_jagsaalt: [],
      zahialga_jagsaalt_utga: [],
      ajiltan_jagsaalt: [],
      staff_jagsaalt: [],
      utguud: [],
      unshijBaigaa: false,
      niitToo: 1,
      songogdsonHuudasToo: 1,
      ognooHyzgaarMax: tsagAvah("ognooHyzgaarMax"),
      ognooHyzgaarMin: tsagAvah("ognooHyzgaarMin"),
      ognoo: tsagAvah("onoodor"),
      layout_turul: LoginAuth.layoutTurulAvah(),
      total_sum: 0,
    };
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.huudasSoligdohod = this.huudasSoligdohod.bind(this);
    this.ajiltanSongohod = this.ajiltanSongohod.bind(this);
    this.ognooSongohod = this.ognooSongohod.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    const { layout_turul, ognoo } = this.state;
    if (layout_turul === 1) {
      this.props.shireeJagsaaltFn();
    } else {
      this.props.ajiltanJagsaaltFn();
    }
    this.props.zahialgaJagsaaltFn(ognoo);
    const info = LoginAuth.baiguullagaInfoAvah();
    let myGarchig = this.state.garchignuud;
    myGarchig.map((garchig) => {
      if (
        garchig.ner_nemelt !== undefined &&
        !!info[garchig.ner] &&
        Number(info[garchig.ner]) > 0
      ) {
        garchig.nuuh = LoginAuth.layoutTurulAvah() === 1 ? false : true;
        garchig.ner_nemelt =
          "( " +
          currency(info[garchig.ner] * 100, { precision: 3, separator: "" }) +
          "% )";
      }
      return null;
    });
    this.setState({
      garchignuud: myGarchig,
    });
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.zahialga_jagsaalt !== state.zahialga_jagsaalt) {
      let zahialga_jagsaalt = props.zahialga_jagsaalt
        ? props.zahialga_jagsaalt
        : [];
      let total_sum = 0;
      zahialga_jagsaalt.map((utga) => {
        total_sum = currency(Number(total_sum)).add(Number(utga.gst));
        total_sum = currency(Number(total_sum)).add(Number(utga.pst));
        total_sum = currency(Number(total_sum)).add(Number(utga.hst));
        total_sum = currency(Number(total_sum)).add(Number(utga.qst));
        total_sum = currency(Number(total_sum)).add(Number(utga.local_tax));
        total_sum = currency(Number(total_sum)).add(Number(utga.state_tax));
        total_sum = currency(Number(total_sum)).add(
          Number(utga.baiguullaga_mungun_dun)
        );
        return null;
      });
      hariu.zahialga_jagsaalt = props.zahialga_jagsaalt;
      hariu.zahialga_jagsaalt_utga = zahialga_jagsaalt ? zahialga_jagsaalt : [];
      hariu.utguud = zahialga_jagsaalt ? zahialga_jagsaalt : [];
      hariu.total_sum = total_sum;
    }
    if (props.ajiltan_jagsaalt !== state.ajiltan_jagsaalt) {
      let ajiltan_jagsaalt = props.ajiltan_jagsaalt
        ? props.ajiltan_jagsaalt
        : [];
      hariu.ajiltan_jagsaalt = props.ajiltan_jagsaalt;
      hariu.staff_jagsaalt = ajiltan_jagsaalt;
    }
    if (props.shiree_jagsaalt !== state.shiree_jagsaalt) {
      let shiree_jagsaalt = props.shiree_jagsaalt ? props.shiree_jagsaalt : [];
      hariu.shiree_jagsaalt = props.shiree_jagsaalt;
      hariu.staff_jagsaalt = shiree_jagsaalt;
    }
    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  huudasSoligdohod(huudas) {
    this.props.zahialgaJagsaaltFn(huudas);
  }

  hadgalahDarahad() {}

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  ajiltanSongohod(index) {
    const { zahialga_jagsaalt_utga, staff_jagsaalt } = { ...this.state };
    let data = [];
    if (index < 0) {
      data = zahialga_jagsaalt_utga;
    } else {
      const ajiltan = staff_jagsaalt[index];
      const ajiltan_id = ajiltan.ajiltan_id;
      data = zahialga_jagsaalt_utga.filter(
        (utga) => utga.ajiltan_id === ajiltan_id
      );
    }
    this.setState({
      utguud: data,
    });
  }
  ognooSongohod(event) {
    const ognoo = event.target.value;
    this.props.zahialgaJagsaaltFn(ognoo);
    this.setState({ ognoo });
  }
  render() {
    const {
      garchignuud,
      utguud,
      flag,
      medegdel,
      unshijBaigaa,
      niitToo,
      staff_jagsaalt,
      ognooHyzgaarMax,
      ognooHyzgaarMin,
      ognoo,
      total_sum,
      layout_turul,
    } = this.state;
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md="4" xs="12">
                  <FormGroup>
                    <Label>{i18n.t("sum_toshop")}</Label>
                    <Input disabled value={total_sum} />
                  </FormGroup>
                </Col>
                <Col md="4" xs="12">
                  <SongoltBox
                    garchig={
                      layout_turul === 1 ? "shiree_haih" : "ajiltan_haih"
                    }
                    songoltUtga={staff_jagsaalt}
                    songohod={this.ajiltanSongohod.bind(this)}
                  />
                </Col>
                <Col md="4" xs="12">
                  <FormGroup>
                    <Label>{i18n.t("ognoo_haih_booking")}</Label>
                    <Input
                      type="date"
                      name="date"
                      placeholder="date placeholder"
                      max={ognooHyzgaarMax}
                      min={ognooHyzgaarMin}
                      value={ognoo}
                      onChange={this.ognooSongohod}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                  </Alert>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    niitToo={niitToo}
                    huudas_soligdohod={this.huudasSoligdohod}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { zahialga_jagsaalt } = state.uilajillagaaReducer;
  const { ajiltan_jagsaalt, shiree_jagsaalt } = state.baiguullagaReducer;
  return {
    zahialga_jagsaalt,
    ajiltan_jagsaalt,
    shiree_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      zahialgaJagsaaltFn,
      ajiltanJagsaaltFn,
      shireeJagsaaltFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Zahialga)
);
