import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Alert,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import Husnegt from "components/Husnegt";
import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import { ajiltanJagsaaltFn } from "../../../store/actions/baiguullaga.actions";
import {
  ajiltanChuluuBurtgehFn,
  ajiltanChuluuJagsaaltFn,
} from "store/actions/uilajillagaa.actions";
import { validEseh, ylgaataiEseh, validationUrt, tsagAvah } from "validation";
import MyLoader from "components/MyLoader";
import SongoltBox from "components/SongoltBox";
import LoginAuth from "services/LoginAuth";
import { ognooFormat_noTimezone } from "validation/uildluud";

class AjiltanChuluu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout_turul: LoginAuth.layoutTurulAvah(),
      flag: 0,
      medegdel: false,

      dialogTuluv: false,
      songogdsonMor: {},
      songogdsonIndex: 0,
      garchignuud: [
        {
          ner: "ajiltan_ner",
          garchig: "ner",
          turul: "songoh",
          songoh: "ajiltan",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "chuluu_date",
          garchig: "ognoo",
          turul: "date_notimezone",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "chuluu_ehleh",
          garchig: "ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "chuluu_duusah",
          garchig: "duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "tailbar",
          garchig: "tailbar",
          turul: "textarea",
          validations: {
            validation: ["isZaaval", "isTemdegttei"],
            urtMax: validationUrt.tailbar_bogino,
          },
        },
      ],
      ajiltan_jagsaalt: [],
      ajiltan_chuluu_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      ognooHyzgaarMax: tsagAvah("ognooHyzgaarMax"),
      ognooHyzgaarMin: tsagAvah("ognooHyzgaarMin"),
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      niitToo: 0,
    };
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.ajiltanSongohod = this.ajiltanSongohod.bind(this);
    this.ognooSongohod = this.ognooSongohod.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    this.props.ajiltanJagsaaltFn();
    this.props.ajiltanChuluuJagsaaltFn(0);
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.ajiltan_jagsaalt !== state.ajiltan_jagsaalt) {
      let ajiltan_jagsaalt = props.ajiltan_jagsaalt
        ? props.ajiltan_jagsaalt
        : [];
      hariu.ajiltan_jagsaalt = ajiltan_jagsaalt;
    }
    if (props.ajiltan_chuluu_jagsaalt !== state.ajiltan_chuluu_jagsaalt) {
      let jagsaalt =
        !!props.ajiltan_chuluu_jagsaalt &&
        !!props.ajiltan_chuluu_jagsaalt.jagsaalt
          ? props.ajiltan_chuluu_jagsaalt.jagsaalt
          : [];
      jagsaalt.map((ajiltan) => {
        ajiltan.ajiltan_ner = state.ajiltan_jagsaalt.filter(
          (a) => a.ajiltan_id === ajiltan.ajiltan_id
        )[0];
        return null;
      });
      hariu.ajiltan_chuluu_jagsaalt = props.ajiltan_chuluu_jagsaalt;
      hariu.utguud = jagsaalt;
      hariu.niitToo = props.ajiltan_chuluu_jagsaalt.too;
    }

    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    if (ner === "ajiltan_ner") {
      songogdsonMor[ner] = utga;
      songogdsonMor["ajiltan_id"] =
        !!utga && !!utga.ajiltan_id ? utga.ajiltan_id : null;
      // validationHariu[ner]={isValid: false, message: i18n("")}
    }
    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }
  huudasSoligdohod(huudas) {
    this.props.ajiltanChuluuJagsaaltFn(huudas);
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor: {},
      validationHariu: {},
      medegdel: false,
    });
    this.props.ajiltanChuluuJagsaaltFn(0);
  }
  hadgalahDarahad() {
    const { songogdsonMor } = this.state;
    this.setState({ unshijBaigaa: true });
    try {
      this.props.ajiltanChuluuBurtgehFn(songogdsonMor).then((hariu) => {
        if (hariu.amjilt === true) {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 0,
            unshijBaigaa: false,
          });
          this.props.ajiltanChuluuJagsaaltFn(0);
        } else {
          if (hariu.message === "zahialga davhtsaj baina") {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 2,
              unshijBaigaa: false,
            });
          } else if (hariu.message === "hyzgaar error") {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 3,
              unshijBaigaa: false,
            });
          } else {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
        }
      });
    } catch (aldaa) {
      this.setState({
        dialogTuluv: false,
        songogdsonMor: {},
        validationHariu: {},
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  ajiltanSongohod(index) {
    const { ajiltan_chuluu_jagsaalt, ajiltan_jagsaalt } = this.state;
    let utguud = [];
    if (index < 0) {
      utguud = ajiltan_chuluu_jagsaalt.jagsaalt;
    } else {
      const ajiltan = ajiltan_jagsaalt[index];
      const ajiltan_id = ajiltan.ajiltan_id;
      utguud =
        ajiltan_chuluu_jagsaalt.jagsaalt &&
        ajiltan_chuluu_jagsaalt.jagsaalt.filter(
          (utga) => utga.ajiltan_id === ajiltan_id
        );
    }
    this.setState({
      utguud,
    });
  }
  ognooSongohod(event) {
    const ognoo = event.target.value;
    const { ajiltan_chuluu_jagsaalt } = this.state;
    let utguud = [];
    if (ognoo === "") {
      utguud = ajiltan_chuluu_jagsaalt.jagsaalt;
    } else {
      utguud =
        ajiltan_chuluu_jagsaalt.jagsaalt &&
        ajiltan_chuluu_jagsaalt.jagsaalt.filter(
          (utga) =>
            ognooFormat_noTimezone(utga.chuluu_date, "YYYY-MM-DD") === ognoo
        );
    }
    this.setState({
      utguud,
    });
  }
  render() {
    const {
      validationHariu,
      garchignuud,
      utguud,
      dialogTuluv,
      songogdsonMor,
      songogdsonIndex,
      flag,
      medegdel,
      unshijBaigaa,
      ajiltan_jagsaalt,
      ognooHyzgaarMax,
      layout_turul,
      ognooHyzgaarMin,
      erh_hyzgaar,
      niitToo,
    } = this.state;
    if (layout_turul === 1) {
      return <Redirect to="/" />;
    }
    if (erh_hyzgaar === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md="4" xs="12">
                  <SongoltBox
                    garchig="ajiltan_haih"
                    songoltUtga={ajiltan_jagsaalt}
                    songohod={this.ajiltanSongohod}
                  />
                </Col>
                <Col md="4" />
                <Col md="4" xs="12">
                  <Label>{i18n.t("ognoo_haih")}</Label>
                  <Input
                    type="date"
                    name="date"
                    placeholder="date placeholder"
                    max={ognooHyzgaarMax}
                    min={ognooHyzgaarMin}
                    onChange={this.ognooSongohod}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="9" />
                <Col md="3" align="right">
                  <Button
                    className="ungu-primary"
                    round="true"
                    outline
                    onClick={this.dialogToggle}
                  >
                    {i18n.t("nemeh")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                    {flag === 2 && (
                      <span>
                        {i18n.t("medegdel_aldaa_tsag_davhtsaj_baina")}
                      </span>
                    )}
                    {flag === 3 && (
                      <span>{i18n.t("medegdel_aldaa_hyzgaar_absense")}</span>
                    )}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    niitToo={niitToo}
                    huudas_soligdohod={this.huudasSoligdohod}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            {dialogTuluv === true && (
              <Form>
                {garchignuud.map((orolt, index) => {
                  return (
                    <GarchigtaiOrolt
                      key={index}
                      index={index}
                      orolt={orolt}
                      utgaAvah={this.modalUtgaOorchlogdohod}
                      utga={songogdsonMor[orolt.ner]}
                      validationHariu={validationHariu[orolt.ner]}
                      ajiltan={ajiltan_jagsaalt}
                    />
                  );
                })}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="ungu-primary"
              onClick={this.hadgalahDarahad}
              disabled={
                !validEseh(garchignuud, songogdsonMor) ||
                !ylgaataiEseh(songogdsonMor, utguud[songogdsonIndex])
              }
            >
              {i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { ajiltan_jagsaalt } = state.baiguullagaReducer;
  const { ajiltan_chuluu_jagsaalt } = state.uilajillagaaReducer;
  return {
    ajiltan_jagsaalt,
    ajiltan_chuluu_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ajiltanJagsaaltFn,
      ajiltanChuluuBurtgehFn,
      ajiltanChuluuJagsaaltFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AjiltanChuluu)
);
