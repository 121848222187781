import Husnegt from "components/Husnegt";
import i18n from "hel/i18n";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import moment from "moment";

import {
  ontsgoiTsagiinHuvaariJagsaaltFn,
  tsagiinHuvaariJagsaaltFn,
  ontsgoiTsagiinHuvaariBurtgelFn,
  tsagiinHuvaariBurtgelFn,
  ontsgoiTsagDeleteFn,
} from "../../../../store/actions/burtgel.actions";
import { hereglegchMe } from "store/actions/hereglegch.actions";
import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import { validEseh, tsagAvah } from "validation";
import MyLoader from "components/MyLoader";

class BaiguullagaTab3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      medegdel: false,
      unshijBaigaa: false,

      garchignuud: [
        {
          ner: "garag",
          garchig: "garag",
          turul: "songoh",
          songoh: "garag",
          validations: {
            validation: ["isZaaval"],
          },
          disabled: true,
        },
        {
          ner: "is_amrah",
          garchig: "is_amrah",
          turul: "switch",
          nuuh: true,
          tsag: true,
        },
        {
          ner: "ehleh_tsag",
          garchig: "ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "duusah_tsag",
          garchig: "duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "amralt1_ehleh_tsag",
          garchig: "amralt1_ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "amralt1_duusah_tsag",
          garchig: "amralt1_duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "amralt2_ehleh_tsag",
          garchig: "amralt2_ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "amralt2_duusah_tsag",
          garchig: "amralt2_duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
      ],
      garchignuudOntsgoi: [
        {
          ner: "durin_ognoo",
          garchig: "ognoo",
          turul: "date",
          validations: {
            validation: ["isZaaval", "isOgnoo"],
          },
          ontsgoi_tsagiin_date: true,
        },
        {
          ner: "is_amrah",
          garchig: "is_amrah",
          turul: "switch",
          nuuh: true,
        },
        {
          ner: "ehleh_tsag",
          garchig: "ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "duusah_tsag",
          garchig: "duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "amralt1_ehleh_tsag",
          garchig: "amralt1_ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "amralt1_duusah_tsag",
          garchig: "amralt1_duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "amralt2_ehleh_tsag",
          garchig: "amralt2_ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
        {
          ner: "amralt2_duusah_tsag",
          garchig: "amralt2_duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval", "isTsag"],
          },
        },
      ],

      garchignuudModal: [
        {
          ner: "garag",
          garchig: "garag",
          turul: "songoh",
          songoh: "garag",
          activeTab: "1",
          disabled: true,
        },
        {
          ner: "durin_ognoo",
          garchig: "ognoo",
          turul: "date",
          validations: {
            validation: ["isOgnoo"],
          },
          activeTab: "2",
        },
        {
          ner: "is_amrah",
          garchig: "is_amrah",
          turul: "switch",
          // nuuh: true,
        },
      ],
      garag: [
        {
          id: 1,
          ner: i18n.t("davaa"),
        },
        {
          id: 2,
          ner: i18n.t("mygmar"),
        },
        {
          id: 3,
          ner: i18n.t("lhagva"),
        },
        {
          id: 4,
          ner: i18n.t("purev"),
        },
        {
          id: 5,
          ner: i18n.t("baasan"),
        },
        {
          id: 6,
          ner: i18n.t("byamba"),
        },
        {
          id: 7,
          ner: i18n.t("nyam"),
        },
      ],
      dialogTuluv: false,
      songogdsonMor: {
        is_amrah: false,
      },
      activeTab: "1",
      tsagiin_huvaari_jagsaalt: [],
      ontsgoi_tsagiin_huvaari_jagsaalt: [],
      validationHariu: [],
      showUstgahAsuult: false,
    };
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.tabToggle = this.tabToggle.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.dialogToggleNemeh = this.dialogToggleNemeh.bind(this);
    this.ustgahAsuultHaah = this.ustgahAsuultHaah.bind(this);
    this.ustgahDarahad = this.ustgahDarahad.bind(this);
  }
  componentDidMount() {
    this.props.tsagiinHuvaariJagsaaltFn();
    tsagAvah("ognooHyzgaarMin");
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.tsagiin_huvaari_jagsaalt !== state.tsagiin_huvaari_jagsaalt) {
      let tsagiin_huvaari_jagsaalt = props.tsagiin_huvaari_jagsaalt
        ? props.tsagiin_huvaari_jagsaalt
        : [];
      tsagiin_huvaari_jagsaalt.map((tsag) => {
        tsag.garag = state.garag.filter((g) => g.id === tsag.udur)[0];
        return null;
      });
      tsagiin_huvaari_jagsaalt.sort((a, b) =>
        a.garag.id > b.garag.id ? 1 : -1
      );
      hariu.tsagiin_huvaari_jagsaalt = tsagiin_huvaari_jagsaalt;
    }
    if (
      props.ontsgoi_tsagiin_huvaari_jagsaalt !==
      state.ontsgoi_tsagiin_huvaari_jagsaalt
    ) {
      let ontsgoi_tsagiin_huvaari_jagsaalt =
        props.ontsgoi_tsagiin_huvaari_jagsaalt
          ? props.ontsgoi_tsagiin_huvaari_jagsaalt
          : [];
      ontsgoi_tsagiin_huvaari_jagsaalt.map((utga) => {
        utga.durin_ognoo = moment(utga.durin_ognoo).format("YYYY-MM-DD");
        return null;
      });
      ontsgoi_tsagiin_huvaari_jagsaalt.sort((a, b) =>
        a.durin_ognoo > b.durin_ognoo ? 1 : -1
      );
      hariu.ontsgoi_tsagiin_huvaari_jagsaalt = ontsgoi_tsagiin_huvaari_jagsaalt;
    }

    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  tabToggle = (activeTab) => {
    if (activeTab === "1") {
      this.props.tsagiinHuvaariJagsaaltFn();
    } else if (activeTab === "2") {
      this.props.ontsgoiTsagiinHuvaariJagsaaltFn();
    }
    this.setState({ activeTab, medegdel: false });
  };
  husnegtTovchDaragdahad(utga, index, tovch) {
    if (tovch === "ustgah") {
      this.setState({
        songogdsonMor: utga,
        medegdel: false,
        showUstgahAsuult: true,
      });
    } else {
      this.setState(
        {
          songogdsonMor: utga,
        },
        function () {
          this.dialogToggle();
        }
      );
    }
  }
  dialogToggleNemeh() {
    const { activeTab, garchignuud, garchignuudOntsgoi } = this.state;

    const odoogiinTsag = tsagAvah("odooTsag");
    const odoogiinOgnoo = tsagAvah("zahialgaAvahHonogLimit");
    let mor = {};

    if (activeTab === "1") {
      garchignuud.map((garchig) => {
        if (garchig.turul === "time") {
          mor[garchig.ner] = odoogiinTsag;
        } else if (garchig.turul === "date") {
          mor[garchig.ner] = odoogiinOgnoo;
        }
        return null;
      });
    } else if (activeTab === "2") {
      garchignuudOntsgoi.map((garchig) => {
        if (garchig.turul === "time") {
          mor[garchig.ner] = odoogiinTsag;
        } else if (garchig.turul === "date") {
          mor[garchig.ner] = odoogiinOgnoo;
        }
        return null;
      });
    }
    this.setState(
      {
        songogdsonMor: mor,
      },
      function () {
        this.dialogToggle();
      }
    );
  }
  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor:
        this.state.dialogTuluv === true ? {} : { ...this.state.songogdsonMor },
      validationHariu: {},
      medegdel: this.state.dialogTuluv === false ? false : this.state.medegdel,
    });
  }
  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;
    if (ner === "garag") {
      songogdsonMor[ner] = utga.id;
      songogdsonMor["udur"] = utga.id;
    }
    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }

  hadgalahDarahad() {
    const { songogdsonMor, activeTab, garchignuud, garchignuudOntsgoi } =
      this.state;
    if (
      validEseh(
        activeTab === "1" ? garchignuud : garchignuudOntsgoi,
        songogdsonMor
      )
    ) {
      this.setState({ unshijBaigaa: true });
      try {
        if (activeTab === "1") {
          this.props.tsagiinHuvaariBurtgelFn(songogdsonMor).then((hariu) => {
            this.props.tsagiinHuvaariJagsaaltFn();
            if (hariu.amjilt === true) {
              this.setState({
                dialogTuluv: false,
                songogdsonMor: {},
                validationHariu: {},
                medegdel: true,
                flag: 0,
                unshijBaigaa: false,
              });
              this.props.hereglegchMe();
            } else if (hariu.message === "zahialga baina") {
              this.setState({
                dialogTuluv: false,
                songogdsonMor: {},
                validationHariu: {},
                medegdel: true,
                flag: 2,
                unshijBaigaa: false,
              });
            } else {
              this.setState({
                dialogTuluv: false,
                songogdsonMor: {},
                validationHariu: {},
                medegdel: true,
                flag: 1,
                unshijBaigaa: false,
              });
            }
          });
        } else if (activeTab === "2") {
          this.props
            .ontsgoiTsagiinHuvaariBurtgelFn(songogdsonMor)
            .then((hariu) => {
              this.props.ontsgoiTsagiinHuvaariJagsaaltFn();
              let myFlag = 1;
              if (hariu.amjilt === true) {
                this.props.hereglegchMe();
                myFlag = 0;
              } else if (hariu.message === "burtgeltei") {
                myFlag = 4;
              }

              this.setState({
                dialogTuluv: false,
                songogdsonMor: {},
                validationHariu: {},
                medegdel: true,
                flag: myFlag,
                unshijBaigaa: false,
              });
            });
        }
      } catch (aldaa) {
        this.setState({
          dialogTuluv: false,
          songogdsonMor: {},
          validationHariu: {},
          medegdel: true,
          flag: 1,
          unshijBaigaa: false,
        });
      }
    } else {
      this.setState({
        medegdel: true,
        flag: 3,
        unshijBaigaa: false,
      });
    }
  }
  ustgahAsuultHaah() {
    this.setState({
      showUstgahAsuult: false,
      songogdsonMor: {},
      validationHariu: {},
    });
  }
  ustgahDarahad() {
    const utga = this.state.songogdsonMor;
    this.setState({
      unshijBaigaa: true,
    });
    this.props.ontsgoiTsagDeleteFn(utga.tsagiin_huvaari_id).then((hariu) => {
      this.ustgahAsuultHaah();
      if (hariu.amjilt === true) {
        this.props.ontsgoiTsagiinHuvaariJagsaaltFn();
        this.setState({
          medegdel: true,
          flag: 5,
          unshijBaigaa: false,
        });
        this.props.hereglegchMe();
      } else {
        this.setState({
          medegdel: true,
          flag: 1,
          unshijBaigaa: false,
        });
      }
    });
  }
  render() {
    const {
      garchignuud,
      tsagiin_huvaari_jagsaalt,
      ontsgoi_tsagiin_huvaari_jagsaalt,
      activeTab,
      garchignuudOntsgoi,
      medegdel,
      flag,
      dialogTuluv,
      songogdsonMor,
      validationHariu,
      garag,
      garchignuudModal,
      unshijBaigaa,
      showUstgahAsuult,
    } = this.state;
    return (
      <div className="tab-zai" style={{ background: "var(--light)" }}>
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <Nav tabs justified>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => {
                this.tabToggle("1");
              }}
            >
              {i18n.t("undsen_tsagiin_huvaari")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => {
                this.tabToggle("2");
              }}
            >
              {i18n.t("ontsgoi_tsagiin_huvaari")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={activeTab}
          style={{ background: "white", height: window.innerHeight * 0.7 }}
        >
          <br />
          <Row>
            <Col>
              <Alert
                isOpen={medegdel}
                toggle={this.medegdelToggle}
                color={flag === 0 || flag === 5 ? "success" : "danger"}
              >
                {flag === 0 && (
                  <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                )}
                {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                {flag === 2 && (
                  <span>{i18n.t("medegdel_aldaa_tsag_zahialgatai")}</span>
                )}
                {flag === 4 && (
                  <span>{i18n.t("medegdel_aldaa_tsag_burtgeltei")}</span>
                )}{" "}
                {flag === 5 && <span>{i18n.t("medegdel_amjilt_1")}</span>}
              </Alert>
            </Col>
          </Row>
          <TabPane tabId="1">
            {activeTab === "1" && (
              <Row>
                <Col sm="12">
                  <br />
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={tsagiin_huvaari_jagsaalt}
                    tovchtoi_eseh={true}
                    tovch_daragdahad={this.husnegtTovchDaragdahad}
                  />
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tabId="2">
            {activeTab === "2" && (
              <Row>
                <Col sm="12">
                  <br />
                  <Row>
                    <Col md="9" />
                    <Col md="3" align="right">
                      <Button
                        className="ungu-primary"
                        round="true"
                        outline
                        onClick={this.dialogToggleNemeh}
                      >
                        {i18n.t("nemeh")}
                      </Button>
                    </Col>
                  </Row>
                  <Husnegt
                    garchignuud={garchignuudOntsgoi}
                    utguud={ontsgoi_tsagiin_huvaari_jagsaalt}
                    tovchtoi_eseh={true}
                    tovch_daragdahad={this.husnegtTovchDaragdahad}
                    tovch={["zasah", "ustgah"]}
                  />{" "}
                </Col>
              </Row>
            )}
          </TabPane>
        </TabContent>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            <Row>
              <Col>
                <Alert
                  isOpen={medegdel && flag > 1}
                  toggle={this.medegdelToggle}
                  color={"danger"}
                >
                  {flag === 3 && (
                    <span>{i18n.t("medegdel_aldaa_validation")}</span>
                  )}
                </Alert>
              </Col>
            </Row>
            {dialogTuluv === true && (
              <Form>
                {songogdsonMor.is_amrah === true ? (
                  <div>
                    {garchignuudModal.map((orolt, index) => {
                      if (orolt.activeTab && orolt.activeTab !== activeTab) {
                        return null;
                      }
                      return (
                        <GarchigtaiOrolt
                          key={index}
                          index={index}
                          orolt={orolt}
                          utgaAvah={this.modalUtgaOorchlogdohod}
                          utga={songogdsonMor[orolt.ner]}
                          validationHariu={validationHariu[orolt.ner]}
                          garag={garag}
                        />
                      );
                    })}
                  </div>
                ) : activeTab === "1" ? (
                  <div>
                    {garchignuud.map((orolt, index) => {
                      return (
                        <GarchigtaiOrolt
                          key={index}
                          index={index}
                          orolt={orolt}
                          utgaAvah={this.modalUtgaOorchlogdohod}
                          utga={songogdsonMor[orolt.ner]}
                          validationHariu={validationHariu[orolt.ner]}
                          garag={garag}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    {garchignuudOntsgoi.map((orolt, index) => {
                      return (
                        <GarchigtaiOrolt
                          key={index}
                          index={index}
                          orolt={orolt}
                          utgaAvah={this.modalUtgaOorchlogdohod}
                          utga={songogdsonMor[orolt.ner]}
                          validationHariu={validationHariu[orolt.ner]}
                        />
                      );
                    })}
                  </div>
                )}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button className="ungu-primary" onClick={this.hadgalahDarahad}>
              {" "}
              {i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showUstgahAsuult} toggle={this.ustgahAsuultHaah}>
          <ModalHeader toggle={this.ustgahAsuultHaah} />
          <ModalBody style={{ textAlign: "center" }}>
            <Row>
              <Col style={{ textAlign: "center" }}>
                {i18n.t("ontsgoi_tsag_ustgah_asuult")}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div style={{ textAlign: "center", width: "100%" }}>
              <Button
                className="ungu-primary"
                onClick={this.ustgahDarahad}
                style={{ width: "30%" }}
              >
                {" "}
                {i18n.t("tiim")}
              </Button>{" "}
              <Button
                color="secondary"
                onClick={this.ustgahAsuultHaah}
                style={{ width: "30%" }}
              >
                {i18n.t("bolih")}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { tsagiin_huvaari_jagsaalt, ontsgoi_tsagiin_huvaari_jagsaalt } =
    state.burtgelReducer;
  return {
    tsagiin_huvaari_jagsaalt,
    ontsgoi_tsagiin_huvaari_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hereglegchMe,
      tsagiinHuvaariJagsaaltFn,
      ontsgoiTsagiinHuvaariJagsaaltFn,
      ontsgoiTsagiinHuvaariBurtgelFn,
      tsagiinHuvaariBurtgelFn,
      ontsgoiTsagDeleteFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaiguullagaTab3)
);
