import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  Alert,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";

import {
  burtgelBatalgaajuulahFn,
  tugjeeTailahFn,
} from "../../store/actions/hereglegch.actions";
import { togtmol } from "delgetsuud/zagvar";
import ReactCodeInput from "react-code-input";
import { validShalgah } from "validation";
import ButsahTovch from "components/ButsahTovch";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
class Batalgaajuulah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medegdel: false,
      flag: 1,
      nevtreh_hayag: this.props.location.state
        ? this.props.location.state.nevtreh_hayag
        : "",
      status: this.props.location.state ? this.props.location.state.status : 1,
      damjsan_eseh:
        this.props.location.state && this.props.location.state.nevtreh_hayag
          ? true
          : false,
      batalgaajuulad_kod: "",
      validationHariu: {},
      tooljBaigaa: true,
      time: {},
      seconds: togtmol.batalgaajuulah_tooluur,
      isMobile: window.matchMedia("(max-width: 767px)").matches,
      isTablet: window.matchMedia("(min-width:768px) and (max-width: 1024px)")
        .matches,
      unshijBaigaa: false,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.utgaOorchlogdohodKod = this.utgaOorchlogdohodKod.bind(this);
    this.urgeljluulehDarahad = this.urgeljluulehDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.kodAvah = this.kodAvah.bind(this);
  }
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  startTimer() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar, tooljBaigaa: true });
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      this.setState({
        tooljBaigaa: false,
        seconds: togtmol.batalgaajuulah_tooluur,
      });
      this.timer = 0;
    }
  }
  utgaOorchlogdohodKod(utga) {
    const urDun = validShalgah("batalgaajuulah_kod", utga, {
      validation: ["isDanToo", "isZaaval"],
      urtTentsuu: 6,
    });
    this.setState({
      batalgaajuulad_kod: utga,
      validationHariu: urDun,
    });
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  kodAvah() {
    const { status } = this.state;
    try {
      if (status === 1) {
        this.props
          .burtgelBatalgaajuulahFn({
            turul: "2", //kod avah
            ner: this.state.nevtreh_hayag,
            kod: "",
          })
          .then((hariu) => {
            if (hariu.amjilt === true) {
              this.setState({
                medegdel: true,
                flag: 0,
              });
            } else if (
              hariu.message !== undefined &&
              hariu.message.includes("error - with - ")
            ) {
              this.setState({
                medegdel: true,
                flag: 4,
              });
            } else if (hariu.message === "limit") {
              this.setState({
                medegdel: true,
                flag: 5,
              });
            } else {
              this.setState({
                medegdel: true,
                flag: 1,
              });
            }
          });
      } else if (status === 5) {
        this.props
          .tugjeeTailahFn({
            turul: "2", //kod avah
            ner: this.state.nevtreh_hayag,
            kod: "",
          })
          .then((hariu) => {
            if (hariu.amjilt === true) {
              this.setState({
                medegdel: true,
                flag: 0,
              });
            } else if (
              hariu.message !== undefined &&
              hariu.message.includes("error - with - ")
            ) {
              this.setState({
                medegdel: true,
                flag: 4,
              });
            } else if (hariu.message === "limit") {
              this.setState({
                medegdel: true,
                flag: 5,
              });
            } else {
              this.setState({
                medegdel: true,
                flag: 1,
              });
            }
          });
      } else {
        LoginAuth.garah();
        this.props.history.push({ pathname: "/login" });
      }
    } catch (aldaa) {
      this.setState({
        medegdel: true,
        flag: 1,
      });
    }
    this.startTimer();
  }
  componentDidMount() {
    if (this.state.damjsan_eseh === false) {
      LoginAuth.garah();
      this.props.history.push({ pathname: "/login" });
    } else {
      this.kodAvah();
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  urgeljluulehDarahad() {
    const { status } = this.state;
    this.setState({ unshijBaigaa: true });
    try {
      if (status === 1) {
        this.props
          .burtgelBatalgaajuulahFn({
            turul: "1", //kod shalgah
            ner: this.state.nevtreh_hayag,
            kod: this.state.batalgaajuulad_kod,
          })
          .then((hariu) => {
            if (hariu.amjilt === true) {
              LoginAuth.garah();
              this.props.history.push({
                pathname: "/login",
              });
            } else {
              this.setState({
                medegdel: true,
                flag: 2,
                unshijBaigaa: false,
              });
            }
          });
      } else if (status === 5) {
        this.props
          .tugjeeTailahFn({
            turul: "1", //kod shalgah
            ner: this.state.nevtreh_hayag,
            kod: this.state.batalgaajuulad_kod,
          })
          .then((hariu) => {
            if (hariu.amjilt === true) {
              LoginAuth.garah();
              this.props.history.push({
                pathname: "/login",
              });
            } else {
              this.setState({
                medegdel: true,
                flag: 2,
                unshijBaigaa: false,
              });
            }
          });
      } else {
        LoginAuth.garah();
        this.props.history.push({ pathname: "/login" });
      }
    } catch (aldaa) {
      this.setState({
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  }

  render() {
    const {
      flag,
      validationHariu,
      tooljBaigaa,
      isMobile,
      isTablet,
      medegdel,
      unshijBaigaa,
      nevtreh_hayag,
    } = this.state;
    const tooluur = this.state.time.s;
    const props = {
      inputStyle: {
        margin: "4px",
        width: isMobile ? "10vw" : isTablet ? "5vw" : "3vw",
        height: isMobile ? "10vw" : isTablet ? "5vw" : "3vw",
        borderRadius: "5px",
        fontSize: isMobile || isTablet ? "4vw" : "1.5vw",
        paddingLeft: "7px",
        border: "1px solid gray",
      },
      inputStyleInvalid: {
        margin: "4px",
        width: isMobile ? "10vw" : isTablet ? "5vw" : "3vw",
        height: isMobile ? "10vw" : isTablet ? "5vw" : "3vw",
        borderRadius: "5px",
        fontSize: isMobile || isTablet ? "4vw" : "1.5vw",
        paddingLeft: "7px",
        color: "var(--danger)",
        border: "1px solid var(--danger)",
      },
    };
    return (
      <div className="deerees-zai">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Alert
              isOpen={medegdel}
              toggle={this.medegdelToggle}
              color={flag === 0 || flag === 3 ? "success" : "danger"}
            >
              {flag === 0 && <span>{i18n.t("medegdel_amjilt_ilgeesen")}</span>}
              {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
              {flag === 2 && <span>{i18n.t("medegdel_aldaa_kod")}</span>}
              {flag === 3 && (
                <span>{i18n.t("medegdel_amjilt_batalgaajsan")}</span>
              )}
              {flag === 4 && (
                <span>{i18n.t("medegdel_aldaa_kod_tur_hulee")}</span>
              )}
              {flag === 5 && (
                <span>{i18n.t("medegdel_aldaa_kod_limit_utas")}</span>
              )}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Card>
              <CardHeader>
                <ButsahTovch {...this.props} />
                <CardTitle tag="h5" align="center">
                  {i18n.t("batalgaajuulah")}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div style={{ textAlign: "center" }}>
                      {i18n.t("batalgaajuulah_utas_tailbar_text", {
                        utas: nevtreh_hayag,
                      })}
                    </div>
                    <br />
                  </Col>
                </Row>
                <Form>
                  <Row>
                    <Col md={1} />
                    <Col md={10} align="center">
                      <ReactCodeInput
                        type="number"
                        fields={6}
                        {...props}
                        onChange={this.utgaOorchlogdohodKod}
                        // isValid={validationHariu.isValid}
                      />
                      {/* {validationHariu.isValid === false && (
                        <label style={{ color: "var(--danger)" }}>
                          {i18n.t(validationHariu.message)}
                        </label>
                      )} */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        onClick={this.urgeljluulehDarahad}
                        disabled={!validationHariu.isValid}
                      >
                        {i18n.t("urgeljluuleh")}
                      </Button>
                    </div>
                  </Row>
                  {tooljBaigaa === true ? (
                    <Row>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        {tooluur !== 0 && (
                          <label>
                            {i18n.t("tooluur_text", { tooluur: tooluur })}
                          </label>
                        )}
                      </div>
                    </Row>
                  ) : (
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="ungu-primary"
                          style={{ width: togtmol.tovchnii_urgun + "px" }}
                          onClick={this.kodAvah}
                          outline
                        >
                          {i18n.t("dahin_kod_avah")}
                        </Button>
                      </div>
                    </Row>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      burtgelBatalgaajuulahFn,
      tugjeeTailahFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Batalgaajuulah)
);
