import { ssubscConstants } from "../constants";

const INITIAL_STATE = {
  service_subsc_jagsaalt: [],
  ssubsc_tulbur_tuuh: [],
  ssubsc_ticket_jagsaalt: [],
};

const hariu = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ssubscConstants.SERVICE_SUBSC_JAGSAALT:
      return { ...state, service_subsc_jagsaalt: action.payload };
    case ssubscConstants.SERVICE_SUBSC_TULBUR_TUUH:
      return { ...state, ssubsc_tulbur_tuuh: action.payload };
    case ssubscConstants.SERVICE_SUBSC_TICKET_JAGSAALT:
      return { ...state, ssubsc_ticket_jagsaalt: action.payload };
    default:
      return state;
  }
};
export default hariu;
