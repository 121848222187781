import i18n from "hel/i18n";
import React from "react";
import LoginAuth from "services/LoginAuth";

function Banner(props) {
  const baiguullaga = LoginAuth.serviceSubscAvah();
  const info = LoginAuth.baiguullagaInfoAvah();
  let tulbur_banner_haruulah = baiguullaga.tulbur_banner;
  let tuluv_banner_haruulah = info.tuluv === "active" ? true : false;

  if (tuluv_banner_haruulah === false) {
    return (
      <div
        style={
          window.innerWidth > 992
            ? {
                height: 90,
                background: "var(--main-warning-orange)",
                color: "white",
                textAlign: "center",
                marginLeft: "260px",
                paddingTop: 20,
                marginTop: 10,
                paddingRight: 50,
                paddingLeft: 50,
              }
            : {
                height: 90,
                background: "var(--main-warning-orange)",
                color: "white",
                textAlign: "center",
                marginTop: 10,
                fontSize: "75%",
                padding: 10,
              }
        }
      >
        {i18n.t("banner_tuluv_not_active")}
      </div>
    );
  } else if (tulbur_banner_haruulah === true) {
    return (
      <div
        style={{
          height: 60,
          background: "#17a2b8",
          color: "white",
          textAlign: "center",
          marginLeft: window.innerWidth > 992 ? "260px" : 0,
          paddingTop: 20,
          fontSize: window.innerWidth > 992 ? "100%" : "90%",
        }}
        onClick={() => {
          props.history.push({ pathname: "/tulbur" });
        }}
      >
        {i18n.t("banner_tulbur_tuluugui")}
      </div>
    );
  } else {
    return null;
  }
}

export default Banner;
