import {
  Ajiltan,
  Baiguullaga,
  Uilchilgee,
  Undsen,
  Shiree,
  Tulbur,
  Zahialga,
  // ZahialgaTuuh,
  AjiltanUilchilgee,
  AjiltanChuluu,
  // DotroosTsagAvah,
  // ShireeBlockloh,
  SubscActive,
  SubscGet,
  SubscSold,
  ServiceSubsc,
  CardInfo,
  ServiceSubscTickets,
  TulburTuuh,
  Subscription,
  NiitGuilgee,
  TermofService,
  PrivacyPolicy,
  BatalgaajuulahDelgets,
  Garah,
} from "delgetsuud/dotroos";
var routes = [
  //baiguullaga medeelel
  {
    path: "/",
    name: "baiguullaga_garchig",
    icon: "bi bi-caret-left",
    component: Baiguullaga,
    erh_hyzgaar: true,
  },

  {
    path: "/tulbur",
    name: "tulbur_garchig",
    icon: "bi bi-caret-left",
    component: Tulbur,
    erh_hyzgaar: true,
  },
  {
    path: "/niitGuilgee",
    name: "niit_guilgee",
    icon: "bi bi-caret-left",
    component: NiitGuilgee,
    erh_hyzgaar: true,
  },

  //service subscription menunuud
  {
    path: "/service/subscription/tickets", // subscription ticket list
    name: "tickets_garchig",
    icon: "bi bi-caret-left",
    component: ServiceSubscTickets,
    erh_hyzgaar: true,
  },
  {
    path: "/service/subscriptions", // subscription list
    name: "service_subsc_garchig",
    icon: "bi bi-caret-left",
    component: ServiceSubsc,
    erh_hyzgaar: true,
  },
  {
    path: "/cards",
    name: "cards_garchig",
    icon: "bi bi-caret-left",
    component: CardInfo,
    erh_hyzgaar: true,
  },
  {
    path: "/service/subscription/history", // subscription ticket history
    name: "service_subsc_history_garchig",
    icon: "bi bi-caret-left",
    component: TulburTuuh,
    erh_hyzgaar: true,
  },

  //owner  subscription menunuud
  {
    path: "/subscription",
    name: "subscription_garchig",
    icon: "bi bi-caret-left",
    component: Subscription,
    is_subsc: true,
    erh_hyzgaar: true,
  },
  {
    path: "/subsc/active",
    name: "subsc_active_garchig",
    icon: "bi bi-caret-left",
    component: SubscActive,
    is_subsc: true,
    // erh_hyzgaar: true,
  },
  {
    path: "/subsc/sold",
    name: "subsc_sold_garchig",
    icon: "bi bi-caret-left",
    component: SubscSold,
    is_subsc: true,
    erh_hyzgaar: true,
  },
  {
    path: "/subsc/get",
    name: "subsc_get_garchig",
    icon: "bi bi-caret-left",
    component: SubscGet,
    is_subsc: true,
    // erh_hyzgaar: true,
  },

  //tsag zahialga menuunuud
  {
    path: "/tsagUndsen",
    name: "undsen_garchig",
    icon: "bi bi-caret-left",
    component: Undsen,
    is_tsag: true,
  },
  {
    path: "/zahialga",
    name: "zahialga_garchig",
    icon: "bi bi-caret-left",
    component: Zahialga,
    is_tsag: true,
  },
  //zahialga ognoogoor avchirdag bolson uchraas haav
  // {
  //   path: "/zahialga/tuuh",
  //   name: "zahialga_tuuh_garchig",
  //   icon: "bi bi-caret-left",
  //   component: ZahialgaTuuh,
  //   is_tsag: true,
  // },
  // ::restaurant
  {
    path: "/shiree",
    name: "shiree_garchig",
    icon: "bi bi-caret-left",
    component: Shiree,
    nuuh_layout: 2,
    erh_hyzgaar: true,
    is_tsag: true,
  },
  // {
  //   path: "/shiree/block",
  //   name: "shiree_block_garchig",
  //   icon: "bi bi-caret-left",
  //   component: ShireeBlockloh,
  //   nuuh_layout: 2,
  //   is_tsag: true,
  // },
  //::busad
  {
    path: "/uilchilgee",
    name: "uilchilgee_garchig",
    icon: "bi bi-caret-left",
    component: Uilchilgee,
    nuuh_layout: 1,
    erh_hyzgaar: true,
    is_tsag: true,
  },
  {
    path: "/ajiltan",
    name: "ajiltan_garchig",
    icon: "bi bi-caret-left",
    component: Ajiltan,
    nuuh_layout: 1,
    erh_hyzgaar: true,
    is_tsag: true,
  },
  {
    path: "/ajiltan/chuluu",
    name: "ajiltan_chuluu_garchig",
    icon: "bi bi-caret-left",
    component: AjiltanChuluu,
    nuuh_layout: 1,
    erh_hyzgaar: true,
    is_tsag: true,
  },
  {
    path: "/termofservice",
    name: "termofservice_garchig",
    icon: "bi bi-caret-left",
    component: TermofService,
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy_garchig",
    icon: "bi bi-caret-left",
    component: PrivacyPolicy,
  },
  // {
  //   path: "/dotroos/tsag",
  //   name: "dotroos_tsag_garchig",
  //   icon: "bi bi-caret-left",
  //   component: DotroosTsagAvah,
  //   nuuh_layout: 1,
  //   is_tsag: true,
  // },
  {
    path: "/garah",
    name: "garah",
    icon: "bi bi-caret-left",
    component: Garah,
  },

  // menund haragdahgui **********************

  {
    path: "/ajiltan/uilchilgee",
    name: "ajiltan_uilchilgee_garchig",
    icon: "bi bi-caret-left",
    component: AjiltanUilchilgee,
    invisible: true,
    nuuh_layout: 1,
    erh_hyzgaar: true,
  },
  {
    path: "/solih/batalgaajuulah",
    name: "solilt_batalgaajuulah",
    icon: "bi bi-caret-left",
    component: BatalgaajuulahDelgets,
    invisible: true,
    erh_hyzgaar: true,
  },
];
export default routes;
