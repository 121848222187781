import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Alert,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import Husnegt from "components/Husnegt";
import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  ajiltanJagsaaltFn,
  ajiltanUilchilgeeJagsaaltFn,
} from "../../../store/actions/baiguullaga.actions";
import {
  dotroosTsagJagsaaltFn,
  dotroosTsagBurtgehFn,
} from "store/actions/uilajillagaa.actions";
import { validEseh, ylgaataiEseh, validationUrt, tsagAvah } from "validation";
import MyLoader from "components/MyLoader";
import SongoltBox from "components/SongoltBox";
import { ognooFormat } from "validation";
import LoginAuth from "services/LoginAuth";

class DotroosTsagAvah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout_turul: LoginAuth.layoutTurulAvah(),
      flag: 0,
      medegdel: false,

      dialogTuluv: false,
      songogdsonMor: {},
      songogdsonIndex: 0,
      garchignuud: [
        {
          ner: "zahialsan_ognoo",
          garchig: "ognoo",
          turul: "date",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "ehleh_tsag",
          garchig: "ehleh_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "duusah_tsag",
          garchig: "duusah_tsag",
          turul: "time",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "ajiltan_ner",
          garchig: "ajiltan",
          turul: "songoh",
          songoh: "ajiltan",
        },
        {
          ner: "uilchilgee_ner",
          garchig: "uilchilgee",
          turul: "songoh",
          songoh: "uilchilgee",
        },
        {
          ner: "hereglegch_ner",
          garchig: "hereglegch_ner",
          validations: {
            validation: ["isZaaval", "isTemdegtgui"],
            urtMax: validationUrt.string,
          },
        },
        {
          ner: "hereglegch_utas",
          garchig: "hereglegch_utas",
          validations: {
            validation: ["isZaaval", "isDanToo"],
          },
        },
        {
          ner: "tailbar",
          garchig: "tailbar",
          turul: "textarea",
          validations: {
            validation: ["isZaaval", "isTemdegttei"],
            urtMax: validationUrt.tailbar_urt,
          },
        },
      ],
      ajiltan_jagsaalt: [],
      dotroos_tsag_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      ognooHyzgaarMax: tsagAvah("ognooHyzgaarMax"),
      ognooHyzgaarMin: tsagAvah("ognooHyzgaarMin"),
    };
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.ajiltanSongohod = this.ajiltanSongohod.bind(this);
    this.ognooSongohod = this.ognooSongohod.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    this.props.ajiltanJagsaaltFn();
    this.props.dotroosTsagJagsaaltFn(0);
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.ajiltan_jagsaalt !== state.ajiltan_jagsaalt) {
      let ajiltan_jagsaalt = props.ajiltan_jagsaalt
        ? props.ajiltan_jagsaalt
        : [];
      hariu.ajiltan_jagsaalt = ajiltan_jagsaalt;
    }
    if (
      props.ajiltan_uilchilgee_jagsaalt !== state.ajiltan_uilchilgee_jagsaalt
    ) {
      let ajiltan_uilchilgee_jagsaalt = props.ajiltan_uilchilgee_jagsaalt
        ? props.ajiltan_uilchilgee_jagsaalt
        : [];
      hariu.ajiltan_uilchilgee_jagsaalt = ajiltan_uilchilgee_jagsaalt;
    }
    if (props.dotroos_tsag_jagsaalt !== state.dotroos_tsag_jagsaalt) {
      let dotroos_tsag_jagsaalt = props.dotroos_tsag_jagsaalt
        ? props.dotroos_tsag_jagsaalt
        : [];
      hariu.dotroos_tsag_jagsaalt = dotroos_tsag_jagsaalt.jagsaalt
        ? dotroos_tsag_jagsaalt.jagsaalt
        : [];
      hariu.utguud = dotroos_tsag_jagsaalt.jagsaalt
        ? dotroos_tsag_jagsaalt.jagsaalt
        : [];
    }

    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    if (ner === "ajiltan_ner" && utga.ajiltan_id !== undefined) {
      this.props.ajiltanUilchilgeeJagsaaltFn(utga.ajiltan_id);
    }

    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor:
        this.state.dialogTuluv === true ? {} : { ...this.state.songogdsonMor },
      validationHariu: {},
      medegdel: false,
    });
    this.props.ajiltanJagsaaltFn();
  }

  husnegtTovchDaragdahad(utga, index) {
    this.setState(
      {
        songogdsonIndex: index,
        songogdsonMor: utga,
      },
      function () {
        this.dialogToggle();
      }
    );
  }
  hadgalahDarahad() {
    const { songogdsonMor } = this.state;
    this.setState({ unshijBaigaa: true });
    try {
      this.props.dotroosTsagBurtgehFn(songogdsonMor).then((hariu) => {
        if (hariu.amjilt === true) {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 0,
            unshijBaigaa: false,
          });
        } else {
          this.setState({
            dialogTuluv: false,
            songogdsonMor: {},
            validationHariu: {},
            medegdel: true,
            flag: 1,
            unshijBaigaa: false,
          });
        }
      });
    } catch (aldaa) {
      this.setState({
        dialogTuluv: false,
        songogdsonMor: {},
        validationHariu: {},
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  ajiltanSongohod(index) {
    const { dotroos_tsag_jagsaalt, ajiltan_jagsaalt } = this.state;
    let utguud = [];
    if (index < 0) {
      utguud = dotroos_tsag_jagsaalt;
    } else {
      const ajiltan = ajiltan_jagsaalt[index];
      const ajiltan_id = ajiltan.ajiltan_id;
      utguud = dotroos_tsag_jagsaalt.filter(
        (utga) => utga.ajiltan_id === ajiltan_id
      );
    }
    this.setState({
      utguud,
    });
  }
  ognooSongohod(event) {
    const ognoo = event.target.value;
    const { dotroos_tsag_jagsaalt } = this.state;
    let utguud = [];
    if (ognoo === "") {
      utguud = dotroos_tsag_jagsaalt;
    } else {
      utguud = dotroos_tsag_jagsaalt.filter(
        (utga) => ognooFormat(utga.chuluu_date, "YYYY-MM-DD") === ognoo //utga.chuluu_date oorchlogdono
      );
    }
    this.setState({
      utguud,
    });
  }
  render() {
    const {
      validationHariu,
      garchignuud,
      utguud,
      dialogTuluv,
      songogdsonMor,
      songogdsonIndex,
      flag,
      medegdel,
      unshijBaigaa,
      ajiltan_jagsaalt,
      ognooHyzgaarMax,
      layout_turul,
      ajiltan_uilchilgee_jagsaalt,
      ognooHyzgaarMin,
    } = this.state;
    if (layout_turul === 1) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col md="4" xs="12">
                  <SongoltBox
                    garchig="ajiltan_haih"
                    songoltUtga={ajiltan_jagsaalt}
                    songohod={this.ajiltanSongohod}
                  />
                </Col>
                <Col md="4" />
                <Col md="4" xs="12">
                  <Label>{i18n.t("ognoo_haih")}</Label>
                  <Input
                    type="date"
                    name="date"
                    placeholder="date placeholder"
                    max={ognooHyzgaarMax}
                    min={ognooHyzgaarMin}
                    onChange={this.ognooSongohod}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="9" />
                <Col md="3" align="right">
                  <Button
                    className="ungu-primary"
                    round="true"
                    outline
                    onClick={this.dialogToggle}
                  >
                    {i18n.t("nemeh")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Husnegt garchignuud={garchignuud} utguud={utguud} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            {dialogTuluv === true && (
              <Form>
                {garchignuud.map((orolt, index) => {
                  return (
                    <GarchigtaiOrolt
                      key={index}
                      index={index}
                      orolt={orolt}
                      utgaAvah={this.modalUtgaOorchlogdohod}
                      utga={songogdsonMor[orolt.ner]}
                      validationHariu={validationHariu[orolt.ner]}
                      ajiltan={ajiltan_jagsaalt}
                      uilchilgee={ajiltan_uilchilgee_jagsaalt}
                    />
                  );
                })}
              </Form>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="ungu-primary"
              onClick={this.hadgalahDarahad}
              disabled={
                !validEseh(garchignuud, songogdsonMor) ||
                !ylgaataiEseh(songogdsonMor, utguud[songogdsonIndex])
              }
            >
              {i18n.t("hadgalah")}
            </Button>{" "}
            <Button color="secondary" onClick={this.dialogToggle}>
              {i18n.t("bolih")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { ajiltan_jagsaalt, ajiltan_uilchilgee_jagsaalt } =
    state.baiguullagaReducer;
  const { dotroos_tsag_jagsaalt } = state.uilajillagaaReducer;
  return {
    ajiltan_jagsaalt,
    dotroos_tsag_jagsaalt,
    ajiltan_uilchilgee_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ajiltanJagsaaltFn,
      dotroosTsagJagsaaltFn,
      dotroosTsagBurtgehFn,
      ajiltanUilchilgeeJagsaaltFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DotroosTsagAvah)
);
