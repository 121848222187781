import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  Alert,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";

import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import { nuutsUgSolihFn } from "../../store/actions/hereglegch.actions";
import { togtmol } from "delgetsuud/zagvar";
import { validationUrt, validEseh } from "validation";
import ButsahTovch from "components/ButsahTovch";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";

class NuutsugSolih extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medegdel: false,
      flag: 1,
      oroltuud: [
        {
          garchig: "shine_nuuts_ug",
          ner: "nuuts_ug",
          placeholder: "nuuts_ug",
          turul: "password",
          validations: {
            validation: ["isNuutsug", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
        {
          garchig: "nuuts_ug_davt",
          ner: "nuuts_ug_davt",
          placeholder: "nuuts_ug_davt",
          turul: "password",
          validations: {
            validation: ["isNuutsug", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
      ],
      utguud: [],
      validationHariu: {},

      nevtreh_hayag: this.props.location.state
        ? this.props.location.state.nevtreh_hayag
        : "",

      kod: this.props.location.state ? this.props.location.state.kod : "",
      damjsan_eseh:
        this.props.location.state && this.props.location.state.nevtreh_hayag
          ? true
          : false,
      unshijBaigaa: false,
    };
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.urgeljluulehDarahad = this.urgeljluulehDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  utgaOorchlogdohod(ner, utga, urDun) {
    let utguud = { ...this.state.utguud };
    utguud[ner] = utga;

    if (
      ner === "nuuts_ug_davt" &&
      utguud.nuuts_ug !== utguud.nuuts_ug_davt &&
      urDun.message === ""
    ) {
      urDun.isValid = false;
      urDun.message = i18n.t("medegdel_aldaa_nuutsug_ylgaatai");
    }

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      utguud,
      validationHariu,
    });
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  urgeljluulehDarahad() {
    const { utguud, nevtreh_hayag, kod } = this.state;
    if (utguud.nuuts_ug === utguud.nuuts_ug_davt) {
      this.setState({ unshijBaigaa: true });
      try {
        this.props
          .nuutsUgSolihFn({
            ner: nevtreh_hayag,
            nuuts_ug: utguud.nuuts_ug,
            kod: kod,
          })
          .then((hariu) => {
            if (hariu.amjilt === true) {
              LoginAuth.garah();
              this.props.history.push({
                pathname: "/login",
              });
            } else {
              this.setState({
                medegdel: true,
                flag: 1,
                unshijBaigaa: false,
              });
            }
          });
      } catch (aldaa) {
        this.setState({
          medegdel: true,
          flag: 1,
          unshijBaigaa: false,
        });
      }
    } else {
      this.setState({
        medegdel: true,
        flag: 2,
      });
    }
  }

  render() {
    const {
      flag,
      validationHariu,
      oroltuud,
      utguud,
      damjsan_eseh,
      medegdel,
      unshijBaigaa,
    } = this.state;
    if (damjsan_eseh === false) {
      LoginAuth.garah();
      this.props.history.push({ pathname: "/login" });
    }
    return (
      <div className="deerees-zai">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Alert
              isOpen={medegdel}
              toggle={this.medegdelToggle}
              color={flag === 0 ? "success" : "danger"}
            >
              {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
              {flag === 2 && (
                <span>{i18n.t("medegdel_aldaa_nuutsug_ylgaatai")}</span>
              )}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Card>
              <CardHeader>
                <ButsahTovch {...this.props} />
                <CardTitle tag="h5" align="center">
                  {i18n.t("nuutsug_solih")}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  {oroltuud.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.utgaOorchlogdohod}
                        utga={utguud[orolt.ner]}
                        validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        onClick={this.urgeljluulehDarahad}
                        disabled={!validEseh(oroltuud, utguud)}
                      >
                        {i18n.t("urgeljluuleh")}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nuutsUgSolihFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NuutsugSolih)
);
