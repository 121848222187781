import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { privacyPolicyFn } from "../../../store/actions/hereglegch.actions";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termdata: "",
    };
  }
  async componentDidMount() {
    try {
      const hariu_term = await this.props.privacyPolicyFn();
      const mylink = hariu_term.data;
      const response = await fetch(mylink);
      const template = await response.text();
      console.log(template);
      this.setState({
        termdata: template,
      });
    } catch (aldaa) {
      console.log(aldaa);
    }
  }
  render() {
    // const { termdata } = this.state;
    return (
      <div className="content">
        <div>
          <iframe
            title="PrivacyPolicy"
            src="https://zurag.yousubsc.com/privacy-policy/PrivacyPolicy.html"
            width="100%"
            height={window.innerHeight * 0.8}
          ></iframe>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ privacyPolicyFn }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy)
);
