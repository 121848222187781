import { togtmol } from "delgetsuud/zagvar";
import React from "react";
import moment from "moment";
import LoginAuth from "services/LoginAuth";
import { ungu } from "delgetsuud/zagvar";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import i18n from "hel/i18n";

const hemjee = {
  tsagUrgun: 2.2,
  tsagUndur: 1.5,
  morUndur: 25,
  morUrgun: 40,
};
class UndsenComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zahialguud: this.props.zahialguud,
      staff: this.props.staff,
      songogdsonOgnoo: this.props.songogdsonOgnoo,
      tsagiin_huvaari: LoginAuth.tsagiinHuvaariAvah(),
      allUrt: 0,
      tsagMain: [],
      tsagDuurelt: [],
      duurgeltUrt: 0,
      songogdsonTsag: {},
      selectedIndex: -1,
      layout_turul: LoginAuth.layoutTurulAvah(),
    };
    this.tailbarHaah = this.tailbarHaah.bind(this);
  }
  componentDidMount() {
    this.tsagZurah();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.zahialguud !== prevProps.zahialguud) {
      this.tsagZurah();
    }
  }
  tsagZurah() {
    const { tsagiin_huvaari, songogdsonOgnoo, staff } = this.state;
    const { zahialguud } = this.props;
    const ognooDate = new Date(songogdsonOgnoo);
    const songogdsonGarag = ognooDate.getDay() === 0 ? 7 : ognooDate.getDay();
    let huvaari = tsagiin_huvaari.filter(
      (utga) => utga.udur.toString() === songogdsonGarag.toString()
    )[0];
    const ontsgoi_tsag = tsagiin_huvaari.filter(
      (utga) =>
        utga.turul.toString() === "2" &&
        utga.durin_ognoo.toString() === songogdsonOgnoo
    );
    if (!!ontsgoi_tsag[0]) {
      huvaari = ontsgoi_tsag[0];
    }
    if (!!huvaari) {
      //huvaariin temdeglegee tootsohod
      let tsag = [];
      let sumMin = 0;
      // moment boloh
      const ehleh_tsag = this.tsagDateBolgoh(huvaari.ehleh_tsag, "HH:mm");
      const duusah_tsag = this.tsagDateBolgoh(huvaari.duusah_tsag, "HH:mm");
      //--
      for (let i = ehleh_tsag.getHours(); i <= duusah_tsag.getHours(); i++) {
        if (i === ehleh_tsag.getHours()) {
          tsag.push({ tsag: i, minut: ehleh_tsag.getMinutes() });
          sumMin += 60 - ehleh_tsag.getMinutes();
        } else if (i === duusah_tsag.getHours()) {
          sumMin += duusah_tsag.getMinutes();
          tsag.push({ tsag: i, minut: duusah_tsag.getMinutes() });
        } else {
          sumMin += 60;
          tsag.push({ tsag: i, minut: 0 });
        }
      }

      //sul = 0
      //locked = 1
      //active = 2
      //dotroos = 3
      //chuluu = 4

      //tsag tootsohod
      const zahialga = zahialguud.filter(
        (utga) => utga.ajiltan_id.toString() === staff.ajiltan_id.toString()
      );
      zahialga.sort((a, b) => (a.ehleh_tsag > b.ehleh_tsag ? 1 : -1));
      let tsag_duurgelt = [];
      let omnoh = moment(huvaari.ehleh_tsag, "HH:mm").format("HH:mm");
      let mySum = 0;
      zahialga.map((utga) => {
        if (omnoh === moment(utga.ehleh_tsag, "HH:mm").format("HH:mm")) {
          const ehleh = moment(utga.ehleh_tsag, "HH:mm");
          const duusah = moment(utga.duusah_tsag, "HH:mm");
          const negj_urt = moment.duration(duusah.diff(ehleh)).as("minutes");
          mySum += parseInt(negj_urt);
          tsag_duurgelt.push({
            ehleh: ehleh.format("HH:mm"),
            duusah: duusah.format("HH:mm"),
            flag: utga.tuluv,
            urt: negj_urt,
            ajiltan_id: utga.ajiltan_id,
            uilchilgee_id: utga.uilchilgee_id,
            uilchilgee_ner: utga.uilchilgee_ner,
            user_ner: utga.user_ner,
            user_utas: utga.user_utas,
            tailbar: utga.tailbar,
          });
          omnoh = duusah.format("HH:mm");
        } else {
          const ehleh = moment(omnoh, "HH:mm");
          const duusah = moment(utga.ehleh_tsag, "HH:mm");
          const negj_urt = moment.duration(duusah.diff(ehleh)).as("minutes");
          mySum += parseInt(negj_urt);
          tsag_duurgelt.push({
            ehleh: ehleh.format("HH:mm"),
            duusah: duusah.format("HH:mm"),
            flag: "hooson",
            urt: negj_urt,
            tailbar: utga.tailbar,
          });
          const ehleh2 = moment(utga.ehleh_tsag, "HH:mm");
          const duusah2 = moment(utga.duusah_tsag, "HH:mm");
          const negj_urt2 = moment.duration(duusah2.diff(ehleh2)).as("minutes");
          mySum += parseInt(negj_urt2);
          tsag_duurgelt.push({
            ehleh: ehleh2.format("HH:mm"),
            duusah: duusah2.format("HH:mm"),
            flag: utga.tuluv,
            urt: negj_urt2,
            ajiltan_id: utga.ajiltan_id,
            uilchilgee_id: utga.uilchilgee_id,
            uilchilgee_ner: utga.uilchilgee_ner,
            user_ner: utga.user_ner,
            user_utas: utga.user_utas,
            tailbar: utga.tailbar,
          });
          omnoh = duusah2.format("HH:mm");
        }
        return 0;
      });
      if (omnoh < moment(huvaari.duusah_tsag, "HH:mm").format("HH:mm")) {
        const ehleh = moment(omnoh, "HH:mm");
        const duusah = moment(huvaari.duusah_tsag, "HH:mm");
        const negj_urt = moment.duration(duusah.diff(ehleh)).as("minutes");
        mySum += parseInt(negj_urt);
        tsag_duurgelt.push({
          ehleh: ehleh.format("HH:mm"),
          duusah: duusah.format("HH:mm"),
          flag: "hooson",
          urt: negj_urt,
        });
      }

      this.setState({
        tsagMain: tsag,
        allUrt: sumMin,
        tsagDuurelt: tsag_duurgelt,
        duurgeltUrt: mySum,
        selectedIndex: -1,
        songogdsonTsag: {},
      });
    }
  }
  tsagDateBolgoh = (data) => {
    let hariu = new Date("1/1/1999 " + data);
    return hariu;
  };
  tsagSongohod = (utga, index) => {
    const { selectedIndex } = this.state;
    // utga.flag = "zahialga_dotroos";
    // utga.uilchilgee_ner = "aa aaaa uichilge";
    // utga.user_ner = "user";
    // utga.user_utas = "99911495";
    // utga.tailbar =
    //   " aaaaaaaaaaaaa affaaaaaafa     hjkjjj   hhhhhhhhhhhh hh hh h";
    if (selectedIndex === index) {
      this.setState({
        songogdsonTsag: {},
        selectedIndex: -1,
      });
    } else {
      this.setState({
        songogdsonTsag: utga,
        selectedIndex: index,
      });
    }
  };
  tailbarHaah() {
    this.setState({
      selectedIndex: -1,
      songogdsonTsag: {},
    });
  }

  render() {
    const {
      tsagMain,
      allUrt,
      tsagDuurelt,
      songogdsonTsag,
      selectedIndex,
      layout_turul,
      staff,
    } = this.state;

    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    if (isMobile === true) {
      return (
        <Card
          style={{
            textAlign: "center",
            height: hemjee.tsagUndur * allUrt + 110,
          }}
        >
          <CardHeader style={{ marginBottom: 10 }}>
            {layout_turul === 1
              ? i18n.t("shiree") + " " + staff.ner
              : staff.ovog + " " + staff.ner}
          </CardHeader>
          <CardBody>
            <div>
              {selectedIndex > -1 && (
                <Modal isOpen={selectedIndex > -1} toggle={this.tailbarHaah}>
                  <ModalHeader toggle={this.tailbarHaah} />
                  <ModalBody>
                    <div
                      onClick={() => {
                        this.tailbarHaah();
                      }}
                    >
                      <div>
                        {(layout_turul === 1 ||
                          songogdsonTsag.flag === "ajiltan_chuluu") && (
                          <p
                            style={{
                              fontStyle: "italic",
                              color: "var(--main-chosen-sidebar)",
                            }}
                          >
                            {i18n.t("timeline_" + songogdsonTsag.flag)}
                          </p>
                        )}
                        <p>
                          {i18n.t("hugatsaa")}:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {songogdsonTsag.ehleh} - {songogdsonTsag.duusah}
                          </span>
                        </p>

                        {!!songogdsonTsag.uilchilgee_ner && (
                          <p>
                            {i18n.t("uilchilgeenii_ner")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {songogdsonTsag.uilchilgee_ner}
                            </span>
                          </p>
                        )}
                        {!!songogdsonTsag.user_ner && (
                          <p>
                            {i18n.t("hereglegch_ner")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {songogdsonTsag.user_ner}
                            </span>
                          </p>
                        )}
                        {!!songogdsonTsag.user_utas && (
                          <p>
                            {i18n.t("hereglegch_utas")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {songogdsonTsag.user_utas}
                            </span>
                          </p>
                        )}
                        {!!songogdsonTsag.tailbar && (
                          <p style={{ lineHeight: 1.5 }}>
                            {i18n.t("tailbar")}:{" "}
                            <span style={{ fontStyle: "italic" }}>
                              {songogdsonTsag.tailbar}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              )}
              <div className="tsag-shugam-esreg">
                <div className="tsag-shugam" style={{}}>
                  {tsagMain.map((i, index) => {
                    return (
                      <div
                        key={index}
                        style={
                          index === tsagMain.length - 1
                            ? {
                                width: hemjee.morUrgun,
                                textAlign: "right",
                                // background: index % 2 === 1 ? "lightblue" : "pink",
                                verticalAlign: "top",
                                paddingRight: "5px",
                              }
                            : {
                                width: hemjee.morUrgun,
                                textAlign: "right",
                                // background: index % 2 === 1 ? "lightblue" : "pink",
                                verticalAlign: "top",
                                paddingRight: "5px",
                                height: hemjee.tsagUndur * (60 - i.minut),
                              }
                        }
                      >
                        {!(index === 0 && i.minut !== 0) && (
                          <p
                            style={{
                              marginTop: -8,
                              fontSize:
                                tsagMain.length > 10
                                  ? togtmol.font * (12 / (allUrt / 60))
                                  : togtmol.font * 0.9,
                            }}
                          >
                            {i.tsag < 10 ? "0" : ""}
                            {i.tsag}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* <div> */}
                <div>
                  <div className="tsag-shugam">
                    {tsagMain.map((i, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: hemjee.morUrgun,
                          }}
                        >
                          {index === 0 || index === tsagMain.length - 1 ? (
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              {i.minut >= 30 ? (
                                <div
                                  className="tsag-shugam"
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  {index !== 0 && (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: hemjee.tsagUndur * 30,
                                      }}
                                    >
                                      <div
                                        style={{
                                          borderTop:
                                            "1px solid var(--main-dark-blue)",
                                          width: "50%",
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      width: "100%",
                                      height:
                                        index === 0
                                          ? hemjee.tsagUndur * (60 - i.minut)
                                          : hemjee.tsagUndur * (i.minut - 30),
                                    }}
                                  >
                                    <div
                                      style={{
                                        borderTop:
                                          i.minut === 30 || index !== 0
                                            ? "1px solid var(--main-dark-blue)"
                                            : 0,
                                        width: i.minut !== 0 ? "25%" : "50%",
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="tsag-shugam"
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height:
                                        index === 0
                                          ? hemjee.tsagUndur * (30 - i.minut)
                                          : hemjee.tsagUndur * i.minut,
                                    }}
                                  >
                                    <div
                                      style={{
                                        borderTop:
                                          i.minut === 0 || index !== 0
                                            ? "1px solid var(--main-dark-blue)"
                                            : 0,
                                        width: "50%",
                                      }}
                                    />
                                  </div>
                                  {index === 0 && (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: hemjee.tsagUndur * 30,
                                      }}
                                    >
                                      {/* d */}
                                      <div
                                        style={{
                                          borderTop:
                                            "1px solid var(--main-dark-blue)",
                                          width: "25%",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                className="tsag-shugam"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: hemjee.tsagUndur * 30,
                                  }}
                                >
                                  <div
                                    style={{
                                      borderTop:
                                        "1px solid var(--main-dark-blue)",
                                      width: "50%",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    height: hemjee.tsagUndur * 30,
                                  }}
                                >
                                  <div
                                    style={{
                                      borderTop:
                                        "1px solid var(--main-dark-blue)",
                                      width: "25%",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="tsag-shugam"
                  style={{
                    position: "absolute",
                    left: hemjee.morUrgun + 15,
                  }}
                >
                  {tsagDuurelt.map((utga, index) => {
                    return (
                      <Button
                        key={index}
                        disabled={utga.flag === "hooson" ? true : false}
                        className="timeline-btn"
                        onClick={() => {
                          this.tsagSongohod(utga, index);
                        }}
                        style={{
                          width: hemjee.morUrgun,
                          height: hemjee.tsagUndur * utga.urt - 2,
                          marginTop: 1,
                          marginBottom: 1,
                          background:
                            selectedIndex === index
                              ? ungu.songogdsonTsag
                              : ungu[utga.flag],
                          boxShadow:
                            utga.flag === "hooson"
                              ? "0px 0px 0px 0px rgb(0 0 0 / 15%)"
                              : "1px 1px 1px 0px rgb(0 0 0 / 15%)",
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            overflow: "scroll",
            marginBottom: 25,
          }}
        >
          <Card
            className="timetable"
            style={{
              width: hemjee.tsagUrgun * allUrt + 50,
            }}
          >
            <CardHeader>
              {layout_turul === 1
                ? i18n.t("shiree") + " " + staff.ner
                : staff.ovog + " " + staff.ner}
            </CardHeader>
            <CardBody>
              <div>
                {selectedIndex > -1 && (
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid var(--main-light-blue)",
                      borderRadius: "15px",
                      marginBottom: "20px",
                      lineHeight: 1,
                    }}
                    onClick={() => {
                      this.tailbarHaah();
                    }}
                  >
                    <div
                      style={{
                        padding: " 15px 0 0 15px",
                      }}
                    >
                      {(layout_turul === 1 ||
                        songogdsonTsag.flag === "ajiltan_chuluu") && (
                        <p
                          style={{
                            fontStyle: "italic",
                            color: "var(--main-chosen-sidebar)",
                          }}
                        >
                          {i18n.t("timeline_" + songogdsonTsag.flag)}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          padding: "0px 15px 15px 15px",
                        }}
                      >
                        <p>
                          {i18n.t("hugatsaa")}:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {songogdsonTsag.ehleh} - {songogdsonTsag.duusah}
                          </span>
                        </p>

                        {!!songogdsonTsag.uilchilgee_ner && (
                          <p>
                            {i18n.t("uilchilgeenii_ner")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {songogdsonTsag.uilchilgee_ner}
                            </span>
                          </p>
                        )}
                        {!!songogdsonTsag.user_ner && (
                          <p>
                            {i18n.t("hereglegch_ner")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {songogdsonTsag.user_ner}
                            </span>
                          </p>
                        )}
                        {!!songogdsonTsag.user_utas && (
                          <p>
                            {i18n.t("hereglegch_utas")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {songogdsonTsag.user_utas}
                            </span>
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          width: "50%",
                          padding: "0px 15px 15px 15px",
                        }}
                      >
                        {!!songogdsonTsag.tailbar && (
                          <p style={{ lineHeight: 1.5 }}>
                            {i18n.t("tailbar")}:{" "}
                            <span style={{ fontStyle: "italic" }}>
                              {songogdsonTsag.tailbar}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <div style={{ position: "absolute" }}>
                    <div className="tsag-shugam">
                      {tsagMain.map((i, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              height: hemjee.morUndur,
                            }}
                          >
                            {index === 0 || index === tsagMain.length - 1 ? (
                              <div
                                style={{
                                  height: "100%",
                                }}
                              >
                                {i.minut >= 30 ? (
                                  <div
                                    className="tsag-shugam"
                                    style={{
                                      height: "100%",
                                    }}
                                  >
                                    {index !== 0 && (
                                      <div
                                        style={{
                                          height: "100%",
                                          width: hemjee.tsagUrgun * 30,
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderLeft:
                                              "1px solid var(--main-dark-blue)",
                                            height: "50%",
                                            position: "absolute",
                                            bottom: 0,
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        height: "100%",
                                        width:
                                          index === 0
                                            ? hemjee.tsagUrgun * (60 - i.minut)
                                            : hemjee.tsagUrgun * (i.minut - 30),
                                      }}
                                    >
                                      <div
                                        style={{
                                          borderLeft:
                                            i.minut === 30 || index !== 0
                                              ? "1px solid var(--main-dark-blue)"
                                              : 0,
                                          height: i.minut !== 0 ? "25%" : "50%",

                                          position: "absolute",
                                          bottom: 0,
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="tsag-shugam"
                                    style={{
                                      height: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: "100%",
                                        width:
                                          index === 0
                                            ? hemjee.tsagUrgun * (30 - i.minut)
                                            : hemjee.tsagUrgun * i.minut,
                                      }}
                                    >
                                      <div
                                        style={{
                                          borderLeft:
                                            i.minut === 0 || index !== 0
                                              ? "1px solid var(--main-dark-blue)"
                                              : 0,
                                          height: "50%",

                                          position: "absolute",
                                          bottom: 0,
                                        }}
                                      />
                                    </div>
                                    {index === 0 && (
                                      <div
                                        style={{
                                          height: "100%",
                                          width: hemjee.tsagUrgun * 30,
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderLeft:
                                              "1px solid var(--main-dark-blue)",
                                            height: "25%",

                                            position: "absolute",
                                            bottom: 0,
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  height: "100%",
                                }}
                              >
                                <div
                                  className="tsag-shugam"
                                  style={{
                                    height: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      width: hemjee.tsagUrgun * 30,
                                    }}
                                  >
                                    <div
                                      style={{
                                        borderLeft:
                                          "1px solid var(--main-dark-blue)",
                                        height: "50%",

                                        position: "absolute",
                                        bottom: 0,
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      height: "100%",
                                      width: hemjee.tsagUrgun * 30,
                                    }}
                                  >
                                    <div
                                      style={{
                                        borderLeft:
                                          "1px solid var(--main-dark-blue)",
                                        height: "25%",

                                        position: "absolute",
                                        bottom: 0,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="tsag-shugam">
                    {tsagDuurelt.map((utga, index) => {
                      return (
                        <Button
                          key={index}
                          disabled={utga.flag === "hooson" ? true : false}
                          className="timeline-btn"
                          onClick={() => {
                            this.tsagSongohod(utga, index);
                          }}
                          style={{
                            height: hemjee.morUndur,
                            width: hemjee.tsagUrgun * utga.urt - 2,
                            marginRight: 1,
                            marginLeft: 1,
                            background:
                              selectedIndex === index
                                ? ungu.songogdsonTsag
                                : ungu[utga.flag],
                            boxShadow:
                              utga.flag === "hooson"
                                ? "0px 0px 0px 0px rgb(0 0 0 / 15%)"
                                : "1px 1px 1px 0px rgb(0 0 0 / 15%)",
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="tsag-shugam">
                  {tsagMain.map((i, index) => {
                    return (
                      <div
                        key={index}
                        style={
                          index === tsagMain.length - 1
                            ? {
                                height: hemjee.morUndur,
                                left: tsagMain.length > 10 ? -5 : -8,
                              }
                            : {
                                width: hemjee.tsagUrgun * (60 - i.minut),
                                height: hemjee.morUndur,
                                left: -5,
                              }
                        }
                      >
                        {!(index === 0 && i.minut !== 0) && (
                          <p
                            style={{
                              fontSize:
                                tsagMain.length > 10
                                  ? togtmol.font * (12 / (allUrt / 60))
                                  : togtmol.font * 0.9,
                            }}
                          >
                            {i.tsag < 10 ? "0" : ""}
                            {i.tsag}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      );
    }
  }
}

export default UndsenComp;
