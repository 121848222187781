const helpers = {
  isExisty(value) {
    return value !== null && value !== undefined;
  },
  isEmpty(value) {
    return value === "";
  },
};
const shalgaltuud = {
  matchRegexp(values, value, regexp) {
    return (
      !helpers.isExisty(value) || helpers.isEmpty(value) || regexp.test(value)
    );
  },
  isZaaval(values, value) {
    return helpers.isExisty(value) && !helpers.isEmpty(value);
  },
  isNuutsug(values, value) {
    return shalgaltuud.matchRegexp(
      values,
      value,
      /^(?=.*[\d])(?=.*[a-zA-Z])[\w!@#$%^&*]{8,}$/
    );
  },
  isDanToo(values, value) {
    return shalgaltuud.matchRegexp(values, value, /^\d{0,}$/);
  },
  isDanText(values, value) {
    return shalgaltuud.matchRegexp(
      values,
      value,
      /^[\u0400-\u04FFa-zA-Z]{0,}$/
    );
  },
  isDanTextSpacetei(values, value) {
    return shalgaltuud.matchRegexp(
      values,
      value,
      /^[\u0400-\u04FFa-zA-Z\s-()_/]{0,}$/
    );
  },
  isTemdegttei(values, value) {
    return shalgaltuud.matchRegexp(
      values,
      value,
      /^[\u0400-\u04FF0-9a-zA-Z\s-()_/.,:]{0,}$/
    );
  },
  isTemdegtgui(values, value) {
    return shalgaltuud.matchRegexp(
      values,
      value,
      /^[\u0400-\u04FF0-9a-zA-Z\s-.,_]{0,}$/
    );
  },
  isEmail(values, value) {
    return shalgaltuud.matchRegexp(
      values,
      value,
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    );
  },
  isUtas(values, value) {
    return shalgaltuud.matchRegexp(values, value, /^\d{10,11}$/);
  },
  isTsag(values, value) {
    return shalgaltuud.matchRegexp(values, value, /^[\d:]{0,}$/);
  },
  isOgnoo(values, value) {
    return shalgaltuud.matchRegexp(values, value, /^[\d-]{0,}$/);
  },
  isMongo(values, value) {
    return shalgaltuud.matchRegexp(values, value, /^[\d.]{0,}$/);
  },
  isHuvi(values, value) {
    return shalgaltuud.matchRegexp(
      values,
      value,
      /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,3})?$)/
    );
  },
  isTokenId(values, value) {
    return shalgaltuud.matchRegexp(values, value, /^[A-Za-z0-9-_.+/=]*$/);
  },
  isLongitudeLatitude(values, value) {
    return shalgaltuud.matchRegexp(values, value, /^[+-\d.()]{0,}$/);
  },
};

const validationUrt = {
  sub_user_tentsuu: 13,
  too: 5,

  tailbar_bogino: 1000,
  tailbar_urt: 10000,
  bogino_string: 50,
  string: 150,
  urt_string: 250,
  turul_kod: 6,
  mongo: 8,
  tuluv: 30,
  utas: 11,
  utas_min: 10,
};
export { shalgaltuud, validationUrt };
