import React from "react";

const MyLoader = (props) => {
  const unshijBaigaa = props.unshijBaigaa ? props.unshijBaigaa : false;
  if (unshijBaigaa) {
    return (
      <div className="loading-background">
        <div className="loading-bar">
          <div className="loading-circle" />
        </div>
      </div>
    );
  }
  return null;
};

export default MyLoader;
