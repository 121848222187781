export const baiguullagaConstants = {
  UILCHILGEENII_JAGSAALT: "Uilchilgeenii jagsaalt",
  AJILTAN_JAGSAALT: "Ajiltan jagsaalt",
  SHIREE_JAGSAALT: "Shiree jagsaalt",
  AJILTAN_UILCHILGEE_JAGSAALT: "Ajiltan uilchilgee jagsaalt",
  SUBSC_JAGSAALT: "Subscription item jagsaalt",
  REPORT_ACTIVE_SUBSC_JAGSAALT: "Active subsc jagsaalt",
  REPORT_SOLD_SUBSC_JAGSAALT: "Sold subsc jagsaalt",
  REPORT_GET_SUBSC_JAGSAALT: "Get subsc jagsaalt",
};
