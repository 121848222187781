import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Husnegt from "components/Husnegt";
import { niitZahialgaJagsaaltFn } from "../../../store/actions/uilajillagaa.actions";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
import currency from "currency.js";

class Zahialga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: 0,
      medegdel: false,
      garchignuud: [
        {
          ner: "guilgeenii_turul",
          garchig: "guilgeenii_turul",
          turul: "guilgeenii_turul",
        },
        {
          ner: "created",
          garchig: "uusgesen_ognoo",
          turul: "ognoo_tsag",
        },
        {
          ner: "info",
          garchig: "info",
        },
        {
          ner: "gross_dun",
          garchig: "niit_mongon_dun",
          // turul: "date",
        },
        // {
        //   ner: "muj_shimtgel_dun",
        //   garchig: "tax_orlogo",
        // },
        {
          ner: "gst",
          garchig: "GST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "pst",
          garchig: "PST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "hst",
          garchig: "HST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "qst",
          garchig: "QST_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "state_tax",
          garchig: "state_tax_toshop",
          nuuh: true,
          ner_nemelt: "",
        },
        {
          ner: "local_tax",
          garchig: "local_tax_toshop",
          nuuh: true,
          // ner_nemelt: "",
        },
        {
          ner: "local_tax_rate",
          garchig: "local_tax_rate",
          turul: "huvi",
          nuuh: true,
          ner_nemelt: "",
        },
        //
        {
          ner: "baiguullaga_mungun_dun",
          garchig: "orlogo",
        },
        {
          ner: "orlogo_dun",
          garchig: "orlogo_niit",
        },
        // {
        //   ner: "zahialga_tuluv",
        //   garchig: "tuluv",
        //   turul: "tuluv",
        // },
        // {
        //   ner: "dussan_eseh",
        //   garchig: "dussan_eseh",
        //   // turul: "date",
        // },
        {
          ner: "cashbackbolson_eseh",
          garchig: "cashback_dugaar",
          lang: true,
        },
      ],
      garchignuud_delgerengui: [
        {
          ner: "dugaar",
          garchig: "id_garchig",
        },
        {
          ner: "huselt_gargasan_ognoo",
          garchig: "ognoo_huselt_gargasan",
          turul: "date",
        },
        {
          ner: "shiljuulsen_ognoo",
          garchig: "ognoo_shiljuulsen",
          turul: "date",
        },
        {
          ner: "tuluv",
          garchig: "tuluv",
          turul: "tuluv-tootsoo",
        },
        {
          ner: "mungu",
          garchig: "niit_mongon_dun_detail",
        },
        {
          ner: "shiljuulsen_dun",
          garchig: "shiljuulsen_dun_detail",
        },
        {
          ner: "shimtgel",
          garchig: "suutgal_detail",
        },
        {
          ner: "owner_to_note",
          garchig: "description",
        },
      ],
      niit_zahialga_jagsaalt: [],
      niit_zahialga_jagsaalt_utga: [],
      utguud: [],
      unshijBaigaa: false,
      niitToo: 1,
      songogdsonHuudasToo: 1,
      dialogTuluv: false,
      songogdsonMor: {},
      songogdsonMorDetail: [],
      layout_turul: LoginAuth.layoutTurulAvah(),
    };
    this.huudasSoligdohod = this.huudasSoligdohod.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
  }

  componentDidMount() {
    this.props.niitZahialgaJagsaaltFn(0);
    const info = LoginAuth.baiguullagaInfoAvah();
    if (info.tuluv !== "active") {
      this.props.history.push({ pathname: "/" });
    }
    let myGarchig = this.state.garchignuud;
    myGarchig.map((garchig) => {
      if (
        garchig.ner_nemelt !== undefined &&
        !!info[garchig.ner] &&
        Number(info[garchig.ner]) > 0
      ) {
        garchig.nuuh = false;
        garchig.ner_nemelt =
          "( " +
          currency(info[garchig.ner] * 100, { precision: 3, separator: "" }) +
          "% )";
      }
      if (garchig.ner === "local_tax" && info.uls_id === 3) {
        garchig.nuuh = false;
      }
      if (garchig.ner === "local_tax_rate" && info.uls_id === 3) {
        garchig.nuuh = false;
      }
      return null;
    });
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.niit_zahialga_jagsaalt !== state.niit_zahialga_jagsaalt) {
      let niit_zahialga_jagsaalt = !!props.niit_zahialga_jagsaalt
        ? props.niit_zahialga_jagsaalt
        : [];
      let myJagsaalt = niit_zahialga_jagsaalt.jagsaalt
        ? niit_zahialga_jagsaalt.jagsaalt
        : [];
      myJagsaalt.map((utga) => {
        let ungu =
          utga.cashbackbolson_eseh === "bolson"
            ? "var(--light-green-husnegt)"
            : utga.cashbackbolson_eseh === "hagas"
            ? "var(--light-blue-husnegt)"
            : "";
        utga.ungu = ungu;
        return null;
      });
      console.log("aani", myJagsaalt);
      hariu.niit_zahialga_jagsaalt = props.niit_zahialga_jagsaalt;
      hariu.niit_zahialga_jagsaalt_utga = myJagsaalt;
      hariu.utguud = myJagsaalt;
      hariu.niitToo = niit_zahialga_jagsaalt.too
        ? niit_zahialga_jagsaalt.too
        : 0;
    }
    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  huudasSoligdohod(huudas) {
    this.props.niitZahialgaJagsaaltFn(huudas);
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  husnegtTovchDaragdahad(utga) {
    let detailInfo = utga.tulbur_tootsoo_info ? utga.tulbur_tootsoo_info : [];
    detailInfo.map((utga) => {
      let detailUngu =
        utga.tuluv === "active" || utga.tuluv === "completed"
          ? "var(--light-green-husnegt)"
          : "";
      utga.detailUngu = detailUngu;
      return null;
    });
    this.setState({
      dialogTuluv: true,
      songogdsonMor: utga,
      songogdsonMorDetail: detailInfo,
    });
  }
  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor: {},
    });
  }
  render() {
    const {
      garchignuud,
      utguud,
      flag,
      medegdel,
      unshijBaigaa,
      niitToo,
      dialogTuluv,
      garchignuud_delgerengui,
      songogdsonMorDetail,
      songogdsonMor,
    } = this.state;
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                  </Alert>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    niitToo={niitToo}
                    huudas_soligdohod={this.huudasSoligdohod}
                    ongoor_ylgah={"ungu"}
                    tovchtoi_eseh={true}
                    tovch_nuhtsul={"niitguilgee"}
                    tovch={["tootsoo_delgerengui"]}
                    tovch_daragdahad={this.husnegtTovchDaragdahad}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Modal
          isOpen={dialogTuluv}
          toggle={this.dialogToggle}
          className="tom-modal"
        >
          <ModalHeader toggle={this.dialogToggle} />
          <ModalBody>
            <Row>
              <Col>
                <Husnegt
                  garchignuud={garchignuud_delgerengui}
                  utguud={songogdsonMorDetail}
                  ongoor_ylgah={"detailUngu"}
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  textAlign: "Right",
                  marginRight: 10,
                  fontSize: "130%",
                  color: "red",
                }}
              >
                {songogdsonMor.cashbackbolson_eseh === "hagas" ? (
                  <Label>
                    {i18n.t("tootsoonii_uldegdel")}:{" "}
                    {songogdsonMor.cashback_uldegdel}
                  </Label>
                ) : (
                  <div />
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { niit_zahialga_jagsaalt } = state.uilajillagaaReducer;
  return {
    niit_zahialga_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      niitZahialgaJagsaaltFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Zahialga)
);
