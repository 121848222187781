import React, { Component } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";
import LoginAuth from "services/LoginAuth";
import Husnegt from "components/Husnegt";
import { subscRepActiveFn } from "store/actions/baiguullaga.actions";
import MyLoader from "components/MyLoader";
class SubscActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTuluv: false,
      songogdsonMor: {},
      unshijBaigaa: false,

      validationHariu: {},
      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
      utguud: [],
      niitToo: 0,
      active_subsc_jagsaalt: [],
      garchignuud: [
        // {
        //   ner: "zurag_dugaar",
        //   garchig: "zurag",
        //   turul: "zurag",
        // },
        {
          ner: "ner",
          garchig: "ner",
        },
        {
          ner: "negj_une",
          garchig: "negj_une",
        },
        {
          ner: "sar_limit",
          garchig: "sar_limit",
        },
        {
          ner: "subsc_sar_too",
          garchig: "used_amount",
        },
      ],
    };
    this.huudasSoligdohod = this.huudasSoligdohod.bind(this);
  }

  componentDidMount() {
    this.setState({
      unshijBaigaa: true,
    });
    this.props.subscRepActiveFn(0).then((hariu) =>
      this.setState({
        unshijBaigaa: false,
      })
    );
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};

    if (props.active_subsc_jagsaalt !== state.active_subsc_jagsaalt) {
      let active_subsc_jagsaalt = props.active_subsc_jagsaalt
        ? props.active_subsc_jagsaalt
        : [];
      hariu.active_subsc_jagsaalt = active_subsc_jagsaalt;
      hariu.utguud = active_subsc_jagsaalt.jagsaalt;
      hariu.niitToo = active_subsc_jagsaalt.too;
    }

    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  huudasSoligdohod(huudas) {
    this.props.subscRepActiveFn(huudas);
  }
  render() {
    const { serviceSubsc_info, garchignuud, utguud, unshijBaigaa, niitToo } =
      this.state;
    if (serviceSubsc_info.subsc_service === false) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card className="card-user">
            <CardBody>
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    niitToo={niitToo}
                    huudas_soligdohod={this.huudasSoligdohod}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { active_subsc_jagsaalt } = state.baiguullagaReducer;
  return { active_subsc_jagsaalt };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ subscRepActiveFn }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscActive)
);
