import i18n from "hel/i18n";
import { shalgaltuud } from "./validation";
import moment from "moment";
import { togtmol } from "delgetsuud/zagvar";
import LoginAuth from "services/LoginAuth";
import tohirgoo from "tohirgoo";

export const validShalgah = (ner, utga, shalgalt) => {
  // ner , utga, shalgalt
  let isValid = true;
  let aldaa = "";
  if (utga === undefined || utga === null) {
    utga = "";
  }
  if (
    shalgalt !== undefined &&
    shalgalt.validation !== undefined &&
    shalgalt.validation.length > 0
  ) {
    shalgalt.validation.map((valid) => {
      const urDun = shalgaltuud[valid](ner, utga);
      if (urDun === false) {
        isValid = false;
        aldaa += i18n.t("validation_" + valid);
      }
      return urDun;
    });

    if (shalgalt.utgaMin !== undefined) {
      if (utga < shalgalt.utgaMin) {
        isValid = false;
        aldaa += i18n.t("validation_isUtgaMin", {
          urt: shalgalt.utgaMin,
        });
      }
    }
    if (shalgalt.utgaMax !== undefined) {
      if (utga > shalgalt.utgaMax) {
        isValid = false;
        aldaa += i18n.t("validation_isUtgaMax", {
          urt: shalgalt.utgaMax,
        });
      }
    }
    if (shalgalt.urtTentsuu !== undefined) {
      if (utga.length !== shalgalt.urtTentsuu) {
        isValid = false;
        aldaa += i18n.t("validation_isTentsuu", {
          urt: shalgalt.urtTentsuu,
        });
      }
    }
    if (shalgalt.urtMin !== undefined && shalgalt.urtMax !== undefined) {
      if (utga.length > shalgalt.urtMax || utga.length < shalgalt.urtMin) {
        isValid = false;
        aldaa += i18n.t("validation_isUrtHoorond", {
          urtMin: shalgalt.urtMin,
          urtMax: shalgalt.urtMax,
        });
      }
    } else {
      if (shalgalt.urtMax !== undefined) {
        if (utga.length > shalgalt.urtMax) {
          isValid = false;
          aldaa += i18n.t("validation_isUrtMax", {
            urt: shalgalt.urtMax,
          });
        }
      }
      if (shalgalt.urtMin !== undefined) {
        if (utga.length < shalgalt.urtMin) {
          isValid = false;
          aldaa += i18n.t("validation_isUrtMin", {
            urt: shalgalt.urtMin,
          });
        }
      }
    }
  }
  return { isValid: isValid, message: aldaa };
};

export const validEseh = (oroltuud, utguud) => {
  let isValid = true;
  oroltuud.map((orolt) => {
    const urDun = validShalgah(orolt.ner, utguud[orolt.ner], orolt.validations);
    if (urDun.isValid === false) {
      isValid = false;
    }
    return urDun;
  });
  return isValid;
};

export const validEsehByHariu = (state) => {
  //validationHariu
  let isValid = true;
  Object.values(state).map((valid) => {
    if (valid.isValid === false) {
      isValid = false;
    }
    return valid.isValid;
  });
  return isValid;
};

export const ylgaataiEseh = (shine, huuchin) => {
  let ylgaatai = true;
  if (JSON.stringify(shine) === JSON.stringify(huuchin)) {
    ylgaatai = false;
  }
  return ylgaatai;
};

export const tsagAvah = (turul) => {
  let hariu = "";
  const baiguullagaInfo = LoginAuth.baiguullagaInfoAvah();
  const timezone =
    !!baiguullagaInfo && !!baiguullagaInfo.tmzone
      ? baiguullagaInfo.tmzone
      : tohirgoo.timeZoneStr;
  const tzdate = new Date().toLocaleString("en-US", {
    timeZone: timezone,
  });
  const date = new Date(tzdate);
  if (turul === "odooTsag") {
    hariu = moment(date).format("HH:00");
  } else if (turul === "onoodor") {
    hariu = moment(date).format("YYYY-MM-DD");
  } else if (turul === "ognooHyzgaarMax") {
    hariu = moment(date).add(1, "years").format("YYYY-12-31");
  } else if (turul === "ognooHyzgaarMin") {
    const min_hyzgaar = togtmol.report_hyzgaar_min;
    hariu = moment(date).subtract(min_hyzgaar, "months").format("YYYY-MM-DD");
  } else if ("zahialgaAvahHonogLimit") {
    const odor = LoginAuth.baiguullagaInfoAvah().zahialga_avah_honog_limit;
    hariu = moment(date).add(odor, "days").format("YYYY-MM-DD");
  }
  return hariu;
};
export const tsagFormat = (tsag, format) => {
  let hariu = "";
  // const date = new Date("2021-01-01 " + tsag);
  hariu = moment(tsag, "HH:mm").format(format);
  return hariu;
};
export const ognooFormat = (ognoo, format) => {
  let hariu = "";
  const baiguullagaInfo = LoginAuth.baiguullagaInfoAvah();
  const timezone =
    !!baiguullagaInfo && !!baiguullagaInfo.tmzone
      ? baiguullagaInfo.tmzone
      : tohirgoo.timeZoneStr;
  const tzdate = new Date(ognoo).toLocaleString("en-US", {
    timeZone: timezone,
  });
  const date = new Date(tzdate);
  hariu = moment(date).format(format);
  return hariu;
};
export const ognooFormat_noTimezone = (ognoo, format) => {
  let hariu = "";
  const date = new Date(ognoo);
  console.log("*** ognoo format ***", ognoo," *** ", date);
  hariu = moment(date).format(format);
  return hariu;
};
