import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  Alert,
  Label,
} from "reactstrap";

import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { bindActionCreators } from "redux";

import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  baiguullagaBurtgelFn,
  burtgelNemeltJagsaaltFn,
} from "../../store/actions/burtgel.actions";
import { hereglegchMe } from "../../store/actions/hereglegch.actions";
import { togtmol } from "delgetsuud/zagvar";
import { validationUrt, validEseh } from "validation";
import LoginAuth from "services/LoginAuth";
import MyLoader from "components/MyLoader";
import MapPicker from "react-google-map-picker";
import tohirgoo from "tohirgoo";
class BaiguullagaBurtgel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      me: LoginAuth.meAvah(),
      medegdel: false,
      flag: 1,
      oroltuud: [
        {
          garchig: "baiguullaga_ner",
          ner: "ner",
          placeholder: "",
          validations: {
            validation: ["isTemdegtgui", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
        // {
        //   ner: "uilchilgeenii_turul",
        //   garchig: "uilchilgeenii_turul",
        //   turul: "songoh",
        //   songoh: "uilchilgeenii_turul",
        //   songolt_tailbar: "",
        //   validations: {
        //     validation: ["isZaaval"],
        //   },
        // },

        {
          garchig: "register_notes",
          ner: "register_notes",
          placeholder: "",
          validations: {
            validation: ["isDanTextSpacetei", "isZaaval"],
            urtMax: validationUrt.urt_string,
          },
        },
        {
          ner: "register_req_subsc",
          garchig: "register_req_subsc",
          turul: "switch",
        },
        {
          ner: "register_req_booking",
          garchig: "register_req_booking",
          turul: "switch",
        },
        {
          ner: "register_req_booking_unegui",
          garchig: "register_req_booking_unegui",
          turul: "switch",
        },
        {
          garchig: "register_req_booking_unegui_too",
          ner: "register_req_booking_unegui_too",
          placeholder: "",
          nuuh_flag: true,
          validations: {
            validation: ["isDanToo"],
            urtMax: validationUrt.bogino_string,
          },
        },
        {
          garchig: "utas_haragdah",
          ner: "utas",
          placeholder: "",
          validations: {
            validation: ["isUtas"],
          },
        },
        // {
        //   garchig: "email_tailbartai_garchig",
        //   ner: "email",
        //   placeholder: "",
        //   validations: {
        //     validation: ["isEmail", "isZaaval"],
        //     urtMax: validationUrt.string,
        //   },
        // },
        {
          garchig: "company_name",
          ner: "company_name",
          placeholder: "",
          validations: {
            validation: ["isTemdegtgui", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
        {
          garchig: "company_number",
          ner: "company_number",
          placeholder: "",
          validations: {
            validation: ["isTemdegtgui", "isZaaval"],
            urtMax: validationUrt.string,
          },
        },
        {
          ner: "uls",
          garchig: "uls",
          turul: "songoh",
          songolt_hevleh: "uls_ner",
          songoh: "uls",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          garchig: "local_tax_label",
          ner: "local_tax",
          placeholder: "",
          nuuh_flag: true,
          validations: {
            validation: ["isHuvi"],
          },
          warning_text: i18n.t("local_tax_warning"),
        },
        {
          ner: "muj",
          garchig: "muj",
          turul: "songoh",
          songoh: "muj",
          hamaarah: "muj",
          hamaarah_shalgalt: "uls",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          ner: "tsagiin_bus",
          garchig: "tsagiin_bus",
          songolt_hevleh: "utc_text",
          turul: "songoh",
          songoh: "tsagiin_bus",
          validations: {
            validation: ["isZaaval"],
          },
        },
        {
          garchig: "hayag",
          ner: "hayag",
          placeholder: "",
          validations: {
            validation: ["isTemdegttei", "isZaaval"],
            urtMax: validationUrt.urt_string,
          },
        },
        {
          garchig: "bairlal",
          ner: "bairlal",
          turul: "hayag",
          placeholder: "",
        },
      ],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      tom_turul_jagsaalt: [],
      muj_jagsaalt: [],
      uls_jagsaalt: [],
      tsagiin_bus_jagsaalt: [],
    };
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.urgeljluulehDarahad = this.urgeljluulehDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
    this.bairlalOorchlogdohod = this.bairlalOorchlogdohod.bind(this);
  }
  componentDidMount() {
    this.props.burtgelNemeltJagsaaltFn();
  }
  static getDerivedStateFromProps(props, state) {
    let hariu = {};
    if (props.tom_turul_jagsaalt !== state.tom_turul_jagsaalt) {
      let tom_turul_jagsaalt = props.tom_turul_jagsaalt
        ? props.tom_turul_jagsaalt
        : [];
      tom_turul_jagsaalt.sort((a, b) => (a.ner > b.ner ? 1 : -1));
      hariu.tom_turul_jagsaalt = tom_turul_jagsaalt;
    }
    if (props.muj_jagsaalt !== state.muj_jagsaalt) {
      let muj_jagsaalt = props.muj_jagsaalt ? props.muj_jagsaalt : [];
      hariu.muj_jagsaalt = muj_jagsaalt;
    }
    if (props.uls_jagsaalt !== state.uls_jagsaalt) {
      let uls_jagsaalt = props.uls_jagsaalt ? props.uls_jagsaalt : [];
      hariu.uls_jagsaalt = uls_jagsaalt;
    }
    if (props.tsagiin_bus_jagsaalt !== state.tsagiin_bus_jagsaalt) {
      let tsagiin_bus_jagsaalt = props.tsagiin_bus_jagsaalt
        ? props.tsagiin_bus_jagsaalt
        : [];
      hariu.tsagiin_bus_jagsaalt = tsagiin_bus_jagsaalt;
    }
    // Return null to indicate no change to state.
    return hariu ? hariu : null;
  }
  utgaOorchlogdohod(ner, utga, urDun) {
    let utguud = { ...this.state.utguud };
    // let myOrolt = this.state.oroltuud;
    utguud[ner] = utga;
    // if (ner === "uilchilgeenii_turul") {
    //   const layout_turul =
    //     !!utga && !!utga.layout_turul ? utga.layout_turul : 0;
    //   myOrolt.map((utga_o) => {
    //     if (utga_o.ner === "uilchilgeenii_turul") {
    //       utga_o.songolt_tailbar =
    //         layout_turul === 0
    //           ? ""
    //           : layout_turul === 1
    //           ? i18n.t("category_tailbar_paid")
    //           : i18n.t("category_tailbar_free");
    //     }
    //     return null;
    //   });
    // }
    if (ner === "register_req_booking" && utga === true) {
      utguud.register_req_booking_unegui = false;
    }
    if (ner === "register_req_booking_unegui" && utga === true) {
      utguud.register_req_booking = false;
    }
    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;
    this.setState({
      utguud,
      validationHariu,
    });
  }
  bairlalOorchlogdohod(lat, lng) {
    let utguud = { ...this.state.utguud };
    utguud["bairlal"] = { lat, lng };
    this.setState({
      utguud,
    });
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  urgeljluulehDarahad() {
    let utguud = { ...this.state.utguud };
    this.setState({ unshijBaigaa: true });
    if ((!utguud.uls || utguud.uls.id === 3) && !!!utguud.local_tax) {
      this.setState({
        medegdel: true,
        flag: 2,
        unshijBaigaa: false,
      });
    } else {
      if (!!utguud.utas && utguud.utas.length === 10) {
        utguud.utas = "1" + utguud.utas;
      }
      try {
        this.props.baiguullagaBurtgelFn(utguud).then((hariu) => {
          if (hariu.amjilt === true) {
            this.props.hereglegchMe().then((hariu) => {
              if (hariu.amjilt === true) {
                this.props.history.push({ pathname: "/" });
              } else {
                LoginAuth.garah();
                this.props.history.push({ pathname: "/login" });
              }
            });
          } else {
            this.setState({
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
        });
      } catch (aldaa) {
        this.setState({
          medegdel: true,
          flag: 1,
          unshijBaigaa: false,
        });
      }
    }
  }
  render() {
    const {
      flag,
      validationHariu,
      oroltuud,
      utguud,
      me,
      medegdel,
      unshijBaigaa,
      tom_turul_jagsaalt,
      muj_jagsaalt,
      uls_jagsaalt,
      tsagiin_bus_jagsaalt,
    } = this.state;
    if (!!me) {
      return <Redirect to="/" />;
    }
    return (
      <div className="deerees-zai">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Alert
              isOpen={medegdel}
              toggle={this.medegdelToggle}
              color={flag === 0 ? "success" : "danger"}
            >
              {flag === 0 && (
                <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
              )}
              {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
              {flag === 2 && <span>{i18n.t("validation_isHuvi")}</span>}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Card>
              <CardHeader>
                <CardTitle tag="h5" align="center">
                  {i18n.t("baiguullaga_medeelel")}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  {oroltuud.map((orolt, index) => {
                    if (orolt.turul === "hayag") {
                      const utga = !!utguud[orolt.ner]
                        ? utguud[orolt.ner]
                        : tohirgoo.bairlal;
                      return (
                        <Row key={index}>
                          <Col lg={1} md={0} sm="0" xs="0" />
                          <Col lg={10} md={11} sm="12" xs="12">
                            <br />
                            <p>{i18n.t("map_tailbar_text")}</p>
                            <MapPicker
                              // defaultLocation={utga}
                              defaultLocation={{
                                lat: parseFloat(utga.lat),
                                lng: parseFloat(utga.lng),
                              }}
                              mapTypeId="roadmap"
                              style={{ height: window.innerHeight * 0.4 }}
                              onChangeLocation={this.bairlalOorchlogdohod}
                              zoom={3}
                              apiKey={tohirgoo.mapKey}
                            />
                            <br />
                          </Col>
                        </Row>
                      );
                    } else {
                      return (
                        <div>
                          {orolt.ner === "register_req_subsc" && (
                            <Row>
                              <Col lg={1} md={0} sm="0" xs="0" />
                              <Col lg={10} md={11} sm="12" xs="12">
                                <Label>
                                  {i18n.t("solution_request_label")}
                                </Label>
                              </Col>
                            </Row>
                          )}
                          <GarchigtaiOrolt
                            id={orolt.ner}
                            key={index}
                            index={index}
                            orolt={orolt}
                            utgaAvah={this.utgaOorchlogdohod}
                            utga={utguud[orolt.ner]}
                            validationHariu={validationHariu[orolt.ner]}
                            uilchilgeenii_turul={tom_turul_jagsaalt}
                            muj={muj_jagsaalt}
                            uls={uls_jagsaalt}
                            tsagiin_bus={tsagiin_bus_jagsaalt}
                            hamaarah_data={{
                              muj: utguud.uls ? utguud.uls.id : 0,
                            }}
                            nuuh_nohtsol={{
                              local_tax: !utguud.uls || utguud.uls.id !== 3,
                              register_req_booking_unegui_too:
                                !utguud.register_req_booking_unegui,
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                  <Row>
                    <Col lg={1} md={0} sm="0" xs="0" />
                    <Col
                      lg={10}
                      md={11}
                      sm="12"
                      xs="12"
                      style={{ textAlign: "center" }}
                    >
                      {i18n.t("burtgel_nemelt_medeelel_avne_text")}
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        onClick={this.urgeljluulehDarahad}
                        disabled={!validEseh(oroltuud, utguud)}
                      >
                        {i18n.t("urgeljluuleh")}
                      </Button>
                    </div>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        round="true"
                        outline
                        onClick={() => {
                          LoginAuth.garah();
                          this.props.history.push({ pathname: "/login" });
                        }}
                      >
                        {i18n.t("garah")}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    tom_turul_jagsaalt,
    muj_jagsaalt,
    uls_jagsaalt,
    tsagiin_bus_jagsaalt,
  } = state.burtgelReducer;
  return {
    tom_turul_jagsaalt,
    muj_jagsaalt,
    uls_jagsaalt,
    tsagiin_bus_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      baiguullagaBurtgelFn,
      hereglegchMe,
      burtgelNemeltJagsaaltFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaiguullagaBurtgel)
);
