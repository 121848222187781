import axios from "axios";
import LoginAuth from "./LoginAuth";
import tohirgoo from "tohirgoo";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
const API_URL = tohirgoo.api.url;

const axiosApiInstance = axios.create({ baseURL: API_URL });
axios.interceptors.request.use(
  (config) => {
    // console.info("debug ", config);
    return config;
  },
  (error) => {
    console.error("debug ", error);
    return Promise.reject(error);
  }
);

axios.defaults.headers.common["Authorization"] =
  "JWT " + LoginAuth.getIdToken();

const API = () => {
  /*let token = "JWT " + LoginAuth.getIdToken();
  return axios.create({
    baseURL: API_URL,
    headers: { Authorization: token }
  });
*/

  // Request interceptor for API calls
  axiosApiInstance.interceptors.request.use(
    async (config) => {
      let token = "JWT " + LoginAuth.getIdToken();

      config.headers = {
        Authorization: token,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Response interceptor for API calls
  axiosApiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (error.response === undefined) return Promise.reject(error);

      const originalRequest = error.config;
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        let token = "JWT " + LoginAuth.getIdToken();
        // const access_token = await refreshAccessToken();
        axios.defaults.headers.common["Authorization"] = token;
        return axiosApiInstance(originalRequest);
      }

      if (error.response.status === 401) {
        LoginAuth.garah();
        history.push({
          pathname: "/login",
        });

        window.location.reload(false);
      }

      return Promise.reject(error);
    }
  );

  return axiosApiInstance;
};

export { API };
