import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Form,
  Alert,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";

import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import { nuutsUgSergeehFn } from "../../store/actions/hereglegch.actions";
import { togtmol } from "delgetsuud/zagvar";
import LoginAuth from "services/LoginAuth";
import ButsahTovch from "components/ButsahTovch";
import MyLoader from "components/MyLoader";

class NuutsugSergeeh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: LoginAuth.getIdToken(),
      medegdel: false,
      flag: 1,
      oroltuud: [
        {
          garchig: "nevtreh_hayag",
          turul: "number",
          ner: "nevtreh_hayag",
          placeholder: "utasnii_dugaar",
        },
      ],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
    };
    this.utgaOorchlogdohod = this.utgaOorchlogdohod.bind(this);
    this.urgeljluulehDarahad = this.urgeljluulehDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  utgaOorchlogdohod(ner, utga, urDun) {
    let utguud = { ...this.state.utguud };
    utguud[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      utguud,
      validationHariu,
    });
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }

  urgeljluulehDarahad() {
    const utguud = { ...this.state.utguud };
    this.setState({ unshijBaigaa: true });
    try {
      this.props
        .nuutsUgSergeehFn({
          turul: "2",
          ner: utguud.nevtreh_hayag,
          kod: "",
        })
        .then((hariu) => {
          if (hariu.amjilt === true) {
            this.props.history.push({
              pathname: "/nuutsug/batalgaajuul",
              state: {
                nevtreh_hayag: utguud.nevtreh_hayag,
              },
            });
          } else if (hariu.message === "not exists") {
            this.setState({
              medegdel: true,
              flag: 2,
              unshijBaigaa: false,
            });
          } else if (
            hariu.message !== undefined &&
            hariu.message.includes("error - with - ")
          ) {
            this.setState({
              medegdel: true,
              flag: 3,
              unshijBaigaa: false,
            });
          } else if (hariu.message === "limit") {
            this.setState({
              medegdel: true,
              flag: 5,
            });
          } else {
            this.setState({
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
        });
    } catch (aldaa) {
      this.setState({
        medegdel: true,
        flag: 1,
        unshijBaigaa: false,
      });
    }
    // this.props.history.push("/batalgaajuulah");
  }

  render() {
    const {
      flag,
      validationHariu,
      oroltuud,
      utguud,
      token,
      medegdel,
      unshijBaigaa,
    } = this.state;
    if (!!token) {
      LoginAuth.garah();
    }
    return (
      <div className="deerees-zai">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Alert
              isOpen={medegdel}
              toggle={this.medegdelToggle}
              color={flag === 0 ? "success" : "danger"}
            >
              {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
              {flag === 2 && <span>{i18n.t("medegdel_aldaa_burtgelgui")}</span>}
              {flag === 3 && (
                <span>{i18n.t("medegdel_aldaa_kod_tur_hulee")}</span>
              )}
              {flag === 5 && (
                <span>{i18n.t("medegdel_aldaa_kod_limit_utas")}</span>
              )}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="1" sm="1" xs="1" />
          <Col lg="6" md="10" sm="10" xs="10">
            <Card>
              <CardHeader>
                <ButsahTovch {...this.props} />
                <CardTitle tag="h5" align="center">
                  {i18n.t("nuutsug_sergeeh")}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  {oroltuud.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.utgaOorchlogdohod}
                        utga={utguud[orolt.ner]}
                        validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="ungu-primary"
                        style={{ width: togtmol.tovchnii_urgun + "px" }}
                        onClick={this.urgeljluulehDarahad}
                      >
                        {i18n.t("urgeljluuleh")}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      nuutsUgSergeehFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NuutsugSergeeh)
);
