import i18n from "hel/i18n";
import React, { Component } from "react";
import {
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";

import Husnegt from "components/Husnegt";
import GarchigtaiOrolt from "components/GarchigtaiOrolt";
import {
  ajiltanJagsaaltFn,
  ajiltanBurtgelFn,
} from "../../../store/actions/baiguullaga.actions";
import { validationUrt, ylgaataiEseh, validEseh } from "validation";
import MyLoader from "components/MyLoader";
import LoginAuth from "services/LoginAuth";
import { ajiltanZuragFn } from "store/actions/zurag.actions";
import { togtmol } from "delgetsuud/zagvar";

class Ajiltan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout_turul: LoginAuth.layoutTurulAvah(),
      flag: 0,
      medegdel: false,

      dialogTuluv: false,
      songogdsonMor: {},
      songogdsonIndex: 0,
      garchignuud: [
        // {
        //   ner: "profile_zurag_dugaar",
        //   img_folder: "staff",
        //   garchig: "zurag",
        //   turul: "zurag",
        //   validations: {
        //     validation: ["isZaaval"],
        //   },
        // },
        // {
        //   ner: "ovog",
        //   garchig: "ovog",
        //   validations: {
        //     validation: ["isZaaval", "isDanText"],
        //     urtMax: validationUrt.string,
        //   },
        // },
        {
          ner: "ner",
          garchig: "ner",
          validations: {
            validation: ["isZaaval", "isTemdegtgui"],
            urtMax: validationUrt.string,
          },
        },
        {
          ner: "tuluv",
          garchig: "tuluv",
          turul: "switch",
        },
        // {
        //   ner: "nickname",
        //   garchig: "nickname",
        //   validations: {
        //     validation: ["isZaaval", "isTemdegtgui"],
        //     urtMax: validationUrt.string,
        //   },
        // },
        // {
        //   ner: "utas",
        //   garchig: "utas",
        //   validations: {
        //     validation: ["isUtas"],
        //   },
        // },
        // {
        //   ner: "namtar",
        //   garchig: "namtar",
        //   turul: "textarea",
        //   validations: {
        //     validation: ["isZaaval", "isTemdegttei"],
        //     urtMax: validationUrt.tailbar_bogino,
        //   },
        // },
        // {
        //   ner: "ajiltan_detail_haruulna",
        //   garchig: "ajiltan_detail_haruulna",
        //   turul: "switch",
        // },
        {
          ner: "undsen_tulbur",
          garchig: "tsag_tulbur",
          nemelt_tailbar: LoginAuth.baiguullagaInfoAvah().temdegt,
          nuuh: !LoginAuth.serviceSubscAvah().preperation_fee_service,
          orolt_nuuh: !LoginAuth.serviceSubscAvah().preperation_fee_service,
          validations: {
            validation:
              LoginAuth.serviceSubscAvah().preperation_fee_service === false
                ? []
                : ["isZaaval", "isMongo"],
            utgaMin:
              LoginAuth.tulburinTurulAvah() === 2
                ? togtmol.tsag_tulbur_min
                : undefined,
            utgaMax:
              LoginAuth.tulburinTurulAvah() === 2
                ? togtmol.tsag_tulbur_max
                : undefined,
          },
        },
      ],
      ajiltan_jagsaalt: [],
      utguud: [],
      validationHariu: {},
      unshijBaigaa: false,
      erh_hyzgaar: LoginAuth.erhAvah() === "admin" ? false : true,
      countActive: 0,
      serviceSubsc_info: LoginAuth.serviceSubscAvah(),
    };
    this.modalUtgaOorchlogdohod = this.modalUtgaOorchlogdohod.bind(this);
    this.dialogToggle = this.dialogToggle.bind(this);
    this.husnegtTovchDaragdahad = this.husnegtTovchDaragdahad.bind(this);
    this.hadgalahDarahad = this.hadgalahDarahad.bind(this);
    this.medegdelToggle = this.medegdelToggle.bind(this);
  }

  componentDidMount() {
    this.setState({
      unshijBaigaa: true,
    });
    this.props.ajiltanJagsaaltFn().then((hariu) => {
      this.setState({
        unshijBaigaa: false,
      });
    });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.ajiltan_jagsaalt !== state.ajiltan_jagsaalt) {
      let ajiltan_jagsaalt = props.ajiltan_jagsaalt
        ? props.ajiltan_jagsaalt
        : [];
      let countActive = 0;
      ajiltan_jagsaalt.map((utga) => {
        if (utga.tuluv === "inactive") {
          utga.tuluv = false;
        } else {
          utga.tuluv = true;
          countActive++;
        }
        if (state.serviceSubsc_info.preperation_fee_service === false) {
          utga.undsen_tulbur = 0;
        }
        return null;
      });
      return {
        ajiltan_jagsaalt: props.ajiltan_jagsaalt,
        utguud: ajiltan_jagsaalt,
        countActive,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  modalUtgaOorchlogdohod(ner, utga, urDun) {
    let songogdsonMor = { ...this.state.songogdsonMor };
    songogdsonMor[ner] = utga;

    let validationHariu = this.state.validationHariu;
    validationHariu[ner] = urDun;

    this.setState({
      songogdsonMor,
      validationHariu,
    });
  }

  dialogToggle() {
    this.setState({
      dialogTuluv: !this.state.dialogTuluv,
      songogdsonMor:
        this.state.dialogTuluv === true ? {} : { ...this.state.songogdsonMor },
      validationHariu: {},
      medegdel: false,
    });
    this.props.ajiltanJagsaaltFn();
  }

  husnegtTovchDaragdahad(utga, index, tovch) {
    if (tovch === "zasah") {
      this.setState(
        {
          songogdsonIndex: index,
          songogdsonMor: utga,
        },
        function () {
          this.dialogToggle();
        }
      );
    } else if (tovch === "uilchilgee") {
      this.props.history.push({
        pathname: "/ajiltan/uilchilgee",
        state: {
          songogdsonMor: utga,
        },
      });
    }
  }
  async hadgalahDarahad() {
    let { songogdsonMor, serviceSubsc_info, countActive } = this.state;
    if (!!songogdsonMor.utga && songogdsonMor.utas.length === 10) {
      songogdsonMor.utas = "1" + songogdsonMor.utas;
    }
    this.setState({ unshijBaigaa: true });

    if (
      serviceSubsc_info.preperation_fee_service === false &&
      serviceSubsc_info.service_subsc_staff_count <= countActive &&
      songogdsonMor.tuluv === true
    ) {
      this.setState({
        dialogTuluv: false,
        songogdsonMor: {},
        validationHariu: {},
        unshijBaigaa: false,
        medegdel: true,
        flag: 2,
      });
    } else {
      try {
        // songogdsonMor.profile_zurag_dugaar = "9-404rizil07xjgit";
        if (
          !!songogdsonMor.profile_zurag_dugaar &&
          songogdsonMor.profile_zurag_dugaar.slice(0, 10) === "data:image"
        ) {
          const hariu = await this.props.ajiltanZuragFn(
            songogdsonMor.profile_zurag_dugaar
          );
          if (hariu.amjilt === true) {
            songogdsonMor.profile_zurag_dugaar = hariu.imageid;
          } else {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
        }
        this.props.ajiltanBurtgelFn(songogdsonMor).then((hariu) => {
          if (hariu.amjilt === true) {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 0,
              unshijBaigaa: false,
            });
            this.props.ajiltanJagsaaltFn();
          } else if (hariu.message === "hyzgaar error") {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 3,
              unshijBaigaa: false,
            });
          } else {
            this.setState({
              dialogTuluv: false,
              songogdsonMor: {},
              validationHariu: {},
              medegdel: true,
              flag: 1,
              unshijBaigaa: false,
            });
          }
        });
      } catch (aldaa) {
        console.log("aldaa ,", aldaa);
        this.setState({
          dialogTuluv: false,
          songogdsonMor: {},
          validationHariu: {},
          medegdel: true,
          flag: 1,
          unshijBaigaa: false,
        });
      }
    }
  }

  medegdelToggle() {
    this.setState({
      medegdel: !this.state.medegdel,
    });
  }
  render() {
    const {
      validationHariu,
      garchignuud,
      utguud,
      dialogTuluv,
      songogdsonMor,
      songogdsonIndex,
      flag,
      medegdel,
      unshijBaigaa,
      layout_turul,
      erh_hyzgaar,
      serviceSubsc_info,
    } = this.state;
    if (
      layout_turul === 1 ||
      serviceSubsc_info.tsag_zahialga_service === false ||
      erh_hyzgaar === true
    ) {
      return <Redirect to="/" />;
    }
    return (
      <div className="content">
        <MyLoader unshijBaigaa={unshijBaigaa} />
        <div>
          <Card>
            <CardBody>
              {serviceSubsc_info.tulbur_tsag_zahialga_service === true && (
                <Row>
                  <Col md="9" />
                  <Col md="3" align="right">
                    <Button
                      className="ungu-primary"
                      round="true"
                      outline
                      onClick={this.dialogToggle}
                    >
                      {i18n.t("nemeh")}
                    </Button>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Alert
                    isOpen={medegdel}
                    toggle={this.medegdelToggle}
                    color={flag === 0 ? "success" : "danger"}
                  >
                    {flag === 0 && (
                      <span>{i18n.t("medegdel_amjilt_burtgegdsen")}</span>
                    )}
                    {flag === 1 && <span>{i18n.t("medegdel_aldaa_1")}</span>}
                    {flag === 2 && (
                      <span>{i18n.t("medegdel_aldaa_staff_count")}</span>
                    )}{" "}
                    {flag === 3 && (
                      <span>{i18n.t("medegdel_aldaa_staff_hyzgaar")}</span>
                    )}
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Husnegt
                    garchignuud={garchignuud}
                    utguud={utguud}
                    tovchtoi_eseh={
                      serviceSubsc_info.tulbur_tsag_zahialga_service === true
                        ? true
                        : false
                    }
                    tovch={["zasah", "uilchilgee"]}
                    tovch_daragdahad={this.husnegtTovchDaragdahad}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        {dialogTuluv && (
          <Modal isOpen={dialogTuluv} toggle={this.dialogToggle}>
            <ModalHeader toggle={this.dialogToggle} />
            <ModalBody>
              {dialogTuluv === true && (
                <Form>
                  {garchignuud.map((orolt, index) => {
                    return (
                      <GarchigtaiOrolt
                        key={index}
                        index={index}
                        orolt={orolt}
                        utgaAvah={this.modalUtgaOorchlogdohod}
                        utga={songogdsonMor[orolt.ner]}
                        validationHariu={validationHariu[orolt.ner]}
                      />
                    );
                  })}
                </Form>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                className="ungu-primary"
                onClick={this.hadgalahDarahad}
                disabled={
                  !validEseh(garchignuud, songogdsonMor) ||
                  !ylgaataiEseh(songogdsonMor, utguud[songogdsonIndex])
                }
              >
                {i18n.t("hadgalah")}
              </Button>{" "}
              <Button color="secondary" onClick={this.dialogToggle}>
                {i18n.t("bolih")}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { ajiltan_jagsaalt } = state.baiguullagaReducer;
  return {
    ajiltan_jagsaalt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ajiltanJagsaaltFn,
      ajiltanBurtgelFn,
      ajiltanZuragFn,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Ajiltan)
);
